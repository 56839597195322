/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import DialogueBox from 'app/components/DialogueBox';
import CategorySetting from 'app/components/Settings/CategorySetting';
import CustomDataTable from 'app/components/data-table/customDataTable';
import { error, success } from 'app/components/toast';
import { getAccessibilitiesListing, removeAccessibility } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { Chip } from 'primereact/chip';
import { useEffect, useState } from 'react';
import CategorySettingsDialog from './categorySettingsDialog';

export interface IAccessibility {
  accessibilityid?: string;
  accessibilityName: string;
  description: string;
  isKey?: boolean;
}

export const accessibilityProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

const Accessibility = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [accessibilities, setAccessibilities] = useState<IAccessibility[]>([]);
  const [sortOrder, setSortOrder] = useState<number>(1);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState(null);

  const fetchAccessibilities = async () => {
    try {
      accessibilityProps.sortorder = sortOrder;
      const response = await getAccessibilitiesListing(accessibilityProps);
      if (response.status === 'Success') {
        response.data.accessibilitys &&
          response.data.accessibilitys.map((acc: any, i: number) => {
            acc.serial = i + 1;
          });
        setAccessibilities(response.data.accessibilitys);
        setLoading(false);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchAccessibilities();
  }, []);

  const onEditAccessibility = (row: any) => {
    setSelectedData(row);
    setShowDialog(true);
  };

  const onArchive = async (id: string) => {
    const body: any = {
      id,
    };
    try {
      setLoading(true);
      const response = await removeAccessibility(body);
      if (response.status === 'Success') {
        success('Accessibility archived successfully.');
        fetchAccessibilities();
        setLoading(false);
      }
    } catch (err) {
      error('Could not archive Accessibility.');
    } finally {
      setLoading(false);
    }
  };

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
      backgroundColor: '#F3F5F8',
      color: 'black',
      width: '80%',
    };
  };

  useEffect(() => {
    fetchAccessibilities();
  }, [sortOrder]);

  const typeTemplate = (row: any) => {
    return <Chip
      label={row.type || 'N/A'}
      className={`${row.type === 'Defect' ? 'bg-danger' : 'bg-success'} header-white`}
    />;
  };

  const columns: any = [
    {
      field: 'serial',
      header: '#',
      style: { minWidth: '100px' },
    },

    {
      field: 'accessibilityName',
      header: 'Name',
      style: { minWidth: '550px' },
    },
    {
      field: 'type',
      header: 'Type',
      style: { minWidth: '200px' },
      body: typeTemplate
    },
    // {
    //   field: 'actions',
    //   header: 'Actions',
    //   headerStyle,
    //   formatter: (cellContent: any, row: any) => (
    //     <div className='d-flex'>
    //       <button
    //         className='btn btn-icon btn-xs btn-primary me-2'
    //         onClick={() => onEditAccessibility(row)}
    //       >
    //         <img src={pencil} alt='pecil' />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className='row'>
        <div className='col-lg-4'>
          <div className='card card-custom h-100'>
            <div className='card-header'>
              <div className='card-title'>
                <div className='card-label'>Category Settings</div>
              </div>
            </div>
            <div className='card-body'>
              <CategorySetting />
            </div>
          </div>
        </div>
        <div className='col-lg-8'>
          <div className='card card-custom card-stretch gutter-b'>
            <div className='card-body pt-0 pb-3'>
              <CategorySettingsDialog
                visible={showDialog}
                onHide={(val: boolean) => {
                  setShowDialog(val);
                  setSelectedData(null);
                }}
                rowData={selectedData}
                categoryType='Accessibility'
                refresh={() => {
                  if (selectedData) {
                    setSelectedData(null);
                  }
                  fetchAccessibilities();
                }}
              />

              {/* <div className='d-flex justify-content-end'>
                <div className='pt-9'>
                  <button type='button' onClick={() => setShowDialog(true)} className='btn btn-primary'>
                    <span className='px-4'>Create </span>
                  </button>
                </div>
              </div> */}
              {
                <>
                  <CustomDataTable
                    data={accessibilities}
                    columns={columns}
                    isHideSearch
                    isShowActionColumns
                    isHideArchieveAction
                    onEdit={(id: string) => {
                      let data = accessibilities.find((x: any) => x._id === id);
                      onEditAccessibility(data);
                    }}
                  />
                </>
              }

              <DialogueBox
                title={'Accessibility Archive'}
                message={'Do you want  to archive?'}
                state={dialogueBox.flag}
                onClick={() => onArchive(dialogueBox.record)}
                handleClose={() => setDialogueBox({ flag: false, record: {} })}
              />
            </div>
          </div>
        </div>
      </div>
    );
};

export default Accessibility;
