import CustomDataTable from 'app/components/data-table/customDataTable';
import { error, success } from 'app/components/toast';
import { getAllModules, getOperativeAllPermissions, updateOperativeTypePermission } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from 'react';

const PermissionDetails = ({ id, operativeTypeEnum }: { id: string; operativeTypeEnum: number; }) => {

  const [permissionsData, setPermissionsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const getAllModulesList = async () => {
    const { data, status } = await getAllModules({
      sortproperty: 'createdAt',
      sortorder: 1,
      offset: 0,
      limit: 10,
      pageNum: 1,
      search: '',
      query: {
        critarion: {
          active: true,
        },
      },
    });

    if (status.toLowerCase() === 'success') {
      return data.modules;
    }

    return [];
  };

  const getAllOperativePermissions = async (id: number | string) => {
    const { data, status } = await getOperativeAllPermissions({
      operativeTypeId: id,
    });

    if (status.toLowerCase() === 'success') {
      return data;
    }
    return [];
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const modules = await getAllModulesList();
    const operativePermissions = await getAllOperativePermissions(id);

    const permissionData = operativePermissions.map((x: any) => {
      let moduledata = modules.find((y: any) => y._id === x.moduleId);
      return {
        ...x,
        name: moduledata ? moduledata.name : '',
        isChanged: false
      };

    });

    if (permissionData?.length) {
      setPermissionsData(permissionData);
    }
  };

  const onViewCheckChange = (e: any, rowData: any) => {
    let data = permissionsData.map((x: any) => {
      return {
        ...x,
        view: x.moduleId === rowData.moduleId ? e.checked : x.view,
        edit: x.moduleId === rowData.moduleId ? e.checked ? x.edit : false : x.edit,
        isChanged: x.moduleId === rowData.moduleId ? true : x.isChanged
      };
    });

    setPermissionsData(data);
  };

  const onEditCheckChange = (e: any, rowData: any) => {
    let data = permissionsData.map((x: any) => {
      return {
        ...x,
        edit: x.moduleId === rowData.moduleId ? e.checked : x.edit,
        isChanged: x.moduleId === rowData.moduleId ? true : x.isChanged
      };
    });

    setPermissionsData(data);
  };

  const viewTemplate = (rowData: any) => {
    return <Checkbox checked={rowData.view} onChange={(e) => onViewCheckChange(e, rowData)}></Checkbox>;
  };

  const editTemplate = (rowData: any) => {
    return <Checkbox checked={rowData.edit} onChange={(e) => onEditCheckChange(e, rowData)}></Checkbox>;
  };

  const columns = [
    {
      field: "name",
      header: "Modules"
    },
    {
      field: "view",
      header: "View",
      body: viewTemplate
    },
    {
      field: "edit",
      header: "Edit",
      body: editTemplate
    }
  ];

  const onUpdate = async () => {
    try {
      if (permissionsData.filter((x: any) => x.isChanged).length) {
        setLoading(true);
        let data: any = {
          operativeTypeId: id,
          modules: []
        };
        permissionsData.map((x: any) => {
          if (x.isChanged) {
            data.modules.push({
              "moduleId": x.moduleId,
              "view": x.view,
              "edit": x.edit
            });
          }
        });

        const res = await updateOperativeTypePermission(data);

        if (res?.status === "Success") {
          success("Permission updated successfully");
          fetchData();
        }
        else {
          error("Something went wrong, please try again later");
        }
        setLoading(false);
      }
    }
    catch (err: any) {
      setLoading(false);
      error("Something went wrong, please try again later");
    }
  };

  return (
    <>
      {permissionsData.length ? (
        <>
          {operativeTypeEnum !== 1 &&
            <div className='d-flex justify-content-end'>
              <Button
                type='button'
                onClick={onUpdate}
                icon={loading && 'pi pi-spin pi-spinner'}
                className="btn btn-primary"
                label={loading ? 'Please wait...' : 'Update Permission'}
              />
            </div>
          }

          <CustomDataTable data={permissionsData} columns={columns} isHideSearch />
        </>
      )
        :
        <p>Loading...</p>
      }
    </>
  );
};

export default PermissionDetails;
