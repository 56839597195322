import { SettingsRoutePath } from 'app/pages/settings/SettingsPage';
import { Link, useLocation } from 'react-router-dom';
import './Category.css';
const CategorySetting = () => {
  const linksArr = [
    { heading: 'Accessibilities', link: SettingsRoutePath.accessibility },
    { heading: 'Fire Barrier', link: SettingsRoutePath.fireBarrier },
    { heading: 'Installation', link: SettingsRoutePath.installation },
    { heading: 'Damper Type', link: SettingsRoutePath.damperType },
    { heading: 'Duct Types', link: SettingsRoutePath.ductType },
  ];

  let { pathname } = useLocation();
  return (
    <div>
      <div className='links_list'>
        <ul>
          {linksArr.map((item: any, i: number) => (
            <li key={i}>
              <Link to={item?.link} className={`${pathname === item?.link ? '' : 'links_link'}`}>
                {item?.heading}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CategorySetting;
