import React from 'react'
import {SettingsRoutePath} from 'app/pages/settings/SettingsPage'
import {Link, useLocation} from 'react-router-dom'
import './Category.css'
const ReportCategory = () => {
  const linksArr = [
    {heading: 'Guidance', link: SettingsRoutePath.reportGuidance},
    {heading: 'Service', link: SettingsRoutePath?.reportService},
    {heading: 'Disclaimer', link: SettingsRoutePath?.reportDisclaimer},
  ]

  let {pathname} = useLocation()
  return (
    <div>
      <div className='links_list'>
        <ul>
          {linksArr.map((item: any, i: number) => (
            <li key={i}>
              <Link
                to={item?.link}
                className={`${pathname === item?.link ? 'text-primary' : 'text-black'}`}
              >
                {item?.heading}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ReportCategory
