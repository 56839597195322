export const SET_REPORT_ID = 'SET_REPORT_ID'
export const SET_REPORT_DATA = 'SET_REPORT_DATA'
export const SET_CREATE_REPORT = 'SET_CREATE_REPORT'

export const actions = {
  setReportId: (data: any) => {
    return {
      type: SET_REPORT_ID,
      payload: data,
    }
  },
  setReportData: (data: any) => {
    return {
      type: SET_REPORT_DATA,
      payload: data,
    }
  },
  setCreateReport: (data: any) => {
    return {
      type: SET_CREATE_REPORT,
      payload: data,
    }
  },
}

const initialState = {
  reportId: null,
  reportData: null,
  createReport: false,
}

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_REPORT_ID:
      return {...state, reportId: action.payload}
    case SET_REPORT_DATA:
      return {...state, reportData: action.payload}
    case SET_CREATE_REPORT:
      return {...state, createReport: action.payload}
    default:
      return state
  }
}
