export const FilteredModules = (moduldePermision: any) => {
  let filter = moduldePermision?.moduleWisePermissions?.filter((module: any) => {
    const viewPermission = module.permissions.find(
      (permission: any) => permission.permissionName === 'view'
    )
    const editPermission = module.permissions.find(
      (permission: any) => permission.permissionName === 'edit'
    )
    return (
      viewPermission &&
      viewPermission.permissionAllowed === false &&
      editPermission &&
      editPermission.permissionAllowed === false
    )
  })
  return filter
}

export const GetPermissionsByName = (moduleName: string, moduldePermision: any) => {
  let requiredPermissions = ['view', 'edit']
  const module = moduldePermision?.moduleWisePermissions?.find(
    (item: any) => item.moduleName === moduleName
  )
  // If the module is found, filter its permissions based on the requiredPermissions
  if (module) {
    const filteredPermissions = module?.permissions?.filter((permission: any) =>
      requiredPermissions.includes(permission.permissionName)
    )

    // Use reduce to create an object with permissionName as keys and permissionAllowed as values
    const result = filteredPermissions?.reduce((acc: any, permission: any) => {
      acc[permission.permissionName] = permission?.permissionAllowed
      return acc
    }, {})

    return result
  }

  return null // Return null if the module is not found
}
