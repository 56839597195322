import DialogueBox from 'app/components/DialogueBox';
import { error, success } from 'app/components/toast';
import { getDefectSubCategories, removeDefectSubCategory } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { Column } from 'primereact/column';
import { DataTable, DataTableRowEvent } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import del from '../../../assets/icons/del-svg.svg';
import pencil from '../../../assets/icons/icon-park-outline_edit-two.svg';
const categoryArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

interface ISubCategory {
  id?: string;
  defectCategoryName: string;
}

interface ICategory {
  [x: string]: any;
  id: string;
  defectCategoryName: string;
  subCategories?: ISubCategory[];
  active?: string;
}

export default function ExpansionDataTable({
  handleEdit
}: {
  handleEdit: (categoryData: any, CategoryMainInfo: any) => void;
}) {
  const [isLoading, setLoading] = useState<boolean>(true);

  const [products, setProducts] = useState<ICategory[]>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);

  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });
  const toast = useRef<Toast>(null);

  const getCategories = async () => {
    const response = await getDefectSubCategories(categoryArgs);
    if (response.status === 'Success') {
      setProducts(response.data.defectCategorys);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { }, [products]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowExpand = (event: DataTableRowEvent) => {
    // toast.current.show({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
  };

  const onRowCollapse = (event: DataTableRowEvent) => {
    // toast.current.show({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
  };

  const expandAll = () => {
    let _expandedRows: any = {};

    products.forEach((p) => (_expandedRows[`${p._id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const formatCurrency = (value: number) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  // const amountBodyTemplate = (rowData: ISubCategory) => {
  //   return formatCurrency(rowData.defectCategoryName);
  // };

  // const statusOrderBodyTemplate = (rowData: ISubCategory) => {
  //   return <Tag value={rowData.status.toLowerCase()} severity={getOrderSeverity(rowData)}></Tag>;
  // };

  const onEditSubCategory = (rowData: any) => {
    let fetchCategory: any = [];
    products.filter((item: any) => {
      item.subCategories.filter((subItem: any) => {
        if (subItem._id === rowData._id) {
          fetchCategory = item;
        }
      });
    });
    handleEdit(rowData, fetchCategory);
  };

  const onRemove = (rowData: any) => {
    let fetchCategory: any = [];
    products.filter((item: any) => {
      item.subCategories.filter((subItem: any) => {
        if (subItem._id === rowData._id) {
          fetchCategory = item;
        }
      });
    });

    setDialogueBox({
      flag: true,
      record: {
        "defectCategoryId": fetchCategory._id,
        "defectSubCategoryId": rowData._id
      }
    });
  };

  const renderAction = (rowData: any) => {
    return (
      <span className='d-flex'>
        <button
          className='btn btn-icon btn-xs btn-primary me-2'
          data-toggle='tooltip'
          title='Edit'
          onClick={() => onEditSubCategory(rowData)}
        >
          {/* <i className='la la-pen' /> */}
          <img src={pencil} alt='pencil' />
        </button>
        <button
          className='btn btn-icon btn-xs btn-secondary'
          data-toggle='tooltip'
          title='Remove'
          onClick={() => onRemove(rowData)}
        >
          {/* <i className='la la-pen' /> */}
          <img src={del} alt='delete' />
        </button>
      </span>
    );
  };

  const rowExpansionTemplate = (data: ICategory) => {
    return (
      <div className='p-3'>
        <h5>Sub Categories of {data.defectCategoryName}</h5>
        <DataTable value={data.subCategories}>
          <Column field='defectType' header='Type' style={{ minWidth: '100px' }}></Column>
          <Column field='defectsubCatgTitle' header='Title' style={{ minWidth: '200px' }}></Column>
          <Column field='defectsubCatgText' header='Description' style={{ minWidth: '550px' }}></Column>
          <Column field='action' header='Action' body={renderAction} />
        </DataTable>
      </div>
    );
  };

  // const header = (

  // )

  const allowExpansion = (rowData: any) => {
    return rowData.subCategories && rowData.subCategories.length > 0;
  };

  const renderStatus = (rowData: ICategory) => {
    return <span>{rowData.active ? 'Active' : 'Inactive'}</span>;
  };

  const sortBy = (event: any) => {
    const { value } = event.target;

    if (value === '1') {
      const numDescending = [...products].sort((a, b) =>
        a.defectCategoryName.toLowerCase() > b.defectCategoryName.toLowerCase() ? 1 : -1
      );

      setProducts(numDescending);
    } else {
      const numAscending = [...products].sort((a, b) =>
        a.defectCategoryName.toLowerCase() > b.defectCategoryName.toLowerCase() ? -1 : 1
      );
      setProducts(numAscending);
    }
  };



  const onRemoveSubCategory = async (data: any) => {
    try {
      const res = await removeDefectSubCategory(data);
      if (res?.status?.toLowerCase() === 'success') {
        success("Sub-category deleted successfully");
        getCategories();
      }
      else {
        error(res?.message ?? "Something went wrong, please try again later");
      }
    }
    catch (err: any) {
      error("Something went wrong, please try again later");
    }
  };


  return (
    <div className='card'>
      <Toast ref={toast} />

      <DialogueBox
        title={'Settings Defect Sub-Category Remove'}
        message={'Do you want  to remove sub-category?'}
        state={dialogueBox.flag}
        onClick={() => {
          onRemoveSubCategory(dialogueBox.record);
        }}
        handleClose={() => setDialogueBox({ flag: false, record: {} })}
      />

      <DataTable
        value={products}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        // onRowExpand={onRowExpand}
        // onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey='_id'
        tableStyle={{ minWidth: '60rem' }}
      >
        <Column expander={allowExpansion} style={{ width: '5rem' }} />
        <Column field='defectCategoryName' header='Defect Category' sortable />
        <Column field='active' header='Status' sortable body={renderStatus} />
        {/* <Column header="Image" body={imageBodyTemplate} /> */}
        {/* <Column field="category" header="Category" sortable />
        <Column field="rating" header="Reviews" sortable body={ratingBodyTemplate} />
        <Column field="inventoryStatus" header="Status" sortable body={statusBodyTemplate} /> */}
      </DataTable>
    </div>
  );
}
