import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import './building.style.css';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 100%;
`;

const AddAssetsModal = ({
  // onCreate,
  onHide,
  loadingUplaod,
  setExcelFileModel,
  setuploadSingleAssetModal,
}: {
  asset: any;
  onHide: () => void;
  // onCreate: (values: any) => void
  loadingUplaod: boolean;
  setExcelFileModel: any;
  setuploadSingleAssetModal: any;
}) => {
  const { building } = useSelector((state: any) => state.job);
  const { id } = useParams<{ id: string; }>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingUpload, setLoadingUplaod] = useState<boolean>(false);

  // const initialValues = {
  //   buildingAssets: '',
  //   request: {
  //     buildingid: '',
  //   },
  // }

  useEffect(() => {
    setLoading(false);
  }, []);

  // const validationSchema: any = Yup.object({
  //   buildingAssets: Yup.string().required('Excel file is required'),
  // })

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   onSubmit: (values) => {
  //     onCreate(values)
  //   },
  // })

  useEffect(() => {
    setLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <form>
        <CardContainer>
          <div className='d-lg-flex justify-content-between'>
            <h3 className='card-title'>Upload Building Assets:</h3>
            <div className='form-group mb-8 d-lg-flex flex-row justify-content-end'>
              <button
                type='button'
                onClick={() => {
                  setExcelFileModel(true);
                  setuploadSingleAssetModal(true);
                  onHide();
                }}
                className='btn btn-primary mt-4 mt-=lg-0'
              >
                Upload Assets
              </button>
            </div>
          </div>
          <div className='form-group mb-8'>
            {/* <input
              type='file'
              placeholder='Choose excel sheet'
              onChange={(e) =>
                handleChange(e)
              }
            />
            {formik.touched.buildingAssets && formik.errors.buildingAssets ? (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.buildingAssets}</div>
              </div>
            ) : null} */}
          </div>

          <div className='form-group mb-8 d-lg-flex flex-row justify-content-between align-items-center'>
            <h3 className='card-title'>Create Building Asset :</h3>
            <button
              type='button'
              onClick={() => {
                setExcelFileModel(false);
                setuploadSingleAssetModal(true);
                onHide();
              }}
              className='btn btn-primary mt-4 mt-lg-0'
            >
              Add New Asset
            </button>
          </div>
        </CardContainer>
      </form>
    );
};

export default AddAssetsModal;
