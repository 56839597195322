import { toAbsoluteUrl } from '_metronic/helpers';
import LoaderComman from 'app/components/shared/loader.component';
import { getClientById } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import CreateClientRepModal from './CreateClientRepModal';

import CustomDataTable from 'app/components/data-table/customDataTable';
import { Dialog } from 'primereact/dialog';
import logoImg from '../../../assets/associate.png';

const StyledLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  width: 120px;
  line-height: 17px;
`;

const StyledValue = styled.label`
  font-weight: 400;
  font-size: 14px;
  padding-left:20px
  line-height: 17px;
`;

const StyledImage = styled.img`
  max-height: 200px;
  max-width: 200px;
  border-radius: 5px;
`;

const StyledRubberStampImage = styled.img`
  max-height: 200px;
  max-width: 200px;
  border-radius: 5px !important;
`;

const AssociationImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: contain;
`;

const StyledDiv = styled.div`
  padding: 0.5rem;
  border: 1pt solid lightgray;
  border-radius: 5px;
`;

const StyledClientRepImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: contain;
`;

export const clientArgs = {
  critarion: {
    _id: '',
  },
  clientUserFields: '_id email name',
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

const ViewClient = ({ clientId, onClose }: { clientId: string; onClose: () => void; }) => {
  const [clientData, setClientData] = useState<any>([]);
  const [Image, setImage] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [showClientRepModal, setClientRepModal] = useState<boolean>(false);
  const [repData, setRepData] = useState<any>({});
  const [associations, setAssociations] = useState([]);

  const history = useHistory();

  // const {clientId} = useSelector((state: any) => state.client)
  const getClientDetails = async (id: string) => {
    clientArgs.critarion._id = id;
    const response = await getClientById(clientArgs);
    const image = process.env.REACT_APP_IMAGE_URL + response.data.logoPath;
    setImage(image);
    setClientData(response.data);
    setLoading(false);

    let assoData = response.data?.associations?.map((x: any) => {
      return {
        ...x.associationId,
        memberShipId: x.memberShipId
      };
    });

    setAssociations(assoData);
  };

  const onLoadRepresentative = async (data: any) => {
    setRepData(data);
    onShowClientRepModal();
  };

  const onShowClientRepModal = (newRep: boolean = false) => {
    if (newRep) {
      setRepData({});
    }
    setClientRepModal(true);
  };

  const onHideClientRepModal = () => {
    setClientRepModal(false);
  };

  const getAllData = () => {
    getClientDetails(clientId);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const bodyTemplate = (rowData: any) => {
    return <img alt='Associaltion Logo' src={process.env.REACT_APP_IMAGE_URL + rowData.associationImage} height={30} width={75} />;
  };

  const columns = [
    {
      field: "associationImage",
      header: "Association Logo",
      body: bodyTemplate,
      style: { minWidth: '200px' }
    },
    {
      field: "associationName",
      header: "Association",
      style: { minWidth: '200px' }
    },
    {
      field: "memberShipId",
      header: "Membership ID",
      style: { minWidth: '200px' }
    }
  ];

  return (
    <LoaderComman isRender={!isLoading}>
      <div className='card card-custom'>
        <div className='card-body p-0'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-column flex-lg-row justify-content-center'>
              <div className='col-md-12 col-lg-8'>
                <div className='d-flex flex-column flex-root'>
                  <div className='d-flex flex-column flex-sm-row justify-content-between '>
                    <div className='d-flex flex-column  mb-sm-0'>
                      <div className='d-flex flex-column gap-4'>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Reference</StyledLabel>
                          <p>:</p>
                          <StyledValue>{clientData?.clientReference || 'No reference'}</StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Client Name</StyledLabel>
                          <p>:</p>
                          <StyledValue>{clientData.clientName}</StyledValue>
                        </div>
                        {/* <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Business Name</StyledLabel>
                          <p>:</p>
                          <StyledValue>{clientData.businessName || '---'}</StyledValue>
                        </div> */}
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Address</StyledLabel>
                          <p>:</p>
                          <StyledValue>{clientData?.address || 'Address not given'}</StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Phone</StyledLabel>
                          <p>:</p>
                          <StyledValue>{clientData?.phoneNumber || 'Phone not given'}</StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Email</StyledLabel>
                          <p>:</p>
                          <StyledValue>{clientData?.clientEmail || 'Email not given'}</StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Website</StyledLabel>
                          <p>:</p>
                          <StyledValue>{clientData?.website || 'Website not given'}</StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>Company Reg.</StyledLabel>
                          <p>:</p>
                          <StyledValue>
                            {clientData?.companyRegisterationNo || 'Company Registration not given'}
                          </StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4'>
                          <StyledLabel>VAT No.</StyledLabel>
                          <p>:</p>
                          <StyledValue>
                            {clientData?.VATNumber || 'VAT Number not given'}
                          </StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4 align-items-center'>
                          <StyledLabel>Brand Color 1</StyledLabel>
                          <p>:</p>
                          <StyledValue
                            style={{
                              backgroundColor: `${clientData?.themeColor}`,
                              width: '150px',
                              height: '50%',
                            }}
                          ></StyledValue>
                        </div>
                        <div className='d-flex flex-row mb-10 mb-sm-0 gap-4 align-items-center'>
                          <StyledLabel>Brand Color 2</StyledLabel>
                          <p>:</p>

                          <StyledValue
                            style={{
                              backgroundColor: `${clientData?.fontColor}`,
                              width: '150px',
                              height: '50%',
                            }}
                          ></StyledValue>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-lg-4 d-flex flex-column flex-wrap gap-4 '>
                <div className='d-flex flex-column gap-4 flex-wrap'>
                  <div className='d-flex flex-row flex-wrap'>
                    <div className='d-flex flex-column flex-wrap'>
                      <StyledLabel className='mb-4'>Logo</StyledLabel>
                      {/* <StyledImage
                    src={API_URL + clientData?.logoPath}
                  /> */}
                      {clientData.logoPath !== '' ? (
                        <StyledImage src={Image} />
                      ) : (
                        <StyledImage src={toAbsoluteUrl('/media/avatars/placeholder.jpg')} />
                      )}
                    </div>
                  </div>
                  <div className='d-flex flex-row flex-wrap'>
                    <div className='d-flex flex-column flex-wrap'>
                      <StyledLabel className='mb-4'>Rubber Stamp</StyledLabel>
                      {clientData.rubberStamp !== '' ? (
                        <StyledRubberStampImage src={process.env.REACT_APP_IMAGE_URL + clientData.rubberStamp} />
                      ) : (
                        <StyledRubberStampImage src={logoImg} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column justify-content-between align-items-start mt-10'>
              <button
                className='btn btn-primary btn-icon-primary align-right mb-4'
                data-target='tooltip'
                title='Create client representative'
                onClick={() => onShowClientRepModal(true)}
              >
                <i className='fas fa-plus text-white' />
              </button>
              <div className='d-flex'>
                <StyledLabel>Representative</StyledLabel>
              </div>

              <div className='col-md-12 col-lg-12 py-4 gap-4 d-flex flex-column flex-md-row flex-wrap'>
                {clientData?.clientRepresentatives?.length > 0 ? (
                  clientData?.clientRepresentatives.map((clientRep: any) => (
                    <RenderClientRep
                      client={clientRep}
                      onShowModal={() => onLoadRepresentative(clientRep)}
                    />
                  ))
                ) : (
                  <></>
                )}
                {/* <RenderClientRep client={{}} onShowModal={onShowClientRepModal} /> */}
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'><h3>Associations</h3></div>
            </div>
            <div className='row'>
              <CustomDataTable
                data={associations}
                columns={columns}
                isHideSearch
              />
            </div>
          </div>
        </div>

        <Dialog header="Client Representative" visible={showClientRepModal} onHide={onHideClientRepModal} style={{ width: '30vw' }} draggable={false}>
          <CreateClientRepModal
            onClose={onHideClientRepModal}
            clientId={clientId}
            getAllData={getAllData}
            editData={repData}
          />
        </Dialog>
      </div>
    </LoaderComman>
  );
};

export default ViewClient;

function RenderClientRep({ client, onShowModal }: { client: any; onShowModal?: () => void; }) {
  return (
    <StyledDiv className='col-md-3 gap-3 cursor-pointer' onClick={onShowModal}>
      <div className='d-flex flex-row flex-root align-items-center justify-content-start gap-4'>
        {/*<StyledClientRepImage src={API_URL + client.logoPath} alt='avatar' />*/}
        <div className='d-flex flex-column'>
          <span className='font-bold'>{client.clientRepresentativeName}</span>
          <span>{client.clientRepRef}</span>
        </div>
      </div>
    </StyledDiv>
  );
}
