import { error, success } from 'app/components/toast';
import {
  createClientRepresentative,
  getClientById,
  getReferences,
  updateClientRepresentative
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { UserProps, clientDetailedArgs } from './Clients';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: end;
  justify-content: end;
  width: fit-content;
`;

const StyledImage = styled.img`
  width: 45px;
  border-radius: 50%;
`;

const ProfilePicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #ccc;
`;

interface IAssociation {
  logoPath: File | string;
  writeup: string;
}

interface IClientRepresentative {
  clientRepresentativeName: string;
  buildings: any[];
  inspectionJobs: any[];
  clientRepRef: string;
  clientId: string;
}

const CreateClientRepModal = ({
  getAllData,
  clientId,
  onClose,
  editData,
}: {
  getAllData: () => void;
  clientId: string;
  onClose: () => void;
  editData: any;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [associations, setAssociations] = useState<IAssociation[]>([]);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [inProcess, setInProcess] = useState<boolean>(false);

  const { id: mainClientId } = useParams<any>();

  const validationSchema = Yup.object().shape({
    clientRepresentativeName: Yup.string().required(' Representative Name is required'),
    buildings: Yup.array().optional(),
    inspectionJobs: Yup.array().optional(),
    clientRepRef: Yup.string().required(' Representative Reference is required'),
  });

  const initialValues: IClientRepresentative = {
    clientRepresentativeName: '',
    buildings: [],
    inspectionJobs: [],
    clientRepRef: '',
    clientId: clientId,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setInProcess(true);
      let bodyData: any = formik.values;
      let response = null;

      if (isEdit) {
        const data: any = values;
        data.clientRepresentativeid = data._id;
        delete data._id;
        delete data.__v;
        delete data.active;
        delete data.addedby;
        response = await updateClientRepresentative(data);
      } else response = await createClientRepresentative(bodyData);
      if (response.status === 'Success') {
        success(` representative ${isEdit ? 'Updated' : 'Created'} successfully`);
        formik.resetForm();
        setEdit(false);
        setStatus({ success: true });
        setSubmitting(false);
        setInProcess(false);
        getAllData();
        onClose();
      } else {
        error('Something went wrong');
        setStatus({ success: false });
        setSubmitting(false);
        setInProcess(false);
      }
    },
  });

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      formik.setValues(editData);
      setEdit(true);
    }
  }, [editData]);

  const getClientRepDetails = async (id: string) => {
    clientDetailedArgs.critarion._id = id;
    const res = await getClientById(clientDetailedArgs);
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      let references = await getReferences({ getReferences: ["CR"] });
      formik.setFieldValue("clientRepRef", references.data["CR"]);
    };

    if (clientId) {
      if (!Object.keys(editData).length) {
        fetchData();
      }
      setLoading(true);
      getClientRepDetails(clientId);
    }
  }, []);

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body p-0'>
              <div className='row justify-content-center'>
                <div className='col-xl-12'>
                  <form
                    className='form fv-plugins-bootstrap fv-plugins-framework'
                    id='kt_form'
                    onSubmit={formik.handleSubmit}
                  >
                    <CardContainer className='card card-custom'>
                      <div className='row justify-content-center'>
                        <div className='row'>
                          {/* <div className="col-lg-12"> */}
                          <div className='col-lg-6'>
                            <div className='form-group row'>
                              <label htmlFor='' className='col-xl-12 col-lg-12 col-form-label required '>
                                Representative Name
                              </label>
                              <div className='col-lg-12 col-xl-12'>
                                <input
                                  type='text'
                                  className='form-control'
                                  {...formik.getFieldProps('clientRepresentativeName')}
                                />
                                {formik.errors.clientRepresentativeName &&
                                  formik.touched.clientRepresentativeName ? (
                                  <div className='text-danger'>
                                    {formik.errors.clientRepresentativeName}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <div className='form-group row'>
                              <label htmlFor='' className='col-xl-12 col-lg-12 col-form-label required '>
                                Reference
                              </label>
                              <div className='col-lg-12 col-xl-12'>
                                <input
                                  type='text'
                                  className='form-control'
                                  {...formik.getFieldProps('clientRepRef')}
                                  disabled
                                />
                                {formik.errors.clientRepRef && formik.touched.clientRepRef ? (
                                  <div className='text-danger'>{formik.errors.clientRepRef}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {/* </div> */}

                          <div className='col-12'>
                            <div className='separator separator-solid my-5' />
                            <div className='d-flex flex-row justify-content-end gap-4'>
                              <button className='btn btn-primary' type='submit'>
                                {!inProcess ? (
                                  !isEdit ? (
                                    'Save'
                                  ) : (
                                    'Update'
                                  )
                                ) : (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                              <button className='btn btn-dark' type='reset' onClick={onClose}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContainer>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default CreateClientRepModal;
