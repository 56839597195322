/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import DialogueBox from 'app/components/DialogueBox';
import CategorySetting from 'app/components/Settings/CategorySetting';
import CustomDataTable from 'app/components/data-table/customDataTable';
import { error, success } from 'app/components/toast';
import {
  createFireBarrier,
  getFireBarrierListing,
  removeFireBarrier,
  updateFireBarrier,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import CategorySettingsDialog from './categorySettingsDialog';
export interface IFireBarrier {
  fireBarrierid?: string;
  fireBarrierName: string;
  description: string;
  isKey?: boolean;
}

export const fireBarrierProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

const FireBarrier = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [fireBarriers, setFireBarriers] = useState<IFireBarrier[]>([]);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState(null);

  const initialValues: IFireBarrier = {
    fireBarrierName: '',
    description: '',
  };

  const validationSchema: any = Yup.object().shape({
    fireBarrierName: Yup.string().required('Fire Barrier Name is required'),
    // description: Yup.string().required('Description is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: IFireBarrier, { setSubmitting }) => {
      setSubmitting(true);
      onCreateFireBarrier(values);
    },
  });

  const fetchFireBarriers = async () => {
    try {
      fireBarrierProps.sortorder = sortOrder;
      const response = await getFireBarrierListing(fireBarrierProps);
      if (response.status === 'Success') {
        response.data.fireBarriers &&
          response.data.fireBarriers.map((fb: any, i: number) => {
            fb.serial = i + 1;
          });
        setFireBarriers(response.data.fireBarriers);
        setLoading(false);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchFireBarriers();
  }, []);

  const onCreateFireBarrier = async (values: IFireBarrier) => {
    try {
      setLoading(true);
      let response = null;
      if (isEditing) {
        response = await updateFireBarrier(values);
      } else {
        response = await createFireBarrier(values);
      }
      if (response.status === 'Success') {
        success(`Fire Barrier ${isEditing ? 'updated' : 'created'} successfully.`);
        fetchFireBarriers();
        formik.resetForm();
        setEditing(false);
        setLoading(false);
      }
    } catch (err) {
      error(`Could not ${isEditing ? 'update' : 'create'} Fire Barrier.`);
    } finally {
      setLoading(false);
    }
  };

  const onEditFireBarrier = (row: any) => {
    setEditing(true);
    setSelectedData(row);
    setShowDialog(true);
  };

  const handleCancel = () => {
    setEditing(false);
    formik.resetForm();
  };

  const onArchive = async (id: string) => {
    const body: any = {
      id,
    };
    try {
      setLoading(true);
      const response = await removeFireBarrier(body);
      if (response.status === 'Success') {
        success('Fire Barrier archived successfully.');
        fetchFireBarriers();
        setLoading(false);
      }
    } catch (err) {
      error('Could not archive Fire Barrier.');
    } finally {
      setLoading(false);
    }
  };

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    };
  };

  useEffect(() => {
    fetchFireBarriers();
  }, [sortOrder]);

  const columns: any = [
    {
      field: 'serial',
      header: '#',
      style: { minWidth: '50px' },
    },
    {
      field: 'fireBarrierName',
      header: 'Name',
      style: { minWidth: '800px' },
    },
  ];

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className='row'>
        <div className='col-lg-4'>
          <div className='card card-custom h-100'>
            <div className='card-header'>
              <div className='card-title'>
                <div className='card-label'>Category Settings</div>
              </div>
            </div>
            <div className='card-body'>
              <CategorySetting />
            </div>
            {/*<form onSubmit={formik.handleSubmit}>
              <div className='card-header'>
                <div className='card-title'>
                  <div className='card-label'>Create Fire Barrier</div>
                </div>
              </div>
              <div className='card-body'>
                <div className='form-group'>
                  <label htmlFor='fireBarrierName'>Fire Barrier Name</label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('fireBarrierName')}
                  />
                  {formik.touched.fireBarrierName && formik.errors.fireBarrierName ? (
                    <div className='text-danger'>{formik.errors.fireBarrierName}</div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='description'>Fire Barrier Description</label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className='text-danger'>{formik.errors.description}</div>
                  ) : null}
                </div>
              </div>
              <CardFooter form={formik} isEditing={isEditing} setEditing={setEditing} />
                  </form>*/}
          </div>
        </div>
        <div className='col-lg-8'>
          <div className='card card-custom card-stretch gutter-b'>
            <div className='card-body pt-0 pb-3'>

              <CategorySettingsDialog
                visible={showDialog}
                onHide={(val: boolean) => { setShowDialog(val); setSelectedData(null); }}
                rowData={selectedData}
                categoryType="FireBarrier"
                refresh={() => {
                  if (selectedData) {
                    setSelectedData(null);
                  }
                  fetchFireBarriers();
                }} />


              {/* <div className='d-flex justify-content-end'>
                <div className='pt-9'>
                  <button type='button' onClick={() => setShowDialog(true)} className='btn btn-primary'>
                    <span className='px-4'>Create </span>
                  </button>
                </div>
              </div> */}

              <CustomDataTable
                data={fireBarriers}
                columns={columns}
                isHideSearch
                isShowActionColumns
                isHideArchieveAction
                onEdit={(id: string) => {
                  let data = fireBarriers.find((x: any) => x._id === id);
                  onEditFireBarrier(data);
                }}
              />
              <DialogueBox
                title={'Fire Barrier Archive'}
                message={'Do you want  to archive?'}
                state={dialogueBox.flag}
                onClick={() => onArchive(dialogueBox.record)}
                handleClose={() => setDialogueBox({ flag: false, record: {} })}
              />
            </div>
          </div>
        </div>
      </div>
    );
};

export default FireBarrier;

function CardFooter({
  form,
  isEditing,
  setEditing,
}: {
  form: any;
  isEditing: boolean;
  setEditing: any;
}) {
  return (
    <div className='card-footer d-flex gap-4'>
      <button className='btn btn-primary' type='submit'>
        {!form.isSubmitting && (
          <span className='indicator-label'>{isEditing ? 'Update' : 'Create'}</span>
        )}
        {form.isSubmitting && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
      {isEditing && (
        <button
          className='btn btn-danger'
          type='reset'
          onClick={() => {
            setEditing(false);
            form.resetForm();
          }}
        >
          Cancel
        </button>
      )}
    </div>
  );
}
