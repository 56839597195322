import { error, success } from 'app/components/toast';
import {
  SET_ASSET_PAGE_DATA,
  SET_ASSET_REGISTER_DATA,
  SET_CERTIFICATE_DATA,
  SET_CREATE_INSPECTION_JOB_DATA,
  SET_DEFECT_LOG_DATA,
  SET_INVENTORY_DATA,
  SET_REPORT_HEADER_DATA,
  SET_SERVICES_DATA,
  SET_SUMMARY_DATA,
} from 'app/modules/auth/redux/create-report/actions';
import {
  UpdateBuildingAsset,
  getBuilding,
  getBuildingAssetByBuildingId,
  getBuildingSummaryCounts,
  getJobsListing,
  getReferences,
  onUploadBuildingAssets
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { BUILDING_DETAILS, SET_BUILDING_ID } from 'app/modules/auth/redux/jobs/actions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { buildingByIdArgs } from '../buildings/Buildings';
import { guidanceTemplateProps } from '../report-templates/guidance-templates';
import { serviceTemplateProps } from '../report-templates/service-templates';
import AddAssetsModal from './AddAssetsModal';
import { RoutePath } from './VaultPage';
import { AssetsTypesProps } from './modal';
// import { SET_ASSET_PAGE_DATA, SET_ASSET_REGISTER_DATA, SET_CERTIFICATE_DATA, SET_CLIENT_DETAILS, SET_CREATE_INSPECTION_JOB_DATA, SET_DEFECT_LOG_DATA, SET_GUIDANCE_DATA, SET_INVENTORY_DATA, SET_JOB_DETAILS, SET_OPERATIVES_COMPETENCES, SET_REPORT_HEADER_DATA, SET_SERVICES_DATA, SET_SUMMARY_DATA } from 'app/modules/auth/redux/create-report/actions';
import {
  createAssetRegister,
  createBuildingAsset,
  createBuildingAssetReport,
  createCompetencyReport,
  createDefectLog,
  createInspectionReport,
  createRemedialInspectionReport,
  createReportCertificate,
  createReportFooterTemplate,
  createReportGuidance,
  createReportHeaderTemplate,
  createReportInventory,
  createReportService,
  createReportSummary,
  getClientRepresentatives,
  // createReportFooterTemplate,
  // createRemedialInspectionReport,
  getReportGuidanceTemplateListing,
  getReportServiceTemplateListing,
  getVaults,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { clientRepresentativeArgs } from '../profile/Clients';
import { vaultArgs } from '../vault';
// import { saveToStorage,getOperativesByIds,getRemedialReportSummary,getRemedialReportActionLog,getFromStorage } from 'common/fetch-data';
import CustomModal from 'app/components/CustomModal';
import DialogueBox from 'app/components/DialogueBox';
import ImageUploader from 'app/components/ImageUploader';
import LoaderComman from 'app/components/shared/loader.component';
import { SET_ADD_ASSETS, SET_SAVED } from 'app/modules/auth/redux/vault';
import {
  getFromStorage,
  getNextTestDate,
  getRemedialReportActionLog,
  getRemedialReportSummary,
  onPublishHeaderRemedial,
  onSaveRemedialActionLog,
  onSaveRemedialCertificate,
  onSaveRemedialCompetency,
  onSaveRemedialFooter,
  onSaveRemedialSummary,
  onSaveReportToLibrary,
  removeEveryKey,
  saveToStorage,
} from 'common/fetch-data';
import { COMMON } from 'constants/commonConstant';
import { useFormik } from 'formik';
import { GetPermissionsByName } from 'hooks/usePermissionAcess';
import { Badge } from 'primereact/badge';
import { RadioButton } from 'primereact/radiobutton';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import AssetsDataTable from '../../components/data-table/customDataTable';
import PreviewReportDialog from '../jobs/previewReportDialog';

export interface ParamsProps {
  id: string;
}
export const buildingsArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: 'buildingName',
  },
};

const buildingDetailsArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 2,
  query: {
    critarion: {
      active: true,
    },
    clientFields: 'clientName',
    clientRepresentativeFields: 'clientRepresentativeName',
    facilityManagerFields: 'facilityManagerName',
    addedby: ' email name',
    lastModifiedBy: ' email name',
  },
};

export const testRegimeArgs = {
  critarion: {
    _id: '',
  },
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

export interface CreateBuildingProps {
  _id?: string;
  buildingid?: string;
  // vaultId: string;
  buildingReference: string;
  buildingName: string;
  buildingAddress: string;
  client: string;
  facilityManager: string;
  clientRepresentative: string;
  testDate?: string;
  // testStatus: string;
  testRegime: string;
  buildingLocation: {
    type: 'Point';
    coordinates: [number, number];
  };
}

export interface LocationProps {
  lat: number;
  lng: number;
  address?: string;
}

export interface IReportProps {
  competencies?: any[];
  header?: any;
  certificate?: any;
  assetRegister?: any[];
  defectLog?: any[];
  inventory?: any;
  assetPage?: any[];
  summary?: any;
  service?: any;
}

const initialSummaryData: any = {
  noOfDirtyDampers: '0',
  noOfModerateDampers: '0',
  noOfCleanDampers: '0',
  totalNumberOfDampers: '0',
  noOfeClass: '0',
  noOfsClass: '0',
  noOfesClass: '0',
  noOfiClass: '0',
  noOfCompliantDampers: '0',
  noOfAdvisorNotes: '0',
  noOfNonCompliantDampers: '0',
  noOfUntestedDampers: '0',
  noOfDmpsAccPnlBothSides: '0',
  noOfDmpsPanlOneSide: '0',
};
const BuildingDetails = ({ }: {
  // onHide:()=>void
  // onCreate: (values: any) => void,
}) => {
  const { reportCertificateData } = useSelector((state: any) => state.reportHeader);
  const { isaddAsset } = useSelector((state: any) => state.vault);
  const [loadingUplaod, setLoadingUpload] = useState<boolean>(false);

  const [vaults, setVaults] = useState<any>([]);

  const [sortProperty, setSortProperty] = useState<any>({
    active: true,
  });
  const history = useHistory();
  const { id } = useParams<ParamsProps>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [building, setBuilding] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [assetsTypes, setAssetsTypes] = useState<AssetsTypesProps[]>([]);
  const [assetsMasterData, setAssetsMasterData] = useState<AssetsTypesProps[]>([]);
  const [uploadsModalVisible, setUploadsModalVisible] = useState<any>(false);
  const [assetPageData, setAssetPageData] = useState<any>(null);
  const [inventoryData, setInventoryData] = useState<any>(null);
  const [guidanceData, setGuidanceData] = useState<any>(null);
  const [competenciesData, setCompetenciesData] = useState<any>(null);
  const dispatch = useDispatch();
  const { buildingId } = useSelector((state: any) => state.job);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [certificateData, setCertificateData] = useState<any>(null);
  const [summaryData, setSummaryData] = useState<any>(initialSummaryData);
  const [headerData, setHeaderData] = useState<any>(null);
  const [defectLogData, setDefectLogData] = useState<any>(null);
  const [assetRegisterData, setAssetRegisterData] = useState<any>(null);
  const [servicesData, setServicesData] = useState<any>(null);
  const [uploadSingleAssetModal, setuploadSingleAssetModal] = useState<any>(false);
  const [excelFileModel, setExcelFileModel] = useState<any>(false);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });
  const { issaved } = useSelector((state: any) => state.vault);
  const [lastDate, setLastDate] = useState<any>([]);

  const [previewDialog, setPreviewDialog] = useState<boolean>(false);
  const [defectData, setDefectData] = useState<any>(null);


  const [isChanged, setChanged] = useState(false);
  const [isTableLoading, setTableLoading] = useState(false);
  const [sortField, setSortField] = useState('createdAt');

  const [dataFilter, setDataFilter] = useState('All');

  const handleAddAssets = () => {
    dispatch({
      type: SET_ADD_ASSETS,
      payload: false,
    });
  };

  const getDefects = (buildingAssets: any): number => {
    let numberOfDefects = 0;

    COMMON.defectTypes.forEach((x) => {
      numberOfDefects += buildingAssets[x]?.defects?.length
        ? buildingAssets[x]?.defects.filter((x: any) => !x.defectRectification.defectRectified)
          .length
        : 0;
    });

    if (buildingAssets?.tests?.length) {
      buildingAssets?.tests?.map((x: any) => {
        numberOfDefects += x?.defects?.length
          ? x?.defects.filter((x: any) => !x.defectRectification.defectRectified)
            .length
          : 0;
      });
    }

    return numberOfDefects;
  };

  const getBuildingDetails = async (isFilter: boolean = false) => {
    buildingByIdArgs.critarion._id = id;
    dispatch({
      type: SET_BUILDING_ID,
      payload: id,
    });
    const response = await getBuilding(buildingByIdArgs);

    if (response.status === 'Success') {
      dispatch({
        type: BUILDING_DETAILS,
        payload: response.data,
      });

      await getAssets();

      const summaryResponse = await getBuildingSummaryCounts({ "buildingId": id });

      if (summaryResponse?.status === 'Success') {
        setDefectData(summaryResponse.data);
      }

      if (isFilter) {
        let data = response.data.active ? response.data : [];
        setBuilding(data);
      }

      return response.data.active ? response.data : null;
    }
    error('Something went wrong, please try again later.');

    return null;
  };

  const getAssets = async () => {
    const config = {
      "sortproperty": sortProperty,
      "sortorder": sortOrder,
      "_id": id
    };

    setTableLoading(true);

    const assetsResponse = await getBuildingAssetByBuildingId(config);
    setTableLoading(false);

    let activeData = assetsResponse?.data?.buildingAssets?.filter((res: any) => res.active === true);
    activeData = activeData?.length
      ? activeData.map((x: any) => {
        let totalDefects = 0;
        totalDefects += getDefects(x);

        return {
          ...x,
          numberOfDefects: totalDefects,
          jobName: x.inspectionJob ? x.inspectionJob.jobReference : '',
          assetLocation: x.assetLocation ? x.assetLocation : 'N/A',
        };
      })
      : [];

    let arr: any = [];
    assetsResponse?.data?.buildingAssets.map((d: any) => {
      if (d.tests.length > 0) {
        let lasttest = d.tests[d.tests.length - 1];
        arr.push(lasttest.testAddedOn);
      }
    });
    setLastDate(arr);
    setAssetsTypes(activeData);
    setAssetsMasterData(activeData);
  };

  const getVaultListing = async () => {
    const vaultData = await getVaults(vaultArgs);
    return vaultData.data.vaults;
  };

  const getClientRepresentativesListing = async () => {
    const clientReps = await getClientRepresentatives(clientRepresentativeArgs);
    return clientReps.data.clientRepresentatives;
  };

  const getAllData = async () => {
    setLoading(true);
    const [building] = await Promise.all([
      getBuildingDetails(),
      // getClientsListing(),
      // getFacilityManagersListing(),
      // getVaultListing(),
      // getClientRepresentativesListing(),
      // getBuildingListing(),
    ]);

    setBuilding(building);
    setLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (issaved === true)
      dispatch({
        type: SET_SAVED,
        payload: false,
      });
  }, [issaved]);

  const navigate = (id: number) => {
    history.push(generatePath(RoutePath.assetsDetails, { id }));
  };

  const handleNavigate = (row: any) => {
    navigate(row._id);
  };

  const permissionData = useSelector((state: any) => state.auth);
  let moduldePermision = permissionData?.user?.userPersonalPermissions;
  let vlt: any = GetPermissionsByName('Vault Access', moduldePermision);
  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    };
  };

  const refBodyTemplate = (row: any) => {
    return (
      <span onClick={() => handleNavigate(row)} className='d-flex cursor-pointer ps-3 text-primary'>
        {row?.assetReference ? row?.assetReference : 'N/A'}
        {row?.duplicate && <Badge value="Duplicate" severity="danger" className='ms-2'></Badge>}
      </span>
    );
  };

  const testStatusBodyTemplate = (row: any) => {
    let lasttest = row?.tests[row.tests.length - 1];
    return row?.tests?.length > 0 ? (
      <span>{moment(lasttest?.testDate).format('DD-MM-YYYY')}</span>
    ) : (
      'N/A'
    );
  };

  const assetsColumns = [
    {
      field: 'assetReference',
      header: 'Reference',
      body: refBodyTemplate,
      style: { minWidth: '150px' },
      sortable: true,
    },
    {
      field: 'assetLocation', header: 'Location', style: { minWidth: '400px' },
      sortable: true,
    },
    {
      field: 'jobName', header: 'Job Reference', style: { minWidth: '350px' },
      sortable: true,
    },
    {
      field: 'testStatus',
      header: 'Last Test Date',
      body: testStatusBodyTemplate,
      style: { minWidth: '300px' },
      sortable: true,
    },
    {
      field: 'numberOfDefects', header: 'Defects', style: { minWidth: '250px' },
      sortable: true,
    },
  ];

  const onUploadAssets = async (data: any) => {
    data.buildingid = buildingId;
    const formData = new FormData();
    formData.append('inspectionJob', data.inspectionJob);
    formData.append('buildingid', data.buildingid);
    formData.append('buildingAssets', data.buildingAssets);
    setLoadingUpload(true);
    const res = await onUploadBuildingAssets(formData);
    setLoadingUpload(false);
    setuploadSingleAssetModal(false);
    handleAddAssets();
    if (res.status === 'Success') {
      success(`Assets uploaded successfully`);
      getAllData();
    }
  };

  const onShowArchivedList = () => {
    history.push(RoutePath.archivedAssets);
  };

  const removeAsset = async (data: any) => {
    try {
      setLoading(true);
      // const body = {
      //   buildingId: id,
      //   // "inspectionJobId": "63b71278164e0061748bc923",
      //   id: data._id,
      // }
      // await removeBuildingAsset(body)
      const body = {
        buildingAssetid: data._id,
        // active: false,
        assetArchived: true,
        previouslyChangedBy: data.lastModifiedBy._id
          ? data.lastModifiedBy._id
          : data.lastModifiedBy,
        previouslyChangedOn: data?.updatedAt,
        lastOperation: 'removeBuildingAsset',
        previousOperation: data.lastOperation || '',
      };
      await UpdateBuildingAsset(body);
      success('Asset Remove Successfully');
      await getBuildingDetails();
      setLoading(false);
    } catch (e) { }
  };

  const getGuidanceTemplate = async () => {
    const args: any = guidanceTemplateProps;
    const res = await getReportGuidanceTemplateListing(args);

    const { guidanceTitle, regulationLaws, regulationsStandands } = res.data.guidanceTemplates[0];
    const data: any = {
      guidanceTitle: 'Guidance',
      regulationLaws: regulationLaws.map((item: any) => {
        delete item._id;
        return item;
      }),
      regulationsStandands: regulationsStandands.map((item: any) => {
        delete item._id;
        return item;
      }),
    };
    onSaveGuidance(data);
  };

  const getServiceTemplates = async () => {
    const args: any = serviceTemplateProps;
    const res = await getReportServiceTemplateListing(args);
    delete res.data.serviceTemplates[0]._id;
    delete res.data.serviceTemplates[0].__v;
    delete res.data.serviceTemplates[0].createdAt;
    delete res.data.serviceTemplates[0].updatedAt;
    delete res.data.serviceTemplates[0].addedby;
    res.data.serviceTemplates[0].serviceBodies.map((body: any) => {
      delete body._id;
    });
    dispatch({
      type: SET_SERVICES_DATA,
      payload: res.data.serviceTemplates[0],
    });
    // setReportData({
    //   ...reportData,
    //   service: res.data.serviceTemplates[0]
    // });
    setServicesData(res.data.serviceTemplates[0]);
    saveToStorage('services', res.data.serviceTemplates[0]);
  };

  const jobArgs: any = {
    critarion: {
      _id: '',
    },
    clientFields:
      'clientName clientEmail address phoneNumber logoPath website VATNumber companyRegisterationNo themeColor fontColor associations memberShipNumber',
    clientRepresentativeFields: 'clientRepresentativeName',
    facilityManagerFields: 'facilityManagerName',
    opperativesFields: 'operativeName',
    buildingFields:
      'buildingReference buildingName buildingAddress testDate testStatus inspectionJobs buildingAssets buildingLocation testRegime',
    buildingAssetsFields: '',
    addedby: '_id email name',
    lastModifiedBy: '_id email name',
  };

  const onSaveHeader = async (clientDetails: any) => {
    const data = {
      clientName: clientDetails.clientName,
      clientPhone: clientDetails.phoneNumber,
      clientEmailAddress: clientDetails.clientEmail,
      clientWebsite: clientDetails.website,
    };
    dispatch({
      type: SET_REPORT_HEADER_DATA,
      payload: data,
    });
    await saveToStorage('header', data);
    // setReportData({
    //   ...reportData,
    //   header: data
    // });
    setHeaderData(data);
  };

  const onPublishHeader = async (data: any) => {
    const headerData = getFromStorage('header');
    const res = await createReportHeaderTemplate(headerData);
    if (res.status === 'Success') {
      const headerId = res.data._id;
      return res.data;
    }
  };

  const onSaveCertificate = async (job: any) => {
    const data: any = {
      address: job.building.buildingAddress,
      phone: job.client.phoneNumber,
      email: job.client.clientEmail,
      client: job.client.clientName,
      associations: job.client.associations,
      themeColor: job.client.themeColor,
      fontColor: job.client.fontColor,
      website: job.client.website,
      logopath: job.client.logoPath,
      jobref: job.jobReference,
      facilitymanager: job.facilityManager.facilityManagerName,
      buildingReference: job.building.buildingReference,
      buildingName: job.building.buildingName,
      clientRepresentative: job.clientRepresentative.clientRepresentativeName,
      testRegime: job.building.testRegime,
      membershipNumber: job.membershipNumber || '',
      operatives: job.operatives || [],
      inspectionDate: moment(Date.now()).format('YYYY-MM-DD'),
    };
    dispatch({
      type: SET_CERTIFICATE_DATA,
      payload: data,
    });
    saveToStorage('certificate', data);
    // setReportData({
    //   ...reportData,
    //   certificate: data
    // });
    setCertificateData(data);
  };

  const onSaveGuidance = async (data: any) => {
    // dispatch({
    //   type: SET_GUIDANCE_DATA,
    //   payload: data
    // });
    setGuidanceData(data);
    saveToStorage('guidance', data);
  };

  const onSaveAssetRegister = async (data: any) => {
    //
    if (data) {
      const getOps = async () => {
        // const ops = await getOperativesByIds(data.operatives).then((res: any) => {
        //   data.operatives = res;
        //   return res;
        // })
        const formData: any[] = [];
        if (data.buildingAssets.length > 0) {
          data.buildingAssets.map((asset: any, i: number) => {
            const latestTest = asset?.buildingAsset.tests.length - 1;
            formData.push({
              assetNo: asset?.buildingAsset?.assetReference || 'N/A',
              locationReference: asset?.buildingAsset?.assetLocation || 'N/A',
              operatives: data.operatives,
              testResult:
                asset?.buildingAsset.tests.length > 0
                  ? moment(asset?.buildingAsset.tests[latestTest].testDate).format('YYYY-MM-DD')
                  : 'N/A',
              installationInspection:
                asset?.buildingAsset.tests.length > 0
                  ? asset?.buildingAsset.tests[latestTest].satisffactoryStatus
                  : 'NOT TESTED',
              fireBarrierInspection: asset?.buildingAsset?.fireBarrier?.satisfactionStatus || 'N/A',
            });
            //
          });
          const obj: any = {
            assets: formData,
            sectionName: 'Asset Register',
          };
          dispatch({
            type: SET_ASSET_REGISTER_DATA,
            payload: obj,
          });
          //
          saveToStorage('assetRegister', obj);
          setAssetRegisterData(obj);
        } else {
          const obj: any = {
            assets: [],
            sectionName: 'Asset Register',
          };
          dispatch({
            type: SET_ASSET_REGISTER_DATA,
            payload: obj,
          });
          saveToStorage('assetRegister', obj);
          setAssetRegisterData(obj);
        }
        // setReportData({
        //   ...reportData,
        //   assetRegister: formData
        // });
      };
      getOps();
    }
  };

  const onSaveDefectLog = async (job: any) => {
    const data: any[] = [];
    job.buildingAssets.map((asset: any, i: number) => {
      let accessibility = asset?.buildingAsset?.accessibility;
      let installation = asset.buildingAsset.installation;
      let fireBarrier = asset.buildingAsset.fireBarrier;
      let accessPanel = asset.buildingAsset.accessPanel;
      let assetRef = asset.buildingAsset.assetReference;

      if (accessibility.defect || accessibility.defect !== null) {
        accessibility.defects.map((defect: any) => {
          data.push({
            name: 'Accessibility',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }

      if (installation.defect || installation.defect !== null) {
        installation.defects.map((defect: any) => {
          data.push({
            name: 'Installation',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }
      if (fireBarrier.defect || fireBarrier.defect !== null) {
        fireBarrier.defects.map((defect: any) => {
          data.push({
            name: 'Fire Barrier',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }
      if (accessPanel.defect || accessPanel.defect !== null) {
        accessPanel.defects.map((defect: any) => {
          data.push({
            name: 'Access Panel',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }
    });
    //
    dispatch({
      type: SET_DEFECT_LOG_DATA,
      payload: data,
    });
    saveToStorage('defectLog', data);
    // setReportData({
    //   ...reportData,
    //   defectLog: data
    // });
    setDefectLogData(data);
  };

  const onSaveInventory = async (job: any) => {
    const data: any = {
      inventoryAssets: [
        ...job.buildingAssets.map((asset: any) => {
          return {
            assetReference: asset?.buildingAsset?.assetReference || 'N/A',
            assetLocation: asset?.buildingAsset?.assetLocation || 'N/A',
            damperType: asset?.buildingAsset?.ductDamper?.damperTypeName.length
              ? asset?.buildingAsset?.ductDamper?.damperTypeName[0]?.damperTypeName
              : 'N/A',
            accessibility: asset?.buildingAsset?.accessibility?.accessibilitySkipped
              ? 'Auto'
              : 'Manual',
          };
        }),
      ],
      assetNo: job?.buildingAssets[0]?.buildingAsset.assetReference || 'N/A',
      assetLocation: job?.buildingAssets[0]?.buildingAsset.assetLocation || 'N/A',
      damperCassification:
        job?.buildingAssets[0]?.buildingAsset.ductDamper.damperTypeName.length ||
        job?.buildingAssets[0]?.buildingAsset.ductDamper.damperTypeName[0].damperTypeName ||
        'N/A',
      accessibility: job?.buildingAssets[0]?.buildingAsset.accessibility.accessibilitySkipped
        ? 'Skipped'
        : job?.buildingAssets[0]?.buildingAsset.accessibility.accessibilityType || 'N/A',
    };

    const assets: any[] = [];
    data.inventoryAssets.length &&
      data.inventoryAssets.map((asset: any) => {
        assets.push(JSON.stringify(asset));
      });
    data.inventoryAssets = assets;

    dispatch({
      type: SET_INVENTORY_DATA,
      payload: data,
    });
    saveToStorage('inventory', data);
    // setReportData({
    //   ...reportData,
    //   inventory: data
    // });
    setInventoryData(data);
  };

  const onSaveAssetPage = async (job: any) => {
    const assetsData: any[] = [];
    const { buildingAssets } = job || {};
    const assets =
      buildingAssets !== undefined || buildingAssets !== null
        ? buildingAssets.length > 0
          ? buildingAssets[0]
          : null
        : null;
    if (job && job.buildingAssets.length && assets) {
      // loop through array of building assets and set values to formik
      job.buildingAssets.map((asset: any) => {
        //
        assetsData.push({
          buildingRef: job?.building?.buildingName || 'N/A',
          buildingName: job?.building?.buildingName || 'N/A',
          assetNo: asset.buildingAsset?.assetReference || 'N/A',
          qrImagePath: asset.buildingAsset.assetQrCode || 'N/A',
          assetImages: asset.buildingAsset.assetImages,
          locationRef: asset.buildingAsset.buildingAsset?.assetLocation || 'N/A',
          accessibilityName:
            asset.buildingAsset.accessibility?.accessibilityOptions[0].accessibilityName || 'N/A',
          accessibilitySatisfaction: asset.buildingAsset.accessibility.satisfactionStatus || 'N/A',
          fireBarrierName:
            asset.buildingAsset.fireBarrier?.fireBarrierOptions[0]?.fireBarrierName || 'N/A',
          fireBarrierSatisfaction: asset.buildingAsset.fireBarrier.satisfactionStatus || 'N/A',
          installationName:
            asset.buildingAsset.installation?.installationName[0]?.installationName || 'N/A',
          installationOrientation: asset.buildingAsset.installation.orientation || 'N/A',
          installationSatisfaction: asset.buildingAsset.installation.satisfactionStatus || 'N/A',
          ductyType: asset.buildingAsset.ductDamper.ductyType || 'N/A',
          ductSize: asset.buildingAsset.ductDamper.ductSize || 'N/A',
          ductShape: asset.buildingAsset.ductDamper.ductShapeName || 'N/A',
          damperClassification:
            asset.buildingAsset.ductDamper.damperTypeName.length > 0
              ? asset.buildingAsset.ductDamper.damperTypeName[0].damperTypeName || 'N/A'
              : 'N/A',
          accessPanelName: asset.buildingAsset.accessPanel.accessPanelOptionValue || 'N/A',
          accessPanelShape: asset.buildingAsset.accessPanel.shape || 'N/A',
          accessPanelSize: asset.buildingAsset.accessPanel.size || 'N/A',
          accessPanelBothSides: asset.buildingAsset.accessPanel.installedBothSides || 'N/A',
          accessPanelSatisfaction: asset.buildingAsset.accessPanel.accessPanelSatisfaction || 'N/A',
          testAsFoundImage: asset.buildingAsset.tests.length
            ? asset.buildingAsset.tests[0]?.test?.asFoundImageUrl || 'N/A'
            : 'N/A',
          testDropTestImage: asset.buildingAsset.tests.length
            ? asset.buildingAsset.tests[0]?.test?.dropTestImageUrl || 'N/A'
            : 'N/A',
          testRestImage: asset.buildingAsset.tests.length
            ? asset.buildingAsset.tests[0]?.test?.resetImageUrl || 'N/A'
            : 'N/A',
          sectionName: 'Asset Page',
          tests: assets.buildingAsset.tests,
        });
      });
      dispatch({
        type: SET_ASSET_PAGE_DATA,
        payload: assetsData,
      });
      saveToStorage('assetPage', assetsData);

      setAssetPageData(assetsData);
    } else {
      assetsData.push({
        buildingRef: 'N/A',
        buildingName: 'N/A',
        assetNo: 'N/A',
        qrImagePath: 'N/A',
        assetImages: 'N/A',
        locationRef: 'N/A',
        accessibilityName: 'N/A',
        accessibilitySatisfaction: 'N/A',
        fireBarrierName: 'N/A',
        fireBarrierSatisfaction: 'N/A',
        installationName: 'N/A',
        installationOrientation: 'N/A',
        installationSatisfaction: 'N/A',
        ductyType: 'N/A',
        ductSize: 'N/A',
        ductShape: 'N/A',
        damperClassification: 'N/A',
        accessPanelName: 'N/A',
        accessPanelShape: 'N/A',
        accessPanelSize: 'N/A',
        accessPanelBothSides: 'N/A',
        accessPanelSatisfaction: 'N/A',
        testAsFoundImage: 'N/A',
        testDropTestImage: 'N/A',
        testRestImage: 'N/A',
        sectionName: 'Asset Page',
        tests: [],
      });
      dispatch({
        type: SET_ASSET_PAGE_DATA,
        payload: assetsData,
      });
      saveToStorage('assetPage', assetsData);
    }
  };

  const onSaveFooter = async (job: any) => {
    const data = {
      reportJobNumber: job?.jobReference,
      reportSection: 'Report Section',
      publishDate: moment().format(),
      clientName: job?.client?.clientName,
      companyNo: job?.client?.companyRegisterationNo,
      vatNumber: job?.client?.VATNumber,
    };
    const res = await createReportFooterTemplate(data);
    if (res.status === 'Success') {
      return res.data;
    }
  };

  const onSaveSummary = async (job: any) => {
    let eClassCount = 0;
    let esClassCount = 0;
    let IClassCount = 0;
    let SClassCount = 0;
    let cleaned = 0;
    let moderate = 0;
    let dirty = 0;
    let compliantDampers = 0;
    let nonCompliantDampers = 0;
    let withAccessPanelBothSide = 0;
    let withoutAccessPanelBothSide = 0;
    let untested = 0;

    if (job.buildingAssets.length > 0) {
      untested = job.buildingAssets.filter(
        (asset: any) => asset.buildingAsset.tests.length === 0
      ).length;
      // setUntestedDampers(untested);
      // setTotalDampers(job.buildingAssets.length);

      job.buildingAssets.length &&
        job.buildingAssets.map((asset: any) => {
          const { installedBothSides, accessPanelSkipped } = asset.buildingAsset.accessPanel;
          const { tests } = asset.buildingAsset;
          if (tests.length) {
            tests.map((test: any) => {
              const { hygiene } = test;

              if (hygiene.hygieneText === 'Clean') {
                cleaned++;
              }
              if (hygiene.hygieneText === 'Moderate') {
                moderate++;
              }
              if (hygiene.hygieneText === 'Dirty') {
                dirty++;
              }
            });
          }

          if (installedBothSides && accessPanelSkipped) {
            // setWithAccessPanelBothSide(withAccessPanelBothSide + 1);
          } else if (installedBothSides && !accessPanelSkipped) {
            // setWithoutAccessPanelBothSide(withoutAccessPanelBothSide + 1);
          }

          if (asset.buildingAsset.ductDamper.damperTypeName.length) {
            asset.buildingAsset.ductDamper.damperTypeName.map((damper: any) => {
              if (damper.damperTypeName.startsWith('S Class')) {
                SClassCount++;
              }
              if (damper.damperTypeName.startsWith('ES Class')) {
                esClassCount++;
              }
              if (damper.damperTypeName.startsWith('E Class')) {
                eClassCount++;
              }
              if (damper.damperTypeName.startsWith('I Class')) {
                IClassCount++;
              }
            });
          }
          if (!asset.buildingAsset.fireBarrier.defect) {
            compliantDampers++;
          } else if (asset.buildingAsset.fireBarrier.defect) {
            nonCompliantDampers++;
          }
        });
      const data = {
        noOfDirtyDampers: dirty + '',
        noOfModerateDampers: moderate + '',
        noOfCleanDampers: cleaned + '',
        totalNumberOfDampers: job.buildingAssets.length + '',
        noOfeClass: eClassCount + '',
        noOfsClass: SClassCount + '',
        noOfesClass: esClassCount + '',
        noOfiClass: IClassCount + '',
        noOfCompliantDampers: compliantDampers + '',
        noOfAdvisorNotes: 12 + '',
        noOfNonCompliantDampers: nonCompliantDampers + '',
        noOfUntestedDampers: untested + '',
        noOfDmpsAccPnlBothSides: withAccessPanelBothSide + '',
        noOfDmpsPanlOneSide: withoutAccessPanelBothSide + '',
      };
      dispatch({
        type: SET_SUMMARY_DATA,
        payload: data,
      });

      setSummaryData(data);
    } else {
      const data = {
        noOfDirtyDampers: '0',
        noOfModerateDampers: '0',
        noOfCleanDampers: '0',
        totalNumberOfDampers: '0',
        noOfeClass: '0',
        noOfsClass: '0',
        noOfesClass: '0',
        noOfiClass: '0',
        noOfCompliantDampers: '0',
        noOfAdvisorNotes: '0',
        noOfNonCompliantDampers: '0',
        noOfUntestedDampers: '0',
        noOfDmpsAccPnlBothSides: '0',
        noOfDmpsPanlOneSide: '0',
      };
      dispatch({
        type: SET_SUMMARY_DATA,
        payload: data,
      });
      setSummaryData(data);
      await saveToStorage('summary', data);
    }
  };

  const onCreateAFDSReport = async (job: any) => {
    await onSaveSummary(job);

    await onSaveAssetRegister(job);

    await onSaveCertificate(job);

    await onSaveDefectLog(job);

    await onSaveInventory(job);

    await onSaveAssetPage(job);

    await onPublishAFDSReport(job);
  };

  const onPublishAFDSReport = async (job: any) => {
    const reportCertificateData = await getFromStorage('certificate');
    const reportHeaderData = await getFromStorage('header');
    const reportGuidanceData = await getFromStorage('guidance');
    const reportServicesData = await getFromStorage('services');
    const reportSummaryData = await getFromStorage('summary');
    const reportAssetRegisterData = await getFromStorage('assetRegister');
    const reportDefectLogData = await getFromStorage('defectLog');
    const reportAssetPageData = await getFromStorage('assetPage');
    const reportInventoryData = await getFromStorage('inventory');
    const reportCompetencyData = await getFromStorage('competency');

    const [
      header,
      certificate,
      guidance,
      services,
      summary,
      assetRegister,
      defectLog,
      assetPage,
      inventory,
      competency,
      footer,
    ] = await Promise.all([
      onPublishHeader(reportHeaderData),
      createReportCertificate(reportCertificateData),
      createReportGuidance(reportGuidanceData),
      createReportService(reportServicesData),
      createReportSummary(reportSummaryData),
      createAssetRegister(reportAssetRegisterData),
      createDefectLog(reportDefectLogData),
      createBuildingAssetReport(reportAssetPageData),
      createReportInventory(reportInventoryData),
      createCompetencyReport(reportCompetencyData),
      onSaveFooter(job),
    ]);
    const reportData = {
      headerDataReports: header._id,
      certificateReports: certificate.data._id,
      guidanceReport: guidance.data._id,
      serviceReport: services.data._id,
      summaryReports: summary.data._id,
      assetRegisterReports: assetRegister.data._id,
      defectLogReports: defectLog.data._id,
      buildingAssetsReports: assetPage.data._id,
      inventoryReports: inventory.data._id,
      competencyReports: competency.data._id,
      footerDataReports: footer._id,
    };

    //

    const response = await createInspectionReport(reportData);

    if (response.status === 'Success') {
      const data: any = {
        inspectionJob: job._id,

        building: job.building._id,
        dateRenewalDate: getNextTestDate(job.building.testDate, job.building.testRegime) + '',
        attachment: [],
        archived: false,
        inspectionReport: response.data._id,
      };

      dispatch({
        type: SET_CREATE_INSPECTION_JOB_DATA,
        payload: data,
      });
      const message = await onSaveReportToLibrary(data);

      if (message === 'success') {
        success(`Report created successfully`);
        removeEveryKey();
      } else if (message === 'error') {
        error(`Could not create report.`);
      }
    } else {
      error(`Could not create report.`);
    }
  };

  const onSaveRemedialSummaryData = async (data: any) => {
    if (data) {
      saveToStorage('remedialSummary', data);
    }
  };

  const onSaveActionLog = async (data: any) => {
    if (data) {
      saveToStorage('actionLog', data);
    }
  };

  const onSaveRemedialFooterData = async (job: any) => {
    const data = {
      reportJobNumber: job?.jobReference,
      reportSection: 'Report Section',
      publishDate: moment().format(),
      clientName: job?.client?.clientName,
      companyNo: job?.client?.companyRegisterationNo,
      vatNumber: job?.client?.VATNumber,
    };
    if (data) {
      saveToStorage('footer', data);
    }
  };

  const onCreateRemedialReport = async (job: any) => {
    await onSaveHeader(job.client);
    await onSaveCertificate(job);
    await onSaveRemedialSummaryData(getRemedialReportSummary(job));
    await onSaveActionLog(getRemedialReportActionLog(job));
    //  await onSaveCompetency(job);
    await onSaveRemedialFooterData(job);

    setTimeout(async () => {
      const headerData = await getFromStorage('header');
      const certificateData = await getFromStorage('certificate');
      const summaryData = await getFromStorage('remedialSummary');
      const actionLogData = await getFromStorage('actionLog');
      const competencyData = await getFromStorage('competency');
      const footerData = await getFromStorage('footer');

      //
      //   'remedial report data=====>>>',
      //   headerData,
      //   certificateData,
      //   summaryData,
      //   actionLogData,
      //   competencyData,
      //   footerData
      // )

      const [header, certificate, summary, actionLog, competency, footer] = await Promise.all([
        onPublishHeaderRemedial(headerData),
        onSaveRemedialCertificate(certificateData),
        onSaveRemedialSummary(summaryData),
        onSaveRemedialActionLog(actionLogData),
        onSaveRemedialCompetency(competencyData),
        onSaveRemedialFooter(footerData),
      ]);

      const reportData = {
        remedialActionReportName: 'Fire Damper Remedial Action Report',
        headerDataReportsRemedial: header._id,
        certificateReportsRemedial: certificate._id,
        remedialActionLogs: actionLog._id,
        footerDataReportsRemedial: footer._id,
        remedialCompetencies: competency._id,
        remedialActionSummary: summary._id,
      };
      //

      const response = await createRemedialInspectionReport(reportData);
      //

      if (response.status === 'Success') {
        const data: any = {
          inspectionJob: job._id,
          client: job.client._id,
          clientRepresentative: job.clientRepresentative._id,
          facilityManager: job.facilityManager._id,
          building: job.building._id,
          dateRenewalDate: getNextTestDate(job.building.testDate, job.building.testRegime) + '',
          attachment: [],
          archived: false,
          remedialInspectionReports: response.data._id,
        };

        const message = await onSaveReportToLibrary(data);

        if (message === 'success') {
          success(`Report created successfully`);
          removeEveryKey();
        } else if (message === 'error') {
          error(`Could not create report.`);
        }
      }
    }, 1000);
  };

  const onArchive = (id: string) => {
    const row = assetsTypes.find((x) => x._id === id);

    if (row) {
      setDialogueBox({ flag: true, record: row });
    }
  };


  const ProfilePicWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

  const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  border: 1px solid #ccc;
`;

  useEffect(() => {
    if (isChanged) {
      getAssets();
    }
  }, [sortField, sortOrder]);


  const onSortChange = (e: any) => {
    setChanged(true);
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
    setTableLoading(true);
  };

  // =============================== report generation process end

  return (
    <LoaderComman isRender={!isLoading}>
      <PreviewReportDialog
        defectData={defectData}
        visible={previewDialog}
        setVisible={(val: boolean) => setPreviewDialog(val)}
      />
      <div className='d-flex flex-column-fluid'>
        <div className='w-100'>
          <div className='card card-custom gutter-b'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-2'>
                  {building?.buildingImage &&
                    <div className='avatar-preview'>
                      <ProfilePicWrapper>
                        <ProfilePic
                          src={
                            building?.buildingImage
                              ? process.env.REACT_APP_IMAGE_URL + building?.buildingImage
                              : ''
                          }
                          alt='Building image'
                          className="img-fluid"
                          style={{ cursor: 'pointer' }}
                        />
                      </ProfilePicWrapper>
                    </div>}
                </div>
                <div className='col-lg-5'>
                  <div className='row mt-5'>
                    <div className='col-lg-3'>
                      <div className='d-flex justify-content-lg-between'>
                        <div style={{ fontWeight: 500 }}>Reference</div>
                        <div style={{ fontWeight: 500 }}>:</div>
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      {building?.buildingReference}
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-3'>
                      <div className='d-flex justify-content-lg-between'>
                        <div style={{ fontWeight: 500 }}>Building Name</div>
                        <div style={{ fontWeight: 500 }}>:</div>
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      {building?.buildingName}
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-3'>
                      <div className='d-flex justify-content-lg-between'>
                        <div style={{ fontWeight: 500 }}>Building Address</div>
                        <div style={{ fontWeight: 500 }}>:</div>
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      {building?.buildingAddress}
                    </div>
                  </div>
                </div>
                <div className='col-lg-5'>
                  <div className='row mt-5'>
                    <div className='col-lg-3'>
                      <div className='d-flex justify-content-lg-between'>
                        <div style={{ fontWeight: 500 }}>Profile</div>
                        <div style={{ fontWeight: 500 }}>:</div>
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      {building?.client?.clientName || 'N/A'}
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-3'>
                      <div className='d-flex justify-content-lg-between'>
                        <div style={{ fontWeight: 500 }}>Representative</div>
                        <div style={{ fontWeight: 500 }}>:</div>
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      {building?.clientRepresentative?.clientRepresentativeName || 'N/A'}
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-3'>
                      <div className='d-flex justify-content-lg-between'>
                        <div style={{ fontWeight: 500 }}>FM</div>
                        <div style={{ fontWeight: 500 }}>:</div>
                      </div>
                    </div>
                    <div className='col-lg-8'>
                      {building?.facilityManager?.facilityManagerName || 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-custom mt-4'>
            <div className='card card-custom mt-4'>
              <div className='card-body pt-0 pb-3 '>
                <div className='d-flex align-items-end justify-content-between'>
                  <div className="d-flex">
                    <div>
                      <RadioButton inputId="All" name="All" value="All" onChange={(e) => {
                        setDataFilter(e.value);
                        setAssetsTypes(assetsMasterData);
                      }} checked={dataFilter === 'All'} />
                      <label htmlFor="ingredient1" className="ms-2">All Data</label>
                    </div>
                    <div className='ms-5'>
                      <RadioButton inputId="Duplicate" name="Duplicate" value="Duplicate" onChange={(e) => {
                        setDataFilter(e.value);
                        setAssetsTypes(assetsMasterData.filter((x: any) => x.duplicate === true));
                      }} checked={dataFilter === 'Duplicate'} />
                      <label htmlFor="ingredient1" className="ms-2">Duplicate</label>
                    </div>

                  </div>
                  <button
                    className='btn btn-danger-secondary'
                    type='button'
                    onClick={() => setPreviewDialog(true)}
                    data-toggle='modal'
                    data-target='#publishReport'
                  >
                    <i className='pi pi-eye'></i>Overview
                  </button>
                </div>
                <AssetsDataTable
                  dataKey='_id'
                  data={assetsTypes}
                  columns={assetsColumns}
                  isShowActionColumns
                  isHideEditAction
                  onArchive={onArchive}
                  isHideSearch
                  onSort={onSortChange}
                  sortOrder={sortOrder}
                  sortField={sortField}
                  isLoading={isTableLoading}
                />
              </div>
            </div>
            <CustomModal
              show={isaddAsset}
              handleClose={() => handleAddAssets()}
              title='Add New Asset'
            >
              <AddAssetsModal
                onHide={() => handleAddAssets()}
                loadingUplaod={loadingUplaod}
                asset={Set}
                setExcelFileModel={setExcelFileModel}
                setuploadSingleAssetModal={setuploadSingleAssetModal}
              />
            </CustomModal>

            <AddSingleAssets
              onCreate={onUploadAssets}
              excelFileModel={excelFileModel}
              uploadSingleAssetModal={uploadSingleAssetModal}
              setLoading={setLoading}
              getAllData={getBuildingDetails}
              setuploadSingleAssetModal={setuploadSingleAssetModal}
            />
            <DialogueBox
              title={'Vault Asset Archive'}
              message={'Do you want  to archive?'}
              state={dialogueBox.flag}
              onClick={() => removeAsset(dialogueBox.record)}
              handleClose={() => setDialogueBox({ flag: false, record: {} })}
            />
          </div>
        </div>
      </div >
    </LoaderComman >
  );
};

export default BuildingDetails;

function RenderAsset({
  asset,
  index,
  building,
  navigate,
}: {
  asset: AssetsTypesProps;
  index: number;
  building: any;
  navigate: any;
}) {
  const totalDefects =
    asset.installation.defects.length +
    asset.accessPanel.defects.length +
    asset.fireBarrier.defects.length +
    asset.accessibility.defects.length;
  return (
    <tr key={index}>
      <td>
        <span
          onClick={() => {
            navigate(asset._id);
          }}
          className='cursor-pointer text-primary'
        >
          {asset.assetReference}
        </span>
      </td>
      <td>{asset.assetLocation}</td>
      <td>{totalDefects ? totalDefects : 'None'}</td>
      <td className='capitalized'>
        {building?.testStatus} / {moment(asset.testDate).format('DD-MM-YYYY')}
      </td>
      <td>{asset.installation.installationSkipped ? 'Yes' : 'No'}</td>
    </tr>
  );
}

function AddSingleAssets({
  uploadSingleAssetModal,
  setuploadSingleAssetModal,
  excelFileModel,
  getAllData,
  setLoading,
  onCreate,
}: {
  uploadSingleAssetModal: any;
  getAllData: any;
  onCreate: (values: any) => void;
  excelFileModel: any;
  setLoading: any;
  setuploadSingleAssetModal: any;
}) {
  const [jobs, setjobs] = useState<any[]>([]);
  const [imgUrl1, setImgUrl1] = useState('');
  const [assetRef, setAssetRef] = useState('');
  const [jobId, setJobId] = useState('');
  const { building } = useSelector((state: any) => state.job);
  const { user } = useSelector((state: any) => state.auth);
  const [singleAsset, setSingleAsset] = useState(true);

  const jobProps = {
    sortproperty: 'createdAt',
    sortorder: -1,
    offset: 0,
    limit: 50,
    query: {
      critarion: { active: true, building: building?._id },
      fields: 'jobReference jobType customerPOReference',
    },
  };

  const getJobList = async () => {
    try {
      const result = await getJobsListing(jobProps);
      setjobs(result.data.inspectionJobs);
    } catch (e) { }
  };

  const fetchData = async () => {
    try {
      let references = await getReferences({ getReferences: ['AR'] });
      if (references.status === 'Success') {
        setAssetRef(references.data.AR);
      }
      if (!jobs.length) {
        getJobList();
      }

    } catch (error) { }
  };

  useEffect(() => {
    if (building?._id) {
      fetchData();
    }
  }, [building]);

  useEffect(() => {
    if (uploadSingleAssetModal) {
      fetchData();
    }
  }, [uploadSingleAssetModal]);

  // Create Asset popup
  const createBuildingAssests = async () => {
    const obj = {
      building: building._id,
      addToJob: true,
      inspectionJob: jobId,
      assetReference: assetRef,
      operative: user?.modelId,
      assetQrCode: imgUrl1,
      lastOperation: 'addNewAssests',
      previousOperation: '',
    };
    setLoading(true);
    const createBuildingAssest = await createBuildingAsset(obj);
    if (createBuildingAssest?.status === "Success") {
      success('Building Asset created successfully!');
      setuploadSingleAssetModal(false);
      fetchData();
      getAllData();
      setLoading(false);
    } else {
      error(createBuildingAssest?.message ?? 'something went wrong ');
    }
  };

  const validationSchema: any = Yup.object({
    buildingAssets: Yup.string().required('Excel file is required'),
  });

  const initialValues = {
    buildingid: '',
    inspectionJob: '',
    buildingAssets: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let payload = {
        ...values,
      };
      onCreate(payload);
    },
  });

  const handleChange = (e: any) => {
    let file = e.target.files[0];
    if (file) {
      const validExtensions = ['csv'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (validExtensions.includes(fileExtension)) {
        formik.setFieldValue('buildingAssets', file);
      } else {
        toast.error('Please upload a valid CSV file.');
      }
    }
  };

  const downloadSampleCsv = async () => {
    try {
      const downloadFile = async (fileLink: string) => {
        const response = await fetch(`${process.env.REACT_APP_IMAGE_URL}${fileLink}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileLink.split('/').pop() || 'AssestSample';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };

      await downloadFile('Sample/AssestSample.csv');

    } catch (err: any) {
      error('Something went wrong, please trt again later');
    }
  };

  return (
    <CustomModal
      show={uploadSingleAssetModal}
      handleClose={() => setuploadSingleAssetModal(false)}
      title='Add New Asset'
    >
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex flex-column justify-content-between'>
                <div>
                  <p className='required'>Please select the option to create building asset: </p>
                  <Select
                    placeholder='jobs ids'
                    options={jobs}
                    getOptionLabel={(option: any) => option.jobReference}
                    getOptionValue={(option: any) => String(option._id)}
                    defaultValue={{
                      label: jobs[0]?._id,
                    }}
                    onChange={(e: any) => {
                      setJobId(e._id);
                      formik.setFieldValue('inspectionJob', e._id);
                    }}
                  />
                </div>
                {!excelFileModel ? (
                  <>
                    <label className='required mt-4'>Asset Reference:</label>
                    <input type='text' className='form-control mb-5' value={assetRef} onChange={(e) => setAssetRef(e.target.value)} />

                    <div style={{ width: 'fit-content' }}>
                      <ImageUploader img={imgUrl1} setImageUrl={setImgUrl1} isQrCodeImg isAdd />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <button
                        className='btn btn-primary w-fit'
                        disabled={!assetRef?.length || !jobId}
                        onClick={() => createBuildingAssests()}
                      >
                        Add New Asset
                      </button>
                    </div>
                  </>
                ) : (
                  <form onSubmit={formik.handleSubmit}>
                    <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'column' }}>
                      <label className='required mt-4'>Upload Excel File:</label>
                      <input
                        type='file'
                        placeholder='Choose excel sheet'
                        className='mt-5'
                        onChange={(e) => handleChange(e)}
                      />
                      {formik.touched?.buildingAssets && formik.errors?.buildingAssets ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block text-danger'>
                            {formik.errors?.buildingAssets}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <button
                        className='btn btn-primary w-fit'
                        type='submit'
                      // disabled={!formik.values.buildingAssets?.length}
                      // onClick={() => createExcelBuildingAssests()}
                      >
                        Add New Asset
                      </button>

                      <button type='button' className='btn btn-primary mt-4 mt-lg-0 ms-lg-3' onClick={downloadSampleCsv}><i className='pi pi-download me-1'></i>Download Sample CSV</button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
