import { Link } from 'react-router-dom';
import v1Img from '../../../assets/v1.png';
import v5Img from '../../../assets/v2.png';
import v2Img from '../../../assets/v3.png';
import v3Img from '../../../assets/v4.png';
import v4Img from '../../../assets/v5.png';
import v6Img from '../../../assets/v6.png';
import v7Img from '../../../assets/v7.png';

import { SettingsRoutePath } from './SettingsPage';

import './style.css';
const MainSetting = () => {
  let linksArr = [
    { heading: 'Category Settings', pic: v1Img, link: SettingsRoutePath.accessibility },
    { heading: 'Defect Settings', pic: v2Img, link: SettingsRoutePath.subCategories },
    { heading: 'PPM Mobile Guidance Text', pic: v3Img, link: SettingsRoutePath.ppmCategories },
    { heading: 'Report Prefix', pic: v4Img, link: SettingsRoutePath.reportGuidance },
    { heading: 'User Access Permissions', pic: v5Img, link: SettingsRoutePath.permissions },
    { heading: 'Associations Settings', pic: v6Img, link: SettingsRoutePath.associations },
    { heading: 'Competencies Settings', pic: v7Img, link: SettingsRoutePath.competencies },
  ];

  return (
    <div>
      <div className='card card-custom card-stretch gutter-b '>
        <div className='card-body pt-5 pb-3'>
          <div className='d-flex justify-content-lg-start justify-content-md-center card-design' style={{ flexWrap: 'wrap' }}>
            {linksArr.map((item, i) => (
              <>
                <div className='my-5 mx-3'>
                  <Link to={item.link}>
                    <div className=' wights'>
                      <div className='d-flex align-items-center justify-content-center'>
                        <div className='img_section'>
                          <img src={item?.pic} style={{ objectFit: 'cover', height: `${item.heading === "Associations Settings" ? '60px' : ''}` }} alt='setting_img' />
                        </div>
                        <div className='content_heading'>{item?.heading}</div>
                      </div>
                    </div>
                  </Link>

                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSetting;
