import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';

export default function SupplementaryObservations({
    observations, setObservationModel, setDialogBox, setShowObservationDialog
}) {

    return (
        <>
            <div className='row'>
                {
                    observations?.map(x => {
                        return (
                            <>
                                <div className='col-lg-6 col-xl-2 mb-10'>
                                    <Card title={x?.observationNote} style={{ height: '280px', textAlign: 'center' }}>
                                        <Image src={process.env.REACT_APP_IMAGE_URL + x?.observationImage}
                                            alt={x?.observationNote}
                                            preview
                                            className="w-6 shadow-2 observations" style={{ maxHeight: '180px', width: '200px' }}
                                        />
                                        {/* <img src={process.env.REACT_APP_IMAGE_URL + x?.observationImage} alt={x?.observationNote} className="w-6 shadow-2" style={{ maxHeight: '180px', width: '200px' }} /> */}
                                    </Card>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <Button
                                            icon="pi pi-pencil"
                                            className="btn btn-primary-bordered d-flex"
                                            rounded text raised severity="warning"
                                            aria-label="Notification"
                                            onClick={() => {
                                                setShowObservationDialog(true);
                                                setObservationModel(x)
                                            }}
                                        />
                                        <Button
                                            icon="pi pi-trash"
                                            className="btn btn-primary-bordered ms-5 d-flex"
                                            rounded text raised severity="warning"
                                            aria-label="Notification"
                                            onClick={() => {
                                                setDialogBox({ flag: true, id: x._id })
                                            }}
                                        />
                                    </div>
                                </div>
                            </>

                        )
                    })
                }
            </div>
        </>
    )


}