/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'
import {LOCATION} from '../../../../constants/endPoints'
import {register} from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'

export interface UserArgsProps {
  sortproperty: string
  sortorder: number
  offset: number
  limit: number
  query: {
    critarion: {
      active: boolean
      role?: string
    }
    addedby?: string
    lastModifiedBy?: string
    fields: string
  }
}

export const userArgs: UserArgsProps = {
  sortproperty: 'name',
  sortorder: 1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    addedby: '_id email name',
    lastModifiedBy: '_id email name',
    fields: 'name email',
  },
}

const ProfilePicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #ccc;
`

const profilePicStyle: any = {
  width: '150px',
  height: '150px',
  objectFit: 'cover',
  borderRadius: '50%',
}

const initialValues = {
  // profilePic: null,
  firstname: '',
  lastname: '',
  phone: '',
  deviceId: '',
  role: '',
  email: '',
  password: '',
  confirmPassword: '',
  location: {
    type: 'Point',
    coordinates: [0, 0],
  },
}

export interface LocationProps {
  lat: number
  lng: number
  address?: string
}

const registrationSchema = Yup.object().shape({
  // profilePic: Yup.object().required('Profile picture is required'),
  // profilePic: Yup.object().required('Profile picture is required').nullable(),
  firstname: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  phone: Yup.string()
    .min(9, 'Minimum 9 characters')
    .max(50, 'Maximum 50 characters')
    .required('Phone is required'),
  deviceId: Yup.string()
    .min(4, 'Minimum 4 characters')
    .max(4, 'Maximum 4 characters')
    .required('Device ID is required'),
  role: Yup.string().required('Role is required'),
  location: Yup.object().shape({
    coordinates: Yup.array().of(Yup.number()).required('Location is required'),
  }),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [profilePic, setProfilePic] = useState<File | any>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [location, setLocation] = useState<LocationProps>({
    lat: 0,
    lng: 0,
  })

  const handleImageChange = (e: any) => {
    if (e) {
      setImgSrc(URL.createObjectURL(e.target.files[0]))
      setProfilePic(e.target.files[0])
      // setProfilePicName('uploads/' + e.target.files[0].name);
      // setEventData({
      //   ...eventData,
      //   image: e.target.files[0]
      // });
    }
  }

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setStatus(null)

      setTimeout(() => {
        register(JSON.stringify(formik.values))
          .then((response) => {
            const {token, status, message} = response.data
            if (status === 'Fail') {
              setStatus(message)
              setSubmitting(false)
              setLoading(false)
              window.scroll({top: 0, left: 0, behavior: 'smooth'})
              return
            }
            setLoading(false)
            setSubmitting(false)
            dispatch(auth.actions.login(token || ''))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('Registration process has broken')
          })
      }, 0)
    },
  })

  const onChangeLocation = (place: any) => {
    formik.setFieldValue('location.coordinates', [
      place.geometry.location.lng(),
      place.geometry.location.lat(),
    ])
    setLocation({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      address: place.formatted_address,
    })
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create an Account</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group profile pic */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-12 mb-7'>
          <label className='form-label fw-bolder text-dark fs-6 hidden w-100 text-left'>
            Choose profile picture
          </label>
          <input
            id='file'
            name='file'
            type='file'
            onChange={(event) => {
              // formik.setFieldValue("profilePic", event.target.files && event.target.files[0]);
              setProfilePic(event.target.files && event.target.files[0])
            }}
          />
          {profilePic ? (
            <div className='avatar-preview mt-6'>
              <ProfilePicWrapper>
                <ProfilePic src={profilePic && URL.createObjectURL(profilePic)} alt='' />
              </ProfilePicWrapper>
            </div>
          ) : (
            <ProfilePicWrapper>
              <ProfilePic
                src={
                  'https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg'
                }
                alt=''
              />
            </ProfilePicWrapper>
          )}
          {profilePic === null &&
            !(formik.values.role === 'admin' || formik.values.role === 'superadmin') && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>Profile picture is required.</span>
                </div>
              </div>
            )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Firstname */}
          <label className='form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
            <input
              placeholder='Last name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Phone */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
        <input
          placeholder='Phone'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Device ID */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Device ID</label>
        <input
          placeholder='Device ID'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('deviceId')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.deviceId && formik.errors.deviceId},
            {
              'is-valid': formik.touched.deviceId && !formik.errors.deviceId,
            }
          )}
        />
        {formik.touched.deviceId && formik.errors.deviceId && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.deviceId}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Role */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Role</label>
        <select
          {...formik.getFieldProps('role')}
          className={clsx(
            'form-select form-select-solid form-select-lg fw-bold',
            {'is-invalid': formik.touched.role && formik.errors.role},
            {
              'is-valid': formik.touched.role && !formik.errors.role,
            }
          )}
        >
          <option value=''>Select</option>
          <option value='admin'>Admin</option>
          <option value='user'>User</option>
        </select>
        {formik.touched.role && formik.errors.role && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.role}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Location */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Location</label>
        <ReactGoogleAutocomplete
          placeholder='Address'
          className='form-control form-control-lg form-control-solid'
          defaultValue={location.address}
          apiKey={LOCATION.apiKey}
          name='location'
          // {...formik.getFieldProps('location')}
          onPlaceSelected={(place: any) => {
            onChangeLocation(place)
          }}
          required={true}
        />
        {formik.touched.location && formik.errors.location && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.location}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      {/* <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div> */}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          // disabled={formik.isSubmitting || !formik.isValid || profilePic === null}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
