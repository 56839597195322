/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
// import { KTSVG } from '_metronic/helpers';
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  MixedWidget8,
  TablesWidget10,
  TablesWidget5,
} from '../../../_metronic/partials/widgets'

const StyledCard = styled(Link)`
  min-width: 150px;
  height: 310px;
`

const StyledCardMini = styled(Link)`
  position: relative;
`

const StyledText = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff !important;
`

const EfsDashboard: FC = () => (
  <>
    <PageTitle>Dashboard</PageTitle>
    <div className='card'>
      <div className='card-body'>
        <div className='row g-0 gap-2 d-flex flex-column flex-md-row'>
          <div className='col'>
            <div className='row g-0'>
              <div className='col-12 '>
                <StyledCard
                  to='/vault'
                  className='bg-light-gray text-white px-md-8 px-4 py-8 rounded-2 me-md-7 mb-7 d-flex flex-column align-items-start justify-content-center'
                >
                  <div className='d-flex flex-column align-items-start justify-content-end h-full w-full'>
                    <div className='d-flex flex-column align-items-start justify-content-center'>
                      {/* <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-white d-block my-2'
                      /> */}
                      Enter Vault
                    </div>
                  </div>
                </StyledCard>
              </div>
              <div className='col-12'>
                <StyledCard
                  to='/administration/jobs'
                  className='bg-light-gray text-white px-md-8 px-4  py-8 rounded-2 me-md-7 mb-7 d-flex flex-column align-items-start justify-content-center'
                >
                  <div className='d-flex flex-column align-items-start justify-content-end h-full w-full'>
                    <div className='d-flex flex-column align-items-start justify-content-center'>
                      {/* <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-white d-block my-2'
                      /> */}
                      Job Administration
                    </div>
                  </div>
                </StyledCard>
                {/* <StyledCard to='/administration'
                  className='bg-light-orange text-white px-md-8 px-4 py-8 rounded-2 me-md-7 mb-7 d-flex flex-column align-items-start justify-content-center'>
                  <div className='d-flex flex-column align-items-start justify-content-between h-full w-full'>
                    <StyledText>Total jobs: 12</StyledText>
                    <div className='d-flex flex-column align-items-start justify-content-center'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-white d-block my-2'
                      />
                      Job Administration
                    </div>
                  </div>
                </StyledCard> */}
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='row g-2 gap-2 d-flex flex-column flex-md-row mb-4'>
              <StyledCardMini
                to='/profile/listing'
                className='text-white h-150px col bg-light-gray  px-6 pt-34 rounded-2'
              >
                Profile Register
              </StyledCardMini>
              <StyledCardMini
                to='/fm'
                className='text-white h-150px col bg-light-orange px-6 pt-34 rounded-2'
              >
                FM Register
              </StyledCardMini>
            </div>

            <div className='row g-2 gap-2 d-flex flex-column flex-md-row mb-4'>
              <StyledCardMini
                to='/operative'
                className='text-white h-150px col bg-light-gray px-6 pt-34 rounded-2'
              >
                Operative Register
              </StyledCardMini>
              <StyledCardMini
                to='/buildings'
                className='text-white h-150px col bg-light-orange px-6 pt-34 rounded-2'
              >
                Building Register
              </StyledCardMini>
            </div>

            <div className='row g-2 gap-2 d-flex flex-column flex-md-row mb-4'>
              <StyledCardMini
                to='/reports'
                className='text-white h-150px col bg-light-gray px-6 pt-34 rounded-2'
              >
                Report Archive
              </StyledCardMini>
              <StyledCardMini
                to='/forum/listing'
                className='text-white h-150px col bg-light-orange px-6 pt-34 rounded-2'
              >
                Forum
              </StyledCardMini>
            </div>

            <div className='row g-2 gap-2 d-flex flex-column flex-md-row mb-4'>
              <StyledCardMini
                to='/news'
                className='text-white h-150px col bg-light-gray px-6 pt-34 rounded-2'
              >
                News
              </StyledCardMini>
              <StyledCardMini
                to='/settings'
                className='text-white h-150px col bg-light-orange px-6 pt-34 rounded-2'
              >
                Settings
              </StyledCardMini>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
    </div>
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/* <DashboardPage /> */}
      <EfsDashboard />
    </>
  )
}

export {DashboardWrapper}
