
import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { error, success } from 'app/components/toast';
import { createAccessibility, createDamperType, createDuctType, createFireBarrier, createInstallation, updateAccessibility, updateDamperType, updateDuctType, updateFireBarrier, updateInstallation } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

export default function CategorySettingsDialog({
    visible, onHide, rowData, categoryType, refresh
}) {

    const [loading, setLoading] = useState(false);

    const fieldProperties = {
        Accessibility: {
            header: `${rowData ? 'Edit' : 'Add'} Accessibility`,
            label: 'Accessibility Name',
            prop: 'accessibilityName',
            initialValues: {
                accessibilityName: '',
                description: '',
            },
            validationSchema: Yup.object().shape({
                accessibilityName: Yup.string().required('Accessibility Name is required'),
            })
        },
        FireBarrier: {
            header: `${rowData ? 'Edit' : 'Add'} Fire Barrier`,
            label: 'Fire Barrier Name',
            prop: 'fireBarrierName',
            initialValues: {
                fireBarrierName: '',
                description: '',
            },
            validationSchema: Yup.object().shape({
                fireBarrierName: Yup.string().required('Fire Barrier Name is required'),
            })
        },
        Installation: {
            header: `${rowData ? 'Edit' : 'Add'} Installation`,
            label: 'Installation Name',
            prop: 'installationName',
            initialValues: {
                installationName: '',
                description: '',
            },
            validationSchema: Yup.object().shape({
                installationName: Yup.string().required('Installation Name is required'),
            })
        },
        DamperType: {
            header: `${rowData ? 'Edit' : 'Add'} Damper Type`,
            label: 'Damper Type Name',
            prop: 'damperTypeName',
            initialValues: {
                damperTypeName: '',
                description: '',
            },
            validationSchema: Yup.object().shape({
                damperTypeName: Yup.string().required('Damper Type Name is required'),
            })
        },
        DuctType: {
            header: `${rowData ? 'Edit' : 'Add'} Duct Type`,
            label: 'Duct Type Name',
            prop: 'ductTypeName',
            initialValues: {
                ductTypeName: '',
                description: '',
            },
            validationSchema: Yup.object().shape({
                ductTypeName: Yup.string().required('Duct Type Name is required'),
            })
        }
    }

    const { header, label, prop, initialValues, validationSchema } = fieldProperties[categoryType];

    useEffect(() => {
        if (rowData) {
            Object.keys(rowData).forEach(property => {
                formik.setFieldValue(property, rowData[property])
            });
        }
    }, [rowData])

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true);
            onCreate(values);
        },
    });

    const onCreate = async (values) => {
        try {
            setLoading(true);
            let response = null;
            if (categoryType === 'Accessibility') {
                if (rowData) {
                    values.accessibilityid = values._id;
                    response = await updateAccessibility(values);
                } else {
                    response = await createAccessibility(values);
                }
            }

            if (categoryType === 'FireBarrier') {
                if (rowData) {
                    values.fireBarrierid = values._id;
                    response = await updateFireBarrier(values);
                } else {
                    response = await createFireBarrier(values);
                }
            }

            if (categoryType === 'Installation') {
                if (rowData) {
                    values.installationid = values._id;
                    response = await updateInstallation(values);
                } else {
                    response = await createInstallation(values);
                }
            }

            if (categoryType === 'DamperType') {
                if (rowData) {
                    values.damperTypeid = values._id
                    response = await updateDamperType(values);
                } else {
                    response = await createDamperType(values);
                }
            }

            if (categoryType === 'DuctType') {
                if (rowData) {
                    values.ductTypeid = values._id
                    response = await updateDuctType(values);
                } else {
                    response = await createDuctType(values);
                }
            }

            if (response.status === 'Success') {
                success(`${categoryType} ${rowData ? 'updated' : 'created'} successfully.`);
                formik.resetForm();
                setLoading(false);
                refresh();
                onHide(false);
            }
            else {
                setLoading(false);
                error("Something went wrong, please try again later")
            }
        } catch (err) {
            error(`Could not ${rowData ? 'update' : 'create'} ${categoryType}.`);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        formik.resetForm();
        onHide(false);
    };

    return (
        <div className="card flex justify-content-center">
            <Dialog draggable={false} header={header} visible={visible} style={{ width: '25vw' }} onHide={handleCancel} position='top'>
                <form onSubmit={formik.handleSubmit}>
                    <p className="m-0">
                        <label className='required'>{label}</label>
                        <input
                            type='text'
                            className='form-control'
                            {...formik.getFieldProps(prop)}
                        />
                        {formik.touched[prop] && formik.errors[prop] ? (
                            <div className='text-danger'>{formik.errors[prop]}</div>
                        ) : null}
                        <div className='d-flex flex-row gap-4 justify-content-end mt-5'>
                            <button className='btn btn-primary-bordered' type='button' onClick={handleCancel}>
                                Cancel
                            </button>
                            <RoleBaseAccess moduleName='settings' isSubmit>
                                <Button
                                    className='btn btn-primary'
                                    type='Submit'
                                    disabled={!formik.getFieldProps(prop).value || loading}
                                    icon={loading && "pi pi-spin pi-spinner"}
                                    label={loading ? rowData ? 'Updating...' : 'Creating...' : rowData ? 'Update' : 'Create'}
                                >
                                </Button>
                            </RoleBaseAccess>
                        </div>
                    </p>
                </form>
            </Dialog>
        </div>
    )
}
