import { jobsArgs } from 'app/pages/jobs/Jobs';
import { takeLatest } from 'redux-saga/effects';
import { getBuilding, getJobsListingWithDetails } from './JobsCRUD';

export const GET_ALL_JOBS = 'GET_ALL_JOBS';
export const BUILDING_DETAILS = 'BUILDING_DETAILS';
export const SET_BUILDING_ID = 'BUILDING_ID';
export const SET_CLIENT_MODAL_VISIBLE = 'SET_CLIENT_MODAL_VISIBLE';
export const SET_FM_MODAL_VISIBLE = 'SET_FM_MODAL_VISIBLE';
export const SET_BUILDING_MODAL_VISIBLE = 'SET_BUILDING_MODAL_VISIBLE';
export const SET_OPERATIVE_MODAL_VISIBLE = 'SET_OPERATIVE_MODAL_VISIBLE';
export const SET_OPERATIVE_DETAIL = 'SET_OPERATIVE_DETAIL';
export const SET_JOB_DETAILS = 'SET_JOB_DETAILS';
export const SET_DEFECT_CATEGORIES = 'SET_DEFECT_CATEGORIES';
export const SET_VAULT_DATA_PRIMARY = 'SET_VAULT_DATA_PRIMARY';
export const SET_CREATE_QUESTION = 'SET_CREATE_QUESTION';
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB';
export const SET_TESTS_DATA = 'SET_TESTS_DATA';
// create actions for jobs
export const actions = {
  // get all jobs
  getAllJobs: () => {
    return {
      type: GET_ALL_JOBS,
    };
  },
  // save FM Registerdata
  saveJobs: (data: any) => {
    return {
      type: BUILDING_DETAILS,
      payload: data,
    };
  },
  // set building id
  setBuildingId: (data: any) => {
    return {
      type: SET_BUILDING_ID,
      payload: data,
    };
  },
  // set client modal visible
  setClientModalVisible: (data: any) => {
    return {
      type: SET_CLIENT_MODAL_VISIBLE,
      payload: data,
    };
  },
  // set fm modal visible
  setFmModalVisible: (data: any) => {
    return {
      type: SET_FM_MODAL_VISIBLE,
      payload: data,
    };
  },
  // set building modal visible
  setBuildingModalVisible: (data: any) => {
    return {
      type: SET_BUILDING_MODAL_VISIBLE,
      payload: data,
    };
  },
  // set operative modal visible
  setOperativeModalVisible: (data: any) => {
    return {
      type: SET_OPERATIVE_MODAL_VISIBLE,
      payload: data,
    };
  },
  // set operative detail
  setOperativeDetail: (data: any) => {
    return {
      type: SET_OPERATIVE_DETAIL,
      payload: data,
    };
  },
  // set job details
  setJobDetails: (data: any) => {
    return {
      type: SET_JOB_DETAILS,
      payload: data,
    };
  },
  // set defect categories
  setDefectCategories: (data: any) => {
    return {
      type: SET_DEFECT_CATEGORIES,
      payload: data,
    };
  },
  setValutData: (data: any) => {
    return {
      type: SET_VAULT_DATA_PRIMARY,
      payload: data,
    };
  },
  setQuestion: (data: any) => {
    return {
      type: SET_CREATE_QUESTION,
      payload: data,
    };
  },
  setSelectJob: (data: any) => {
    return {
      type: SET_SELECTED_JOB,
      payload: data,
    };
  },
  setTests: (data: any) => {
    return {
      type: SET_TESTS_DATA,
      payload: data,
    };
  },
};

const initialState = {
  jobs: [],
  singleJob: {},
  building: null,
  isQuestion: false,
  clientModalVisible: false,
};

// create reducer for jobs
export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_JOBS:
      return { ...state, jobs: action.payload };
    case SET_SELECTED_JOB:
      return { ...state, singleJob: action.payload };
    case BUILDING_DETAILS:
      return { ...state, building: action.payload };
    case SET_BUILDING_ID:
      return { ...state, buildingId: action.payload };
    case SET_CLIENT_MODAL_VISIBLE:
      return { ...state, clientModalVisible: action.payload };
    case SET_FM_MODAL_VISIBLE:
      return { ...state, fmModalVisible: action.payload };
    case SET_BUILDING_MODAL_VISIBLE:
      return { ...state, buildingModalVisible: action.payload };
    case SET_OPERATIVE_MODAL_VISIBLE:
      return { ...state, operativeModalVisible: action.payload };
    case SET_OPERATIVE_DETAIL:
      return { ...state, operativeDetail: action.payload };
    case SET_JOB_DETAILS:
      return { ...state, jobDetails: action.payload };
    case SET_DEFECT_CATEGORIES:
      return { ...state, defectCategories: action.payload };
    case SET_CREATE_QUESTION:
      return { ...state, isQuestion: action.payload };
    case SET_TESTS_DATA:
      const newValues = action.payload;
      return {
        ...state,
        building: {
          ...state.building,
          buildingAssets: state.building?.buildingAssets?.map((item: any) =>
            item._id === newValues?._id
              ? {
                ...item,
                tests: [...item?.tests, newValues.testData],
              }
              : item
          ),
        },
      };
    case SET_VAULT_DATA_PRIMARY:
      // Assuming action.payload contains the new value
      const newValue = action.payload;
      // Perform the update based on a condition
      if (newValue.lastOperation === 'addAccessibilityDefect') {
        // Create a new state object with the updated value
        return {
          ...state,
          building: {
            ...state.building,
            buildingAssets: state.building?.buildingAssets?.map((item: any) =>
              item._id === newValue.id
                ? {
                  ...item,
                  accessibility: {
                    ...item.accessibility,
                    defects: newValue.defects,
                    defect: true,
                    satisfactionStatus: newValue?.status,
                  },
                }
                : item
            ),
          },
        };
      } else if (newValue.lastOperation === 'addFireBarrierDefect') {
        // Create a new state object with the updated value
        return {
          ...state,
          building: {
            ...state.building,
            buildingAssets: state.building?.buildingAssets?.map((item: any) =>
              item._id === newValue.id
                ? {
                  ...item,
                  fireBarrier: {
                    ...item.fireBarrier,
                    defects: newValue.defects,
                    defect: true,
                    satisfactionStatus: newValue?.status,
                  },
                }
                : item
            ),
          },
        };
      } else if (newValue.lastOperation === 'addInstallationDefect') {
        // Create a new state object with the updated value
        return {
          ...state,

          building: {
            ...state.building,
            buildingAssets: state.building?.buildingAssets?.map((item: any) =>
              item._id === newValue.id
                ? {
                  ...item,
                  installation: {
                    ...item.installation,
                    defects: newValue.defects,
                    defect: true,
                    satisfactionStatus: newValue?.status,
                  },
                }
                : item
            ),
          },
        };
      } else if (newValue.lastOperation === 'addTestDefect') {
        return {
          ...state,
          building: {
            ...state.building,
            buildingAssets: state.building?.buildingAssets?.map((item: any) =>
              item._id === newValue.id
                ? {
                  ...item,
                  tests: item?.tests?.map((tes: any) =>
                    tes?._id === newValue?.testid
                      ? {
                        ...tes,
                        defects: newValue.defects,
                        defect: true,
                        satisfactionStatus: newValue?.status,
                      }
                      : tes
                  ),
                }
                : item
            ),
          },
        };
      } else {
        return {
          ...state,
          building: {
            ...state.building,
            buildingAssets: state?.building?.buildingAssets?.map((item: any) =>
              item._id === newValue.id
                ? {
                  ...item,
                  accessPanel: {
                    ...item.accessPanel,
                    defects: newValue.defects,
                    defect: true,
                    satisfactionStatus: newValue?.status,
                  },
                }
                : item
            ),
          },
        };
      }

    // return {
    //   ...state,
    //   building: {
    //     ...state.building,
    //     accessibility: {
    //       ...state.nestedObject.nestedNestedObject,
    //       key3: newValue.defects
    //     }
    //   }
    // };
    default:
      return state;
  }
};

// create saga for jobs
export function* saga() {
  yield takeLatest(GET_ALL_JOBS, getAllJobs);
  yield takeLatest(BUILDING_DETAILS, getBuildingDetails);
}

// create api for jobs
export const getAllJobs = async () => {
  const response = await getJobsListingWithDetails(jobsArgs);
  return response.data;
};

// create api for building details
export const getBuildingDetails = async (args: any) => {
  const response = await getBuilding(args);
  return response.data;
};
