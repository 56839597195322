import { error, success } from 'app/components/toast';
import {
  addAccessPanelDefect,
  addAccessibilityDefect,
  addFirebarrierDefect,
  addInstallationDefect,
  addTestDefect,
  archivedAccessPanelt,
  archivedAccessibilityDefect,
  archivedFirebarrierDefect,
  archivedInstallationDefect,
  archivedTestPanelt,
  updateAccessPanelDefect,
  updateAccessibilityDefect,
  updateFirebarrierDefect,
  updateInstallationDefect,
  updateTestDefect,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { FC, useState } from 'react';
import Select from 'react-select';
import RectifyFlow from './RectifyFlow';
// import {getBuildingDetails} from 'common/fetch-data'
import { SET_VAULT_DATA_PRIMARY } from 'app/modules/auth/redux/jobs/actions';
import { useDispatch, useSelector } from 'react-redux';
// import { SET_VAULT_DATA_PRIMARY } from 'app/modules/auth/redux/vault'
// import {log} from 'console'
import ImageUploader from 'app/components/ImageUploader';
import CustomDataTable from 'app/components/data-table/customDataTable';
import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
interface Props {
  asset: any;
  options: any;
  isAccessibility?: boolean;
  isFireBarrier?: boolean;
  isAccessPanel?: boolean;
  isInstallation?: boolean;
  isTest?: boolean;
  setOpen?: any;
  setAssetDetail?: any;
  selectedDefect?: any;
  satisfactionStatuss?: string;
  latestTest?: any;
  selectedDefectOptions?: any;
  setSelectedDefectOptions?: any;
  selectedOrientation?: any;
  getDefectDetails?: any;
}

const DefectFlow: FC<Props> = ({
  asset,
  options = [],
  isAccessibility = false,
  isFireBarrier = false,
  isAccessPanel = false,
  isInstallation = false,
  isTest = false,
  setOpen = false,
  setAssetDetail,
  selectedDefect,
  satisfactionStatuss,
  latestTest,
  selectedDefectOptions,
  setSelectedDefectOptions,
  selectedOrientation,
  getDefectDetails
}) => {
  const [details, setDetails] = useState(selectedDefect?.defectDetails ?? '');
  const [readOnly, setReadOnly] = useState(selectedDefect?.defectDetails ?? '');
  const [selectedOption, setSelectedOption] = useState(selectedDefect?.defectCategoryName ?? '');
  const [subCategoryType, setSubCategoryType] = useState(selectedDefect?.defectCategoryType ?? '');
  const [showRectifyModal, setShowRectifyModal] = useState(false);
  const [imgUrl1, setImgUrl1] = useState(selectedDefect?.defectImageUrls?.[0] ?? '');
  const [imgUrl2, setImgUrl2] = useState(selectedDefect?.defectImageUrls?.[1] ?? '');
  const [defectNote, setDefectNote] = useState(selectedDefect?.defectNote ?? '');
  const [selectedAccessPanel, setSelectedAccessPanel] = useState<any[]>([]);

  React.useEffect(() => {
    if (isAccessPanel && selectedDefect) {
      const defect = options.filter((x: any) => x.label === selectedDefect.defectCategoryName);

      if (defect.length && selectedDefect) {
        setSelectedAccessPanel([{
          ...selectedDefect,
          label: selectedDefect.defectCategoryName,
          value: selectedDefect.defectCategoryName
        }]);
      }

    }
  }, []);

  const { building } = useSelector((state: any) => state.job);

  // let status = satisfactionStatuss

  const dispatch = useDispatch();

  const previousData = (title: any) => {
    return {
      previouslyChangedBy: asset.lastModifiedBy?._id
        ? asset.lastModifiedBy._id
        : asset.lastModifiedBy,
      previouslyChangedOn: asset?.updatedAt,
      lastOperation: title,
      previousOperation: asset.lastOperation || '',
    };
  };

  const handleSubmit = async () => {
    if (isAccessibility) {
      const res: any = await (selectedDefect?._id
        ? updateAccessibilityDefect({
          defectid: selectedDefect?._id,
          //addToJob: false,
          defect: {
            defectCategoryName: selectedOption,
            defectImageUrls: [imgUrl1, imgUrl2],
            defectDetails: readOnly,
            defectNote: defectNote,
            inspectionJobId: asset?.inspectionJob,
          },
          inspectionJobId: asset?.inspectionJob,
          buildingAssetId: asset?._id,
          buildingid: asset?.building,
          satisfactionStatus: satisfactionStatuss,
          ...previousData('updateAccessibilityDefect'),
        })
        : addAccessibilityDefect({
          accessibilityOptions: selectedDefectOptions,
          defect: {
            defectCategoryName: selectedOption,
            defectImageUrls: [imgUrl1, imgUrl2],
            defectDetails: readOnly,
            defectNote: defectNote,
            inspectionJobId: asset?.inspectionJob,
          },
          inspectionJobId: asset?.inspectionJob,
          buildingAssetId: asset?._id,
          buildingid: asset?.building,
          satisfactionStatus: satisfactionStatuss,
          ...previousData('addAccessibilityDefect'),
        }));

      if (res?.status === 'Success') {
        success(
          selectedDefect?._id
            ? 'Accessibilty Defect Updated Successfully!'
            : 'Accessibilty Defect Added Successfully!'
        );

        setSelectedDefectOptions && setSelectedDefectOptions([]);

        let obj = {
          id: asset?._id,
          status: satisfactionStatuss,
          defects: res.data.accessibility?.defects,
          lastOperation: 'addAccessibilityDefect',
        };
        dispatch({
          type: SET_VAULT_DATA_PRIMARY,
          payload: obj,
        });
        if (res.data) {
          setAssetDetail(res.data);
        }
        setOpen(false);
      }
      else {
        error("Something went wrong, please try again later");
      }
    }
    if (isFireBarrier) {
      const res = await (selectedDefect?._id
        ? updateFirebarrierDefect({
          defectid: selectedDefect?._id,
          addToJob: false,
          defect: [{
            defectCategoryName: selectedOption,
            defectCategoryType: subCategoryType,
            defectDetails: readOnly,
            defectNote: defectNote,
            defectImageUrls: [imgUrl1, imgUrl2],
          }],
          inspectionJobId: asset?.inspectionJob,
          buildingid: asset?.building,
          fireBarrierOptions: selectedDefectOptions,
          buildingAssetId: asset?._id,
          ...previousData('updateFireBarrierDefect'),
        })
        : addFirebarrierDefect({
          fireBarrierOptions: selectedDefectOptions,
          satisfactionStatus: satisfactionStatuss,
          satisfactory: asset?.fireBarrier.satisfactory,
          defect: [{
            inspectionJobId: asset?.inspectionJob,
            defectCategoryName: selectedOption,
            defectDetails: readOnly,
            defectCategoryType: subCategoryType,
            defectNote: defectNote,
            defectImageUrls: [imgUrl1, imgUrl2],
          }],
          inspectionJobId: asset?.inspectionJob,
          buildingid: asset?.building,
          buildingAssetId: asset?._id,
          ...previousData('addFireBarrierDefect'),
        }));

      if (res.status === 'Success') {
        success('Fire Barrier Defect Added Successfully!');

        setSelectedDefectOptions && setSelectedDefectOptions([]);

        if (res.data) {
          setAssetDetail(res.data);
        }

        let obj = {
          id: asset?._id,
          status: satisfactionStatuss,
          defects: res.data.fireBarrier?.defects,
          lastOperation: 'addFireBarrierDefect',
        };
        dispatch({
          type: SET_VAULT_DATA_PRIMARY,
          payload: obj,
        });
        setOpen(false);
      }
      else {
        error(res?.message ?? 'Something went wrong, please try again later');
      }
    }
    if (isInstallation) {
      const res = await (selectedDefect?._id
        ? updateInstallationDefect({
          defectid: selectedDefect?._id,
          addToJob: false,
          defect: [{
            defectCategoryName: selectedOption,
            inspectionJobId: asset?.inspectionJob,
            defectCategoryType: subCategoryType,
            defectDetails: readOnly,
            defectNote: defectNote,
            defectImageUrls: [imgUrl1, imgUrl2],
          }],
          inspectionJobId: asset?.inspectionJob,
          buildingid: asset?.building,
          installationName: selectedDefectOptions.value,
          orientation: selectedOrientation,
          buildingAssetId: asset?._id,
          ...previousData('updateInstallationDefect'),
        })
        : addInstallationDefect({

          installationName: selectedDefectOptions.value,
          orientation: selectedOrientation,
          defect: [{
            defectCategoryName: selectedOption,
            inspectionJobId: asset?.inspectionJob,
            defectCategoryType: subCategoryType,
            defectDetails: readOnly,
            defectNote: defectNote,
            defectImageUrls: [imgUrl1, imgUrl2],
          }],
          buildingAssetId: asset?._id,
          buildingid: asset?.building,
          inspectionJobId: asset?.inspectionJob,
          satisfactionStatus: satisfactionStatuss,
          ...previousData('addInstallationDefect'),
        }));

      if (res.status === 'Success') {
        success('Installation Defect Added Successfully!');

        setSelectedDefectOptions && setSelectedDefectOptions([]);

        if (res.data) {
          setAssetDetail(res.data);
        }


        let obj = {
          id: asset?._id,
          status: satisfactionStatuss,
          defects: res.data.installation?.defects,
          lastOperation: 'addInstallationDefect',
        };
        dispatch({
          type: SET_VAULT_DATA_PRIMARY,
          payload: obj,
        });
        setOpen(false);
      }
      else {
        error(res?.message ?? 'Something went wrong, please try again later');
      }
    }
    if (isAccessPanel) {
      let res;
      res = await (
        selectedDefect?._id
          ? updateAccessPanelDefect({
            defectid: selectedDefect?._id,
            addToJob: false,
            satisfactionStatus: satisfactionStatuss,
            installedBothSides: asset?.accessPanel?.installedBothSides,
            defect: {
              defectCategoryName: selectedOption,
              defectCategoryType: subCategoryType,
              defectDetails: readOnly,
              defectNote: defectNote,
              defectImageUrls: [imgUrl1, imgUrl2],
              inspectionJobId: asset?.inspectionJob,
            },
            inspectionJobId: asset?.inspectionJob,
            buildingid: asset?.building,
            accessPanelOptionValue: asset?.accessPanel?.accessPanelOptionValue,
            buildingAssetId: asset?._id,
            ...previousData('updateAccessPanelDefect'),
          })
          :
          selectedOption ?
            addAccessPanelDefect({
              accessPanelOptionValue: asset?.accessPanel?.accessPanelOptionValue,
              shape: asset?.accessPanel?.shape,
              size: asset?.accessPanel?.size,
              installedBothSides: asset?.accessPanel?.installedBothSides,
              satisfactionStatus: satisfactionStatuss,
              inspectionJobId: asset?.inspectionJob,
              defect: [{
                inspectionJobId: asset?.inspectionJob,
                defectCategoryName: selectedOption,
                defectDetails: '',
                defectNote: defectNote,
                defectCategoryType: subCategoryType,
                defectImageUrls: [imgUrl1, imgUrl2],
              }],
              buildingid: asset?.building,
              buildingAssetId: asset?._id,
              ...previousData('addAccessPanelDefect'),
            })
            :
            selectedAccessPanel.forEach(async (element: any, index: number) => {
              res = await addAccessPanelDefect({
                accessPanelOptionValue: asset?.accessPanel?.accessPanelOptionValue,
                shape: asset?.accessPanel?.shape,
                size: asset?.accessPanel?.size,
                installedBothSides: asset?.accessPanel?.installedBothSides,
                satisfactionStatus: satisfactionStatuss,
                inspectionJobId: asset?.inspectionJob,
                defect: [{
                  inspectionJobId: asset?.inspectionJob,
                  defectCategoryName: element.defectsubCatgTitle,
                  defectDetails: element.defectsubCatgText,
                  defectNote: defectNote,
                  defectCategoryType: element.defectType,
                  defectImageUrls: [imgUrl1, imgUrl2],
                }],
                buildingid: asset?.building,
                buildingAssetId: asset?._id,
                ...previousData('addAccessPanelDefect'),
              });
              if (res?.status === 'Success' && index === selectedAccessPanel.length - 1) {
                success('Access Panel Defect Added Successfully!');

                if (res.data) {
                  setAssetDetail(res.data);
                }

                let obj = {
                  id: asset?._id,
                  status: satisfactionStatuss,
                  defects: res.data.accessPanel?.defects,
                  lastOperation: 'addAccessPanelDefect',
                };
                dispatch({
                  type: SET_VAULT_DATA_PRIMARY,
                  payload: obj,
                });

                setOpen(false);
              }
            })
      );

      if (res && res?.status === 'Success') {
        success('Access Panel Defect Added Successfully!');

        if (res.data) {
          setAssetDetail(res.data);
        }

        let obj = {
          id: asset?._id,
          status: satisfactionStatuss,
          defects: res.data.accessPanel?.defects,
          lastOperation: 'addAccessPanelDefect',
        };
        dispatch({
          type: SET_VAULT_DATA_PRIMARY,
          payload: obj,
        });

        setOpen(false);
      }
      else {
        res && error(res?.message ?? 'Something went wrong, please try again later');
      }
    }
    if (isTest) {
      const res = await (selectedDefect?._id
        ? updateTestDefect({
          defectid: selectedDefect?._id,
          defect: {
            defectCategoryName: selectedOption,
            defectCategoryType: satisfactionStatuss,
            defectDetails: readOnly,
            defectNote: defectNote,
            defectImageUrls: [imgUrl1, imgUrl2],
          },
          inspectionJobId: asset?.inspectionJob,
          buildingid: asset?.building,
          testid: latestTest?._id,
          buildingAssetId: asset?._id,
          ...previousData('updateTestDefect'),
        })
        : addTestDefect({
          satisffactoryStatus: satisfactionStatuss,
          testDate: latestTest?.testDate,
          defect: {
            inspectionJobId: asset?.inspectionJob,
            defectCategoryName: selectedOption,
            defectDetails: readOnly,
            defectNote: defectNote,
            defectCategoryType: satisfactionStatuss,
            defectImageUrls: [imgUrl1, imgUrl2],
          },
          inspectionJobId: asset?.inspectionJob,
          buildingid: asset?.building,
          testid: latestTest?._id,
          buildingAssetId: asset?._id,
          ...previousData('addTestDefect'),
        }));
      if (res.status === 'Success') {
        success(
          selectedDefect?._id
            ? 'Test Defect updated Successfully!'
            : 'Test Defect Added Successfully!'
        );

        if (res.data) {
          setAssetDetail(res.data);

          if (getDefectDetails) {
            getDefectDetails(res.data);
          }
        }

        let defect = res.data?.tests.find((f: any) => f._id === latestTest?._id);

        let obj = {
          id: asset?._id,
          testid: latestTest?._id,
          status: satisfactionStatuss,
          defects: defect?.defects,
          lastOperation: 'addTestDefect',
        };
        dispatch({
          type: SET_VAULT_DATA_PRIMARY,
          payload: obj,
        });
        setOpen(false);
      }
      else {
        error(res?.message ?? 'Something went wrong, please try again later');
      }
    }
  };

  const archivedDefect = async () => {
    let res;
    let body = {
      defectid: selectedDefect?._id,
      buildingAssetId: asset?._id,
    };
    if (isAccessibility) {
      body = {
        ...body,
        ...previousData('Archive Accessibility Defect1'),
      };

      res = await archivedAccessibilityDefect(body);
      if (res?.status === 'Success') {
        success('Accessibilty Defect Archived Successfully!');
        setOpen(false);
      }
    } else if (isFireBarrier) {
      body = {
        ...body,
        ...previousData('Archive FireBarrier Defect1'),
      };

      res = await archivedFirebarrierDefect(body);
      if (res?.status === 'Success') {
        success('FireBarrier Defect Archived Successfully!');
        setOpen(false);
      }
    } else if (isInstallation) {
      body = {
        ...body,
        ...previousData('Archive Installation Defect1'),
      };

      res = await archivedInstallationDefect(body);
      if (res?.status === 'Success') {
        success('Installation Defect Archived Successfully!');
        setOpen(false);
      }
    } else if (isAccessPanel) {
      body = {
        ...body,
        ...previousData('Archive AccessPanel Defect1'),
      };
      res = await archivedAccessPanelt(body);
      if (res?.status === 'Success') {
        success('AccessPanel Defect Archived Successfully!');
        setOpen(false);
      }
    } else if (isTest) {
      body = {
        ...body,
        ...previousData('Archive Test Defect1'),
      };
      res = await archivedTestPanelt(body);
      if (res?.status === 'Success') {
        success('Test Defect Archived Successfully!');
        setOpen(false);
      }
    }
  };

  const isDisable = readOnly || selectedOption || selectedAccessPanel?.length ? false : true;

  return (
    <div className='model_data'>
      {selectedDefect?._id && <DefectsViewTable defect={selectedDefect} />}
      <div style={{ padding: '10px 20px' }}>
        <label>
          <b>Choose Subcategory</b>
        </label>
        {isAccessPanel
          ?
          <>
            <MultiSelect
              hasSelectAll={false}
              options={options}
              value={selectedAccessPanel}
              onChange={(e: any) => {
                setSelectedAccessPanel(e);
              }
              }
              className='multi-select'
              labelledBy='Select'
            />
          </>
          :
          <>
            <Select
              placeholder='Subcategory'
              options={options}
              isClearable
              getOptionLabel={(option: any) => option.defectsubCatgTitle}
              getOptionValue={(option: any) => String(option.defectsubCatgText)}
              onChange={(e: any) => {
                setReadOnly(e?.defectsubCatgText);
                setSelectedOption(e?.defectsubCatgTitle);
                setSubCategoryType(e?.defectType);
              }}
              defaultValue={options.find(
                (item: any) => item?.defectsubCatgTitle === selectedDefect?.defectCategoryName
              )}
            />
          </>
        }
        <br />

        <div>
          <p>
            <b>Details</b>
          </p>
          {isAccessPanel &&
            selectedAccessPanel?.length ?

            selectedAccessPanel?.map((item: any) => (
              <>
                <div className={`row`}>
                  <div className='col-lg-12'>
                    <b key={item?._id}>{item?.label}</b>
                    <p style={{ color: 'black' }}>{item?.defectsubCatgText}</p>
                  </div>
                </div>
              </>
            ))
            :
            <></>

          }
          <p style={{ color: 'black' }}>{readOnly}</p>

          <label>
            <b>Operative Notes</b>
          </label>
          <textarea
            value={defectNote}
            onChange={(e) => setDefectNote(e.target.value)}
            rows={5}
            name='defectSubcategory'
            className='my-2 form-control'
            disabled={isDisable}
          />
          <br />
          {!isDisable &&
            (
              <>
                <label>
                  <b>Add Photo</b>
                </label>
                <div className='d-xl-flex pt-4 pb-5 flex-row justify-content-start align-items-center '>
                  <div className='mb-5'>
                    <ImageUploader img={imgUrl1} building={asset} setImageUrl={setImgUrl1} isDefectImg />
                  </div>
                  <div className='ms-xl-10'>
                    <ImageUploader img={imgUrl2} building={asset} setImageUrl={setImgUrl2} isDefectImg />
                  </div>
                </div>
              </>
            )
          }

          <RoleBaseAccess moduleName='Vault' isSubmit>
            <div className='d-lg-flex justify-content-end align-items-center gap-5 mt-4 mb-4'>
              {selectedDefect && (
                <button
                  className='btn btn-primary mb-5 mb-lg-0'
                  onClick={() => {
                    setShowRectifyModal(true);
                  }}
                >
                  Rectify Defect
                </button>
              )}

              <button className='btn btn-primary' onClick={handleSubmit}>
                {selectedDefect?._id ? 'Update' : 'Submit'}
              </button>

              {/* {selectedDefect?._id && (
                <button className='btn btn-light text-dark' onClick={archivedDefect}>
                  Archived
                </button>
              )} */}
            </div>
          </RoleBaseAccess>
        </div>

        <Dialog header={"Rectify Defect"} visible={showRectifyModal} style={{ width: '45vw' }} onHide={() => setShowRectifyModal(false)} draggable={false}>
          <RectifyFlow
            isAccessPanel={isAccessPanel}
            isAccessibility={isAccessibility}
            isFireBarrier={isFireBarrier}
            isInstallation={isInstallation}
            isTest={isTest}
            setOpen={setOpen}
            selectedDefect={selectedDefect}
            assetDetails={asset}
          />
        </Dialog>
      </div>
    </div >
  );
};

export default DefectFlow;

const DefectsViewTable = ({ defect }: { defect: any; }) => {

  const jobref = useSelector((state: any) => state.job?.singleJob);

  const defects = [
    {
      ...defect,
      index: 1,
      isDefectRectified: defect?.defectRectification?.defectRectified ? 'Rectified' : 'Defect',
      opName: defect?.defectAddedBy || 'N/A',
      jobReference: jobref?.jobReference,
      addedOn: defect?.defectAddedOn ?? 'N/A',
      addedTime: defect?.defectAddedTime ?? 'N/A'
    }
  ];

  const columns = [
    { field: 'index', header: 'No' },
    { field: 'defectCategoryName', header: 'Defect' },
    { field: 'isDefectRectified', header: 'Status' },
    { field: 'opName', header: 'Operative Name' },
    { field: 'jobReference', header: 'Job Ref No' },
    { field: 'addedOn', header: 'Date' },
    { field: 'addedTime', header: 'Time' },
  ];

  return (
    <>
      <CustomDataTable
        data={defects}
        columns={columns}
        isHideSearch
      />
    </>
  );
};
