export const SET_REPORT_HEADER_DATA = 'SET_REPORT_HEADER_DATA'
export const SET_HEADER_FORM_INSTANCE = 'SET_HEADER_FORM_INSTANCE'
export const SET_REPORT_HEADER_ID = 'SET_REPORT_HEADER_ID'
export const SET_REPORT_CERTIFICATE_ID = 'SET_REPORT_CERTIFICATE_ID'
export const SET_REPORT_GUIDANCE_ID = 'SET_SET_REPORT_GUIDANCE_ID'
export const SET_REPORT_SERVICE_ID = 'SET_SET_REPORT_SERVICE_ID'
export const SET_REPORT_SUMMARY_ID = 'SET_SET_REPORT_SUMMARY_ID'
export const SET_REPORT_ASSET_REGISTER_ID = 'SET_SET_REPORT_ASSET_REGISTER_ID'
export const SET_REPORT_DEFECT_LOG_ID = 'SET_REPORT_DEFECT_LOG_ID'
export const SET_REPORT_INVENTORY_ID = 'SET_REPORT_INVENTORY_ID'
export const REPORT_FORM_SUBMITTING = 'SET_REPORT_FORM_SUBMITTING'
export const SET_CLIENT_DETAILS = 'SET_SET_CLIENT_DETAILS'
export const SET_JOB_DETAILS = 'SET_JOB_DETAILS'
export const DISABLE_SUBMIT_BUTTON = 'DISABLE_SUBMIT_BUTTON'
export const SET_REPORT_DOWNLOADING = 'SET_REPORT_DOWNLOADING'

// gather the new report data
export const SET_CREATE_INSPECTION_JOB_DATA = 'SET_CREATE_INSPECTION_JOB_DATA'
export const SET_CREATE_INSPECTION_JOB_ID = 'SET_CREATE_INSPECTION_JOB_ID'
export const SET_CREATE_REMEDIAL_INSPECTION_JOB_DATA = 'SET_CREATE_REMEDIAL_INSPECTION_JOB_DATA'
export const SET_HEADER_DATA = 'SET_HEADER_DATA'
export const SET_CERTIFICATE_DATA = 'SET_CERTIFICATE_DATA'
export const SET_GUIDANCE_DATA = 'SET_GUIDANCE_DATA'
export const SET_SERVICES_DATA = 'SET_SERVICES_DATA'
export const SET_SUMMARY_DATA = 'SET_SUMMARY_DATA'
export const SET_ASSET_REGISTER_DATA = 'SET_ASSET_REGISTER_DATA'
export const SET_DEFECT_LOG_DATA = 'SET_DEFECT_LOG_DATA'
export const SET_ASSET_PAGE_DATA = 'SET_ASSET_PAGE_DATA'
export const SET_INVENTORY_DATA = 'SET_INVENTORY_DATA'
export const SET_DISCLAIMER_DATA = 'SET_DISCLAIMER_DATA'
export const SET_COMPETENCY_DATA = 'SET_COMPETENCY_DATA'
export const SET_REPORT_TYPE = 'SET_REPORT_TYPE'
export const SET_OPERATIVES_COMPETENCES = 'SET_OPERATIVES_COMPETENCES'
export const SET_COMPETENCEY_DATA = 'SET_COMPETENCEY_DATA'
export const SET_CLEAR_DATA = 'SET_CLEAR_DATA'
export const SET_BUILDING_DATA = 'SET_BUILDING_DATA'

export const actions = {
  setJobid: (data: any) => {
    return {
      type: SET_CREATE_INSPECTION_JOB_DATA,
      payload: data,
    }
  },
  setRemedialJobid: (data: any) => {
    return {
      type: SET_CREATE_REMEDIAL_INSPECTION_JOB_DATA,
      payload: data,
    }
  },
  setHeaderData: (data: any) => {
    return {
      type: SET_HEADER_DATA,
      payload: data,
    }
  },
  setGuidanceData: (data: any) => {
    return {
      type: SET_GUIDANCE_DATA,
      payload: data,
    }
  },
  setCertificateData: (data: any) => {
    return {
      type: SET_CERTIFICATE_DATA,
      payload: data,
    }
  },
  setSummaryData: (data: any) => {
    return {
      type: SET_SUMMARY_DATA,
      payload: data,
    }
  },
  setAssetRegisterData: (data: any) => {
    return {
      type: SET_ASSET_REGISTER_DATA,
      payload: data,
    }
  },
  setDefectLogData: (data: any) => {
    return {
      type: SET_DEFECT_LOG_DATA,
      payload: data,
    }
  },
  setAssetPageData: (data: any) => {
    return {
      type: SET_ASSET_PAGE_DATA,
      payload: data,
    }
  },
  setInventoryData: (data: any) => {
    return {
      type: SET_INVENTORY_DATA,
      payload: data,
    }
  },
  setCompetencyData: (data: any) => {
    return {
      type: SET_COMPETENCY_DATA,
      payload: data,
    }
  },
  // =============================================================
  setReportHeaderData: (data: any) => {
    return {
      type: SET_REPORT_HEADER_DATA,
      payload: data,
    }
  },
  setHeaderFormInstance: (form: any) => {
    return {
      type: SET_HEADER_FORM_INSTANCE,
      payload: form,
    }
  },
  setReportHeaderId: (id: any) => {
    return {
      type: SET_REPORT_HEADER_ID,
      payload: id,
    }
  },
  setReportCertificateId: (id: any) => {
    return {
      type: SET_REPORT_CERTIFICATE_ID,
      payload: id,
    }
  },
  setReportFormSubmitting: (data: any) => {
    return {
      type: REPORT_FORM_SUBMITTING,
      payload: data,
    }
  },
  setClientDetails: (data: any) => {
    return {
      type: SET_CLIENT_DETAILS,
      payload: data,
    }
  },
  setReportGuidanceId: (id: string) => {
    return {
      type: SET_REPORT_GUIDANCE_ID,
      payload: id,
    }
  },
  setReportServiceId: (id: string) => {
    return {
      type: SET_REPORT_SERVICE_ID,
      payload: id,
    }
  },
  setJobDetails: (data: any) => {
    return {
      type: SET_JOB_DETAILS,
      payload: data,
    }
  },
  setReportDownloading: (data: any) => {
    return {
      type: SET_REPORT_DOWNLOADING,
      payload: data,
    }
  },
  setDefectLogId: (id: string) => {
    return {
      type: SET_REPORT_DEFECT_LOG_ID,
      payload: id,
    }
  },
  setInventoryId: (id: string) => {
    return {
      type: SET_REPORT_INVENTORY_ID,
      payload: id,
    }
  },
  setSummaryId: (id: string) => {
    return {
      type: SET_REPORT_SUMMARY_ID,
      payload: id,
    }
  },
  setAssetRegisterId: (id: string) => {
    return {
      type: SET_REPORT_ASSET_REGISTER_ID,
      payload: id,
    }
  },
  disableSubmitButton: (data: any) => {
    return {
      type: DISABLE_SUBMIT_BUTTON,
      payload: data,
    }
  },
  setReportType: (data: any) => {
    return {
      type: SET_REPORT_TYPE,
      payload: data,
    }
  },
  setOperativesCompetences: (data: any) => {
    return {
      type: SET_OPERATIVES_COMPETENCES,
      payload: data,
    }
  },
  setCompetenctData: (data: any) => {
    return {
      type: SET_COMPETENCEY_DATA,
      payload: data,
    }
  },
  setClear: (data: any) => {
    return {
      type: SET_CLEAR_DATA,
      payload: '',
    }
  },

  setBuildingData: (data: any) => {
    return {
      type: SET_BUILDING_DATA,
      payload: data,
    }
  },
  setInpectionJobId: (data: any) => {
    return {
      type: SET_CREATE_INSPECTION_JOB_ID,
      payload: data,
    }
  },

  setDisclaimer: (data: any) => {
    return {
      type: SET_DISCLAIMER_DATA,
      payload: data,
    }
  },
}
