function TemplateCardFooter({
  form,
  isEditing,
  setEditing,
  formDisabled,
}: {
  form: any
  isEditing?: boolean
  setEditing?: any
  formDisabled?: boolean
}) {
  return (
    <div className='card-footer d-flex gap-4 justify-content-end'>
      <button className='btn btn-primary' type='submit' disabled={formDisabled}>
        {!form.isSubmitting && (
          <span className='indicator-label'>{isEditing ? 'Update' : 'Create'}</span>
        )}
        {form.isSubmitting && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
      {isEditing && (
        <button
          className='btn btn-danger'
          type='reset'
          onClick={() => {
            setEditing(false)
            form.resetForm()
          }}
        >
          Cancel
        </button>
      )}
    </div>
  )
}

export default TemplateCardFooter
