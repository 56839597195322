import {
  DISABLE_SUBMIT_BUTTON,
  REPORT_FORM_SUBMITTING,
  SET_ASSET_PAGE_DATA,
  SET_ASSET_REGISTER_DATA,
  SET_BUILDING_DATA,
  SET_CERTIFICATE_DATA,
  SET_CLEAR_DATA,
  SET_CLIENT_DETAILS,
  SET_COMPETENCEY_DATA,
  SET_COMPETENCY_DATA,
  SET_CREATE_INSPECTION_JOB_DATA,
  SET_CREATE_INSPECTION_JOB_ID,
  SET_CREATE_REMEDIAL_INSPECTION_JOB_DATA,
  SET_DEFECT_LOG_DATA,
  SET_DISCLAIMER_DATA,
  SET_GUIDANCE_DATA,
  SET_HEADER_FORM_INSTANCE,
  SET_INVENTORY_DATA,
  SET_JOB_DETAILS,
  SET_OPERATIVES_COMPETENCES,
  SET_REPORT_ASSET_REGISTER_ID,
  SET_REPORT_CERTIFICATE_ID,
  SET_REPORT_DEFECT_LOG_ID,
  SET_REPORT_DOWNLOADING,
  SET_REPORT_GUIDANCE_ID,
  SET_REPORT_HEADER_DATA,
  SET_REPORT_HEADER_ID,
  SET_REPORT_INVENTORY_ID,
  SET_REPORT_SERVICE_ID,
  SET_REPORT_SUMMARY_ID,
  SET_REPORT_TYPE,
  SET_SERVICES_DATA,
  SET_SUMMARY_DATA,
} from './actions'

const initialState = {
  buildingData: {},
  inspectionJobId: '',
  reportHeaderData: null,
  formInstance: null,
  reportHeaderId: null,
  reportCertificateId: null,
  reportFormSubmitting: false,
  clientDetails: null,
  reportGuidanceId: null,
  reportServiceId: null,
  jobDetails: null,
  isReportDownloading: false,
  reportDefectLogId: null,
  reportInventoryId: null,
  reportSummaryId: null,
  worksheet: {},
  reportAssetRegisterId: null,
  createInspectionJobData: {
    inspectionJob: '',
    client: '',
    clientRepresentative: '',
    facilityManager: '',
    building: '',
    dateRenewalDate: '',
    attachment: '',
    archived: '',
    inspectionReport: '',
    remedialInspectionReports: '',
  },
  createRemedialInspectionReportData: {
    remedialActionReportName: '',
    headerDataReportsRemedial: '',
    certificateReportsRemedial: '',
    remedialActionLogs: '',
    footerDataReportsRemedial: '',
    remedialCompetencies: '',
    remedialActionSummary: '',
  },
  disableSubmitButton: true,
  reportType: '',
  compentancy: [],
}

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    // ******************create report data******************
    case SET_CREATE_INSPECTION_JOB_DATA:
      return {
        ...state,
        createInspectionJobData: action.payload,
      }
    case SET_DISCLAIMER_DATA:
      return {
        ...state,
        worksheet: action.payload,
      }
    case SET_CREATE_REMEDIAL_INSPECTION_JOB_DATA:
      return {
        ...state,
        createRemedialInspectionReportData: action.payload,
      }
    case SET_REPORT_HEADER_DATA:
      return {
        ...state,
        reportHeaderData: action.payload,
      }
    case SET_CERTIFICATE_DATA:
      return {
        ...state,
        reportCertificateData: action.payload,
      }
    case SET_GUIDANCE_DATA:
      return {
        ...state,
        reportGuidanceData: action.payload,
      }
    case SET_SERVICES_DATA:
      return {
        ...state,
        reportServicesData: action.payload,
      }
    case SET_SUMMARY_DATA:
      return {
        ...state,
        reportSummaryData: action.payload,
      }
    case SET_ASSET_REGISTER_DATA:
      return {
        ...state,
        reportAssetRegisterData: action.payload,
      }
    case SET_DEFECT_LOG_DATA:
      return {
        ...state,
        reportDefectLogData: action.payload,
      }
    case SET_ASSET_PAGE_DATA:
      return {
        ...state,
        reportAssetPageData: action.payload,
      }
    case SET_INVENTORY_DATA:
      return {
        ...state,
        reportInventoryData: action.payload,
      }
    case SET_COMPETENCY_DATA:
      return {
        ...state,
        reportCompetencyData: action.payload,
      }
    case SET_REPORT_TYPE:
      return {
        ...state,
        reportType: action.payload,
      }
    // ******************create report data******************

    // case SET_REPORT_HEADER_DATA:
    //   return { ...state, reportHeaderData: action.payload };
    case SET_HEADER_FORM_INSTANCE:
      return {
        ...state,
        formInstance: action.payload,
      }
    case SET_REPORT_HEADER_ID:
      return {
        ...state,
        reportHeaderId: action.payload,
      }
    case SET_REPORT_CERTIFICATE_ID:
      return {
        ...state,
        reportCertificateId: action.payload,
      }
    case REPORT_FORM_SUBMITTING:
      return {
        ...state,
        reportFormSubmitting: action.payload,
      }
    case SET_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      }
    case SET_REPORT_GUIDANCE_ID:
      return {
        ...state,
        reportGuidanceId: action.payload,
      }
    case SET_REPORT_SERVICE_ID:
      return {
        ...state,
        reportServiceId: action.payload,
      }
    case SET_JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.payload,
      }
    case SET_REPORT_DOWNLOADING:
      return {
        ...state,
        isReportDownloading: action.payload,
      }
    case SET_REPORT_DEFECT_LOG_ID:
      return {
        ...state,
        reportDefectLogId: action.payload,
      }
    case SET_REPORT_INVENTORY_ID:
      return {
        ...state,
        reportInventoryId: action.payload,
      }
    case SET_REPORT_SUMMARY_ID:
      return {
        ...state,
        reportSummaryId: action.payload,
      }
    case SET_REPORT_ASSET_REGISTER_ID:
      return {
        ...state,
        reportAssetRegisterId: action.payload,
      }
    case DISABLE_SUBMIT_BUTTON:
      return {
        ...state,
        disableSubmitButton: action.payload,
      }
    case SET_OPERATIVES_COMPETENCES:
      return {
        ...state,
        operativeCompetences: action.payload,
      }
    case SET_BUILDING_DATA:
      return {
        ...state,
        buildingData: action.payload,
      }
    case SET_CLEAR_DATA:
      return {
        ...initialState,
      }
    case SET_COMPETENCEY_DATA:
      return {
        ...state,
        compentancy: action.payload,
      }
    case SET_CREATE_INSPECTION_JOB_ID:
      return {
        ...state,
        inspectionJobId: action.payload,
      }

    default:
      return state
  }
}
