import {error, success} from 'app/components/toast'
import {DISABLE_SUBMIT_BUTTON} from 'app/modules/auth/redux/create-report/actions'
import {
  createReportGuidanceTemplate,
  getReportGuidanceTemplateListing,
  removeReportGuidanceTemplate,
  updateReportGuidanceTemplate,
} from 'app/modules/auth/redux/jobs/JobsCRUD'
import {useFormik} from 'formik'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import TemplateCardFooter from '../TemplateCardFooter'

const StyledButton = styled.button`
  display: flex;
  align-items: end;
  justify-content: end;
  width: fit-content;
`

interface IRegulationStandard {
  orderNo: string
  paraText: string
}

interface IRegulationLaw {
  orderNo: string
  regulationTitle: string
  regulationText: string
}

interface IGuidance {
  guidanceTemplateid?: string
  guidanceTitle: string
  regulationsStandands: IRegulationStandard[]
  regulationLaws: IRegulationLaw[]
}

export const guidanceTemplateProps: any = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: [],
  },
}

const GuidanceTemplate = () => {
  const [reportGuidanceTemplates, setReportGuidanceTemplates] = useState<IGuidance[]>([])
  const [sortOrder, setSortOrder] = useState<number>(-1)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isEditing, setEditing] = useState<boolean>(false)

  const dispatch = useDispatch()

  const getGuidanceTemplates = async () => {
    try {
      const res = await getReportGuidanceTemplateListing(guidanceTemplateProps)
      if (res.status === 'Success') {
        res.data.guidanceTemplates.length &&
          res.data.guidanceTemplates.map((temp: any, i: number) => {
            temp.serial = i + 1
          })
        return res.data.guidanceTemplates
      }
    } catch (err) {
      setLoading(false)
      error('Could not fetch guidance templates')
    }
  }

  const getAllData = async () => {
    const [templates] = await Promise.all([getGuidanceTemplates()])
    setReportGuidanceTemplates(templates)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getAllData()
  }, [])

  const initialValues: IGuidance = {
    guidanceTitle: '',
    regulationsStandands: [],
    regulationLaws: [],
  }

  const validationSchema: any = Yup.object().shape({
    guidanceTitle: Yup.string().required('Guidance Title is required'),
    regulationsStandands: Yup.array().of(
      Yup.object().shape({
        paraText: Yup.string().required('Para Text is required'),
      })
    ),
    regulationLaws: Yup.array().of(
      Yup.object().shape({
        regulationTitle: Yup.string().required('Regulation Title is required'),
        regulationText: Yup.string().required('Regulation Text is required'),
      })
    ),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: IGuidance, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      let response = null
      setSubmitting(false)
      if (isEditing) {
        response = await updateReportGuidanceTemplate(values)
      } else {
        response = await createReportGuidanceTemplate(values)
      }
      try {
        if (response.status === 'Success') {
          success('Guidance template created successfully')
          dispatch({
            type: DISABLE_SUBMIT_BUTTON,
            payload: false,
          })
          setSubmitting(false)
          setEditing(false)
          getAllData()
        }
      } catch (err) {
        setLoading(false)
        error('Could not create Guidance template')
      }
    },
  })

  const sortBy = (event: any) => {
    const {value} = event.target

    if (sortOrder === 1) {
      setSortOrder(-1)
    } else {
      setSortOrder(1)
    }
  }

  const onEditGuidance = (row: any) => {
    setEditing(true)
    formik.setValues(row)
    formik.setFieldValue('guidanceTemplateid', row._id)
  }

  const onArchive = async (id: string) => {
    const body = {id}
    const res = await removeReportGuidanceTemplate(body)
    if (res.status === 'Success') {
      success('Guidance template archived successfully')
      getAllData()
    } else {
      error('Could not archive Guidance template')
    }
  }

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    }
  }

  const columns: any = [
    {
      dataField: 'serial',
      text: '#',
      sort: true,
      headerStyle: (column: any, index: number) => {
        return {
          fontWeight: 'bold',
          width: '5%',
        }
      },
    },
    {
      dataField: 'guidanceTitle',
      text: 'Title',
      sort: true,
      headerStyle,
    },
    {
      dataField: 'updatedAt',
      text: 'Created',
      sort: true,
      headerStyle,
      formatter: (cellContent: any, row: any) => (
        <span>{moment(row.updatedAt).format('YYYY-MM-DD')}</span>
      ),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      headerStyle,
      formatter: (cellContent: any, row: any) => (
        <div>
          <span
            className='btn btn-xs btn-icon btn-secondary me-2'
            onClick={() => onEditGuidance(row)}
          >
            <i className='la la-pencil-alt' />
          </span>
          <span
            data-toggle='tooltip'
            title='Archive'
            className='btn btn-xs btn-icon btn-info me-2'
            onClick={() => onArchive(row._id)}
          >
            <i className='la la-archive' />
          </span>
        </div>
      ),
    },
  ]

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else
    return (
      <div className='row w-full'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <form onSubmit={formik.handleSubmit}>
              <div className='card-body'>
                <div className='form-group'>
                  <label htmlFor='guidanceTitle'>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('guidanceTitle')}
                  />
                  {formik.touched.guidanceTitle && formik.errors.guidanceTitle ? (
                    <div className='text-danger'>{formik.errors.guidanceTitle}</div>
                  ) : null}
                </div>
                <div className='form-group d-flex flex-column gap-2'>
                  <div className='d-flex flex-row w-full justify-content-between align-items-center'>
                    <label htmlFor=''>Regulation Standards</label>
                    <button
                      type='button'
                      className='btn btn-icon btn-primary'
                      onClick={() =>
                        formik.setFieldValue('regulationsStandands', [
                          ...formik.values.regulationsStandands,
                          '',
                        ])
                      }
                    >
                      <i className='la la-plus text-lg' />
                    </button>
                  </div>
                  {formik.values.regulationsStandands.map((regulation, index) => (
                    <div
                      key={index}
                      className='form-group d-flex flex-column mb-2 gap-2 align-items-end'
                    >
                      <StyledButton
                        type='button'
                        className='btn btn-icon btn-danger'
                        onClick={() =>
                          formik.setFieldValue(
                            'regulationsStandands',
                            formik.values.regulationsStandands.filter((_, i) => i !== index)
                          )
                        }
                      >
                        <i className='la la-trash text-lg' />
                      </StyledButton>
                      <input
                        type='text'
                        className='form-control d-none'
                        {...formik.getFieldProps(`regulationsStandands[${index}].orderNo`)}
                        defaultValue={regulation.orderNo}
                        value={index}
                        placeholder='Order No'
                        // onChange={() => formik.setFieldValue('orderNo', index)}
                      />
                      <textarea
                        rows={4}
                        className='form-control'
                        {...formik.getFieldProps(`regulationsStandands[${index}].paraText`)}
                        defaultValue={regulation.paraText}
                        placeholder='Enter paragragh'
                        required={true}
                        onBlur={() => {
                          formik.setFieldValue(`regulationsStandands[${index}].orderNo`, index)
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className='form-group d-flex flex-column gap-2'>
                  <div className='d-flex flex-row w-full justify-content-between align-items-center'>
                    <label htmlFor=''>Regulation Laws</label>
                    <button
                      type='button'
                      className='btn btn-icon btn-primary'
                      onClick={() =>
                        formik.setFieldValue('regulationLaws', [
                          ...formik.values.regulationLaws,
                          '',
                        ])
                      }
                    >
                      <i className='la la-plus text-lg' />
                    </button>
                  </div>
                  {formik.values.regulationLaws.map((regulation, index) => (
                    <div
                      key={index}
                      className='form-group d-flex flex-column mb-2 gap-2 align-items-end'
                    >
                      <StyledButton
                        type='button'
                        className='btn btn-icon btn-danger'
                        onClick={() =>
                          formik.setFieldValue(
                            'regulationLaws',
                            formik.values.regulationLaws.filter((_, i) => i !== index)
                          )
                        }
                      >
                        <i className='la la-trash text-lg' />
                      </StyledButton>
                      <input
                        type='text'
                        className='form-control d-none'
                        {...formik.getFieldProps(`regulationLaws[${index}].orderNo`)}
                        defaultValue={regulation.orderNo}
                        value={index}
                        placeholder='Order No'
                        onChange={() =>
                          formik.setFieldValue(`regulationLaws[${index}].orderNo`, index)
                        }
                      />
                      <input
                        type='text'
                        className='form-control'
                        {...formik.getFieldProps(`regulationLaws[${index}].regulationTitle`)}
                        defaultValue={regulation.regulationTitle}
                        placeholder='Regulation Title'
                        required={true}
                        onBlur={() => {
                          formik.setFieldValue(`regulationLaws[${index}].orderNo`, index)
                        }}
                      />
                      <textarea
                        rows={4}
                        className='form-control'
                        {...formik.getFieldProps(`regulationLaws[${index}].regulationText`)}
                        defaultValue={regulation.regulationText}
                        placeholder='Regulation Text'
                        required={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <TemplateCardFooter form={formik} isEditing={isEditing} setEditing={setEditing} />
            </form>
          </div>
        </div>
      </div>
    )
}

export default GuidanceTemplate
