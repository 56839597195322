import {error, success} from 'app/components/toast'
import {DISABLE_SUBMIT_BUTTON} from 'app/modules/auth/redux/create-report/actions'
import {
  createReportServiceTemplate,
  getReportServiceTemplateListing,
  removeReportServiceTemplate,
  updateReportServiceTemplate,
} from 'app/modules/auth/redux/jobs/JobsCRUD'
import {useFormik} from 'formik'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'
import TemplateCardFooter from '../TemplateCardFooter'

const StyledButton = styled.button`
  display: flex;
  align-items: end;
  justify-content: end;
  width: fit-content;
`

interface IServiceBody {
  parasText: Array<string>
  bulletsText: Array<string>
}

interface IService {
  serviceTemplateid?: string
  serviceTitle: string
  serviceBodies: IServiceBody[]
}

export const serviceTemplateProps: any = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: [],
  },
}

const ServiceTemplate = () => {
  const [reportServiceTemplates, setReportServiceTemplates] = useState<IService[]>([])
  const [sortOrder, setSortOrder] = useState<number>(-1)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isEditing, setEditing] = useState<boolean>(false)

  const dispatch = useDispatch()

  const getServiceTemplates = async () => {
    try {
      const res = await getReportServiceTemplateListing(serviceTemplateProps)
      if (res.status === 'Success') {
        res.data.serviceTemplates.length &&
          res.data.serviceTemplates.map((temp: any, i: number) => {
            temp.serial = i + 1
          })
        return res.data.serviceTemplates
      }
    } catch (err) {
      setLoading(false)
      error('Could not fetch Service templates')
    }
  }

  const getAllData = async () => {
    const [templates] = await Promise.all([getServiceTemplates()])
    setReportServiceTemplates(templates)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getAllData()
  }, [])

  const initialValues: IService = {
    serviceTitle: '',
    serviceBodies: [],
  }

  const validationSchema: any = Yup.object().shape({
    serviceTitle: Yup.string().required('Service Title is required'),
    serviceBodies: Yup.array().of(
      Yup.object().shape({
        parasText: Yup.array().of(Yup.string().required('Paragraph is required')),
        bulletsText: Yup.array().of(Yup.string().required('Bullet is required')),
      })
    ),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: IService, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      let response = null
      setSubmitting(false)
      if (isEditing) {
        response = await updateReportServiceTemplate(values)
      } else {
        response = await createReportServiceTemplate(values)
      }
      try {
        if (response.status === 'Success') {
          success(`Service template ${isEditing ? 'updated' : 'created'} successfully`)
          dispatch({
            type: DISABLE_SUBMIT_BUTTON,
            payload: true,
          })
          formik.resetForm()
          setSubmitting(false)
          setEditing(false)
          getAllData()
        }
      } catch (err) {
        setLoading(false)
        error(`Could not ${isEditing ? 'update' : 'create'} Service template`)
      }
    },
  })

  const sortBy = (event: any) => {
    const {value} = event.target

    if (sortOrder === 1) {
      setSortOrder(-1)
    } else {
      setSortOrder(1)
    }
  }

  const onEditService = (row: any) => {
    setEditing(true)
    formik.setValues(row)
    formik.setFieldValue('serviceTemplateid', row._id)
  }

  const onArchive = async (id: string) => {
    const body = {id}
    const res = await removeReportServiceTemplate(body)
    if (res.status === 'Success') {
      success('Service template archived successfully')
      getAllData()
    } else {
      error('Could not archive Service template')
    }
  }

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    }
  }

  const columns: any = [
    {
      dataField: 'serial',
      text: '#',
      sort: true,
      headerStyle: (column: any, index: number) => {
        return {
          fontWeight: 'bold',
          width: '5%',
        }
      },
    },
    {
      dataField: 'serviceTitle',
      text: 'Title',
      sort: true,
      headerStyle,
    },
    {
      dataField: 'updatedAt',
      text: 'Created',
      sort: true,
      headerStyle,
      formatter: (cellContent: any, row: any) => (
        <span>{moment(row.updatedAt).format('YYYY-MM-DD')}</span>
      ),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      headerStyle,
      formatter: (cellContent: any, row: any) => (
        <div>
          <span
            className='btn btn-xs btn-icon btn-secondary me-2'
            onClick={() => onEditService(row)}
          >
            <i className='la la-pencil-alt' />
          </span>
          <span
            data-toggle='tooltip'
            title='Archive'
            className='btn btn-xs btn-icon btn-info me-2'
            onClick={() => onArchive(row._id)}
          >
            <i className='la la-archive' />
          </span>
        </div>
      ),
    },
  ]

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else
    return (
      <div className='row w-full'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <form onSubmit={formik.handleSubmit}>
              <div className='card-header'>
                <div className='card-title'>
                  <div className='card-label'>Create Service Template</div>
                </div>
              </div>
              <div className='card-body'>
                <div className='form-group'>
                  <label htmlFor='serviceTitle'>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('serviceTitle')}
                  />
                  {formik.touched.serviceTitle && formik.errors.serviceTitle ? (
                    <div className='text-danger'>{formik.errors.serviceTitle}</div>
                  ) : null}
                </div>
                <div className='form-group d-flex flex-column gap-2'>
                  <div className='d-flex flex-row w-full justify-content-between align-items-center'>
                    <label htmlFor=''>Service Body</label>
                    <button
                      type='button'
                      className='btn btn-icon btn-primary'
                      data-toggle='tooltip'
                      title='Add body'
                      onClick={() =>
                        formik.setFieldValue('serviceBodies', [
                          ...formik.values.serviceBodies,
                          {
                            parasText: [''],
                            bulletsText: [''],
                          },
                        ])
                      }
                    >
                      <i className='la la-plus text-lg' />
                    </button>
                  </div>
                  {formik.values.serviceBodies.map((body, index) => (
                    <div
                      key={index}
                      className='form-group d-flex flex-column mb-2 gap-2 align-items-end'
                    >
                      <StyledButton
                        type='button'
                        className='btn btn-icon btn-danger'
                        data-toggle='tooltip'
                        title='Remove body'
                        onClick={() =>
                          formik.setFieldValue(
                            'serviceBodies',
                            formik.values.serviceBodies.filter((_, i) => i !== index)
                          )
                        }
                      >
                        <i className='la la-trash text-lg' />
                      </StyledButton>

                      <div className='d-flex flex-row w-full justify-content-between align-items-center'>
                        <label htmlFor=''>Paragraphs</label>
                        <button
                          type='button'
                          className='btn btn-icon btn-primary'
                          data-toggle='tooltip'
                          title='Add paragraph'
                          onClick={() =>
                            formik.setFieldValue(`serviceBodies[${index}].parasText`, [
                              ...formik.values.serviceBodies[index].parasText,
                              '',
                            ])
                          }
                        >
                          <i className='la la-plus text-lg' />
                        </button>
                      </div>
                      {formik.values.serviceBodies[index].parasText.map((bullet, paraIndex) => (
                        <div
                          key={paraIndex}
                          className='form-group d-flex flex-row-reverse mb-2 gap-2 align-items-center w-full'
                        >
                          <StyledButton
                            type='button'
                            className='btn btn-icon btn-danger'
                            data-toggle='tooltip'
                            title='Remove paragraph'
                            onClick={() =>
                              formik.setFieldValue(
                                `serviceBodies[${index}].parasText`,
                                formik.values.serviceBodies[index].parasText.filter(
                                  (_, i) => i !== paraIndex
                                )
                              )
                            }
                          >
                            <i className='la la-trash text-lg' />
                          </StyledButton>
                          <input
                            type='text'
                            className='form-control'
                            {...formik.getFieldProps(
                              `serviceBodies[${index}].parasText[${paraIndex}]`
                            )}
                            defaultValue={bullet}
                            placeholder='Enter paragragh'
                            required={true}
                          />
                        </div>
                      ))}

                      <div className='d-flex flex-row w-full justify-content-between align-items-center'>
                        <label htmlFor=''>Bullets</label>
                        <StyledButton
                          type='button'
                          className='btn btn-icon btn-primary'
                          data-toggle='tooltip'
                          title='Add bullet'
                          onClick={() =>
                            formik.setFieldValue(`serviceBodies[${index}].bulletsText`, [
                              ...formik.values.serviceBodies[index].bulletsText,
                              '',
                            ])
                          }
                        >
                          <i className='la la-plus text-lg' />
                        </StyledButton>
                      </div>
                      {formik.values.serviceBodies[index].bulletsText.map((bullet, bulletIndex) => (
                        <div
                          key={bulletIndex}
                          className='form-group d-flex flex-row-reverse mb-2 gap-2 align-items-center w-full'
                        >
                          <StyledButton
                            type='button'
                            className='btn btn-icon btn-danger'
                            data-toggle='tooltip'
                            title='Remove bullet'
                            onClick={() =>
                              formik.setFieldValue(
                                `serviceBodies[${index}].bulletsText`,
                                formik.values.serviceBodies[index].bulletsText.filter(
                                  (_, i) => i !== bulletIndex
                                )
                              )
                            }
                          >
                            <i className='la la-trash text-lg' />
                          </StyledButton>
                          <input
                            type='text'
                            className='form-control'
                            {...formik.getFieldProps(
                              `serviceBodies[${index}].bulletsText[${bulletIndex}]`
                            )}
                            defaultValue={bullet}
                            placeholder='Enter bullet'
                            required={true}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <TemplateCardFooter form={formik} isEditing={isEditing} setEditing={setEditing} />
            </form>
          </div>
        </div>
      </div>
    )
}

export default ServiceTemplate
