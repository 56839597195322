import RemoteDataTable from 'app/components/data-table/RemoteDataTable'
import {getBuildingSnapshots} from 'app/modules/auth/redux/jobs/JobsCRUD'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {generatePath, useHistory, useParams} from 'react-router-dom'
import {RoutePath} from './VaultPage'

const SnapshotDetails = () => {
  const history = useHistory()
  const {id} = useParams<any>()
  const [buildings, setBuildings] = useState<any>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const dispatch = useDispatch()

  const navigate = (id: number) => {
    history.push(generatePath(RoutePath.snapshotAssetsDetails, {id}))
  }

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    }
  }

  const columns: any[] = [
    {
      dataField: 'assetReference',
      text: 'Number',
      sort: true,
      headerStyle,
      formatter: (cell: any, row: any) => (
        <span onClick={() => navigate(row._id)} className='cursor-pointer text-primary'>
          {row?.assetReference ? row?.assetReference : 'N/A'}
        </span>
      ),
    },
    {
      dataField: 'assetLocation',
      text: 'Location',
      sort: true,
      headerStyle,
    },
    {
      dataField: 'building.testStatus',
      text: 'Last Test Date',
      headerStyle,
      formatter: (cell: any, row: any) => {
        return row.testDueDate !== null ? (
          <span>{moment(new Date(row.testDueDate)).format('YYYY-MM-DD')}</span>
        ) : (
          ''
        )
      },
    },
    {
      dataField: 'building.testDate',
      text: 'Number of Notes/Defects',
      sort: true,
      headerStyle,
      formatter: (cell: any, row: any) => {
        const totalDefects =
          row.installation.defects.length +
          row.accessPanel.defects.length +
          row.fireBarrier.defects.length +
          row.accessibility.defects.length
        return <span>{totalDefects ? totalDefects : 'None'}</span>
      },
    },
    {
      dataField: 'building.LastModified',
      text: 'Last Modified',
      sort: true,
      headerStyle,
      formatter: (cell: any, row: any) => {
        return row.testDueDate !== null ? (
          <span>{moment(new Date(row.changedOn)).format('YYYY-MM-DD')}</span>
        ) : (
          ''
        )
      },
    },
  ]

  useEffect(() => {
    if (id !== undefined) getSnapShotData()
  }, [id])

  const getSnapShotData = async () => {
    try {
      const result = await getBuildingSnapshots({
        sortproperty: 'createdAt',
        sortorder: -1,
        offset: 0,
        limit: 50,
        query: {
          critarion: {active: true, buildingAssetid: id},
          addedby: ' email name',
          lastModifiedBy: ' email name',
        },
      })
      const {buildingAssetSnapShots} = result.data
      if (buildingAssetSnapShots?.length) {
        setBuildings(buildingAssetSnapShots)
        dispatch({
          type: 'BUILDING_DETAILS',
          payload: buildingAssetSnapShots,
        })
      }
      setLoading(false)
    } catch (e) {}
  }

  if (isLoading) {
    return (
      <div className='card card-custom'>
        <div className='card-body'>
          <div className='d-flex justify-content-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <>
        <div className='d-flex flex-column-fluid printable' id='content'>
          <div className='container-fluid'>
            <div className='card card-custom card-stretch gutter-b'>
              <div className='card-body printable'>
                <button className='btn btn-icon-primary' onClick={() => history.goBack()}>
                  <i className='fas fa-arrow-left' />
                </button>
                <div className='card-body pt-0 pb-3'>
                  <RemoteDataTable
                    isShow={true}
                    columns={columns}
                    data={buildings}
                    keyField={'_id'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default SnapshotDetails
