import { BuildingDetailedListProps } from 'app/modules/auth/redux/Types';
import {
  getBuildingsWithDetails,
  getClients,
  getFacilityManagers,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { buildingDetailsArgs } from '../buildings/Buildings';
import { fmArgs } from '../facility-manager/FacilityManager';
import { RoutePath } from './VaultPage';
import { AssetsTypesProps } from './modal';

import LoaderComman from 'app/components/shared/loader.component';
import { COMMON } from 'constants/commonConstant';
import VaultDataTable from '../../components/data-table/customDataTable';

export const vaultArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 10,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export const AssetTypes = {
  installation: 'installation',
  ductDamper: 'damper',
  accessPanel: 'accessPanel',
  fireBarrier: 'fireBarrier',
  accessibility: 'accessibility',
};

export const vaultDetailedArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    buildingFields: '',
    addedby: ' email name',
    lastModifiedBy: ' email name',
  },
};

export interface CreateVaultProps {
  _id?: string;
  vaultName: string;
}

export interface VaultListingProps {
  _id: string;
  vaultName: string;
  active: boolean;
}

const Vault = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [buildingsList, setBuildingsList] = useState<BuildingDetailedListProps[]>([]);
  const [buildings, setBuildings] = useState<BuildingDetailedListProps[]>([]);
  const [sortProperty, setSortProperty] = useState({
    active: true,
  });
  const [clients, setClients] = useState<any>([]);
  const [Fm, setFm] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState(-1);

  const [buildingFilter, setBuildingFilter] = useState<any>(null);
  const [facilityFilter, setFacilityFilter] = useState<any>(null);
  const [clientFilter, setClientFilter] = useState<any>(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [firstRow, setFirstRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isTableLoading, setTableLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const [sortField, setSortField] = useState('createdAt');
  const [isChanged, setChanged] = useState(false);

  const initialValues: CreateVaultProps = {
    vaultName: '',
  };

  const validationSchema = Yup.object().shape({
    vaultName: Yup.string().required('Vault Name is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      setLoading(false);
    },
  });

  const getDefects = (buildingAssets: any): number => {
    let numberOfDefects = 0;

    if (!buildingAssets.assetArchived) {
      COMMON.defectTypes.forEach((x) => {
        numberOfDefects += buildingAssets[x]?.defects?.length
          ? buildingAssets[x]?.defects.filter((x: any) => !x.defectRectification.defectRectified)
            .length
          : 0;
      });
    }

    if (buildingAssets?.tests?.length) {
      buildingAssets?.tests?.map((x: any) => {
        numberOfDefects += x?.defects?.length
          ? x?.defects.filter((x: any) => !x.defectRectification.defectRectified)
            .length
          : 0;
      });
    }

    return numberOfDefects;
  };

  const getDetailedBuildingListing = async () => {
    buildingDetailsArgs.pageNum = pageNumber;
    buildingDetailsArgs.limit = pageSize;
    buildingDetailsArgs.sortorder = sortOrder;
    buildingDetailsArgs.sortproperty = sortField;
    buildingDetailsArgs.search = filterValue;

    const args = buildingDetailsArgs;
    args.sortorder = sortOrder;
    args.offset = 0;
    args.limit = pageSize;
    args.query.critarion.active = sortProperty.active;
    const response = await getBuildingsWithDetails(args);

    setTotalRecords(response.data.totalRecords);

    let data = response?.data?.buildings?.map((building: any) => {
      let totalDefects = 0;
      let totalAssets = 0;
      let totalAssetsWithDefects = 0;
      totalAssets = building.buildingAssets.length;
      building.buildingAssets.map((asset: any) => {
        let buildingAssets: AssetsTypesProps = asset;
        totalDefects += getDefects(buildingAssets);
      });
      building.totalAssets = totalAssets;
      building.totalAssetsWithDefects = totalAssetsWithDefects;

      let month = building?.testRegime?.includes('3')
        ? 3
        : building?.testRegime?.includes('6')
          ? 6
          : building?.testRegime?.includes('9')
            ? 9
            : building?.testRegime?.includes('12')
              ? 12
              : 0;

      let tDate;
      if (building?.testDate) {
        tDate = new Date(building?.testDate);
        tDate.setMonth(tDate.getMonth() + month);
      }

      return {
        ...building,
        clientName: building.client?.clientName,
        clientRepresentativeName: building.clientRepresentative?.clientRepresentativeName,
        facilityManagerName: building.facilityManager?.facilityManagerName,
        testDate: building.testDate ? moment(building.testDate).format('DD/MM/YYYY') : '',
        nextTestDate: tDate ? moment(tDate).format('DD/MM/YYYY') : '',
        numberOfAssets: building.buildingAssets.filter((y: any) => y.assetArchived === false).length,
        numberOfDefects: totalDefects,
      };
    });
    setBuildings([]);
    setBuildings(data);
    setTableLoading(false);
    return data;
  };

  const clientsArgs = {
    sortproperty: 'createdAt',
    sortorder: -1,
    offset: 0,
    limit: 50,
    search: '',
    pageNum: 1,
    query: {
      critarion: {
        active: true,
      },
      fields: 'clientName',
    },
  };

  const onChangeFilter = async (option: any, name: string) => {
    let val = option?.target.value;
    if (option) {
      if (name === 'building') {
        setBuildingFilter(val);
      } else if (name === 'client') {
        setClientFilter(val);
      } else if (name === 'fm') {
        setFacilityFilter(val);
      }
    } else {
      setLoading(true);
      name === 'building'
        ? setBuildingFilter(null)
        : name === 'client'
          ? setClientFilter(null)
          : setFacilityFilter(null);
      const data = await fetchJobs();
      // setLoading(false )
      // setJobs(data)
    }
    setChanged(true);
  };

  const fetchClients = async () => {
    const clientsData = await getClients(clientsArgs);
    return clientsData.data.clients;
  };

  const getFacilityManagersListing = async () => {
    const facilityManagersData = await getFacilityManagers(fmArgs);
    return facilityManagersData.data.facilityManagers;
  };

  const detailedJobsArgs = {
    sortproperty: 'createdAt',
    sortorder: -1,
    offset: 0,
    limit: 50,
    query: {
      critarion: {
        active: true,
      },
      clientFields: 'clientName',
      clientRepresentativeFields: 'clientRepresentativeName',
      facilityManagerFields: 'facilityManagerName',
      buildingFields:
        'buildingReference buildingName buildingAddress testDate testStatus inspectionJobs buildingAssets buildingLocation numberOfDefects',
      addedby: ' email name',
      lastModifiedBy: ' email name',
    },
  };

  const getFilters = (args: any) => {
    if (buildingFilter) {
      args.query.critarion.building = buildingFilter;
    } else {
      delete args.query.critarion.building;
    }
    if (clientFilter) {
      args.query.critarion.client = clientFilter;
    } else {
      delete args.query.critarion.client;
    }
    if (facilityFilter) {
      args.query.critarion.facilityManager = facilityFilter;
    } else {
      delete args.query.critarion.facilityManager;
    }

    return args;
  };

  const fetchJobs = async (filter: boolean = false) => {
    const args = getFilters(detailedJobsArgs);
    args.sortorder = sortOrder;
    args.query.critarion.active = true;

    const response = await getBuildingsWithDetails(args);

    if (response) {
      let data = response?.data?.buildings?.map((building: any) => {
        let totalDefects = 0;
        let totalAssets = 0;
        let totalAssetsWithDefects = 0;
        totalAssets += building.buildingAssets.length;
        building.buildingAssets.map((asset: any) => {
          let buildingAssets: AssetsTypesProps = asset;
          totalDefects += getDefects(buildingAssets);
        });
        building.totalAssets = totalAssets;
        building.totalAssetsWithDefects = totalAssetsWithDefects;

        let month = building?.testRegime?.includes('3')
          ? 3
          : building?.testRegime?.includes('6')
            ? 6
            : building?.testRegime?.includes('9')
              ? 9
              : building?.testRegime?.includes('12')
                ? 12
                : 0;

        let tDate;
        if (building?.testDate) {
          tDate = new Date(building?.testDate);
          tDate.setMonth(tDate.getMonth() + month);
        }

        return {
          ...building,
          clientName: building.client.clientName,
          clientRepresentativeName: building.clientRepresentative.clientRepresentativeName,
          facilityManagerName: building.facilityManager.facilityManagerName,
          testDate: building.testDate ? moment(building.testDate).format('DD/MM/YYYY') : '',
          nextTestDate: tDate ? moment(tDate).format('DD/MM/YYYY') : '',
        };
      });
      setTotalRecords(data?.length);
      setBuildings(data);
    }
  };

  useEffect(() => {
    if (isChanged) {
      if (clientFilter || buildingFilter || facilityFilter) {
        fetchJobs(true);
      } else if (clientFilter === null || buildingFilter === null || facilityFilter === null) {
        getAllData();
      }
    }
  }, [clientFilter, buildingFilter, facilityFilter]);

  const getAllData = async () => {
    let [buildings, facilityManagers, clients] = await Promise.all([
      getDetailedBuildingListing(),
      getFacilityManagersListing(),
      fetchClients(),
    ]);

    setBuildingsList(buildings);
    setBuildings(buildings);
    setLoading(false);
    setClients(clients);
    setFm(facilityManagers);
  };

  // useEffect(() => {
  //   // fetchJobs(true)
  // }, [buildingFilter, clientFilter])

  useEffect(() => {
    getAllData();
  }, []);

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
      paddingLeft: '10px',
    };
  };

  const referenceBodyTemplate = (row: any) => {
    return (
      <div className=''>
        <label>
          <span
            style={{ paddingLeft: '10px' }}
            className='font-weight-bolder d-flex justify-content-center align-items-center  cursor-pointer text-primary'
            onClick={() => navigate(row._id)}
          >
            {row.buildingReference}
          </span>
        </label>
      </div>
    );
  };

  const vaultColumns: any[] = [
    {
      field: 'buildingReference',
      header: 'Reference',
      sortable: true,
      body: referenceBodyTemplate,
      style: { minWidth: '125px' },
    },
    {
      field: 'buildingName',
      header: 'Building',
      sortable: true,
      style: { minWidth: '150px' },
    },
    {
      field: 'clientName',
      header: 'Profile',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'clientRepresentativeName',
      header: 'Representatives',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'facilityManagerName',
      header: 'Facility Manager',
      sortable: true,
      style: { minWidth: '250px' },
    },
    {
      field: 'testDate',
      header: 'Last Test Date',
      sortable: true,
      style: { minWidth: '160px' },
    },
    {
      field: 'nextTestDate',
      header: 'Next Test Date',
      sortable: true,
      style: { minWidth: '160px' },
    },
    {
      field: 'numberOfAssets',
      header: 'Assets',
      sortable: true,
      style: { minWidth: '100px' },
    },
    {
      field: 'numberOfDefects',
      header: 'Defects',
      sortable: true,
      style: { minWidth: '100px' },
    },
  ];

  const sortBy = (event: any) => {
    const { value } = event.target;
    if (value === '1') {
      const numDescending = [...buildings].sort((a, b) =>
        a.buildingName.toLowerCase() > b.buildingName.toLowerCase() ? 1 : -1
      );

      setBuildings(numDescending);
    } else {
      const numAscending = [...buildings].sort((a, b) =>
        a.buildingName.toLowerCase() > b.buildingName.toLowerCase() ? -1 : 1
      );
      setBuildings(numAscending);
    }
  };

  const FilterBy = (event: any) => {
    const { value, name } = event.target;
    if (name === 'status') {
      setSortProperty({
        ...sortProperty,
        active: value,
      });
    }
  };

  // useEffect(() => {
  //   getAllData()
  // }, [sortOrder, sortProperty])

  const navigate = (id: number) => {
    history.push(generatePath(RoutePath.buildingDetails, { id }));
  };

  useEffect(() => {
    if (isChanged) {
      getDetailedBuildingListing();
    }
  }, [pageNumber, pageSize, sortField, sortOrder]);

  const onPageNumberChange = (e: any) => {
    setChanged(true);
    let newPageNumber = e.page + 1;

    if (pageNumber !== newPageNumber || pageSize !== e.rows) {
      setFirstRow(e.first);
      setPageNumber(newPageNumber);
      setPageSize(e.rows);
      setTableLoading(true);
    }
  };

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        setTableLoading(true);
        getDetailedBuildingListing();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [filterValue]);

  const onGlobalFilterChange = (e: any) => {
    setChanged(true);
    setFilterValue(e.target.value);
  };

  const onSortChange = (e: any) => {
    setChanged(true);
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
    setTableLoading(true);
  };

  const clearFilter = () => {
    setChanged(true);
    setFilterValue('');
  };

  return (
    <LoaderComman isRender={!isLoading}>
      <div className='card card-custom card-stretch gutter-b'>
        <div className='card-body pt-0 pb-3'>
          <VaultDataTable
            dataKey='_id'
            data={buildings}
            columns={vaultColumns}
            isLoading={isTableLoading}
            isShowPaginator
            isShowPagSize
            rowCount={pageSize}
            totalRecords={totalRecords}
            firstRow={firstRow}
            onPageChange={onPageNumberChange}
            onGlobalFilterChange={onGlobalFilterChange}
            filterValue={filterValue}
            onSort={onSortChange}
            sortOrder={sortOrder}
            sortField={sortField}
            clearFilterValue={clearFilter}
          />
        </div>
      </div>
    </LoaderComman>
  );
};

export default Vault;
