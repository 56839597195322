export const colors = {
  green: '#61A60E',
  success: '#A7E521',
  textGreen: '#8FC813',
  borderGreen: '#5B9C0D',
  outlinedGreen: '#61A60E',
  outlineTextGreen: '#528D0C',
  accentOrange: '#F04F24',
  indigo: '#1C7898',
  orange: '#F05A3B',
  accentGrey: '#666666',
  blue: '#03C3EC',
  info: '#03C3EC',
  borderBlue: '#2EBCCF',
  linkBlue: '#047899',
  skyblue: '#1C7999',
  darkBlue: '#2D485C',
  barBlue: '#162C46',
  barLightBlue: '#469DD5',
  barGray: '#DFDFDF',
  secondaryBlue: '#2A9DD5',
  lightBlue: '#1D799D',
  darkBlack: '#201E1E',
  acccentLightBlue: '#4297CB',

  red: '#FF3E1D',
  error: '#FF3E1D',
  borderRed: '#F97050',
  outlineRed: '#FA3E1F',
  errorBackground: 'rgba(240,90,59,0.1)',

  yellow: '#FFD422',
  alert: '#FFD422',
  borderYellow: '#FFCD00',
  tagYellow: '#F6AA1C',

  purple: '#5E61FF',
  textPurple: '#696CFF',
  lightPurple: '#7079FF',
  tagPurple: '#68478D',

  pastelPurple: '#F2F2FF',
  pastelGreen: '#FAFFEA',
  pastelBlue: '#DAF8FC',
  pastelRed: '#FFE7E2',
  pastelYellow: '#FFF5CB',

  toastRed: '#D40000',
  toastGrey: '#505050',
  toastGreen: '#2b744e',
  toastYellow: '#FAB100',

  lightGrey: '#D8D8D8',
  grey100: '#AEB7C4',
  grey200: '#FAFBFD',
  grey400: '#F0F4F8',
  grey500: '#DCE1E7',
  grey600: '#D7E1EA',
  grey700: '#778699',
  grey700Hover: '#7786998A',
  grey800: '#72809D',
  grey: '#35425B',
  greyDisabled: '#F7FAFC',
  borderGrey: '#CACACA',
  toolbarGrey: '#c4c4c4',
  backgroundGrey: '#fafafa',
  fontGrey: '#364F69',
  textGrey: '#8C97AC',

  white: '#ffffff',
  black: '#000000',
  overlayBlack: '#0E0E0E',
  lightBlack: '#2C3F58',
  dashedBlack: '#072547',
  buttonBlue: '#112553',

  textUser: '#254050',
  textBlue: '#047899',
  textHighlight: '#054F81',

  lightChartBlue: '#0074C7',
  hardChartBlue: '#0047BB',
  chartAqua: '#00AAAD',
  mediumChartPurple: '#845FC8',
  lightChartAqua: '#7FD9DF',
  lightChartPurple: '#B783CA',

  footer: '#F04F24',
  background: '#111E32',
  flamingo: '#F05A3B',
  downriver: '#072547',
  title: '#1D293F',
  description: '#7D7CA7',

  newBlue: '#1B2760',
  newGreen: '#4DA911',
  newCyan: '#40A0EF',
  newRed: '#E81B00',

  tertiaryBlue: '#003DA5',
  tagBG: '#EFEFEF',
}

export const fonts = {
  primary: 'FrankNew',
  secondary: "'Mislab', sans-serif",
}
