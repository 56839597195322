import { ToastContainer, ToastContent, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import FailIcon from '../../_metronic/assets/media/icons/failIcon.svg';
import InfoIcon from '../../_metronic/assets/media/icons/infoIcon.svg';
import SuccessIcon from '../../_metronic/assets/media/icons/successIcon.svg';
import WarningIcon from '../../_metronic/assets/media/icons/warningIcon.svg';

const BodyWrapper = styled.div`
  display: flex;
`;
const Icon = styled.img`
  margin: 0 20px 0 5px;
`;
const ContentWrapper = ({ content, icon }: { content: ToastContent; icon: string; }) => (
  <BodyWrapper>
    <ToastContainer />
    <Icon src={icon} />
    {content}
  </BodyWrapper>
);

export const info = (content: ToastContent, options?: ToastOptions | undefined) => {
  toast.info(<ContentWrapper content={content} icon={InfoIcon} />, options);
};

export const error = (content: ToastContent, options?: ToastOptions | undefined) => {
  toast.error(<ContentWrapper content={content} icon={FailIcon} />, options);
};

export const success = (content: ToastContent, options?: ToastOptions | undefined) => {
  toast.success(<ContentWrapper content={content} icon={SuccessIcon} />, options);
};

export const warning = (content: ToastContent, options?: ToastOptions | undefined) => {
  toast.warning(<ContentWrapper content={content} icon={WarningIcon} />, options);
};

function Toaster() {
  return (
    <div>
      <ToastContainer />
    </div>
  );
}

export const toaster = {
  success,
  warning,
  error,
  info,
};
export default Toaster;
