import { Redirect, Route, Switch } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import Accessibility from './Accessibility';
import AddDefectSubCategory from './AddDefectSubCategory';
import DamperTypes from './DamperTypes';
import DuctTypes from './DuctTypes';
import FireBarrier from './FireBarrier';
import Installation from './Installation';
import MainSetting from './MainSetting';
import Permissions from './Permissions';
import AccessPanel from './PpmCategory/AccessPanel';
import DamperClassification from './PpmCategory/DamperClassification';
import PpmAccessibility from './PpmCategory/PpmAccessibilty';
import PpmCategories from './PpmCategory/PpmCategories';
import PpmFireBarrier from './PpmCategory/PpmFireBarrier';
import PpmInspection from './PpmCategory/PpmInspection';
import PpmInstallation from './PpmCategory/PpmInstallation';
import Associations from './associations';
import Competencies from './competencies';
import Disclaimer from './reportPrefixCategory/Disclaimer';
import Guidance from './reportPrefixCategory/Guidance';
import Service from './reportPrefixCategory/Service';

const settingsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Settings',
    path: '/settings/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const settingsBreadCrumb2: Array<PageLink> = [
  {
    title: 'Category Settings',
    path: '/settings/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const settingsBreadCrumb: Array<PageLink> = [
  {
    title: 'Category Settings',
    path: '/settings/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const settingsBreadCrumb3: Array<PageLink> = [
  {
    title: 'PPM Mobile Guidance Text',
    path: '/settings/ppmCategories/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const settingsdeffect: Array<PageLink> = [
  {
    title: 'Defect Categories',
    path: '/settings/list',
    isSeparator: false,
    isActive: false,
  },
];

const settingsBreadCrumb4: Array<PageLink> = [
  {
    title: 'Category Settings',
    path: '/settings/userAccess/list',
    isSeparator: false,
    isActive: false,
  },
];

const settingsBreadCrumb5: Array<PageLink> = [
  {
    title: 'User Access Permission',
    path: '/settings/list',
    isSeparator: false,
    isActive: false,
  },
];

export const SettingsRoutePath = {
  main: '/settings/list',
  accessibility: '/settings/accessibility/list',
  ppmCategories: '/settings/ppmRegulation/list',
  ppmDamper: '/settings/ppmDamper/list',
  ppmInstallation: '/settings/ppmInstallation/list',
  ppmFireBarrier: '/settings/ppmFireBarrier/list',
  ppmAccessibility: '/settings/ppmAccessibility/list',
  ppmInspection: '/settings/ppmInspection/list',
  associations: '/settings/associations',
  competencies: '/settings/competencies',
  permissions: '/settings/permissions',
  permissionDetails: '/settings/permission',
  // report
  reportGuidance: '/settings/reportGuidance/list',
  reportService: '/settings/reportService/list',
  reportDisclaimer: '/settings/reportDisclaimer/list',

  // user management

  userweight: '/settings/userAccess/list',
  adminOperative: '/settings/adminOperative/list',
  adminPermission: '/settings/permissions/list',
  siteOperative: '/settings/siteOperative/list',
  agencyOperative: '/settings/agencyOperative/list',

  subCategories: '/settings/categories/list',
  fireBarrier: '/settings/fire-barrier/list',
  installation: '/settings/installation/list',
  damperType: '/settings/damper-types/list',
  ductType: '/settings/duct-types/list',
  accesPanel: '/settings/accesspanel/list',
  redirectTo: '/settings/list',
};

const SettingsPage = () => {
  return (
    <Switch>
      <Route path={SettingsRoutePath.main}>
        <PageTitle breadcrumbs={settingsBreadCrumbs}>Settings</PageTitle>
        <MainSetting />
      </Route>
      <Route path={SettingsRoutePath.accessibility}>
        <PageTitle breadcrumbs={settingsBreadCrumb}>Accessibility</PageTitle>
        <Accessibility />
      </Route>
      <Route path={SettingsRoutePath.subCategories}>
        <PageTitle breadcrumbs={settingsdeffect}>Defect Categories</PageTitle>
        <AddDefectSubCategory />
      </Route>
      {/* Ppm Routes Start */}
      <Route path={SettingsRoutePath.ppmCategories}>
        <PageTitle breadcrumbs={settingsBreadCrumb3}>Settings</PageTitle>
        <PpmCategories />
      </Route>
      <Route path={SettingsRoutePath.ppmDamper}>
        <PageTitle breadcrumbs={settingsBreadCrumb3}>Settings</PageTitle>
        <DamperClassification />
      </Route>
      <Route path={SettingsRoutePath.ppmInstallation}>
        <PageTitle breadcrumbs={settingsBreadCrumb3}>Settings</PageTitle>
        <PpmInstallation />
      </Route>
      <Route path={SettingsRoutePath.ppmFireBarrier}>
        <PageTitle breadcrumbs={settingsBreadCrumb3}>Settings</PageTitle>
        <PpmFireBarrier />
      </Route>
      <Route path={SettingsRoutePath.ppmAccessibility}>
        <PageTitle breadcrumbs={settingsBreadCrumb3}>Settings</PageTitle>
        <PpmAccessibility />
      </Route>
      <Route path={SettingsRoutePath.accesPanel}>
        <PageTitle breadcrumbs={settingsBreadCrumb3}>Settings</PageTitle>
        <AccessPanel />
      </Route>
      <Route path={SettingsRoutePath.ppmInspection}>
        <PageTitle breadcrumbs={settingsBreadCrumb3}>Settings</PageTitle>
        <PpmInspection />
      </Route>
      {/* Ppm Routes End */}

      {/* Report Category Start */}
      <Route path={SettingsRoutePath.reportGuidance}>
        <PageTitle breadcrumbs={settingsBreadCrumb}> Settings</PageTitle>
        <Guidance />
      </Route>
      <Route path={SettingsRoutePath.reportService}>
        <PageTitle breadcrumbs={settingsBreadCrumb}> Settings</PageTitle>
        <Service />
      </Route>
      <Route path={SettingsRoutePath.reportDisclaimer}>
        <PageTitle breadcrumbs={settingsBreadCrumb}> Settings</PageTitle>
        <Disclaimer />
      </Route>
      {/* Report Category End */}

      {/* Usermanagment Start */}
      {/* Usermanagment End */}
      <Route path={SettingsRoutePath.fireBarrier}>
        <PageTitle breadcrumbs={settingsBreadCrumb2}>Fire Barrier</PageTitle>
        <FireBarrier />
      </Route>
      <Route path={SettingsRoutePath.installation}>
        <PageTitle breadcrumbs={settingsBreadCrumb2}>Installation</PageTitle>
        <Installation />
      </Route>
      <Route path={SettingsRoutePath.damperType}>
        <PageTitle breadcrumbs={settingsBreadCrumb2}>Damper Types</PageTitle>
        <DamperTypes />
      </Route>
      <Route path={SettingsRoutePath.ductType}>
        <PageTitle breadcrumbs={settingsBreadCrumb2}>Duct Types</PageTitle>
        <DuctTypes />
      </Route>
      <Route path={SettingsRoutePath.associations}>
        <PageTitle breadcrumbs={settingsBreadCrumb2}>Associations Settings</PageTitle>
        <Associations />
      </Route>
      <Route path={SettingsRoutePath.competencies}>
        <PageTitle breadcrumbs={settingsBreadCrumb2}>Competencies Settings</PageTitle>
        <Competencies />
      </Route>
      <Route path={SettingsRoutePath.permissions}>
        <PageTitle breadcrumbs={settingsBreadCrumb5}>Permissions</PageTitle>
        <Permissions />
      </Route>
      {/* <Route path={SettingsRoutePath.permissionDetails}>
        <PageTitle breadcrumbs={settingsBreadCrumb2}>Permission Details</PageTitle>
        <PermissionDetails />
      </Route> */}
      <Redirect from='/settings' exact={true} to={SettingsRoutePath.redirectTo} />
      <Redirect to={SettingsRoutePath.redirectTo} />
    </Switch>
  );
};

export default SettingsPage;
