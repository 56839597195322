import styled from 'styled-components'
import {ToastContainer} from 'react-toastify'
import {colors, fonts} from './variables'

const StyledToastContainer = styled(ToastContainer).attrs({
  style: {width: '480px'},
})`
  .Toastify__toast {
    min-height: 51px;
    border-radius: 4px;
    padding: 8px 16px;
  }
  .Toastify__toast--error {
    background-color: ${colors.toastRed};
  }
  .Toastify__toast--warning {
    background-color: ${colors.toastYellow};

    .Toastify__toast-body {
      color: ${colors.toastGrey};
    }
  }
  .Toastify__toast--success {
    background-color: ${colors.toastGreen};
  }
  .Toastify__toast--info {
    background-color: ${colors.toastGrey};
  }
  .Toastify__toast-body {
    color: ${colors.white};
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .Toastify__progress-bar {
  }
  .Toastify__close-button > svg {
    height: 25px;
    width: 22px;
  }
  .Toastify__close-button {
    align-self: center;
    opacity: 1;
  }
  .Toastify__close-button--warning > svg {
    color: ${colors.toastGrey};
  }
`

export default StyledToastContainer
