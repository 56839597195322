import { Dialog } from 'primereact/dialog';
import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap-v5';

interface CustomModalProps {
  title?: string;
  children: ReactNode;
  show?: boolean;
  handleClose?: any;
}

const CustomModal: React.FC<CustomModalProps> = ({ title, children, show = false, handleClose }) => {
  return (
    <>
      <Dialog header={title} position='top' visible={show} style={{ width: '50vw' }} onHide={() => handleClose(false)} draggable={false}>
        {children}
      </Dialog>
    </>
  );
};
export const DefectModal: React.FC<CustomModalProps> = ({
  title,
  children,
  show = false,
  handleClose,
}) => {
  return (
    <>
      <Dialog header={title} visible={show} style={{ width: '50vw' }} onHide={() => handleClose(false)} draggable={false}>
        {children}
      </Dialog>
    </>
  );
};

export default CustomModal;
