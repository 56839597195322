import DialogueBox from 'app/components/DialogueBox';
import CustomDataTable from 'app/components/data-table/customDataTable';
import { success } from 'app/components/toast';
import { UpdateBuildingAsset, getBuildingAsset } from 'app/modules/auth/redux/jobs/JobsCRUD';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { RoutePath } from './VaultPage';

export interface ParamsProps {
  id: string;
}

export const buildingsArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: 'buildingName',
  },
};

export const testRegimeArgs = {
  critarion: {
    _id: '',
  },
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

export interface CreateBuildingProps {
  _id?: string;
  buildingid?: string;
  // vaultId: string;
  buildingReference: string;
  buildingName: string;
  buildingAddress: string;
  client: string;
  facilityManager: string;
  clientRepresentative: string;
  testDate?: string;
  // testStatus: string;
  testRegime: string;
  buildingLocation: {
    type: 'Point';
    coordinates: [number, number];
  };
}

export interface LocationProps {
  lat: number;
  lng: number;
  address?: string;
}

export interface IReportProps {
  competencies?: any[];
  header?: any;
  certificate?: any;
  assetRegister?: any[];
  defectLog?: any[];
  inventory?: any;
  assetPage?: any[];
  summary?: any;
  service?: any;
}

export default function AssetsArchived() {
  const history = useHistory();
  const { id } = useParams<ParamsProps>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [archivedList, setArchivedList] = useState<any>([]);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
      paddingLeft: '10px',
    };
  };

  const assetRef = (row: any) => {
    return (
      <span
        // onClick={() => navigate(row._id)}
        className='text-primary px-3'>
        {row?.assetReference ? row?.assetReference : 'N/A'}
      </span>
    );
  };

  const dueDate = (row: any) => {
    return row.testDueDate !== null ? (
      <span>{moment(new Date(row.testDueDate)).format('YYYY-MM-DD')}</span>
    ) : (
      ''
    );
  };

  const columns: any[] = [
    {
      field: 'assetReference',
      header: 'Number',
      body: assetRef
    },
    {
      field: 'assetLocation',
      header: 'Location',
    },
    {
      field: 'testDueDate',
      header: 'Last Test Date',
      body: dueDate
    },
  ];

  const navigate = (id: number) => {
    history.push(generatePath(RoutePath.archivedAssetsDetails, { id }));
  };

  const updateAssetStatus = async (data: any) => {
    setLoading(true);
    const body = {
      buildingAssetid: data._id,
      // active: true,
      assetArchived: false,
      previouslyChangedBy: data.lastModifiedBy._id ? data.lastModifiedBy._id : data.lastModifiedBy,
      previouslyChangedOn: data?.updatedAt,
      lastOperation: 'removeBuildingAsset',
      previousOperation: data.lastOperation || '',
    };

    await UpdateBuildingAsset(body);
    success('Asset Active Successfully');
    getArchivedList();
  };

  const getArchivedList = async () => {
    try {
      const body = {
        sortproperty: 'createdAt',
        sortorder: -1,
        offset: 0,
        limit: 50,
        query: {
          critarion: { assetArchived: true },
          fields:
            'assetReference assetQrCode assetLocation testDueDate assetImages lastModifiedBy updatedAt',
        },
      };
      const result = await getBuildingAsset(body);
      setLoading(false);
      if (result.status != 'Success') return;
      const { buildingAssets } = result.data;

      setArchivedList(buildingAssets);
    } catch (e) { }
  };

  useEffect(() => {
    getArchivedList();
  }, []);

  if (isLoading) {
    return (
      <div className='card card-custom'>
        <div className='card-body'>
          <div className='d-flex justify-content-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className='d-flex flex-column-fluid'>
        <div className='container-fluid'>
          {/* <div className='d-flex justify-content-between my-2'>
            <button className='btn btn-icon-primary' onClick={() => history.goBack()}>
              <i className='fas fa-arrow-left' />
            </button>
          </div> */}
          <div className='card card-custom '>
            <div className='card card-custom mt-4'>
              <div className='card-header border-0 py-5'>
                <h3 className='card-title align-items-start flex-row'>
                  <span className='card-label font-weight-bolder text-dark'>Archive</span>
                  <span className='text-muted mt-1 font-weight-bold font-size-sm'>
                    {archivedList.length} Assets
                  </span>
                </h3>
                <div className='card-toolbar d-flex flex-row gap-2'></div>
              </div>
              <div className='card-body pt-0 pb-3'>
                {/* <RemoteDataTable
                  isShow={true}
                  columns={columns}
                  data={archivedList}
                  keyField={'_id'}
                /> */}

                <CustomDataTable
                  className='table-gray mt-5'
                  data={archivedList}
                  columns={columns}
                  isHideSearch
                  isShowActionColumns
                  isHideEditAction
                  isHideArchieveAction
                  onRestore={(id: string) => {
                    let data = archivedList.find((x: any) => x._id === id);
                    setDialogueBox({ flag: true, record: data });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <DialogueBox
          title={'Vault Asset Unarchive'}
          message={'Do you want  to unarchive?'}
          state={dialogueBox.flag}
          onClick={() => updateAssetStatus(dialogueBox.record)}
          handleClose={() => setDialogueBox({ flag: false, record: {} })}
        />
      </div>
    );
}
