import { useEffect, useState } from "react";

import LoaderComman from "app/components/shared/loader.component";
import { error, success } from "app/components/toast";
import { create_update_Association, deleteAssociation, getAssociation, uploadAssociationImage } from "app/modules/auth/redux/jobs/JobsCRUD";
import { useFormik } from 'formik';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
import * as Yup from 'yup';
import logoImg from '../../../assets/associate.png';

import DialogueBox from "app/components/DialogueBox";
import RoleBaseAccess from "app/components/shared/roleBaseAccess.component";
import { Dropdown } from "primereact/dropdown";

function Associations({
    isDropdown = false,
    showModal = false,
    setShowModal = {},
    clientFormik = {}
}) {

    const [associations, setAssociations] = useState([]);

    const [visible, setVisiblle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [errMsg, setErrorMsg] = useState('');
    const [isFileChanged, setFileChanged] = useState(false);
    const [selectedAssociations, setSelectedAssociations] = useState(null);
    const [membershipValue, setMemberShipValue] = useState('');
    const [confirmModal, setConfirmModal] = useState({ flag: false, id: '' });

    const associationProps = {
        sortProperty: "associationName",
        sortOrder: "asc",
        limit: 1000,
        pageNum: 1,
        search: "",
    }

    const getAssociations = async () => {
        try {
            setLoading(true);
            const res = await getAssociation(associationProps);
            setLoading(false);
            if (res?.status) {
                if (res.data && res.data.AssociationsDataData?.length) {
                    setAssociations(res.data.AssociationsDataData)
                }
            }
            else {
                error(res?.message ?? "Error occurred while getting associations")
            }
        }
        catch (err) {
            setLoading(false);
            error("Error occurred while getting associations")
        }
    }


    useEffect(() => {
        getAssociations();
    }, [])


    const jobValidationSchema = Yup.object().shape({
        associationName: Yup.string().required('Association Name is required'),
    });

    const initialValues = {
        associationName: '',
        associationImage: ''
    };

    const formik = useFormik({
        initialValues,
        validationSchema: jobValidationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (!values.associationImage) {
                setErrorMsg("Association image is required");
                return;
            }

            setSubmitting(true);
            setBtnLoading(true);
            let imgResponse = null;
            let response = null;

            if (isFileChanged) {
                const formData = new FormData();
                formData.append('foldername', 'associations');
                formData.append('file', values.associationImage);

                imgResponse = await uploadAssociationImage(formData);

                if (imgResponse?.status === "Success") {
                    values.associationImage = imgResponse.data;
                }
                else {
                    values.associationImage = '';
                    setBtnLoading(false);
                    error(imgResponse?.message ? imgResponse?.message : 'Error occurred while uploading image');
                    return;
                }
            }

            response = await create_update_Association(values);

            if (response?.status === `Success`) {
                success(`Association ${values?._id ? 'updated' : 'created'} successfully.`);
                setVisiblle(false);
                formik.resetForm();
                setSelectedImage(null);
                await getAssociations();
            } else {
                setStatus(response?.message);
                error(response?.message ?? `Error occurred while ${values?._id ? 'updating' : 'creating'} association`);
            }
            setBtnLoading(false);
            setSubmitting(false);
        },
    });

    const onImageChange = (e) => {
        const file = e.target.files[0];
        setFileChanged(true);
        formik.setFieldValue('associationImage', file)
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const imagePreview = document.getElementById('imagePreview');
                setSelectedImage(e.target.result);
                imagePreview.style.display = 'block';
            };
            reader.readAsDataURL(file);
        }
    }

    const onRemoveAssociation = async (id = '') => {
        if (id) {
            const res = await deleteAssociation({ "_id": id });

            if (res?.status === 'Succcess') {
                success(res.message);
                setConfirmModal({ flag: false, id: '' });
                getAssociations();
            }
            else {
                error(res?.message ?? "Something went wrong, please try again later")
            }
        }
    }

    return (
        <>
            <LoaderComman isRender={!loading}>
                <Dialog draggable={false} position="top" header="Add Association" visible={visible || showModal} onHide={() => {
                    setSelectedImage(null);
                    setVisiblle(false)
                    setShowModal(false)
                    setSelectedAssociations(null);
                    setMemberShipValue(null);
                    formik.resetForm();
                }} style={{ width: '30vw' }}>
                    {isDropdown
                        ?
                        <>
                            <div className="row">
                                <div className="col-lg-12 mb-5">
                                    <label className="required">Associations</label>
                                    <Dropdown
                                        value={selectedAssociations}
                                        onChange={(e) => setSelectedAssociations(e.value)}
                                        options={associations}
                                        optionLabel="associationName"
                                        placeholder="Select Associations" className="w-full md:w-20rem" />
                                </div>
                                <div className="col-lg-12 mb-5">
                                    <label className="required">Membership Number</label>
                                    <input type="text"
                                        value={membershipValue}
                                        onChange={(e) => setMemberShipValue(e.target.value)}
                                        placeholder="Membership Number"
                                        className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button type="button" className="btn btn-primary-bordered" label="Cancel" onClick={() => {
                                    setSelectedAssociations(null);
                                    setMemberShipValue(null);
                                    setShowModal(false);
                                }} />
                                <Button
                                    type="button"
                                    className="btn btn-primary ms-4"
                                    label={'Add Association'}
                                    disabled={!selectedAssociations || !membershipValue}
                                    onClick={() => {
                                        clientFormik.setFieldValue("associations",
                                            [...clientFormik.values.associations,
                                            {
                                                associationId: selectedAssociations?._id,
                                                associationName: selectedAssociations?.associationName,
                                                associationImage: selectedAssociations?.associationImage,
                                                memberShipId: membershipValue
                                            }]);
                                        setSelectedAssociations(null);
                                        setMemberShipValue('');
                                        setShowModal(false);
                                    }
                                    }
                                />
                            </div>
                        </>
                        :
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-5 mb-5">
                                    <label className="required">Choose</label>
                                    <input type="file" id="fileInput" onChange={(e) => onImageChange(e)} />
                                </div>
                                <div className="col-lg-7 d-flex justify-content-end mb-5">
                                    <img id="imagePreview" src={selectedImage ? selectedImage : formik.values.associationImage ? formik.values.associationImage : logoImg} alt="associationImage"
                                        style={{ display: 'block', maxHeight: '150px', maxWidth: '250px' }} />
                                </div>
                                <p className="text-danger">{errMsg}</p>
                                <div className="col-lg-12 mb-5">
                                    <label className="required">Association Name:</label>
                                    <input
                                        name="associationName"
                                        className="form-control"
                                        type="text"
                                        {...formik.getFieldProps('associationName')}
                                    />
                                    {formik.touched.associationName && formik.errors &&
                                        <p className="text-danger">{formik.errors.associationName}</p>
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button type="button" className="btn btn-primary-bordered" label="Cancel" onClick={() => {
                                        setVisiblle(false);
                                        setSelectedImage(null);
                                        formik.resetForm();
                                    }} />
                                    <RoleBaseAccess moduleName="settings" isSubmit>
                                        <Button
                                            type="submit"
                                            className="btn btn-primary ms-4"
                                            icon={btnLoading && 'pi pi-spin pi-spinner'}
                                            disabled={btnLoading}
                                            label={btnLoading ? 'Please wait...' : formik.values?._id ? 'Update' : 'Add'} />
                                    </RoleBaseAccess>
                                </div>
                            </div>
                        </form>
                    }

                </Dialog>
                {!isDropdown &&
                    <>
                        <DialogueBox
                            title={'Settings Remove Association'}
                            message={'Do you want  to remove association?'}
                            state={confirmModal.flag}
                            onClick={() => onRemoveAssociation(confirmModal.id)}
                            handleClose={() => setConfirmModal({ flag: false, id: '' })}
                        />
                        <div className="card">
                            <div className="card-body">

                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <p className="text-muted">Total associations: {associations?.length ?? '0'}</p>
                                    <Button className="btn btn-primary" icon={"pi pi-plus"} label="Add Association" onClick={() => setVisiblle(true)} />
                                </div>
                                <Panel header="Associations">
                                    {associations?.length
                                        ?
                                        associations.map(x => {
                                            return (
                                                <>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <img alt="" src={x.associationImage ? process.env.REACT_APP_IMAGE_URL + x.associationImage : logoImg} style={{ maxHeight: '150px', maxWidth: '300px' }} />
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <textarea
                                                                value={x.associationName}
                                                                id={x._id}
                                                                rows="6"
                                                                className="form-control"
                                                                disabled
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                                            <Button
                                                                icon="pi pi-pencil"
                                                                className="btn btn-primary-bordered d-flex"
                                                                rounded text raised severity="warning"
                                                                aria-label="Notification"
                                                                onClick={() => {
                                                                    formik.setFieldValue('associationImage', x.associationImage ? x.associationImage : logoImg);
                                                                    formik.setFieldValue('associationName', x.associationName);
                                                                    formik.setFieldValue('_id', x._id);
                                                                    setSelectedImage(process.env.REACT_APP_IMAGE_URL + x.associationImage);
                                                                    setVisiblle(true)
                                                                }}
                                                            />
                                                            <Button
                                                                icon="pi pi-trash"
                                                                className="btn btn-primary-bordered d-flex ms-3"
                                                                rounded text raised severity="warning"
                                                                aria-label="Notification"
                                                                onClick={() => {
                                                                    setConfirmModal({ flag: true, id: x._id })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Divider />
                                                </>

                                            )
                                        })
                                        :
                                        <div className="col-lg-12">
                                            No Associations Found
                                        </div>
                                    }
                                </Panel>
                            </div>
                        </div>
                    </>
                }
            </LoaderComman>
        </>
    )
}
export default Associations;

