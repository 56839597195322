import { useEffect, useState } from "react";

import DialogueBox from "app/components/DialogueBox";
import LoaderComman from "app/components/shared/loader.component";
import RoleBaseAccess from "app/components/shared/roleBaseAccess.component";
import { error, success } from "app/components/toast";
import { create_update_Competencies, deleteCompetencies, getAllCompetencies, uploadCompetencyImage } from "app/modules/auth/redux/jobs/JobsCRUD";
import { useFormik } from 'formik';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import * as Yup from 'yup';
import logoImg from '../../../assets/associate.png';

function Competencies({
    isDropdown = false,
    showModal = false,
    setShowModal = {},
    clientFormik = {}
}) {

    const [competncies, setCompetencies] = useState([]);

    const [visible, setVisiblle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [errMsg, setErrorMsg] = useState('');
    const [isFileChanged, setFileChanged] = useState(false);
    const [selectedCompetencies, setSelectedCompetencies] = useState(null);
    const [membershipValue, setMemberShipValue] = useState('');
    const [confirmModal, setConfirmModal] = useState({ flag: false, id: '' });

    const competenciesProps = {
        "sortProperty": "competenciesName",
        "sortOrder": "asc",
        "limit": 1000,
        "pageNum": 1,
        "search": ""
    }

    const getCompetencies = async () => {
        try {
            setLoading(true);
            const res = await getAllCompetencies(competenciesProps);
            setLoading(false);
            if (res?.status) {
                if (res.data && res.data.CompetenciesData?.length) {
                    setCompetencies(res.data.CompetenciesData)
                }
            }
            else {
                error(res?.message ?? "Error occurred while getting competencies")
            }
        }
        catch (err) {
            setLoading(false);
            error("Error occurred while getting competencies")
        }
    }


    useEffect(() => {
        getCompetencies();
    }, [])


    const competenciesValidationSchema = Yup.object().shape({
        competenciesName: Yup.string().required('Name is required'),
    });

    const initialValues = {
        competenciesName: '',
        competenciesImage: ''
    };

    const formik = useFormik({
        initialValues,
        validationSchema: competenciesValidationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (!values.competenciesImage) {
                setErrorMsg("Competency image is required");
                return;
            }

            setSubmitting(true);
            setBtnLoading(true);
            let imgResponse = null;
            let response = null;

            if (isFileChanged) {
                const formData = new FormData();
                formData.append('foldername', 'competencies');
                formData.append('file', values.competenciesImage);

                imgResponse = await uploadCompetencyImage(formData);

                if (imgResponse?.status === "Success") {
                    values.competenciesImage = imgResponse.data;
                }
                else {
                    values.competenciesImage = '';
                    setBtnLoading(false);
                    error(imgResponse?.message ? imgResponse?.message : 'Error occurred while uploading image');
                    return;
                }
            }

            response = await create_update_Competencies(values);

            if (response?.status === `Success`) {
                success(`Competency ${values?._id ? 'updated' : 'created'} successfully.`);
                setVisiblle(false);
                formik.resetForm();
                setSelectedImage(null);
                await getCompetencies();
            } else {
                setStatus(response?.message);
                error(response?.message ?? `Error occurred while ${values?._id ? 'updating' : 'creating'} competency`);
            }
            setBtnLoading(false);
            setSubmitting(false);
        },
    });

    const onImageChange = (e) => {
        const file = e.target.files[0];
        setFileChanged(true);
        setErrorMsg(false);
        formik.setFieldValue('competenciesImage', file)
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const imagePreview = document.getElementById('imagePreview');
                setSelectedImage(e.target.result);
                imagePreview.style.display = 'block';
            };
            reader.readAsDataURL(file);
        }
    }

    const onRemoveAssociation = async (id = '') => {
        if (id) {
            setBtnLoading(true);
            const res = await deleteCompetencies({ "_id": id });

            if (res?.status === 'Succcess') {
                success(res.message);
                setConfirmModal({ flag: false, id: '' });
                getCompetencies();
            }
            else {
                error(res?.message ?? "Something went wrong, please try again later")
            }
            setBtnLoading(false);
        }
    }

    return (
        <>
            <LoaderComman isRender={!loading}>
                <Dialog draggable={false} position="top" header="Add Competencies" visible={visible || showModal} onHide={() => {
                    setSelectedImage(null);
                    setVisiblle(false)
                    setShowModal(false)
                    setSelectedCompetencies(null);
                    setMemberShipValue(null);
                    formik.resetForm();
                }} style={{ width: '30vw' }}>
                    {isDropdown
                        ?
                        <>
                            <div className="row">
                                <div className="col-lg-12 mb-5">
                                    <label className="required">Associations</label>
                                    <Dropdown
                                        value={selectedCompetencies}
                                        onChange={(e) => setSelectedCompetencies(e.value)}
                                        options={competncies}
                                        optionLabel="competenciesName"
                                        placeholder="Select Competencies" className="w-full md:w-20rem" />
                                </div>
                                <div className="col-lg-12 mb-5">
                                    <label className="required">Membership Number</label>
                                    <input type="text"
                                        value={membershipValue}
                                        onChange={(e) => setMemberShipValue(e.target.value)}
                                        placeholder="Membership Number"
                                        className="form-control" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button type="button" className="btn btn-primary-bordered" label="Cancel" onClick={() => {
                                    setSelectedCompetencies(null);
                                    setMemberShipValue(null);
                                    setShowModal(false);
                                }} />
                                <Button
                                    type="button"
                                    className="btn btn-primary ms-4"
                                    label={'Add Competency'}
                                    disabled={!selectedCompetencies || !membershipValue}
                                    onClick={() => {
                                        clientFormik.setFieldValue("competencies",
                                            [...clientFormik.values.competencies,
                                            {
                                                competenciesId: selectedCompetencies?._id,
                                                competenciesName: selectedCompetencies?.competenciesName,
                                                competenciesImage: selectedCompetencies?.competenciesImage,
                                                memberShipId: membershipValue
                                            }]);
                                        setSelectedCompetencies(null);
                                        setMemberShipValue('');
                                        setShowModal(false);
                                    }}
                                />
                            </div>
                        </>
                        :
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-5 mb-5">
                                    <label className="required">Choose</label>
                                    <input type="file" id="fileInput" onChange={(e) => onImageChange(e)} />
                                </div>
                                <div className="col-lg-7 d-flex justify-content-end mb-5">
                                    <img id="imagePreview" src={selectedImage ? selectedImage : formik.values.competenciesImage ? formik.values.competenciesImage : logoImg} alt="competenciesImage"
                                        style={{ display: 'block', maxHeight: '150px', maxWidth: '250px' }} />
                                </div>
                                <p className="text-danger">{errMsg}</p>
                                <div className="col-lg-12 mb-5">
                                    <label className="required">Competency Name:</label>
                                    <input
                                        name="competenciesName"
                                        className="form-control"
                                        type="text"
                                        {...formik.getFieldProps('competenciesName')}
                                    />
                                    {formik.touched.competenciesName && formik.errors &&
                                        <p className="text-danger">{formik.errors.competenciesName}</p>
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button type="button" className="btn btn-primary-bordered" label="Cancel" onClick={() => {
                                        setVisiblle(false);
                                        setSelectedImage(null);
                                        formik.resetForm();
                                    }} />
                                    <RoleBaseAccess moduleName="settings" isSubmit>
                                        <Button
                                            type="submit"
                                            className="btn btn-primary ms-4"
                                            icon={btnLoading && 'pi pi-spin pi-spinner'}
                                            disabled={btnLoading}
                                            label={btnLoading ? 'Please wait...' : formik.values?._id ? 'Update' : 'Add'} />
                                    </RoleBaseAccess>
                                </div>
                            </div>
                        </form>
                    }

                </Dialog>
                {!isDropdown &&
                    <>
                        <DialogueBox
                            title={'Settings Remove Competencies'}
                            message={'Do you want  to remove competency?'}
                            state={confirmModal.flag}
                            onClick={() => onRemoveAssociation(confirmModal.id)}
                            handleClose={() => setConfirmModal({ flag: false, id: '' })}
                        />

                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mb-5">
                                    <p className="text-muted">Total competencies: {competncies?.length ?? '0'}</p>
                                    <Button className="btn btn-primary" icon={"pi pi-plus"} label="Add Competencies" onClick={() => setVisiblle(true)} />
                                </div>
                                <Panel header="Competencies">
                                    {competncies?.length
                                        ?
                                        competncies.map(x => {
                                            return (
                                                <>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <img alt="" src={x.competenciesImage ? process.env.REACT_APP_IMAGE_URL + x.competenciesImage : logoImg} style={{ maxHeight: '150px', maxWidth: '300px' }} />
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <textarea
                                                                value={x.competenciesName}
                                                                id={x._id}
                                                                rows="6"
                                                                className="form-control"
                                                                disabled
                                                            >
                                                            </textarea>
                                                        </div>
                                                        <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                                            <Button
                                                                icon="pi pi-pencil"
                                                                className="btn btn-primary-bordered d-flex"
                                                                rounded text raised severity="warning"
                                                                aria-label="Notification"
                                                                onClick={() => {
                                                                    formik.setFieldValue('competenciesImage', x.competenciesImage ? x.competenciesImage : logoImg);
                                                                    formik.setFieldValue('competenciesName', x.competenciesName);
                                                                    formik.setFieldValue('_id', x._id);
                                                                    setSelectedImage(process.env.REACT_APP_IMAGE_URL + x.competenciesImage);
                                                                    setVisiblle(true)
                                                                }}
                                                            />
                                                            <Button
                                                                icon="pi pi-trash"
                                                                className="btn btn-primary-bordered ms-3 d-flex"
                                                                rounded text raised severity="warning"
                                                                aria-label="Notification"
                                                                onClick={() => {
                                                                    setConfirmModal({ flag: true, id: x._id })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Divider />
                                                </>

                                            )
                                        })
                                        :
                                        <div className="col-lg-12">
                                            No Competencies Found
                                        </div>
                                    }
                                </Panel>
                            </div>
                        </div>
                    </>
                }
            </LoaderComman>
        </>
    )
}
export default Competencies;

