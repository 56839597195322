import { error, success } from 'app/components/toast';
import {
    uploadAssetImages,
    uploadDefectImages,
    uploadHygieneImages,
    uploadQrImages,
    uploadRectifyImages,
    uploadSatisfactoryImages,
    uploadTestImages,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import placeholderimage from '../../assets/placeholderimage.jpg';

export default function ImageUploader({
    img: image,
    building: buildingDetails,
    setImageUrl,
    setImageDate,
    isDefectImg,
    isAssetImg,
    isTestImg,
    isHygieneImg,
    isRectifyImg,
    isSatisfactory,
    isQrCodeImg,
    setCount,
    isAdd,
}: {
    img: any;
    building?: any;
    setImageUrl?: any;
    setImageDate?: any;
    isDefectImg?: boolean;
    isAssetImg?: boolean;
    isTestImg?: boolean;
    isHygieneImg?: boolean;
    isRectifyImg?: boolean;
    isSatisfactory?: boolean;
    setCount?: any;
    isQrCodeImg?: any;
    isAdd?: boolean;
}) {
    const { isedit } = useSelector((state: any) => state.vault);
    const history = useHistory();
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isEdit, setEdit] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEdit(isedit);
    }, [isedit]);

    const uploadedAssetImages = async (e: any) => {
        const formData = new FormData();
        formData.append('foldername', 'buildingAssetImages');
        formData.append('allimgase', e.files[0]);
        setLoading(true);
        const response = await uploadAssetImages(formData);
        setLoading(false);

        setImageUrl(response?.data[0]);
        if (response) {
            success('image uploaded successfully');
            handleClose();
        } else {
            error('something went wrong there');
            history.goBack();
        }
    };

    const uploadedTestsImages = async (e: any) => {
        try {
            const formData = new FormData();
            formData.append('foldername', 'testImages');
            formData.append('allimgase', e.files[0]);
            setLoading(true);
            const response = await uploadTestImages(formData);
            setLoading(false);
            setImageUrl(response?.data?.[0]);
            setImageDate(moment().format('DD-MM-YYYY h:m:s'));
            if (response) {
                success('Test image uploaded successfully');
                handleClose();
            } else {
                error('something went wrong there');
            }
        }
        catch (err: any) {
            setLoading(false);
            error(err.message ?? 'something went wrong please try again later');
        }
    };

    const satisFactory = async (e: any) => {
        try {
            const formData = new FormData();
            formData.append('foldername', 'satisfactoryImages');
            formData.append('allimgase', e.files[0]);
            setLoading(true);
            const response = await uploadSatisfactoryImages(formData);
            setLoading(false);
            setImageUrl(response?.data?.[0]);
            if (response) {
                success(' satisfactory image uploaded successfully');
                handleClose();
            } else {
                error('something went wrong there');
            }
        }
        catch (err: any) {
            setLoading(false);
            error(err.message ?? 'something went wrong please try again later');
        }
    };

    const uploadDefect = async (e: any) => {
        try {
            const formData = new FormData();
            formData.append('foldername', 'defectImages');
            formData.append('allimgase', e.files[0]);
            setLoading(true);
            const response = await uploadDefectImages(formData);
            setLoading(false);
            setImageUrl(response?.data[0]);
            if (response) {
                success('defect image uploaded successfully');
                handleClose();
            } else {
                error('something went wrong there');
            }
        }
        catch (err: any) {
            setLoading(false);
            error(err.message ?? 'something went wrong please try again later');
        }
    };
    const uploadHygiene = async (e: any) => {
        try {
            const formData = new FormData();
            formData.append('foldername', 'hygieneImages');
            formData.append('allimgase', e.files[0]);
            setLoading(true);
            const response = await uploadHygieneImages(formData);
            setLoading(false);
            setImageUrl(response?.data[0]);

            if (response) {
                success('Hygiene image uploaded successfully');
                handleClose();
            } else {
                error('something went wrong there');
            }
        }
        catch (err: any) {
            setLoading(false);
            error(err.message ?? 'something went wrong please try again later');
        }
    };

    const uploadRectify = async (e: any) => {
        try {
            const formData = new FormData();
            formData.append('foldername', 'rectifyImages');
            formData.append('allimgase', e.files[0]);
            setLoading(true);
            const response = await uploadRectifyImages(formData);
            setLoading(false);
            setImageUrl(response?.data[0]);
            if (response) {
                success(' Rectify image uploaded successfully');
                handleClose();
            } else {
                error('something went wrong there');
            }
        }
        catch (err: any) {
            setLoading(false);
            error(err.message ?? 'something went wrong please try again later');
        }
    };
    const uploadedQr = async (e: any) => {
        //e.preventDefault();
        const formData = new FormData();
        formData.append('foldername', 'qRCodeImages');
        formData.append('file', e.files[0]);

        setLoading(true);
        const response = await uploadQrImages(formData);
        setLoading(false);
        if (response) {
            success('Qr image uploaded successfully');
            setImageUrl(response?.data);
            handleClose();
        } else {
            error('something went wrong there');
        }
    };

    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleImageClick = () => {
        setIsFullscreen(!isFullscreen);
    };

    const onUploadImage = async (e: any) => {
        if (isDefectImg)
            await uploadDefect(e);
        else if (isQrCodeImg) {
            uploadedQr(e);
        }
        else if (isAssetImg)
            uploadedAssetImages(e);
        else if (isSatisfactory)
            satisFactory(e);
        else if (isTestImg)
            uploadedTestsImages(e);
        else if (isHygieneImg)
            uploadHygiene(e);
        else if (isRectifyImg)
            uploadRectify(e);
    };

    return (
        <>
            <Dialog header="Image Viewer" visible={show} style={{ width: '40vw' }} onHide={handleClose} draggable={false}>
                <div className="card">
                    <FileUpload
                        name="demo[]"
                        accept="image/*"
                        maxFileSize={25000000}
                        emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
                        customUpload
                        uploadHandler={onUploadImage}
                        uploadLabel={loading ? 'Uploading...' : 'Upload'}
                    />
                </div>
            </Dialog>
            <div className='image-container px-3'>
                <Image src={Boolean(image?.length) ? process.env.REACT_APP_IMAGE_URL + image : placeholderimage}
                    alt="asset media"
                    preview={Boolean(image?.length) ? true : false}
                />
                {(isEdit || isAdd) ?
                    <button className="edit-button btn btn-primary btn-icon" onClick={handleShow}>
                        <i className='pi pi-pencil'></i>
                    </button>
                    : <></>}

            </div>

        </>
    );
}
