import ReactDOM from 'react-dom'

import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'

// Apps
import './_metronic/assets/sass/style.scss'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import {App} from './app/App'

import './_metronic/assets/sass/style.react.scss'

// primereact
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css'

//core
import 'primereact/resources/primereact.min.css'

//icons
import 'primeicons/primeicons.css'

// primereact

const {PUBLIC_URL} = process.env

/* const mock = */ _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
