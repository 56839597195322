export const ROUTES = {
  VERIFY_CODE: '/auth/verify-code',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  LOGOUT: '/logout',
  ERROR: '/error',
  ADMINISTRATION_JOBS: '/administration/jobs',
  ADMINISTRATION_JOBS_CREATE: '/administration/jobs/create-job',
  ADMINISTRATION_JOBS_EDIT: '/administration/jobs/edit',
  OPERATIVE_PROFILE_OVERVIEW: '/operative/profile-overview/:id',
  VAULT_HOME: '/vault/home',
  BUILDING_LIST: '/buildings/list',
  BUILDING_ARCHIVE: '/buildings/archive',
  FORUM_LIST: '/forum/listing',
  CLIENT_LIST: '/profile/listing',
  ARCHIVE_CLIENT: '/profile/archive',
  DASHBOARD: '/dashboard',
  FACILITY_MANAGER: '/fm/listing',
  FACILITY_MANAGER_ARCHIVE: '/fm/archive',
  OPERATIVE_LIST: '/operative/list',
  OPERATIVE_ARCHIVE: '/operative/archive',
  OPERATIVE_OVERVIEW: '/operative/profile-overview/',
  REPORT_LISTING: '/reports/listing',
  ARCHIVED_REPORTS: '/reports/archive-report',
  CREATE_REPORT: '/reports/create-report',
  CREATE_REMEDIAL_REPORT: '/reports/create-remedial-report',
  VAULT_DETAIL: '/vault/building-details/',
  ASSETS_DETAIL: '/vault/assets-details/',
  ASSETS_ARCHIVE: '/vault/archivedassets',
  NEWS_LIST: '/news/list',
  NEWS_ARCHIVE: '/news/archive/',
  DEFECT_SETTINGS: '/settings/categories/list',
  ACCESIBILTY: '/settings/accessibility/list',
  FIREBARRIROR: '/settings/fire-barrier/list',
  INSTALLATION: '/settings/installation/list',
  DAMPTER: '/settings/damper-types/list',
  DUCT: '/settings/duct-types/list',
  SHAPS: '/settings/duct-shapes/list',
  ADMIN_OPERATIVE: '/settings/adminOperative/list',
  SETTINGS_LIST: '/settings/list',
  PPM_MOBILE_GUIDANCE: '/settings/ppmRegulation/list',
  REPORT_GUIDANCE: '/settings/reportGuidance/list',
  REPORT_SERVICE: '/settings/reportService/list',
  REPORT_DISCLAIMER: '/settings/reportDisclaimer/list',
  USER_ACCESS_PERMISSIONS: '/settings/permissions',
  ASSOCIATIONS: '/settings/associations',
  COMPETENCIES: '/settings/competencies',
}
