import LoaderComman from 'app/components/shared/loader.component';
import { error, success } from 'app/components/toast';
import { useFormik } from 'formik';
import { CreateRegulation, GetRegulation, UpdateRegulation } from 'lib/SettingsApis';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import './style.css';

const PpmCategories = () => {
  const [isLoading, setLoading] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({ title: '', description: '', mobileGuidanceTextId: '' });

  useEffect(() => {
    fetchRegulation();
  }, []);

  const fetchRegulation = async () => {
    try {
      setLoading(true);
      const res = await GetRegulation();
      if (res?.status === "Success") {
        setLoading(false);
        if (res?.data.length > 0) {
          let data = res?.data?.map((item: any) => ({
            title: item.title,
            description: item.description,
            mobileGuidanceTextId: item._id
          }));
          setInitialValues(data[0]);
        }
      } else {
        error(res?.message ?? "Something went wrong, please try again later");
      }
    } catch (err: any) {
      setLoading(false);
      error(err?.message ?? "Something went wrong, please try again later");
    }
  };

  const ppmValidationSchema =
    Yup.object().shape({
      title: Yup.string().required('Title is required'),
      description: Yup.string().nullable().required('Description is required')
    });

  const formik = useFormik({
    initialValues,
    validationSchema: ppmValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setBtnLoading(true);
        let res;
        let isNew = values.mobileGuidanceTextId ? false : true;
        if (isNew) {
          res = await CreateRegulation(values);
        }
        else {
          res = await UpdateRegulation(values);
        }
        setBtnLoading(false);
        if (res?.status === "Success") {
          await fetchRegulation();
          success(`Guidance ${isNew ? 'created' : 'updated'} successfully`);
        }
        else {
          error(res?.message ?? `Error occurred while ${isNew ? 'creating' : 'updating'} guidance`);
        }
      }
      catch (err: any) {
        setLoading(false);
        error(err?.message ?? `Something went wrong, pleae try again later`);
      }
    },
  });

  return (
    <div>
      <LoaderComman isRender={!isLoading}>
        <div className='row'>
          <div className='col-lg-12'>
            <form onSubmit={formik.handleSubmit}>
              <div className='card card-custom h-100'>
                <div className='card-header border-bottom'>
                  <div className='card-title w-100 d-flex justify-content-between align-items-center'>
                    <div className='card-label'>
                      PPM Mobile Guidance Text
                    </div>
                    <Button type='submit' className='btn btn-primary' icon={isBtnLoading && 'pi pi-spin pi-spinner'} label={isBtnLoading ? 'Please wait...' : 'Update'} />
                  </div>
                </div>
                <div className='card-body'>
                  <div>
                    <div className='form-group'>
                      <label className='pb-1 text-xl required'>
                        Title
                      </label>
                      <input
                        className='form-control w-50'
                        value={formik.values.title}
                        onChange={(e) => formik.setFieldValue('title', e.target.value)}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className="text-danger">
                          {formik.errors.title}
                        </div>
                      )}

                    </div>
                    <div className='form-group'>
                      <label className='pb-1 text-xl required'>
                        Description
                      </label>
                      <Editor
                        value={formik.values.description}
                        onTextChange={(e) => formik.setFieldValue('description', e.htmlValue)}
                        style={{ height: '320px' }}
                      />
                      {formik.touched.description && formik.errors.description && (
                        <div className="text-danger">
                          {formik.errors.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </LoaderComman>
    </div>
  );
};

export default PpmCategories;
