export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  const currentSplit = current.split('/')[1]
  const urlSplit = url.split('/')[1]

  if (current === url || currentSplit === urlSplit) {
    return true
  }

  // if (current.indexOf(url) > -1) {
  //   return true
  // }

  return false
}
