import {SET_CLIENT_ID} from 'app/modules/auth/redux/client'
import {getBuildingList, getClients} from 'app/modules/auth/redux/jobs/JobsCRUD'
import {SET_BUILDING_ID} from 'app/modules/auth/redux/jobs/actions'
import {buildingsArgs} from 'app/pages/buildings/Buildings'
import {clientsArgs} from 'app/pages/profile/Clients'
import {AutoComplete} from 'primereact/autocomplete'
import {Sidebar} from 'primereact/sidebar'
import React from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {error} from '../toast'

const Filter = ({
  setSidebarVisible,
  sidebarVisible,
}: {
  setSidebarVisible: (sidebarVisible: boolean) => void
  sidebarVisible: boolean
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [buildings, setBuildings] = React.useState<any[]>([])
  const [isLoading, setLoading] = React.useState<boolean>(true)
  const [selectedBuilding, setSelectedBuilding] = React.useState<any>(null)
  const [filteredBuildings, setFilteredBuildings] = React.useState<any[]>([])
  const [clients, setClients] = React.useState<any[]>([])
  const [selectedClient, setSelectedClient] = React.useState<any>(null)
  const [filteredClients, setFilteredClients] = React.useState<any[]>([])

  React.useEffect(() => {
    if (sidebarVisible) {
      setTimeout(() => {
        const header: any = document.getElementsByClassName('p-sidebar-header')[0]
        header.style.display = 'flex'
        header.style.flexDirection = 'row-reverse'
        header.style.justifyContent = 'space-between'
        header.style.borderBottom = '1px solid #dddddd'

        const title: any = document.createElement('span')
        title.innerText = 'Filter'
        title.style.fontSize = '1.5rem'
        title.style.fontWeight = 'bold'
        title.style.color = '#000'
        header.appendChild(title)

        setBuildings([])
        getBuildingListing()
        getClientsListing()
      }, 100)
    }
  }, [sidebarVisible])

  const onOpenBuildingDetails = () => {
    if (selectedBuilding && selectedBuilding._id) {
      dispatch({
        type: SET_BUILDING_ID,
        payload: selectedBuilding._id,
      })
      setSidebarVisible(false)
      history.push(`/vault/building-details/${selectedBuilding._id}`)
    } else {
      error('Please select a building')
    }
  }

  const onOpenClientDetails = () => {
    if (selectedClient && selectedClient._id) {
      dispatch({
        type: SET_CLIENT_ID,
        payload: selectedClient._id,
      })
      localStorage.setItem('clientId', selectedClient._id)
      setTimeout(() => {
        setSidebarVisible(false)
        history.push(`/clients/view/${selectedClient._id}`)
      }, 500)
    } else {
      error('Please select a client')
    }
  }

  const search = (event: any, type: string) => {
    setTimeout(() => {
      let _filteredData

      if (type === 'building') {
        if (!event.query.trim().length) {
          _filteredData = [...buildings]
        } else {
          _filteredData = buildings.filter((building: any) => {
            return building.buildingName.toLowerCase().includes(event.query.toLowerCase())
          })
        }

        setFilteredBuildings(_filteredData)
      } else if (type === 'client') {
        if (!event.query.trim().length) {
          _filteredData = [...clients]
        } else {
          _filteredData = clients.filter((client: any) => {
            return client.clientName.toLowerCase().includes(event.query.toLowerCase())
          })
        }

        setFilteredClients(_filteredData)
      }
    }, 250)
  }

  const getBuildingListing = async () => {
    const args = buildingsArgs
    args.sortorder = -1
    args.offset = 0
    args.limit = 50
    args.query.critarion.active = true
    const res = await getBuildingList(args)
    setBuildings(res.data.buildings)
    setLoading(false)
  }

  const getClientsListing = async () => {
    clientsArgs.query.fields = 'clientName'
    clientsArgs.query.critarion.active = true
    clientsArgs.sortorder = -1
    const response = await getClients(clientsArgs)
    const activeClients = response.data.clients
    setClients(activeClients || [])
  }

  return (
    <Sidebar
      visible={sidebarVisible}
      onHide={() => setSidebarVisible(false)}
      position='right'
      style={{minWidth: 400}}
    >
      <div className='d-flex flex-column align-items-start justify-content-start gap-2'>
        <span className='font-bold text-lg mt-4'>Buildings:</span>
        <div className='d-flex flex-row justify-content-center gap-2 w-full'>
          <AutoComplete
            className='w-full'
            field='buildingName'
            value={selectedBuilding}
            suggestions={filteredBuildings}
            completeMethod={(e) => search(e, 'building')}
            onChange={(e: any) => setSelectedBuilding(e.target.value)}
            placeholder='Search Building'
          />
          <button className='btn btn-primary btn-icon' onClick={onOpenBuildingDetails}>
            <i className='la la-search' />
          </button>
        </div>
        <span className='font-bold text-lg mt-4'>Clients:</span>
        <div className='d-flex flex-row justify-content-center gap-2 w-full'>
          <AutoComplete
            className='w-full'
            field='clientName'
            value={selectedClient}
            suggestions={filteredClients}
            completeMethod={(e) => search(e, 'client')}
            onChange={(e: any) => setSelectedClient(e.target.value)}
            placeholder='Search Client'
          />
          <button className='btn btn-primary btn-icon' onClick={onOpenClientDetails}>
            <i className='la la-search' />
          </button>
        </div>
      </div>
    </Sidebar>
  )
}

export default Filter
