import {axiosInstance} from 'app/App'
import {API_URL} from 'app/modules/auth/redux/jobs/JobsCRUD'
import {toast} from 'react-toastify'
export const UPLOAD_S3_IMAGES_URL = `${API_URL}/buildingAssets/uploadSingleFile`
export const GET_UPLOADED_S3_IMAGES = `${API_URL}/buildingAssets/getFileFromS3`

// Update Regulation section
export const UploadS3Images = async (data: any) => {
  try {
    const res = await axiosInstance.post(UPLOAD_S3_IMAGES_URL, data)
    if (res) {
      return res.data
    }
  } catch (err) {}
}

export const GetS3Images = async (data: any) => {
  try {
    const res = await axiosInstance.post(GET_UPLOADED_S3_IMAGES, data, {responseType: 'blob'})
    return res.data
  } catch (err) {}
}
