import { success } from 'app/components/toast';
import { deleteOperativeType, getOperativeTypes } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PermissionDetails from './PermissionDetails';

const Permissions = () => {
  const payload = {
    sortproperty: 'createdAt',
    sortorder: -1,
    offset: 0,
    limit: 10,
    pageNum: 1,
    search: '',
    query: {
      critarion: {
        active: true,
      },
    },
  };

  const history = useHistory();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [operativeTypes, setOperativeTypes] = useState([]);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });

  const onDelete = async (id: string) => {
    const payload = { operativeTypeId: id };
    const response = await deleteOperativeType(payload);
    if (response?.status?.toLowerCase() === 'success') {
      success('Operative deleted successfully');
      fetchOperativeTypes();
    }
  };

  const fetchOperativeTypes = async () => {
    setLoading(true);
    const { data, status } = await getOperativeTypes(payload);
    if (status?.toLowerCase() === 'success') {
      setOperativeTypes(data.operativeTypes);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOperativeTypes();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card card-custom'>
              <div className='card-body'>
                <div className='d-flex justify-content-center'>
                  <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='card p-3'>
          <div className='row'>
            <div className='col-lg-12'>
              <Accordion>
                {operativeTypes?.length && operativeTypes?.map((item: any, index: number) => (
                  <AccordionTab header={item.name}>
                    <PermissionDetails id={item._id} operativeTypeEnum={item.operativeTypeEnum} />
                  </AccordionTab>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Permissions;
