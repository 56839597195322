import PpmCategory from 'app/components/Settings/PpmCategory'
import React, {useState, useEffect} from 'react'
// import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
// import { useForm, SubmitHandler, useFieldArray } from "react-hook-form"
import {yupResolver} from '@hookform/resolvers/yup'

import * as Yup from 'yup'
import {UpdateInspection, GetInspection} from 'lib/SettingsApis'
import {InspecProps} from 'lib/Types'
import {toast} from 'react-toastify'
import {useForm, SubmitHandler, useFieldArray} from 'react-hook-form'
import './style.css'
import SettingHeader from 'app/components/GenericComponents/SettingHeader'

const PpmInspection = () => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [parentId, setParentId] = useState<string>('')

  const [colorChange, setColorChnage] = useState<number>()

  // Define the validation schema with Yup
  const validationSchema = Yup.object({
    dynamicInputs: Yup.array().of(
      Yup.object().shape({
        insTstSubSectionTitle: Yup.string().required('Title is required'),
        insTstSubSectionDescription: Yup.string().required('Description is required'),
      })
    ),
  })
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: {dynamicInputs: [{insTstSubSectionTitle: '', insTstSubSectionDescription: ''}]},
    resolver: yupResolver(validationSchema),
  })

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'dynamicInputs',
  })

  const onSubmit: SubmitHandler<InspecProps> = async (data) => {
    setSaveLoading(true)
    let res = await UpdateInspection(data?.dynamicInputs, parentId)
    if (res) {
      setSaveLoading(false)
      setIsEdit(false)
      toast.success(res.message)
    }
  }

  useEffect(() => {
    let fetch = async () => {
      let res = await GetInspection()
      if (res) {
        let obj = Object.assign({}, ...res?.data?.inspTestingGuidances)
        setLoading(false)
        setParentId(obj._id)
        if (obj?.insTstSubSection.length > 0) {
          reset({
            dynamicInputs: obj?.insTstSubSection, // Replace 'initialArray' with your actual initial array
          })
        }
      }
    }
    fetch()
  }, [])

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else
    return (
      <div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='card card-custom h-100'>
              <div className='card-header'>
                <div className='card-title'>
                  <div className='card-label'>Category</div>
                </div>
              </div>
              <div className='card-body'>
                <PpmCategory />
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='card card-custom h-100'>
                <div className='card-header border-bottom'>
                  <div className='card-title w-100 d-flex justify-content-between align-items-center'>
                    <div className='card-label'>Inspection & Testing</div>
                    <SettingHeader
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      saveLoading={saveLoading}
                    />
                  </div>
                </div>
                <div className='card-body'>
                  <div>
                    {fields.map((field, index: number) => (
                      <div className='pb-5 '>
                        <div key={index}>
                          <div className='form-group'>
                            <label className='pb-1 text-xl' htmlFor='accessibilityName'>
                              Title
                            </label>
                            <input
                              disabled={isEdit ? false : true}
                              className='form-control w-50'
                              {...register(`dynamicInputs.${index}.insTstSubSectionTitle` as const)}
                              defaultValue={field.insTstSubSectionTitle}
                            />

                            {errors.dynamicInputs?.[index]?.insTstSubSectionTitle && (
                              <p className='text-danger'>
                                {errors.dynamicInputs[index]?.insTstSubSectionTitle?.message}
                              </p>
                            )}
                          </div>
                          <div className='d-flex justify-content-end align-items-end'>
                            <button
                              disabled={isEdit ? false : true}
                              type='button'
                              onClick={() => setColorChnage(index)}
                              className='btn btn-primary'
                            >
                              <span className='px-4'>Highlight text box</span>
                            </button>
                          </div>
                          <div className='form-group'>
                            <label className='pb-1 text-xl' htmlFor='accessibilityName'>
                              Description
                            </label>
                            <textarea
                              disabled={isEdit ? false : true}
                              {...register(
                                `dynamicInputs.${index}.insTstSubSectionDescription` as const
                              )}
                              defaultValue={field.insTstSubSectionDescription}
                              className={` ${index === colorChange && 'bg-light'}  form-control`}
                              rows={10}
                            ></textarea>

                            {errors.dynamicInputs?.[index]?.insTstSubSectionDescription && (
                              <p className='text-danger'>
                                {errors.dynamicInputs[index]?.insTstSubSectionDescription?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <button
                            type='button'
                            disabled={isEdit ? false : true}
                            onClick={() =>
                              append({insTstSubSectionTitle: '', insTstSubSectionDescription: ''})
                            }
                            className='w-100 h-40px btn '
                            style={{
                              backgroundColor: '#DD6939',
                              borderRadius: '5px',
                              color: 'white',
                            }}
                          >
                            <div className='d-flex justify-content-center align-items-center'>
                              <i className='bi bi-plus-circle px-3 text-white'></i>
                              Add new section
                            </div>
                          </button>
                        </div>
                        {fields?.length > 1 && (
                          <div className='pt-6 hr_row'>
                            <hr />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
}

export default PpmInspection
