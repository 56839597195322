import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {resetPassword} from '../redux/AuthCRUD'
// import { useDispatch } from 'react-redux'
// import * as auth from '../redux/AuthRedux'
// import { ROUTES } from '../../../../constants/routes'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(20, 'Maximum 20 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [message, setMessage] = useState<String | undefined>('')
  // const dispatch = useDispatch();
  // const history = useHistory();

  const onSubmit = (values: any) => {
    setLoading(true)
    setHasErrors(undefined)
    setTimeout(() => {
      resetPassword(values.password).then((response: any) => {
        // if (response.status === `Fail`) {
        //   setHasErrors(true);
        //   setLoading(false);
        //   // setSubmitting(false);
        //   setMessage(response.message);
        //   // setStatus(response.message);
        // }
        // else if (response.status === 'Success') {
        //   setHasErrors(false);
        //   // setStatus(response.message);
        //   setMessage(response.message);
        //   // dispatch(auth.actions.setEmail(""))
        //   setLoading(false);
        //   history.push(ROUTES.FORGOT_PASSWORD);
        // }
      })
      // .catch(() => {
      //   setHasErrors(true);
      //   setLoading(false);
      //   // setSubmitting(false);
      //   // setStatus('Something went wrong');
      // })
    }, 0)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit,
    // onSubmit: (values, { setStatus, setSubmitting }) => {
    //   setLoading(true)
    //   setHasErrors(undefined)
    //   setTimeout(() => {
    //     resetPassword(values.password)
    //       .then((response: any) => {
    //         if (response.status === `Fail`) {
    //           setHasErrors(true);
    //           setLoading(false);
    //           setSubmitting(false);
    //           setMessage(response.message);
    //           setStatus(response.message);
    //         }
    //         else if (response.status === 'Success') {
    //           setHasErrors(false);
    //           setStatus(response.message);
    //           setMessage(response.message);
    //           // dispatch(auth.actions.setEmail(""))
    //           setLoading(false);
    //           // history.push(ROUTES.LOGIN);
    //         }
    //       })
    //       .catch(() => {
    //         setHasErrors(true);
    //         setLoading(false);
    //         setSubmitting(false);
    //         setStatus('Something went wrong');
    //       })
    //   }, 0)
    // },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter a new password twice.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{message}</div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{message}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group: Email */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group: Password */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
