/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import DialogueBox from 'app/components/DialogueBox';
import CategorySetting from 'app/components/Settings/CategorySetting';
import CustomDataTable from 'app/components/data-table/customDataTable';
import { error, success } from 'app/components/toast';
import {
  createInstallation,
  getInstallationListing,
  removeInstallation,
  updateInstallation,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useFormik } from 'formik';
import { Chip } from 'primereact/chip';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import CategorySettingsDialog from './categorySettingsDialog';
export interface IInstallation {
  installationid?: string;
  installationName: string;
  description: string;
  isKey?: boolean;
}

export const installationProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

const Installation = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [installations, setInstallations] = useState<IInstallation[]>([]);
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState(null);

  const initialValues: IInstallation = {
    installationName: '',
    description: '',
  };

  const validationSchema: any = Yup.object().shape({
    installationName: Yup.string().required('Installation Name is required'),
    // description: Yup.string().required('Description is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: IInstallation, { setSubmitting }) => {
      setSubmitting(true);
      onCreateInstallation(values);
    },
  });

  const handleCancel = () => {
    setEditing(false);
    formik.resetForm();
  };

  const fetchInstallations = async () => {
    try {
      installationProps.sortorder = sortOrder;
      const response = await getInstallationListing(installationProps);
      if (response.status === 'Success') {
        response.data.installations &&
          response.data.installations.map((ins: any, i: number) => {
            ins.serial = i + 1;
          });
        setInstallations(response.data.installations);
        setLoading(false);
      }
    } catch (err) { }
  };

  useEffect(() => {
    fetchInstallations();
  }, []);

  const onCreateInstallation = async (values: IInstallation) => {
    try {
      setLoading(true);
      let response = null;
      if (isEditing) {
        response = await updateInstallation(values);
      } else {
        response = await createInstallation(values);
      }
      if (response.status === 'Success') {
        success(`Installation ${isEditing ? 'updated' : 'created'} successfully.`);
        fetchInstallations();
        formik.resetForm();
        setLoading(false);
      }
    } catch (err) {
      error(`Could not ${isEditing ? 'update' : 'create'} Installation.`);
    } finally {
      setLoading(false);
    }
  };

  const onEditInstallation = (row: any) => {
    setSelectedData(row);
    setShowDialog(true);
  };

  const onArchive = async (id: string) => {
    const body: any = {
      id,
    };
    try {
      setLoading(true);
      const response = await removeInstallation(body);
      if (response.status === 'Success') {
        success('Installation archived successfully.');
        fetchInstallations();
        setLoading(false);
      }
    } catch (err) {
      error('Could not archive Installation.');
    } finally {
      setLoading(false);
    }
  };

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    };
  };

  useEffect(() => {
    fetchInstallations();
  }, [sortOrder]);

  const renderChip = (row: any) => {
    if (row.type === 'Satisfactory') {
      return <Chip label={row.type} className="bg-success text-white" />;
    }

    if (row.type === 'Limitation') {
      return <Chip label={row.type} className="bg-info text-white" />;
    }

    if (row.type === 'Defect') {
      return <Chip label={row.type} className="bg-danger text-white" />;
    }
  };

  const columns: any = [
    {
      field: 'serial',
      header: '#',
      style: { minWidth: '50px' },
    },
    {
      field: 'installationName',
      header: 'Name',
      style: { minWidth: '600px' },
    },
    {
      field: 'type',
      header: 'Type',
      body: renderChip,
      style: { minWidth: '100px' },
    },
  ];

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className='row'>
        <div className='col-lg-4'>
          <div className='card card-custom h-100'>
            <div className='card-header'>
              <div className='card-title'>
                <div className='card-label'>Category Settings</div>
              </div>
            </div>
            <div className='card-body'>
              <CategorySetting />
            </div>
            {/*<form onSubmit={formik.handleSubmit}>
              <div className='card-header'>
                <div className='card-title'>
                  <div className='card-label'>Create Installation</div>
                </div>
              </div>
              <div className='card-body'>
                <div className='form-group'>
                  <label htmlFor='installationName'>Installation Name</label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('installationName')}
                  />
                  {formik.touched.installationName && formik.errors.installationName ? (
                    <div className='text-danger'>{formik.errors.installationName}</div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label htmlFor='description'>Installation Description</label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('description')}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className='text-danger'>{formik.errors.description}</div>
                  ) : null}
                </div>
              </div>
              <CardFooter form={formik} isEditing={isEditing} setEditing={setEditing} />
                  </form>*/}
          </div>
        </div>
        <div className='col-lg-8'>
          <div className='card card-custom card-stretch gutter-b'>
            <div className='card-body pt-0 pb-3'>

              <CategorySettingsDialog
                visible={showDialog}
                onHide={(val: boolean) => { setShowDialog(val); setSelectedData(null); }}
                rowData={selectedData}
                categoryType="Installation"
                refresh={() => {
                  if (selectedData) {
                    setSelectedData(null);
                  }
                  fetchInstallations();
                }} />
              {/* 
              <div className='d-flex justify-content-end'>
                <div className='pt-9'>
                  <button type='button' onClick={() => setShowDialog(true)} className='btn btn-primary'>
                    <span className='px-4'>Create </span>
                  </button>
                </div>
              </div> */}

              <CustomDataTable
                data={installations}
                columns={columns}
                isHideSearch
                isShowActionColumns
                isHideArchieveAction
                onEdit={(id: string) => {
                  let data = installations.find((x: any) => x._id === id);
                  onEditInstallation(data);
                }}
              />
              <DialogueBox
                title={'Installation Archive'}
                message={'Do you want  to archive?'}
                state={dialogueBox.flag}
                onClick={() => onArchive(dialogueBox.record)}
                handleClose={() => setDialogueBox({ flag: false, record: {} })}
              />
            </div>
          </div>
        </div>
      </div>
    );
};

export default Installation;
