/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { AsideMenu } from './AsideMenu';

const Logo = styled.img`
  width: 90%;
`;

const AsideDefault: FC = () => {
  const { config, classes } = useLayout();
  const { aside } = config;

  const [isSideBarCollapsed, setSideBarCollapsed] = useState(false);
  const [isSideBarFocusLost, setSideBarFocusLost] = useState(true);

  const handleMouseEnter = () => {
    setSideBarFocusLost(false);
  };

  const handleMouseLeave = () => {
    setSideBarFocusLost(true);
  };

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {aside.theme === 'dark' && (
          <Link to='/dashboard'>
            <Logo alt='Logo' className='logo' src={toAbsoluteUrl('/media/logos/logo.jpg')} />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img alt='Logo' className='h-60px logo' src={toAbsoluteUrl('/media/logos/logo.jpg')} />
          </Link>
        )}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            onClick={() => setSideBarCollapsed(!isSideBarCollapsed)}
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
      </div>

      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} isSideBarCollapsed={isSideBarCollapsed} isSideBarFocusLost={isSideBarFocusLost} />
      </div>
    </div>
  );
};

export { AsideDefault };

