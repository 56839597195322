import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import moment from 'moment'
import {useEffect, useState} from 'react'
import './calendar.style.scss'

export function CalendarView({data}: {data: any[]}) {
  const [eventsData, setEventsData] = useState<any[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const events: any[] = []
    if (data.length) {
      data.map((job: any) => {
        events.push({
          title: job.jobReference,
          start: job.jobStartDate,
          end: job.jobEndDate,
          backgroundColor: '#FFA4A0',
          borderColor: '#FFA4A0',
          borderTopLeftRadius: '10px',
          borderbottomLeftRadius: '10px',
        })
      })
      setEventsData(events)
      setLoading(false)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className='d-flex flex-column-fluid'>
        <div className='container d-flex flex-column gap-8'>
          <div className='d-flex flex-column align-items-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
            <span className='mt-3'>Loading...</span>
          </div>
        </div>
      </div>
    )
  } else
    return (
      <div>
        <FullCalendar
          plugins={[dayGridPlugin, listPlugin]}
          initialView='dayGridMonth'
          weekends={false}
          events={eventsData}
          eventContent={renderEventContent}
          themeSystem='bootstrap5'
          stickyHeaderDates={true}
          eventMinHeight={100}
          height={640}
          contentHeight={640}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth',
          }}
          dayHeaderFormat={{
            weekday: 'long',
          }}
        />
      </div>
    )
}

function renderEventContent(eventInfo: any) {
  return (
    <>
      <b className='text-dark'>
        {moment(eventInfo?.event.start).format('DD-MM-YYYY') || 'No Time'}
      </b>
      <span className='ms-4 text-dark'>{eventInfo.event.title}</span>
    </>
  )
}
