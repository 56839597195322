import { DefectModal } from 'app/components/CustomModal';
import { error, success, warning } from 'app/components/toast';
import {
  CreateBuildingAssetSnapShot,
  UpdateBuildingAssets,
  addtest,
  archivedAccessPanelt,
  archivedAccessibilityDefect,
  archivedFirebarrierDefect,
  archivedInstallationDefect,
  createFireBarrier,
  deleteObservation,
  getAccessibilitiesListing,
  getBuilding,
  getBuildingAssetById,
  getDamperTypeListing,
  getDefectSubCategories,
  getDuctTypeListing,
  getFireBarrierListing,
  getInstallationListing,
  getJobsListing,
  removeDefects,
  saveObservation,
  updateAccessPanelDefect,
  updateAccessibilityDefect,
  updateFirebarrierDefect,
  updateInstallationDefect,
  uploadObservationImage
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import moment from 'moment-timezone';
import './style.css';
// import momentTime from 'moment-timezone'
import CustomDataTable from 'app/components/data-table/customDataTable';
import LoaderComman from 'app/components/shared/loader.component';
import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { UserModel } from 'app/modules/auth/models/UserModel';
import { BuildingDetailedListProps } from 'app/modules/auth/redux/Types';
import { SET_SELECTED_JOB, SET_TESTS_DATA } from 'app/modules/auth/redux/jobs/actions';
import { SET_ASSET_INFO, SET_EDIT_VALUT, SET_INITAIL_VAULT, SET_SAVED, SET_SHOW_ACTIVITY, SET_TESTS } from 'app/modules/auth/redux/vault';
import { GetPermissionsByName } from 'hooks/usePermissionAcess';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { RootState } from 'setup';
import CheckImg from '../../../assets/associate.png';
import { buildingByIdArgs } from '../buildings/Buildings';
import { damperTypeProps } from '../settings/DamperTypes';
import DefectFlow from './DefectFlow';
import SupplementaryObservations from './supplementaryObservations.component';

import DialogueBox from 'app/components/DialogueBox';
import ImageUploader from 'app/components/ImageUploader';
import logoImg from '../../../assets/associate.png';


export const accessibilityProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export const fireBarrierProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export const installationsProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export const DuctProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export const buidlingProps: any = {
  sortproperty: 'created_at',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export default function AssetPageTemplate() {
  //const {building} = useSelector((state: any) => state.job)
  const [building, setBuinding] = useState<BuildingDetailedListProps>();
  const { issaved, istest, isedit, isactivity } = useSelector((state: any) => state.vault);
  const { id } = useParams<{ id: string; }>();

  const [assetDetails, setAssetDetail] = useState<any>({});
  // let assetDetails = building?.buildingAssets?.length
  //   ? building.buildingAssets.find((asset: any) => asset._id === id)
  //   : null

  const [imgUrl1, setImgUrl1] = useState('');
  const [imgUrl2, setImgUrl2] = useState('');
  const [qrCodeImg, setQrCodeImg] = useState('');
  const [assetReference, setAssetReference] = useState('');
  const [showRefMsg, setShowRefMsg] = useState(false);
  const [locationRef, setLocationRef] = useState('');
  const [isEdit, setEdit] = useState(false);
  const [defectSubcategoryData, setDefectSubcategoryData] = useState<any>({});
  const [allDefectList, setAllDefectList] = useState<any>({
    archiveDefects: [],
    unarchivedDefects: [],
  });
  const [defectListOpen, setDefectListOpen] = useState<boolean>(false);
  const [defectDetailViewOpen, setDefectDetailViewOpen] = useState<boolean>(false);
  const [currentDefect, setCurrentDefect] = useState<any>();
  const [defectArchivedListOpen, setArchivedDefectListOpen] = useState<boolean>(false);
  const [testListingOpen, setTestListingOpen] = useState<boolean>(false);
  const [testDetailView, setTestDetailView] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [showObservationDialog, setShowObservationDialog] = useState(false);
  const [observationModel, setObservationModel] = useState<any>({
    observationNote: '',
    observationImage: ''
  });
  const [selectedObservationImage, setSelectedObservationImage] = useState<any>('');
  const [isFileChanged, setFileChanged] = useState(false);

  const [dialogueBox, setDialogBox] = useState<any>({});
  const [observationDialogueBox, setObservationDialogueBox] = useState({ flag: false, id: '' });

  const [defectSubCategories, setDefectSubCategories] = useState({
    "Test": [], "Access Panel": [], "Installation": [], "Fire Barriers": [], "Accessibility": []
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const TestsRef = useRef<any>();

  const array = window.location.pathname.split('/');
  const assetId = array[array.length - 1];

  const [latestTest, setLatestTest] = useState(null);

  const [installationName, setInstallationName] = useState({
    label: '',
    value: '',
  });

  useEffect(() => {
    const { installation } = assetDetails;

    if (installation) {
      setInstallationName({
        label: installation.installationName,
        value: installation.installationName,
      });
    }
    if (Object.keys(assetDetails).length) {
      getDefectDetails(assetDetails);
    }
  }, [assetDetails]);


  useEffect(() => {
    if (assetId) {
      const fetchData = async () => {
        await getAssetById(assetId);
      };

      fetchData();
    }
  }, []);

  const assetArgs = {
    critarion: {
      _id: '',
    },
    buildingAssetsFilter: { assetArchived: false },
    addedby: '_id email name',
    lastModifiedBy: '_id email name',
  };

  const getAssetById = async (id = '') => {
    dispatch({
      type: SET_SAVED,
      payload: false,
    });
    dispatch({
      type: SET_EDIT_VALUT,
      payload: false,
    });
    setLoading(true);
    assetArgs.critarion._id = id;
    const response = await getBuildingAssetById(assetArgs);
    if (response.status === 'Success') {
      dispatch({
        type: SET_ASSET_INFO,
        payload: response.data,
      });
      setAssetDetail(response.data);
      setImgUrl1(response.data?.assetImages?.[0]?.imagePath ?? '');
      setImgUrl2(response.data?.assetImages?.[1]?.imagePath ?? '');
      setQrCodeImg(response.data?.assetQrCode ?? '');
      setAssetReference(response.data?.assetReference);

      const latestTest = response.data?.tests?.length > 0 ? response.data?.tests[response.data?.tests?.length - 1] : null;

      setLatestTest(latestTest);

      buildingByIdArgs.critarion._id = response.data.building;
      const buildingResponse = await getBuilding(buildingByIdArgs);
      if (buildingResponse.status === 'Success') {
        setBuinding(buildingResponse.data);

        dispatch({
          type: SET_INITAIL_VAULT,
          payload: buildingResponse.data,
        });
      }

      setLoading(false);
    }
  };

  const handleActivity = () => {
    dispatch({
      type: SET_SHOW_ACTIVITY,
      payload: false,
    });
  };

  const handleTest = () => {
    dispatch({
      type: SET_TESTS,
      payload: false,
    });
  };

  const addType = (data: any, type: string) => {
    try {
      for (let i = 0; i < data.length; i++) {
        const v = data[i];
        v[type] = true;
      }
      return data;
    } catch (e) { }
  };

  const getDefectDetails = async (resData: any) => {
    let tests: any[] = [];

    const latestTest = resData?.tests?.length > 0 ? resData?.tests[resData?.tests?.length - 1] : null;

    if (latestTest?.defects?.length) {
      latestTest?.defects.map((x: any) => {
        tests.push(x);
      });
    }

    const allDefect = [
      ...addType(assetDetails?.accessibility?.defects, 'accessibility'),
      ...addType(assetDetails?.fireBarrier?.defects, 'fireBarrier'),
      ...addType(assetDetails?.installation?.defects, 'installation'),
      ...addType(assetDetails?.accessPanel?.defects, 'accessPanel'),
      ...addType(tests, 'tests'),
    ];
    const data = archivedUnarchived(allDefect);

    setAllDefectList({
      archiveDefects: data.archived,
      unarchivedDefects: data.unarchived,
    });
  };

  const getAssetDetails = async () => {
    try {
      const res = await getBuildingAssetById({
        critarion: { _id: id },
      });

      getDefectDetails(res?.data);
      setAssetDetail(res?.data);

      const jobProps = {
        sortproperty: 'createdAt',
        sortorder: -1,
        offset: 0,
        limit: 50,
        query: {
          critarion: { active: true, building: res?.data?.building },
          fields: 'jobReference jobType customerPOReference',
        },
      };

      const getJobList = async () => {
        try {
          const result = await getJobsListing(jobProps);
          let obj = Object.assign({}, ...result?.data?.inspectionJobs);
          dispatch({
            type: SET_SELECTED_JOB,
            payload: obj,
          });
        } catch (e) { }
      };

      getJobList();
    } catch (e) { }
  };

  const archivedUnarchived = (allDefect: any) => {
    const archived = [];
    const unarchived = [];
    for (let i = 0; i < allDefect.length; i++) {
      const v = allDefect[i];
      if (v.defectArchived) {
        archived.push(v);
      } else {
        unarchived.push(v);
      }
    }
    return { archived, unarchived };
  };

  const getDefectSubcategoryList = async () => {
    try {
      const body = {
        sortproperty: 'createdAt',
        sortorder: -1,
        offset: 0,
        limit: 50,
        query: {
          critarion: {
            active: true,
          },
          fields: '',
        },
      };
      const result = await getDefectSubCategories(body);
      const { defectCategorys } = result.data;

      let keys = Object.keys(defectSubCategories);
      let data = keys.reduce((acc: any, key: any) => {
        let subCategories = defectCategorys.find((y: any) => y.defectCategoryName === key).subCategories;
        acc[key] = subCategories;
        return acc;
      }, {});

      setDefectSubCategories(data);
      setDefectSubcategoryData(defectCategorys);
    } catch (e) { }
  };

  useEffect(() => {
    getDefectSubcategoryList();
    getAssetDetails();
  }, [isactivity]);

  useEffect(() => {
    if (issaved === true && Object.keys(formData)?.length > 0) {
      createSnapShotInDb();
      handleUpdate();
    }
  }, [issaved, formData]);

  useEffect(() => {
    setEdit(isedit);
  }, [isedit]);

  const createSnapShotInDb = async () => {
    try {
      const body = {
        buildingAssetId: assetDetails._id,
      };
      await CreateBuildingAssetSnapShot(body);
    } catch (e) { }
  };

  // Update Assset
  const handleUpdate = async () => {
    try {
      if (!showRefMsg) {
        dispatch({
          type: SET_EDIT_VALUT,
          payload: true,
        });
        dispatch({
          type: SET_SAVED,
          payload: false,
        });
        if (!TestsRef?.current?.submitTest()) {
          warning("Please submit the test before updating the asset");
          return;
        }

        const AccessibilityBody = formData?.accesibilty;
        const FireBarrierBody = formData?.Firebarrier;
        const InstallationBody = formData?.installData;
        const DuctTypeBody = formData?.ductDamperObj;
        const AccessPanelBody = formData?.accessPanelObj;

        let fireBarrier = {
          ...FireBarrierBody,
        };

        let ductDamper = {
          ...DuctTypeBody,
        };

        let accessPanel = {
          ...AccessPanelBody,
        };

        const body = {
          buildingAssetid: assetDetails._id,
          assetQrCode: qrCodeImg,
          assetReference: assetReference,
          assetLocation: locationRef?.length ? locationRef : assetDetails?.assetLocation,
          assetImages: [
            {
              imagePath: imgUrl1,
              imageDescription: '',
              imageActive: true,
            },
            {
              imagePath: imgUrl2,
              imageDescription: '',
              imageActive: true,
            },
          ],
          ...AccessibilityBody,
          fireBarrier,
          ...InstallationBody,
          accessPanel,
          ductDamper,

          previouslyChangedBy: assetDetails.lastModifiedBy._id
            ? assetDetails.lastModifiedBy._id
            : assetDetails.lastModifiedBy,
          previouslyChangedOn: assetDetails?.updatedAt,
          lastOperation: 'updateBuildingAsset',
          previousOperation: assetDetails.lastOperation || '',
          active: true,
        };
        let sendData = async () => {
          await UpdateBuildingAssets(body);
        };
        if (Object.keys(formData)?.length > 0) {
          sendData();
          history.goBack();
        }
      }
    } catch (e) { }
  };

  const previousData = (title: any) => {
    return {
      previouslyChangedBy: assetDetails.lastModifiedBy._id
        ? assetDetails.lastModifiedBy._id
        : assetDetails.lastModifiedBy,
      previouslyChangedOn: assetDetails?.updatedAt,
      lastOperation: title,
      previousOperation: assetDetails.lastOperation || '',
    };
  };

  const archivedDefect = async (record: any) => {
    try {
      const { accessibility, installation, fireBarrier, tests, accessPanel } = record;

      let allDefect = [...allDefectList.archiveDefects, ...allDefectList.unarchivedDefects];
      for (let i = 0; i < allDefect.length; i++) {
        const element = allDefect[i];
        if (record._id === element._id) {
          element.defectArchived = !element.defectArchived;
        }
      }
      const data = archivedUnarchived(allDefect);
      let res;
      let body = {
        defectid: record?._id,
        buildingAssetId: assetDetails?._id,
        inspectionJobId: assetDetails?.inspectionJob,
        buildingid: building?._id,
      };
      if (accessibility) {
        body = {
          ...body,
          ...previousData('Archive Accessibility Defect1'),
        };
        res = await archivedAccessibilityDefect(body);
        if (res?.status === 'Success') success('Accessibilty Defect Removed Successfully!');
      } else if (fireBarrier) {
        body = {
          ...body,
          ...previousData('Archive FireBarrier Defect1'),
        };
        res = await archivedFirebarrierDefect(body);
        if (res?.status === 'Success') success('FireBarrier Defect Removed Successfully!');
      } else if (installation) {
        body = {
          ...body,
          ...previousData('Archive Installation Defect1'),
        };
        res = await archivedInstallationDefect(body);
        if (res?.status === 'Success') success('Installation Defect Removed Successfully!');
      } else if (accessPanel) {
        body = {
          ...body,
          ...previousData('Archive AccessPanel Defect1'),
        };
        res = await archivedAccessPanelt(body);
        if (res?.status === 'Success') success('AccessPanel Defect Removed Successfully!');
      } else if (tests) {
        body = {
          ...body,
          ...previousData('Archive Test Defect1'),
        };
        // res = await archivedTestPanelt(body)
        // if (res?.status === 'Success')
        success('Test Defect Archived Successfully!');
      }

      setAllDefectList({
        archiveDefects: data.archived,
        unarchivedDefects: data.unarchived,
      });
    } catch (e) { }
  };

  const unArchivedDefect = async (record: any) => {
    try {
      const { accessibility, installation, fireBarrier, tests, accessPanel } = record;

      let allDefect = [...allDefectList.archiveDefects, ...allDefectList.unarchivedDefects];
      for (let i = 0; i < allDefect.length; i++) {
        const element = allDefect[i];
        if (record._id === element._id) {
          element.defectArchived = false;
        }
      }
      const data = archivedUnarchived(allDefect);
      let res;
      let body = {
        defectid: record?._id,
        buildingAssetId: assetDetails?._id,
        inspectionJobId: assetDetails?.inspectionJob,
        defectArchived: false,
      };
      if (accessibility) {
        body = {
          ...body,
          ...previousData('UnArchive Accessibility Defect1'),
        };
        res = await updateAccessibilityDefect(body);
        if (res?.status === 'Success') success('Accessibilty Defect Unarchived Successfully!');
      } else if (fireBarrier) {
        body = {
          ...body,
          ...previousData('UnArchive FireBarrier Defect1'),
        };
        res = await updateFirebarrierDefect(body);
        if (res?.status === 'Success') success('FireBarrier Defect Unarchived Successfully!');
      } else if (installation) {
        body = {
          ...body,
          ...previousData('UnArchive Installation Defect1'),
        };
        res = await updateInstallationDefect(body);
        if (res?.status === 'Success') success('Installation Defect Unarchived Successfully!');
      } else if (accessPanel) {
        body = {
          ...body,
          ...previousData('UnArchive AccessPanel Defect1'),
        };
        res = await updateAccessPanelDefect(body);
        if (res?.status === 'Success') success('AccessPanel Defect Unarchived Successfully!');
      } else if (tests) {
        body = {
          ...body,
          ...previousData('UnArchive Test Defect1'),
        };
        // res = await archivedTestPanelt(body)
        // if (res?.status === 'Success')
        success('Test Defect Unarchived Successfully!');
      }

      setAllDefectList({
        archiveDefects: data.archived,
        unarchivedDefects: data.unarchived,
      });
    } catch (e) { }
  };

  const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;

  const deleteDefect = async () => {
    try {
      let defect = {
        "buildingAssetId": assetDetails._id,
        "defectid": dialogueBox.defectId,
        "defect": true,
        "lastOperation": "Remove Defect",
        "previouslyChangedBy": user?._id,
        "previousOperation": "newCreation",
        "previouslyChangedOn": new Date().toLocaleString(),
        "inspectionJobId": assetDetails.inspectionJob,
        "buildingid": assetDetails.building,
        "testid": dialogueBox?.testId ? dialogueBox?.testId : null
      };

      let data = {
        [Object.keys(dialogueBox)[0]]: true,
        defect: defect
      };

      setBtnLoading(true);

      const response = await removeDefects(data);

      if (response.status === "Success") {
        success("Defect deleted successfully");

        const assetDetails = response.data;

        if (dialogueBox.isAllDefect) {

          getDefectDetails(response.data);
        }
        setDialogBox(null);
        setAssetDetail(response.data);
      }
      else {
        error("Something went wrong, please try again later");
      }
      setBtnLoading(false);
    }
    catch (err: any) {
      setBtnLoading(false);
      error("Something went wrong, please try again later");
    }

  };

  const onImageChange = (e: any) => {
    const file = e.target.files[0];
    setFileChanged(true);
    if (file) {
      let model = { ...observationModel };
      model.observationImage = file;
      setObservationModel(model);
      const reader = new FileReader();
      reader.onload = function (e) {
        const imagePreview = document.getElementById('imagePreview');
        if (e.target) {
          setSelectedObservationImage(e.target.result ? e.target.result : '');
        }
        if (imagePreview) {
          imagePreview.style.display = 'block';
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const token = localStorage.getItem('user');
  const userData = token ? JSON.parse(token)?.data : null;

  const submitObservation = async () => {
    try {
      setBtnLoading(true);
      let imgResponse = null;
      let response = null;

      let model = { ...observationModel };

      if (isFileChanged) {
        const formData = new FormData();
        formData.append('foldername', 'observations');
        formData.append('allimgase', model.observationImage);

        imgResponse = await uploadObservationImage(formData);

        if (imgResponse?.status === "Success") {
          model.observationImage = imgResponse.data[0];
        }
        else {
          model.observationImage = '';
          setBtnLoading(false);
          error(imgResponse?.message ? imgResponse?.message : 'Error occurred while uploading image');
          return;
        }
      }

      let data = {
        "inspectionJobId": assetDetails.inspectionJob,
        "buildingAssetId": assetDetails._id,
        "buildingid": assetDetails.building,
        "observations": {
          "observationId": model?._id ? model?._id : '',
          "observationNote": model.observationNote,
          "observationImage": model.observationImage,
          "observationOperative": userData._id
        }
      };

      response = await saveObservation(data);

      if (response?.status === `Success`) {
        success(`Observation  ${model?._id ? 'updated' : 'created'} successfully.`);
        setShowObservationDialog(false);
        setObservationModel({ observationImage: '', observationNote: '' });
        setSelectedObservationImage(null);
        await getAssetById(assetId);
        setFileChanged(false);
      } else {
        error(response?.message ?? `Error occurred while ${model?._id ? 'updating' : 'creating'} observation`);
      }
      setBtnLoading(false);
    }
    catch (err: any) {
      error(err?.message);
    }
  };

  const onRemoveObservation = async (id: string) => {
    try {
      let data = {
        "buildingAssetId": assetDetails._id,
        "observationId": id
      };

      const response = await deleteObservation(data);

      if (response?.status === `Success`) {
        success(`Observation deleted successfully.`);
        setShowObservationDialog(false);
        await getAssetById(assetId);
      } else {
        error(response?.message ?? `Error occurred while deleting observation`);
      }
      setBtnLoading(false);
    }
    catch (err: any) {
      error(err?.message ?? `Error occurred while deleting observation`);
    }
  };

  const renderSubCategories = (currentDefect: any) => {
    if (currentDefect?.accessibility)
      return defectSubCategories.Accessibility;
    else if (currentDefect?.fireBarrier)
      return defectSubCategories['Fire Barriers'];
    else if (currentDefect?.installation)
      return defectSubCategories.Installation;
    else if (currentDefect?.accessPanel)
      return defectSubCategories['Access Panel'].map((x: any) => {
        return {
          ...x,
          label: x.defectsubCatgTitle,
          value: x.defectsubCatgTitle
        };
      });
    else if (currentDefect?.tests)
      return defectSubCategories.Test;
    else
      return [];

  };

  const renderData = () => {
    return (
      <LoaderComman isRender={!loading}>
        <Dialog
          draggable={false}
          header="Add Observation"
          visible={showObservationDialog}
          style={{ width: '30vw' }}
          onHide={() => setShowObservationDialog(false)}
          position='top'
        >

          <div className="row">
            <div className="col-lg-5 mb-5">
              <label className="required">Choose</label>
              <input type="file" id="fileInput" onChange={(e) => onImageChange(e)} />
            </div>
            <div className="col-lg-7 d-flex justify-content-end mb-5">
              <img
                id="imagePreview"
                src={selectedObservationImage ?
                  selectedObservationImage :
                  observationModel.observationImage ?
                    process.env.REACT_APP_IMAGE_URL + observationModel.observationImage :
                    logoImg}
                alt="observationImage"
                style={{ display: 'block', maxHeight: '150px', maxWidth: '250px' }} />
            </div>
            <div className="col-lg-12 mb-5">
              <label className="required">Observation Note:</label>
              <input
                name="competenciesName"
                className="form-control"
                value={observationModel.observationNote}
                type="text"
                onChange={(e: any) => {
                  let model = { ...observationModel };
                  model.observationNote = e.target.value;
                  setObservationModel(model);
                }}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button type="button" className="btn btn-primary-bordered" label="Cancel" onClick={() => {
                setShowObservationDialog(false);
                setObservationModel({ observationImage: '', observationNote: '' });
              }} />
              <RoleBaseAccess moduleName="vault" isSubmit>
                <Button
                  type="button"
                  className="btn btn-primary ms-4"
                  icon={btnLoading && 'pi pi-spin pi-spinner'}
                  disabled={btnLoading || !observationModel.observationImage || !observationModel.observationNote}
                  label={btnLoading ? 'Please wait...' : 'Save'}
                  onClick={submitObservation}
                />
              </RoleBaseAccess>
            </div>
          </div>
        </Dialog>

        <DialogueBox
          title={'Vault Remove Observation'}
          message={'Do you want  to remove observation?'}
          state={observationDialogueBox.flag}
          onClick={() => onRemoveObservation(observationDialogueBox.id)}
          handleClose={() => setObservationDialogueBox({ flag: false, id: '' })}
          btnLoading={btnLoading}
        />

        <div className='d-flex flex-column-fluid printable' id='content'>
          <div className='w-full'>
            <div className='card card-custom card-stretch gutter-b'>
              <div className='card-body printable'>
                <div className='row pt-2'>
                  <div className='col-lg-12 col-xl-6 mb-5 mb-lg-0'>
                    <div className='d-flex '>
                      <p className='w-150px'>Building Name/Ref</p>
                      <span>:</span>
                      <p className='px-4'>{building?.buildingName}</p>
                    </div>
                    <div className='row pt-3'>
                      <div className='col-lg-2'>
                        <p className='w-150px required'>Asset No. :</p>
                      </div>
                      <div className='col-lg-10'>
                        <input
                          type='text'
                          className='form-control'
                          value={assetReference}
                          onChange={(e) => {
                            let val = e.target.value;
                            setAssetReference(val);
                            if (!val) {
                              setShowRefMsg(true);
                            }
                          }}
                          disabled={isEdit ? false : true}
                        />
                        {showRefMsg && <span className='text-danger'>Asset Reference is required</span>}
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-lg-12 mb-5'></div>
                  <div className='col-xl-3 col-lg-12'>
                    <div className='d-flex justify-content-center align-items-center flex-column pr-10'>


                      {assetDetails?.assetQrCode ?
                        <>
                          <ImageUploader
                            isQrCodeImg
                            img={qrCodeImg}
                            building={assetDetails}
                            setImageUrl={setQrCodeImg}
                          />
                          <p>Qr Code</p>
                        </>
                        :
                        <p>QR Code not found</p>
                      }
                    </div>
                  </div>
                </div>

                <hr />

                <div className='row py-5 d-lg-flex justify-content-lg-between'>
                  <div className='col-lg-12 col-xl-6 mb-5'>
                    <div className='row'>
                      <div className='col-lg-4 mb-5 mb-lg-0'>
                        <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Location Reference:</span></button>
                      </div>
                      <div className='col-lg-8 mb-5 mb-lg-0'>
                        <textarea
                          rows={10}
                          disabled={isEdit ? false : true}
                          className='form-control w-100'
                          defaultValue={
                            assetDetails?.assetLocation?.length > 0 ? assetDetails?.assetLocation : ''
                          }
                          onChange={(e: any) => {
                            setLocationRef(e?.target?.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-12 col-xl-6'>
                    <div className='d-lg-flex align-items-center justify-content-center justify-content-xl-end'>
                      <div className='d-flex justify-content-center mb-5 mb-lg-0'>
                        <ImageUploader
                          isAssetImg
                          img={imgUrl1}
                          building={assetDetails}
                          setImageUrl={setImgUrl1}
                        />
                      </div>
                      <div className='d-flex justify-content-center'>
                        <ImageUploader
                          isAssetImg
                          img={imgUrl2}
                          building={assetDetails}
                          setImageUrl={setImgUrl2}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Dialog draggable={false} header="Remove Defect" visible={dialogueBox?.flag} style={{ width: '30vw' }} onHide={() => setDialogBox(null)}>
                  Do you want to remove the defect?
                  <div className='d-flex justify-content-end'>
                    <RoleBaseAccess moduleName='Vault' isSubmit>
                      <Button
                        type='button'
                        className='btn btn-primary me-3'
                        icon={btnLoading && 'pi pi-spin pi-spinner'}
                        onClick={deleteDefect}
                        label={btnLoading ? 'Please wait...' : 'Yes'}
                        disabled={btnLoading}
                      />
                    </RoleBaseAccess>
                    <button type='button' className='btn btn-primary' disabled={btnLoading} onClick={() => setDialogBox(null)}>
                      No
                    </button>
                  </div>
                </Dialog>

                <hr />
                <div className='py-5'>
                  {building?.buildingAssets?.length ? (
                    <Accessibility
                      setFormData={setFormData}
                      isEdit={isEdit}
                      asset={assetDetails}
                      setAssetDetail={setAssetDetail}
                      defectSubcategoryData={
                        !!defectSubcategoryData.length
                          ? defectSubcategoryData?.find(
                            (v: any) => v.defectCategoryName == 'Accessibility'
                          )
                          : {}
                      }
                      setDeleteDialogBox={setDialogBox}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <hr />
                <div className='py-5'>
                  {building?.buildingAssets?.length ? (
                    <FireBarrier
                      setFormData={setFormData}
                      isEdit={isEdit}
                      asset={assetDetails}
                      setAssetDetail={setAssetDetail}
                      defectSubcategoryData={
                        !!defectSubcategoryData.length
                          ? defectSubcategoryData?.find(
                            (v: any) => v.defectCategoryName == 'Fire Barriers'
                          )
                          : {}
                      }
                      setDeleteDialogBox={setDialogBox}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <hr />

                <div className='py-5'>
                  {building?.buildingAssets?.length ? (
                    <Installation
                      setFormData={setFormData}
                      isEdit={isEdit}
                      setAssetDetail={setAssetDetail}
                      asset={assetDetails}
                      defectSubcategoryData={
                        !!defectSubcategoryData.length
                          ? defectSubcategoryData?.find(
                            (v: any) => v.defectCategoryName == 'Installation'
                          )
                          : {}
                      }
                      setDeleteDialogBox={setDialogBox}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <hr />
                <div className='py-5'>
                  {building?.buildingAssets?.length ? (
                    <DuctType setFormData={setFormData} isEdit={isEdit} asset={assetDetails} />
                  ) : (
                    <></>
                  )}
                </div>
                <hr />
                <div className='py-5'>
                  {building?.buildingAssets?.length ? (
                    <AccessPanel
                      setFormData={setFormData}
                      isEdit={isEdit}
                      asset={assetDetails}
                      setAssetDetail={setAssetDetail}
                      defectSubcategoryData={
                        !!defectSubcategoryData.length
                          ? defectSubcategoryData?.find(
                            (v: any) => v.defectCategoryName == 'Access Panel'
                          )
                          : {}
                      }
                      setDeleteDialogBox={setDialogBox}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <hr />
                <div className='py-5'>
                  {building?.buildingAssets?.length ? (
                    <Tests
                      ref={TestsRef}
                      setAssetDetail={setAssetDetail}
                      isEdit={isEdit}
                      asset={assetDetails}
                      building={building}
                      defectSubcategoryData={
                        !!defectSubcategoryData.length
                          ? defectSubcategoryData?.find((v: any) => v.defectCategoryName == 'Test')
                          : {}
                      }
                      setDeleteDialogBox={setDialogBox}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <hr
                style={{
                  border: '1px solid #000',
                  color: '#000',
                }}
              />



              <div className='row justify-content-between my-5 mx-8'>
                <div className='col-xl-2 col-lg-5 mb-5'>
                  <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Supplementary Observations:</span></button>
                </div>
                <div className='col-xl-10 col-lg-7 mb-5 text-end'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => setShowObservationDialog(true)}
                    disabled={isEdit ? false : true}
                  >
                    <i className='pi pi-plus me-1'></i>Add Observation
                  </button>
                </div>
                {assetDetails?.observations?.length ?
                  <>
                    <div className='col-lg-12 my-5 px-lg-10'>
                      <SupplementaryObservations
                        observations={assetDetails.observations}
                        setObservationModel={setObservationModel}
                        setDialogBox={setObservationDialogueBox}
                        setShowObservationDialog={setShowObservationDialog}
                      />
                    </div>
                  </>
                  :
                  <h5 className=''>No observations available</h5>
                }
              </div>

              <hr
                style={{
                  border: '1px solid #000',
                  color: '#000',
                }}
              />
              <div className='card-body printable'>
                <div className='row justify-content-lg-between mb-10'>
                  <div className='col-lg-6 mb-5'>
                    <div className='row'>
                      <div className='d-flex'>
                        Operation Name : &nbsp;&nbsp; {assetDetails?.lastModifiedBy?.name}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 mb-5'>
                    <div className='row'>
                      <div className='d-flex justify-content-lg-end'>
                        Updated On :&nbsp;&nbsp; {moment(assetDetails?.updatedAt)
                          .tz('Europe/London')
                          .format('DD-MM-YYYY HH:mm:ss')}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 mb-5'>
                    <div className='row'>
                      <div className='d-flex'>
                        Reference No :&nbsp;&nbsp; {assetDetails?.lastModifiedBy?.modelId?.operativeRefNo}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row justify-content-lg-between mb-10'>
                  <div className='col-lg-6 mb-5'>
                    <div className='d-flex'>
                      Operation Name :&nbsp;&nbsp; {assetDetails?.lastModifiedBy?.name}
                    </div>
                  </div>
                  <div className='col-lg-6 mb-5'>
                    <div className='row'>
                      <div className='d-flex justify-content-lg-end'>
                        Last Updated :&nbsp;&nbsp; {moment(assetDetails?.previouslyChangedOn)
                          .tz('Europe/London')
                          .utc()
                          .format('DD-MM-YYYY HH:mm:ss')}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 mb-5'>
                    <div className='row'>
                      <div className='d-flex'>
                        Reference No :&nbsp;&nbsp; {assetDetails?.previouslyChangedBy?.modelId?.operativeRefNo}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <DefectModal show={isactivity} title='View Defects' handleClose={() => handleActivity()}>
                <DefectsViewTable
                  assetDetails={{ defects: allDefectList?.unarchivedDefects }}
                  archivedDefect={archivedDefect}
                  setSelectedDefect={setCurrentDefect}
                  setShowModal={setDefectDetailViewOpen}
                  setShowDefects={setDefectListOpen}
                  archivedList={() => setArchivedDefectListOpen(true)}
                  title='Defect Remove'
                  message='Do you want to remove defect?'
                  allDefects
                  setDeleteDialogBox={(val: any) => {
                    setDialogBox({ ...val, isAllDefect: true });
                  }}
                  latestTest={latestTest}
                />
              </DefectModal>

              <DefectModal
                show={defectDetailViewOpen}
                handleClose={() => {
                  setDefectDetailViewOpen(false);
                  setCurrentDefect(undefined);
                }}
                title='Add Defect'
              >
                <DefectFlow
                  isAccessibility={currentDefect?.accessibility}
                  isFireBarrier={currentDefect?.fireBarrier}
                  isAccessPanel={currentDefect?.accessPanel}
                  isInstallation={currentDefect?.installation}
                  isTest={currentDefect?.tests}
                  setAssetDetail={setAssetDetail}
                  asset={assetDetails}
                  setOpen={setDefectDetailViewOpen}
                  selectedDefect={currentDefect}
                  latestTest={latestTest}
                  options={renderSubCategories(currentDefect)}
                  getDefectDetails={getDefectDetails}
                  selectedDefectOptions={installationName}
                  setSelectedDefectOptions={setInstallationName}
                />
              </DefectModal>

              <DefectModal
                show={defectArchivedListOpen}
                handleClose={() => setArchivedDefectListOpen(false)}
                title='View Defects'
              >
                <DefectsViewTable
                  setSelectedDefect={setCurrentDefect}
                  setShowModal={setDefectDetailViewOpen}
                  setShowDefects={setDefectListOpen}
                  assetDetails={{ defects: allDefectList?.archiveDefects }}
                  archivedDefect={unArchivedDefect}
                  title='Defect Unarchive'
                  message='Do you want  to Unarchive?'
                />
              </DefectModal>

              <DefectModal show={istest} title='Test History' handleClose={() => handleTest()}>
                <TestHistoryViewTable
                  assetDetail={assetDetails}
                  assetDetails={assetDetails.tests}
                  handleClose={() => handleTest()}
                  detailView={() => setTestDetailView(true)}
                />
              </DefectModal>
              {/* Test History */}

              <DefectModal show={testDetailView} handleClose={() => setTestDetailView(false)}>
                <TestHistoryDetailView asset={assetDetails} />
              </DefectModal>
            </div>
          </div>
        </div>
      </LoaderComman>
    );
  };

  return renderData();
}

const NotesData = [
  { value: 'satisfactory', label: 'satisfactory' },
  { value: 'Major', label: 'Major' },
  { value: 'Minor', label: 'Minor' },
];

const Cleaned = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const Hygienes = [{ hygvalue: 'Clean' }, { hygvalue: 'Moderate' }, { hygvalue: 'Dirty' }];

const Accessibility = forwardRef(
  ({
    asset,
    isEdit,
    setAssetDetail,
    defectSubcategoryData,
    setFormData,
    setDeleteDialogBox
  }: {
    asset: any;
    isEdit: boolean;
    setAssetDetail: any;
    defectSubcategoryData: any;
    setFormData: any;
    setDeleteDialogBox: any;
  }) => {
    const [accessibilities, setAccessibilities] = useState<any[]>([]);
    const { building } = useSelector((state: any) => state.job);
    const [showModal, setShowModal] = useState(false);
    const [showDefects, setShowDefects] = useState(false);
    const [selectedDefect, setSelectedDefect] = useState();
    const { id } = useParams<{ id: string; }>();
    const { issaved } = useSelector((state: any) => state.vault);
    const assetDetails = building?.buildingAssets?.length
      ? building.buildingAssets.find((asset: any) => asset._id === id)
      : null;
    useEffect(() => {
      fetchAccessibilities();
    }, []);

    const [selectedDefectOptions, setSelectedDefectOptions] = useState(asset.accessibility?.accessibilityOptions?.length ? asset.accessibility?.accessibilityOptions : []);

    const fetchAccessibilities = async () => {
      try {
        const response = await getAccessibilitiesListing(accessibilityProps);
        if (response.status === 'Success') {
          response.data.accessibilitys &&
            response.data.accessibilitys.map((acc: any, i: number) => {
              acc.serial = i + 1;
            });
          let obj: any = [];
          response?.data?.accessibilitys.map((acc: any) => {
            if (acc?.accessibilityName) {
              obj.push({
                value: acc._id,
                label: acc.accessibilityName,
                isKey: acc.isKey,
                type: acc.type
              });
            }
          });
          setAccessibilities(obj);
        }
      } catch (err) { }
    };

    const { accessibility } = asset;

    const [satisfactionStatus, setSatisfactionStatus] = useState(
      accessibility?.satisfactionStatus ? accessibility?.satisfactionStatus : ''
    );
    const [selectedAccessibility, setSelectedAccessibility] = useState([{ label: '', value: '' }]);

    useEffect(() => {
      if (issaved === true) {
        let newObject = { ...accessibility };
        let arr = [];
        for (let index = 0; index < selectedAccessibility.length; index++) {
          const element = selectedAccessibility[index];
          let payload = {
            accessibilityName: element?.label,
            _id: element?.value,
            isKey: false,
          };
          arr.push(payload);
        }
        const accesibilty = {
          accessibility: {
            defects: accessibility?.defects,
            accessibilityOptions: arr,
            accessibilitySkipped: false,
            satisfactionStatus: satisfactionStatus,
            defect: satisfactionStatus === 'satisfactory' ? false : true,
          },
        };
        setFormData((prev: any) => ({
          ...prev,
          accesibilty,
        }));
      }
    }, [issaved]);

    const NotesData = [
      { value: 'satisfactory', label: 'satisfactory' },
      { value: 'Major', label: 'Major' },
      { value: 'Minor', label: 'Minor' },
      { value: 'untested', label: 'Untested' },
    ];

    useEffect(() => {
      setSatisfactionStatus(accessibility?.satisfactionStatus);
      let arr = [];
      for (let index = 0; index < accessibility?.accessibilityOptions.length; index++) {
        const element = accessibility?.accessibilityOptions[index];
        let payload = {
          label: element?.accessibilityName,
          value: element?._id,
        };
        arr.push(payload);
      }
      setSelectedAccessibility(arr);
    }, [accessibility, accessibilities]);

    return (
      <>
        <DefectModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSelectedDefect(undefined);
          }}
          title='Add Defect'
        >
          <DefectFlow
            isAccessibility
            asset={asset}
            setAssetDetail={setAssetDetail}
            setOpen={setShowModal}
            satisfactionStatuss={satisfactionStatus}
            selectedDefect={selectedDefect}
            options={defectSubcategoryData?.subCategories}
            selectedDefectOptions={selectedDefectOptions}
            setSelectedDefectOptions={setSelectedDefectOptions}
          />
        </DefectModal>
        <DefectModal
          show={showDefects}
          handleClose={() => {
            setShowDefects(false);
            setSelectedDefect(undefined);
          }}
          title='View Defects'
        >
          <DefectsViewTable
            setSelectedDefect={setSelectedDefect}
            setShowModal={setShowModal}
            setShowDefects={setShowDefects}
            assetDetails={asset?.accessibility}
            setDeleteDialogBox={(val: any) => {
              setDeleteDialogBox({ accessibility: true, defectId: val.defectId, flag: val.flag });
            }}
          />
        </DefectModal>

        <div className='row py-5 d-lg-flex justify-content-lg-between'>
          <div className='col-lg-12 col-xl-6 mb-5'>
            <div className='row'>
              <div className='col-lg-4 mb-5 mb-lg-0'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Accessibility:</span></button>
              </div>
              <div className='col-lg-8 mb-5 mb-lg-0'>
                <MultiSelect
                  disabled={isEdit ? false : true}
                  hasSelectAll={false}
                  options={accessibilities}
                  value={selectedAccessibility}
                  onChange={(e: any) => {
                    const lastObject = e[e.length - 1];
                    const Objectinacceblities = accessibilities.find(obj => obj.value === lastObject.value);
                    const accessibilitytype = Objectinacceblities ? Objectinacceblities.type : null;
                    const existingtype = satisfactionStatus;
                    setSelectedAccessibility(e);
                    if (existingtype.toLowerCase() !== accessibilitytype.toLowerCase()) {
                      setSelectedAccessibility([lastObject]);
                      setSelectedDefectOptions([{ _id: lastObject.value, accessibilityName: lastObject.label, isKey: lastObject.isKey }]);
                    }
                    setSatisfactionStatus(accessibilitytype);
                  }}
                  className='multi-select'
                  labelledBy='Select'
                />
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-xl-3 mb-5'>
            {satisfactionStatus}
          </div>
          <div className='col-lg-6 col-xl-3 mb-5 text-lg-end'>
            {satisfactionStatus === 'Defect' ? (
              <button
                disabled={isEdit ? false : true}
                className={`${isEdit ? 'btn btn-primary' : 'btn btn-secondary'}`}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add defect
              </button>
            ) : (
              ''
            )}
          </div>
          <div className='col-lg-12 text-center'>
            {(Boolean(asset?.accessibility?.defects?.length) && satisfactionStatus === 'Defect') ? (
              <button
                disabled={isEdit ? false : true}
                className={`${isEdit ? 'btn btn-primary' : 'btn btn-secondary'}`}
                onClick={() => {
                  setShowDefects(true);
                }}
              >
                View Accessibility Defects
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
);

const FireBarrier = forwardRef(
  (
    {
      asset,
      isEdit,
      setAssetDetail,
      setFormData,
      defectSubcategoryData,
      setDeleteDialogBox
    }: {
      asset: any;
      isEdit: boolean;
      defectSubcategoryData: any;
      setAssetDetail: any;
      setFormData: any;
      setDeleteDialogBox: any;
    },
    ref
  ) => {
    const [fireBarrieries, setFireBarrieries] = useState<any[]>([]);
    const [others, setothers] = useState<any>();
    const { building } = useSelector((state: any) => state.job);
    const { issaved } = useSelector((state: any) => state.vault);
    // get id from url using useParams
    const { id } = useParams<{ id: string; }>();
    const { fireBarrier } = asset;
    const assetDetails = building?.buildingAssets?.length
      ? building.buildingAssets.find((asset: any) => asset._id === id)
      : null;

    const [satisfactoryStatus, setSatisfactoryStatus] = useState(
      fireBarrier?.satisfactionStatus ? fireBarrier?.satisfactionStatus : 'satisfactory'
    );
    const [showModal, setShowModal] = useState(false);
    const [showDefects, setShowDefects] = useState(false);
    const [selectedDefect, setSelectedDefect] = useState();

    const [firebarrirName, setFirebarrirName] = useState([{ label: '', value: '' }]);

    const [imgUrl1, setImgUrl1] = useState(
      fireBarrier?.satisfactory?.satisfactoryImages?.[0] ?? ''
    );
    const [imgUrl2, setImgUrl2] = useState(
      fireBarrier?.satisfactory?.satisfactoryImages?.[1] ?? ''
    );

    const [selectedDefectOptions, setSelectedDefectOptions] = useState(asset.fireBarrier?.fireBarrierOptions?.length ? asset.fireBarrier?.fireBarrierOptions : []);

    let arr = [];
    for (let index = 0; index < firebarrirName.length; index++) {
      const element = firebarrirName[index];
      let payload = {
        fireBarrierName: element?.label,
        _id: element?.value,
      };
      arr.push(payload);
    }

    const Firebarrier = {
      defects: fireBarrier?.defects,
      fireBarrierSkipped: false,
      defect: satisfactoryStatus === 'satisfactory' ? false : true,
      fireBarrierOptions: arr,
      satisfactionStatus: satisfactoryStatus,
      satisfactory: {
        satisfactoryImages: [imgUrl1, imgUrl2],
      },
    };

    useEffect(() => {
      if (issaved === true)
        setFormData((prev: any) => ({
          ...prev,
          Firebarrier,
        }));
    }, [issaved]);

    useEffect(() => {
      fetchFireBarrier();
    }, []);

    useEffect(() => {
      setSatisfactoryStatus(fireBarrier?.satisfactionStatus);
      let arr = [];
      for (let index = 0; index < fireBarrier?.fireBarrierOptions.length; index++) {
        const element = fireBarrier?.fireBarrierOptions[index];
        let paylad = {
          label: element?.fireBarrierName,
          value: element?._id,
        };
        arr.push(paylad);
      }

      setFirebarrirName(arr);
    }, [fireBarrier, fireBarrieries]);

    const fetchFireBarrier = async () => {
      try {
        const response = await getFireBarrierListing(fireBarrierProps);
        if (response.status === 'Success') {
          response.data.fireBarriers &&
            response.data.fireBarriers.map((acc: any, i: number) => {
              acc.serial = i + 1;
            });
          let obj: any = [];
          response?.data?.fireBarriers.map((acc: any) => {
            obj.push({
              value: acc._id,
              label: acc.fireBarrierName,
            });
          });
          setFireBarrieries(obj);
        }
      } catch (err) { }
    };

    const handleChange = (e: any) => {
      setothers({ ...others, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      await createFireBarrier(others);
    };

    const NotesData = [
      { value: 'satisfactory', label: 'satisfactory' },
      { value: 'Defect', label: 'Defect' }
    ];

    return (
      <>
        <DefectModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSelectedDefect(undefined);
          }}
          title='Add Defect'
        >
          <DefectFlow
            isFireBarrier
            setAssetDetail={setAssetDetail}
            asset={asset}
            satisfactionStatuss={satisfactoryStatus}
            setOpen={setShowModal}
            selectedDefect={selectedDefect}
            options={defectSubcategoryData?.subCategories}
            selectedDefectOptions={selectedDefectOptions}
            setSelectedDefectOptions={setSelectedDefectOptions}
          />
        </DefectModal>
        <DefectModal
          show={showDefects}
          handleClose={() => {
            setShowDefects(false);
            setSelectedDefect(undefined);
          }}
          title='View Defects'
        >
          <DefectsViewTable
            setSelectedDefect={setSelectedDefect}
            setShowModal={setShowModal}
            assetDetails={asset?.fireBarrier}
            setShowDefects={setShowDefects}
            setDeleteDialogBox={(val: any) => {
              setDeleteDialogBox({ fireBarrier: true, defectId: val.defectId, flag: val.flag });
            }}
          />
        </DefectModal>

        <div className='row d-lg-flex justify-content-lg-between'>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Fire Barrier:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <MultiSelect
                  disabled={isEdit ? false : true}
                  options={fireBarrieries}
                  value={firebarrirName}
                  onChange={(e: any) => {
                    let data = e?.map((x: any) => {
                      return {
                        _id: x.value,
                        fireBarrierName: x.label
                      };
                    });
                    setFirebarrirName(e);
                    setSelectedDefectOptions(data);
                  }}
                  className='multi-select'
                  labelledBy='Select'
                />
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-lg-4 mb-5'>
            <Select
              isDisabled={isEdit ? false : true}
              placeholder='Advisory Note'
              options={NotesData}
              isClearable
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => String(option.value)}
              defaultValue={{
                label: fireBarrier?.satisfactionStatus,
                value: fireBarrier?.satisfactionStatus,
              }}
              onChange={(e: any) => {
                setSatisfactoryStatus(e?.value);
              }}
            />
          </div>
          <div className='col-lg-3 mb-5 text-lg-end'>
            {satisfactoryStatus?.toLowerCase() === 'defect' ? (
              <button
                disabled={isEdit ? false : true}
                className={`${isEdit ? 'btn btn-primary' : 'btn btn-secondary'}`}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add defect
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 col-xl-6'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Others:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <input
                  disabled={isEdit ? false : true}
                  type='text'
                  onChange={(e) => handleChange(e)}
                  name='fireBarrierName'
                  className='form-control h-40px '
                />
              </div>
            </div>
          </div>
          <div className='col-lg-12 text-center'>
            <div className='d-xl-flex align-items-center justify-content-center justify-content-xl-start'>
              <div className='d-xl-flex justify-content-center justify-content-lg-start mb-5'>
                <ImageUploader
                  isSatisfactory
                  img={imgUrl1}
                  building={assetDetails}
                  setImageUrl={setImgUrl1}
                />
              </div>
              <div className='d-xl-flex justify-content-center mb-5'>
                <ImageUploader
                  isSatisfactory
                  img={imgUrl2}
                  building={assetDetails}
                  setImageUrl={setImgUrl2}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-12 text-center'>
            {(Boolean(asset?.fireBarrier?.defects?.length) && satisfactoryStatus?.toLowerCase() === 'defect') ? (
              <button
                disabled={isEdit ? false : true}
                className={` px-10 ${isEdit ? 'btn btn-primary' : 'btn btn-secondary'}`}
                onClick={() => {
                  setShowDefects(true);
                }}
              >
                View Firebarrier defects
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
);

const Installation = forwardRef(
  (
    {
      asset,
      isEdit,
      setFormData,
      setAssetDetail,
      defectSubcategoryData,
      setDeleteDialogBox
    }: {
      asset: any;
      isEdit: boolean;
      defectSubcategoryData: any;
      setAssetDetail: any;
      setFormData: any;
      setDeleteDialogBox: any;
    },
    ref
  ) => {
    const [installations, setInstallations] = useState([]);
    const { building } = useSelector((state: any) => state.job);
    const { issaved } = useSelector((state: any) => state.vault);
    const { installation, fireBarrier } = asset;
    const isSatisfactory: boolean = fireBarrier.satisfactionStatus === 'satisfactory' ? true : false;
    // get id from url using useParams
    const { id } = useParams<{ id: string; }>();
    const [installationName, setInstallationName] = useState({
      label: installation.installationName,
      value: installation.installationName,
    });
    const [assetDetails, setAsset] = useState(asset);
    const [orientation, setOrientation] = useState<any>(installation.orientation);

    // const [satisfactoryStatus, setSatisfactoryStatus] = useState(
    //   assetDetails?.installation.satisfactionStatus ?? ''
    // )
    const [satisfactoryStatus, setSatisfactoryStatus] = useState(
      assetDetails?.installation?.satisfactionStatus
        ? assetDetails?.installation?.satisfactionStatus
        : 'satisfactory'
    );
    const [showModal, setShowModal] = useState(false);
    const [showDefects, setShowDefects] = useState(false);
    const [selectedDefect, setSelectedDefect] = useState();
    const [selectedDefectOptions, setSelectedDefectOptions] = useState(installationName ? installationName : null);

    useEffect(() => {
      fetchInstallations();
    }, []);

    const installData = {
      installation: {
        defects: installation.defects,
        defect: satisfactoryStatus === true ? false : true,
        installationName: installationName.value,
        installationSkipped: false,
        orientation: orientation,
        satisfactionStatus: satisfactoryStatus,
      },
    };

    useEffect(() => {
      if (issaved) {
        setFormData((prev: any) => ({
          ...prev,
          installData,
        }));
      }
    }, [issaved]);

    const fetchInstallations = async () => {
      try {
        const response = await getInstallationListing(installationsProps);
        if (response.status === 'Success') {
          response.data.installations &&
            response.data.installations.map((acc: any, i: number) => {
              acc.serial = i + 1;
            });
          let obj: any = [];
          response?.data?.installations.map((acc: any) => {
            obj.push({
              value: acc.installationName,
              label: acc._id,
              type: acc.type
            });
          });
          setInstallations(obj);
        }
      } catch (err) { }
    };

    const NotesData = [
      { value: 'satisfactory', label: 'satisfactory' },
      { value: 'Major', label: 'Major' },
      { value: 'Minor', label: 'Minor' },
    ];

    const orientationValues = [
      { value: 'Horizontal', label: ' Horizontal' },
      { value: 'Vertical', label: ' Vertical' },
    ];

    return (
      <>
        <DefectModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSelectedDefect(undefined);
          }}
          title='Add Defect'
        >
          <DefectFlow
            isInstallation
            setAssetDetail={setAssetDetail}
            asset={asset}
            setOpen={setShowModal}
            satisfactionStatuss={satisfactoryStatus}
            selectedDefect={selectedDefect}
            options={defectSubcategoryData?.subCategories}
            selectedDefectOptions={selectedDefectOptions}
            setSelectedDefectOptions={setSelectedDefectOptions}
            selectedOrientation={orientation}
          />
        </DefectModal>
        <DefectModal
          show={showDefects}
          handleClose={() => {
            setShowDefects(false);
            setSelectedDefect(undefined);
          }}
          title='View Defects'
        >
          <DefectsViewTable
            setSelectedDefect={setSelectedDefect}
            setShowModal={setShowModal}
            assetDetails={asset?.installation}
            setShowDefects={setShowDefects}
            setDeleteDialogBox={(val: any) => {
              setDeleteDialogBox({ installation: true, defectId: val.defectId, flag: val.flag });
            }}
          />
        </DefectModal>
        <>

          <div className='row d-lg-flex justify-content-lg-between'>
            <div className='col-lg-12 col-xl-6'>
              <div className='row'>
                <div className='col-lg-4 mb-5'>
                  <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Installation:</span></button>
                </div>
                <div className='col-lg-8 mb-5'>
                  <Select
                    isDisabled={isEdit ? false : true}
                    placeholder='installation'
                    options={installations}
                    isClearable
                    getOptionLabel={(option: any) => option.value}
                    getOptionValue={(option: any) => String(option.value)}
                    defaultValue={installationName ?? ''}
                    onChange={(e: any) => {
                      const lastObject: any = e;
                      const Objectinacceblities: any = installations.find((obj: any) => obj.label === lastObject.label);
                      const accessibilitytype = Objectinacceblities ? Objectinacceblities.type : null;
                      const existingtype = satisfactoryStatus;
                      setInstallationName(e);
                      if (existingtype.toLowerCase() !== accessibilitytype.toLowerCase()) {
                        setInstallationName(lastObject);
                        setSelectedDefectOptions(lastObject);
                      }
                      setSatisfactoryStatus(accessibilitytype);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-xl-3 mb-5'>
              {satisfactoryStatus}
            </div>
            <div className='col-lg-6 col-xl-3 mb-5 text-lg-end'>
              {satisfactoryStatus === 'Defect' ? (
                <button
                  disabled={isEdit ? false : true}
                  className={`${isEdit ? 'btn btn-primary' : 'btn btn-secondary'}`}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Add defect
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12 col-xl-6'>
              <div className='row'>
                <div className='col-lg-4 mb-5'>
                  <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Orientation:</span></button>
                </div>
                <div className='col-lg-8 mb-5'>
                  <Select
                    isDisabled={isEdit ? false : true}
                    placeholder='installation'
                    options={orientationValues}
                    isClearable
                    getOptionLabel={(option: any) => option.value}
                    getOptionValue={(option: any) => String(option.value)}
                    defaultValue={{
                      label: installation.orientation.length > 0 ? installation.orientation : '',
                      value: installation.orientation.length > 0 ? installation.orientation : '',
                    }}
                    onChange={(e: any) => {
                      setOrientation(e?.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-12 text-center'>
              {(Boolean(asset?.installation?.defects?.length) && satisfactoryStatus === 'Defect') ? (
                <button
                  disabled={isEdit ? false : true}
                  className={` px-10 ${isEdit ? 'btn btn-primary' : 'btn btn-secondary'}`}
                  onClick={() => {
                    setShowDefects(true);
                  }}
                >
                  View Installation defects
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </>
      </>
    );
  }
);

const DuctType = forwardRef(
  ({ asset, isEdit, setFormData }: { asset: any; isEdit: boolean; setFormData: any; }, ref) => {
    const { ductDamper } = asset;
    const [ductDampers, setductDampers] = useState<any[]>([]);
    const [ductDampersTypes, setductDamperTypes] = useState<any>(ductDamper.ductTypeName);
    const [ductDampersShapes, setductDamperShapes] = useState<any>(ductDamper.ductShapeName);
    const [ductDampersSize, setductDampersSize] = useState<any>(ductDamper.ductSize);
    const { issaved } = useSelector((state: any) => state.vault);
    const [damperClassificationOptions, setDamperClassificationOptions] = useState<any[]>([]);
    const { building } = useSelector((state: any) => state.job);
    const { id } = useParams<{ id: string; }>();
    const assetDetails = building?.buildingAssets?.length
      ? building.buildingAssets.find((asset: any) => asset._id === id)
      : null;

    const fetchDuctDampers = async () => {
      try {
        const response = await getDuctTypeListing(DuctProps);
        if (response.status === 'Success') {
          response.data.ductTypes &&
            response.data.ductTypes.map((acc: any, i: number) => {
              acc.serial = i + 1;
            });
          let obj: any = [];

          response?.data?.ductTypes.map((acc: any) => {
            obj.push({
              value: acc.ductTypeName,
              label: acc._id,
            });
          });
          setductDampers(obj);
        }
      } catch (err) { }
    };

    const fetchDamperTypes = async () => {
      try {
        const response = await getDamperTypeListing(damperTypeProps);
        const { damperTypes } = response.data;
        if (response.status === 'Success') setDamperClassificationOptions(damperTypes);
      } catch (err) { }
    };

    const [dumperName, setDyamperName] = useState({
      label: ductDamper.damperTypeName[0]?._id,
      value: ductDamper.damperTypeName[0]?.damperTypeName,
    });

    useEffect(() => {
      fetchDuctDampers();
      fetchDamperTypes();
    }, []);

    const ductDamperObj = {
      ...ductDamper,
      damperTypeName: [
        {
          damperTypeName: dumperName.value,
        },
      ],
      ductShapeName: ductDampersShapes,
      ductTypeName: ductDampersTypes,
      ductSize: ductDampersSize,
    };

    useEffect(() => {
      if (issaved === true) {
        setFormData((prev: any) => ({
          ...prev,
          ductDamperObj,
        }));
      }
    }, [issaved]);

    const Shapes = [{ shapevalue: 'Circle' }, { shapevalue: 'Square/Rectangle' }, { shapevalue: 'Oval' }];

    return (
      <>
        <div className='row d-lg-flex justify-content-lg-between'>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Duct Type:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <Select
                  isDisabled={isEdit ? false : true}
                  placeholder='ductTypes'
                  options={ductDampers}
                  isClearable
                  id='ductType'
                  getOptionLabel={(option: any) => option.value}
                  getOptionValue={(option: any) => String(option.value)}
                  defaultValue={{
                    label: ductDamper.ductTypeName.length > 0 ? ductDamper.ductTypeName : '',
                    value: ductDamper.ductTypeName.length > 0 ? ductDamper.ductTypeName : '',
                  }}
                  onChange={(e: any) => setductDamperTypes(e.value)}
                />
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Duct Shape:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <Select
                  isDisabled={isEdit ? false : true}
                  placeholder='ductShapeName  '
                  options={Shapes}
                  id='ductShape'
                  isClearable
                  getOptionLabel={(option: any) => option.shapevalue}
                  getOptionValue={(option: any) => String(option.shapevalue)}
                  defaultValue={{
                    shapevalue: ductDamper.ductShapeName.length > 0 ? ductDamper.ductShapeName : '',
                  }}
                  onChange={(e: any) => setductDamperShapes(e.shapevalue)}
                />
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Duct Size:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <input
                  disabled={isEdit ? false : true}
                  type='text'
                  id='ductSize'
                  className='form-control'
                  defaultValue={ductDamper?.ductSize ?? ''}
                  onChange={(e: any) => setductDampersSize(e?.target?.value)}
                />
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Damper Classification:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <Select
                  isDisabled={isEdit ? false : true}
                  placeholder='ductClass'
                  options={damperClassificationOptions}
                  id='ductClass'
                  isClearable
                  getOptionLabel={(option: any) => option.damperTypeName}
                  getOptionValue={(option: any) => String(option.damperTypeName)}
                  onChange={(e: any) => {
                    setDyamperName({
                      value: e.damperTypeName,
                      label: e._id,
                    });
                  }}
                  defaultValue={{
                    damperTypeName: ductDamper?.damperTypeName?.[0]?.damperTypeName,
                    description: ductDamper?.damperTypeName?.[0]?.damperTypeName,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

const AccessPanel = forwardRef(
  (
    {
      asset,
      isEdit,
      setAssetDetail,
      setFormData,
      defectSubcategoryData,
      setDeleteDialogBox
    }: {
      asset: any;
      isEdit: boolean;
      setAssetDetail: any;
      defectSubcategoryData: any;
      setFormData: any;
      setDeleteDialogBox: any;
    },
    ref
  ) => {
    const accessPanelOptions = [
      {
        id: 1,
        defectName: 'Access Panel installed and accessible or not required',
        defectValue: 'Access Panel installed and accessible or not required',
        type: 'Satisfactory'
      },
      {
        id: 2,
        defectName: 'No access panel installed',
        defectValue: 'No access panel installed',
        type: 'Defect'
      },
    ];

    const { accessPanel } = asset;
    const { building } = useSelector((state: any) => state.job);
    const { id } = useParams<{ id: string; }>();
    const { issaved } = useSelector((state: any) => state.vault);
    const assetDetails = building?.buildingAssets?.length
      ? building.buildingAssets.find((asset: any) => asset._id === id)
      : null;
    const [satisfactoryStatus, setSatisfactoryStatus] = useState(
      assetDetails?.accessPanel?.satisfactionStatus
        ? assetDetails?.accessPanel?.satisfactionStatus
        : 'satisfactory'
    );
    const [accessOption, setAccessOption] = useState(accessPanel?.accessPanelOptionValue ?? '');
    const [defectType, setDefectType] = useState(accessPanel?.satisfactionStatus ?? 'satisfactory');
    const [accessInstalled, setAccessInstalled] = useState(accessPanel?.installedBothSides);

    const [accessShape, setAccessShape] = useState(accessPanel?.shape ?? '');
    const [accessSize, setAccessSize] = useState(accessPanel?.size ?? '');
    const [sizeOptions, setSizeOptions] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [showDefects, setShowDefects] = useState(false);
    const [selectedDefect, setSelectedDefect] = useState();
    const [selectedAccesPanel, setSelectedAccessPanel] = useState(
      accessPanel?.accessPanelOptionValue
        ? accessPanelOptions.find((x) =>
          accessPanel?.accessPanelOptionValue
            ?.toLowerCase()
            ?.includes(x.defectName?.toLowerCase())
        )
        : null
    );

    const options = defectSubcategoryData?.subCategories?.map((x: any) => {
      return {
        ...x,
        label: x.defectsubCatgTitle,
        value: x.defectsubCatgTitle
      };
    });

    const circularOptions = [
      { label: '100 x 100mm', value: '100 x 100mm' },
      { label: '100 x 200mm', value: '100 x 200mm' },
      { label: '150 x 150mm', value: '150 x 150mm' },
      { label: '200 x 200mm', value: '200 x 200mm' },
      { label: '200 x 300mm', value: '200 x 300mm' },
      { label: '250 x 250mm', value: '250 x 250mm' },
      { label: '300 x 150mm', value: '300 x 150mm' },
      { label: '300 x 300mm', value: '300 x 300mm' },
      { label: '300 x 450mm', value: '300 x 450mm' },
      { label: '300 x 600mm', value: '300 x 600mm' },
      { label: '350 x 350mm', value: '350 x 350mm' },
      { label: '400 x 200mm', value: '400 x 200mm' },
      { label: '400 x 400mm', value: '400 x 400mm' },
      { label: '400 x 600mm', value: '400 x 600mm' },
      { label: '450 x 450mm', value: '450 x 450mm' },
      { label: '500 x 500mm', value: '500 x 500mm' },
      { label: '600 x 600mm', value: '600 x 600mm' },
      { label: 'Others', value: 'Others' },
    ];

    const rectangleOptions = [
      { label: '80', value: '80' },
      { label: '100/125', value: '100/125' },
      { label: '150/160', value: '150/160' },
      { label: '150', value: '150' },
      { label: '200', value: '200' },
      { label: '250', value: '250' },
      { label: '400/500', value: '400/500' },
      { label: '400', value: '400' },
      { label: '450', value: '450' },
      { label: '500', value: '500' },
      { label: '560', value: '560' },
      { label: '630', value: '630' },
      { label: '710', value: '710' },
      { label: 'Others', value: 'Others' },
    ];

    useEffect(() => {
      if (accessShape === 'Square/Rectangle') {
        setSizeOptions(circularOptions);
      } else if (accessShape === 'Circular/Spiral') {
        setSizeOptions(rectangleOptions);
      }
    }, [accessShape]);

    let accessPanelObj = {
      ...accessPanel,
      accessPanelOptionValue: accessOption,
      shape: accessShape,
      size: accessSize,
      accessPanelSkipped: true,
      installedBothSides: accessInstalled,
      satisfactionStatus: defectType,
    };

    useEffect(() => {
      if (issaved === true) {
        setFormData((prev: any) => ({
          ...prev,
          accessPanelObj,
        }));
      }
    }, [issaved]);

    const NotesData = [
      { value: 'satisfactory', label: 'satisfactory' },
      { value: 'Major', label: 'Major' },
      { value: 'Minor', label: 'Minor' },
    ];

    return (
      <>
        <DefectModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          title='Add Defect'
        >
          <DefectFlow
            isAccessPanel
            selectedDefect={selectedDefect}
            setAssetDetail={setAssetDetail}
            satisfactionStatuss={satisfactoryStatus}
            asset={asset}
            setOpen={setShowModal}
            options={options}
          />
        </DefectModal>

        <DefectModal
          show={showDefects}
          handleClose={() => {
            setShowDefects(false);
            setSelectedDefect(undefined);
          }}
          title='View Defects'
        >
          <DefectsViewTable
            setSelectedDefect={setSelectedDefect}
            setShowModal={setShowModal}
            assetDetails={asset?.accessPanel}
            setShowDefects={setShowDefects}
            title='Archive'
            message='Do you want to archive the defect?'
            setDeleteDialogBox={(val: any) => {
              setDeleteDialogBox({ accessPanel: true, defectId: val.defectId, flag: val.flag });
            }}
          />
        </DefectModal>

        <div className='row d-lg-flex justify-content-lg-between'>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Access Panel:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <Dropdown value={selectedAccesPanel} dataKey='id' onChange={(e) => {
                  setSelectedAccessPanel(e.value);
                  setAccessOption(e.value.defectName);
                  setDefectType(e.value.type);
                }} options={accessPanelOptions} optionLabel="defectName"
                  placeholder="Select" className="w-full md:w-14rem" disabled={isEdit ? false : true} />
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Access Panel Shape:</span></button>
              </div>
              <div className='col-lg-5 col-xl-6 mb-5'>
                <Select
                  isDisabled={isEdit ? false : true}
                  placeholder='accessShape'
                  options={[
                    {
                      label: 'Square/Rectangle',
                      value: 'Square/Rectangle',
                    },
                    {
                      label: 'Circular/Spiral',
                      value: 'Circular/Spiral',
                    },
                    {
                      label: 'N/A',
                      value: 'N/A',
                    },
                  ]}
                  id='accessShape'
                  isClearable
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => String(option.value)}
                  defaultValue={{
                    label: accessPanel?.shape,
                    value: accessPanel?.shape,
                  }}
                  onChange={(e: any) => {
                    setAccessShape(e?.value);
                  }}
                />
              </div>
              <div className='col-lg-3 col-xl-2 text-end mb-5'>
                {defectType.toLowerCase() === 'defect' &&
                  <button
                    disabled={isEdit ? false : true}
                    className={`${isEdit ? 'btn btn-primary' : 'btn btn-secondary'}`}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Add defect
                  </button>
                }
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Access Panel Size:</span></button>
              </div>
              <div className='col-lg-8 mb-5'>
                <Select
                  isDisabled={isEdit ? false : true}
                  placeholder='accessOption'
                  options={sizeOptions}
                  id='accessOption'
                  isClearable
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => String(option.value)}
                  defaultValue={{
                    label: accessPanel?.size,
                    value: accessPanel?.size,
                  }}
                  onChange={(e: any) => {
                    setAccessSize(e?.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='col-xl-6 col-lg-12'>
            <div className='row'>
              <div className='col-lg-4 mb-5'>
                <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Damper Accessible on both Sides:</span></button>
              </div>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 mb-5'>
                    <Select
                      isDisabled={isEdit ? false : true}
                      placeholder='accessInstalled'
                      options={[
                        {
                          label: 'Yes',
                          value: 'Yes',
                        },
                        {
                          label: 'No',
                          value: "No",
                        },
                      ]}
                      id='accessInstalled'
                      isClearable
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => String(option.value)}
                      defaultValue={{
                        label: accessPanel?.installedBothSides,
                        value: accessPanel?.installedBothSides,
                      }}
                      onChange={(e: any) => {
                        setAccessInstalled(e?.value);
                      }}
                    />
                  </div>
                  <div className='col-lg-6 mb-5'>
                    <input type='text' className='form-control' value={defectType} disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-12 text-center'>
            {(asset?.accessPanel?.defects?.length) ? (
              <button
                disabled={isEdit ? false : true}
                className={isEdit ? 'btn btn-primary' : 'btn btn-secondary'}
                onClick={() => {
                  setShowDefects(true);
                }}
              >
                View Access Panel defects
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
);

const Tests = forwardRef(
  (
    {
      asset,
      building,
      isEdit,
      setAssetDetail,
      defectSubcategoryData,
      setDeleteDialogBox
    }: {
      asset: any;
      building: any;
      isEdit?: boolean;
      setAssetDetail: any;
      defectSubcategoryData?: any;
      setDeleteDialogBox: any;
    },
    ref
  ) => {
    const { tests } = asset;
    const { id } = useParams<{ id: string; }>();
    const assetDetails = asset;
    const latestTest = tests?.length > 0 ? tests[tests?.length - 1] : null;
    const [hygImage, setHygImage] = useState(latestTest?.hygiene?.hygienImageUrl ?? '');
    const [asfoundImg, setasfoundImg] = useState(latestTest?.test?.asFoundImageUrl ?? '');
    const [asfoundImgDate, setasfoundImgDate] = useState(latestTest?.test?.testAsFoundDate ?? '');
    const [dropTestImg, setDropTestImg] = useState(latestTest?.test?.dropTestImageUrl ?? '');
    const [dropTestImgDate, setDropTestImgDate] = useState(latestTest?.test?.testDropTestDate ?? '');
    const [resetImg, setResetImg] = useState(latestTest?.test?.resetImageUrl ?? '');
    const [resetImgDate, setResetImgDate] = useState(latestTest?.test?.testRestDate ?? '');
    const [satisfactionStatus, setSatisfactionStatus] = useState(latestTest?.satisffactoryStatus ? latestTest?.satisffactoryStatus : '');
    const [hygValue, setHygValue] = useState(latestTest?.hygiene?.hygieneText ?? '');
    const [cleanedVal, setCleanedVal] = useState(latestTest?.hygiene?.cleaned ?? '');
    const [showModal, setShowModal] = useState(false);
    const [showDefects, setShowDefects] = useState(false);
    const [selectedDefect, setSelectedDefect] = useState();

    const dispatch = useDispatch();

    const updateTests = () => {
      const newObject = { ...tests }; // Creating a shallow copy
      if (newObject?.defects?.length === 0) {
        delete newObject['defects'];
      }
      const body = {
        buildingAssetid: assetDetails._id,
        tests: [
          ...newObject,
          {
            satisffactoryStatus: satisfactionStatus,
            testSkipped: false,
            test: {
              asFoundImageUrl: asfoundImg,
              dropTestImageUrl: dropTestImg,
              resetImageUrl: resetImg,
              testAsFoundDate: asfoundImgDate,
              testDropTestDate: dropTestImgDate,
              testRestDate: resetImgDate,
            },
            hygiene: {
              hygienImageUrl: hygImage,
              hygieneText: hygValue,
              cleaned: cleanedVal,
            },
          },
        ],
      };
      return body;
    };

    useImperativeHandle(ref, () => ({
      Testsbody() {
        return updateTests();
      },
      submitTest: () => {
        if ((latestTest?.hygiene?.hygienImageUrl && (latestTest?.hygiene?.hygienImageUrl !== hygImage))
          || (latestTest?.test?.asFoundImageUrl && (latestTest?.test?.asFoundImageUrl !== asfoundImg))
          || (latestTest?.test?.dropTestImageUrl && (latestTest?.test?.dropTestImageUrl !== dropTestImg))
          || (latestTest?.test?.resetImageUrl && (latestTest?.test?.resetImageUrl !== resetImg))
          || (latestTest?.satisffactoryStatus && (latestTest?.satisffactoryStatus !== satisfactionStatus))
          || (latestTest?.hygiene?.hygieneText && (latestTest?.hygiene?.hygieneText !== hygValue))
          || (latestTest?.hygiene?.cleaned && (latestTest?.hygiene?.cleaned !== cleanedVal))
        ) {
          return false;
        }
        else {
          return true;
        }
      }
    }));

    const onSubmitTest = async () => {
      try {
        let [month, text] = building.testRegime.split(' ');
        let currentDate = new Date();
        if (month) {
          currentDate.setMonth(currentDate.getMonth() + Number(month));
          if (currentDate.getMonth() > 11) {
            currentDate.setFullYear(currentDate.getFullYear() + 1);
            currentDate.setMonth(currentDate.getMonth() - 12);
          }
          let lastDayOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
          ).getDate();
          if (currentDate.getDate() > lastDayOfMonth) {
            currentDate.setDate(lastDayOfMonth);
          }
        }

        const body = {
          testData: {
            testDate: moment().tz('Europe/London').format('YYYY-MM-DD HH:mm:ss'),
            testDueDate: moment(currentDate).tz('Europe/London').format('YYYY-MM-DD HH:mm:ss'),
            satisffactoryStatus: satisfactionStatus,
            testSkipped: false,
            defect: false,
            test: {
              asFoundImageUrl: asfoundImg,
              dropTestImageUrl: dropTestImg,
              resetImageUrl: resetImg,
              testAsFoundDate: moment().tz('Europe/London').format('YYYY-MM-DD HH:mm:ss'),
              testDropTestDate: moment().tz('Europe/London').format('YYYY-MM-DD HH:mm:ss'),
              testRestDate: moment().tz('Europe/London').format('YYYY-MM-DD HH:m:ss'),
            },
            hygiene: {
              hygienImageUrl: hygImage,
              hygieneText: hygValue,
              cleaned: !cleanedVal ? false : true,
            },
            defects: [],
            inspectionJobId: assetDetails?.inspectionJob,
          },
          buildingid: assetDetails?.building,
          buildingAssetId: assetDetails._id,
        };

        const result = await addtest(body);
        if (result.status === 'Success') {
          success('Test Added Successfully');

          setAssetDetail(result.data);

          dispatch({
            type: SET_TESTS_DATA,
            payload: result?.data,
          });
        } else {
          error(result?.message);
        }
      } catch (e) { }
    };

    const handleCheck = (e: { value: any; }) => {
      if (assetDetails.tests.length === 0 && (e.value === 'Major' || e.value === 'Minor')) {
        toast.error('Please submit a test first to add a defect ');
        setSatisfactionStatus('satisfactory');
      } else {
        setSatisfactionStatus(e?.value);
      }
    };

    let defects: any = [];

    for (let index = 0; index < assetDetails?.tests?.length; index++) {
      const element = assetDetails?.tests[index];
      if (element?.defects?.length > 0) {
        defects = defects.concat(element.defects);
      }
    }

    return (
      <>
        <DefectModal
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setSelectedDefect(undefined);
          }}
          title='Add Defect'
        >
          <DefectFlow
            isTest
            setAssetDetail={setAssetDetail}
            latestTest={latestTest}
            selectedDefect={selectedDefect}
            satisfactionStatuss={satisfactionStatus}
            asset={asset}
            setOpen={setShowModal}
            options={defectSubcategoryData?.subCategories}
          />
        </DefectModal>

        <DefectModal
          show={showDefects}
          handleClose={() => {
            setShowDefects(false);
            setSelectedDefect(undefined);
          }}
          title='View Defects'
        >
          <DefectsViewTable
            setSelectedDefect={setSelectedDefect}
            setShowModal={setShowModal}
            assetDetails={latestTest}
            setShowDefects={setShowDefects}
            setDeleteDialogBox={(val: any) => {
              setDeleteDialogBox({ tests: true, defectId: val.defectId, flag: val.flag, testId: val.testId });
            }}
            latestTest={latestTest}
          />
        </DefectModal>
        <>

          <div className='row d-lg-flex justify-content-lg-between'>
            <div className='col-lg-6'>
              <div className='row'>
                <div className='col-lg-4 mb-5'>
                  <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Tests:</span></button>
                </div>
                <div className='col-lg-8 mb-5'>

                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='d-lg-flex justify-content-lg-end'>
                {satisfactionStatus === 'Minor' || satisfactionStatus === 'Major' ? (
                  <button
                    disabled={isEdit ? false : true}
                    className={`${isEdit ? 'btn btn-primary mb-5' : 'btn btn-secondary mb-5'}`}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Add defect
                  </button>
                ) : (
                  ''
                )}
                {(Boolean(asset?.tests.some((item: any) => item?.defects?.length > 0)) &&
                  satisfactionStatus === 'Minor') ||
                  (Boolean(asset?.tests.some((item: any) => item?.defects?.length > 0)) &&
                    satisfactionStatus === 'Major') ? (
                  <button
                    disabled={isEdit ? false : true}
                    className={`${isEdit ? 'btn btn-primary' : 'btn btn-secondary'} mb-5 ms-lg-4`}
                    onClick={() => {
                      setShowDefects(true);
                    }}
                  >
                    View Test defects
                  </button>
                ) : (
                  '')}
                <button
                  disabled={isEdit ? false : true}
                  className={`${isEdit ? 'btn btn-primary' : 'btn btn-secondary'} mb-5 ms-lg-4`}
                  onClick={() => {
                    onSubmitTest();
                  }}
                >
                  Submit Test
                </button>
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='d-xl-flex'>
                <div className='mb-5'>
                  <div className='d-flex flex-column justify-content-start align-items-center'>
                    <h6>As Found</h6>
                    <ImageUploader
                      img={asfoundImg}
                      setImageUrl={setasfoundImg}
                      setImageDate={setasfoundImgDate}
                      isTestImg
                      building={assetDetails}
                    />
                  </div>
                  <div className='text-center mt-2'>
                    {moment(latestTest?.test?.testAsFoundDate)
                      .utc()
                      .format('DD-MM-YYYY HH:mm:ss')}
                  </div>
                </div>
                <div className='mb-5'>
                  <div className='d-flex flex-column justify-content-start align-items-center'>
                    <h6>Drop Test</h6>
                    <ImageUploader
                      img={dropTestImg}
                      building={assetDetails}
                      isTestImg
                      setImageDate={setDropTestImgDate}
                      setImageUrl={setDropTestImg}
                    />
                  </div>
                  <div className='text-center mt-2'>
                    {moment(latestTest?.test?.testDropTestDate)
                      .utc()
                      .format('DD-MM-YYYY HH:mm:ss')}
                  </div>
                </div>
                <div className='mb-5'>
                  <div className='d-flex flex-column justify-content-start align-items-center'>
                    <h6>Reset</h6>
                    <ImageUploader
                      img={resetImg}
                      building={assetDetails}
                      isTestImg
                      setImageDate={setResetImgDate}
                      setImageUrl={setResetImg}
                    />
                  </div>
                  <div className='text-center mt-2'>
                    {moment(latestTest?.test?.testRestDate).utc().format('DD-MM-YYYY HH:mm:ss')}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12 col-xl-6'>
              <div className='row'>
                <div className='col-lg-4 mb-5'>
                  <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Hygiene:</span></button>
                </div>
                <div className='col-lg-8 mb-5'>
                  <Select
                    isDisabled={isEdit ? false : true}
                    placeholder='Hygienes'
                    options={Hygienes}
                    isClearable
                    getOptionLabel={(option: any) => option.hygvalue}
                    getOptionValue={(option: any) => String(option.hygvalue)}
                    defaultValue={{
                      hygvalue: latestTest?.hygiene?.hygieneText,
                    }}
                    onChange={(e: any) => {
                      setHygValue(e?.hygvalue);
                    }}
                  />
                </div>
                <div className='col-lg-4 mb-5'>
                  <button disabled className='btn btn-secondary w-100 text-start'><span className='text-dark'>Cleaned:</span></button>
                </div>
                <div className='col-lg-8 mb-5'>
                  <Select
                    isDisabled={isEdit ? false : true}
                    options={Cleaned}
                    isClearable
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => String(option.value)}
                    defaultValue={{
                      label: latestTest?.hygiene?.cleaned === true ? 'Yes' : 'No',
                      value: latestTest?.hygiene?.cleaned,
                    }}
                    onChange={(e: any) => {
                      setCleanedVal(e?.value);
                    }}
                  />
                </div>
                <div className='col-lg-4 mb-5'>
                </div>
                <div className='col-lg-8 mb-5'>
                  <Select
                    isDisabled={isEdit ? false : true}
                    placeholder='Advisory Note'
                    options={NotesData}
                    isClearable
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => String(option.value)}
                    defaultValue={{
                      value: latestTest?.satisffactoryStatus,
                      label: latestTest?.satisffactoryStatus,
                    }}
                    onChange={(e: any) => {
                      handleCheck(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-12 col-xl-6'>
              <div className='d-xl-flex text-center'>
                <ImageUploader
                  img={hygImage}
                  setImageUrl={setHygImage}
                  building={assetDetails}
                  isHygieneImg
                />
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
);

interface IDefectsViewTable {
  assetDetails: any;
  setSelectedDefect?: any;
  setShowModal?: any;
  setShowDefects?: any;
  archivedDefect?: any;
  archivedList?: any;
  title?: string;
  message?: string;
  setDeleteDialogBox?: any;
  allDefects?: boolean;
  latestTest?: any;
}

const DefectsViewTable: React.FC<IDefectsViewTable> = ({
  assetDetails,
  setSelectedDefect,
  setShowModal,
  setShowDefects,
  archivedDefect,
  archivedList,
  title,
  message,
  setDeleteDialogBox,
  allDefects,
  latestTest
}) => {

  const permissionData = useSelector((state: any) => state.auth);
  const jobref = useSelector((state: any) => state.job?.singleJob);
  let moduldePermision = permissionData?.user?.userPersonalPermissions;
  let vlt: any = GetPermissionsByName('Vault Access', moduldePermision);

  const defectKeys = [
    'accessibility',
    'fireBarrier',
    'installation',
    'accessPanel',
    'tests',
  ];

  const categoryName = (defect: any) => {
    return (
      <div className='cursor-pointer defect_list' onClick={() => {
        if (setSelectedDefect && setShowModal && setShowDefects) {
          setSelectedDefect(defect);
          setShowModal(true);
          setShowDefects(false);
        }
      }}>{defect?.defectCategoryName}</div>
    );
  };

  const columns = [
    {
      field: 'index',
      header: '#'
    },
    {
      field: 'defectCategoryName',
      header: 'Defect',
      body: categoryName
    },
    {
      field: 'isDefectRectified',
      header: 'Status'
    },
    {
      field: 'defectAddedBy',
      header: 'Operative Name'
    },
    {
      field: 'jobReference',
      header: 'Job Ref No.'
    },
    {
      field: 'defectAddedOn',
      header: 'Date'
    },
    {
      field: 'defectAddedTime',
      header: 'Time'
    },
  ];

  const defects = assetDetails?.defects?.map((defect: any, index: number) => {
    let dat = moment(defect?.addedOn).tz('Europe/London').utc();
    return {
      ...defect,
      index: index + 1,
      isDefectRectified: defect.defectRectification?.defectRectified ? 'Rectified' : 'Defect',
      defectAddedBy: defect?.addedBy ? defect?.addedBy?.modelId?.operativeName : 'N/A',
      defectAddedOn: defect?.addedOn ? moment(defect?.addedOn).format('DD-MM-YYYY') : 'N/A',
      defectAddedTime: defect?.addedOn ? dat.format('HH:mm:ss') : 'N/A',
      jobReference: jobref?.jobReference
    };
  });


  return (
    <>
      <CustomDataTable
        data={defects}
        columns={columns}
        isShowActionColumns
        isHideSearch
        isHideArchieveAction
        isHideEditAction
        onDelete={(id: string) => {
          let defect = defects.find((x: any) => x._id === id);
          if (allDefects) {
            const matchingKeys = defectKeys.filter(key => defect.hasOwnProperty(key));
            const dynamicProperties: any = {};
            matchingKeys.forEach(key => {
              dynamicProperties[key] = true;
            });

            setDeleteDialogBox({
              ...dynamicProperties,
              flag: true,
              defectId: defect._id,
              testId: latestTest?._id,
            });
          }
          else {
            setDeleteDialogBox({ flag: true, defectId: defect._id, testId: latestTest?._id });
          }
        }}
      />
    </>
  );
};

const TestHistoryViewTable = ({
  assetDetails,
  handleClose,
  detailView,
  assetDetail,
}: {
  assetDetail: any;
  assetDetails: any;
  handleClose: any;
  detailView: any;
}) => {
  const permissionData = useSelector((state: any) => state.auth);
  const jobref = useSelector((state: any) => state.job?.singleJob);

  const tests = assetDetails?.reverse().map((defect: any, index: number) => {
    let dat = moment(defect?.testDate).tz('Europe/London').utc();
    return {
      ...defect,
      index: `${index + 1}`,
      addedBy: defect?.addedBy?.modelId?.operativeName || permissionData?.user?.name,
      jobReference: jobref?.jobReference,
      addedDate: defect?.testDate
        ? moment(defect?.testDate).format('DD-MM-YYYY')
        : moment().format('DD-MM-YYYY'),
      addedTime: defect?.testDate ? dat.format('HH:mm:ss') : 'N/A'
    };
  });

  const defectTemplate = (rowData: any) => {
    return (
      <div className='cursor-pointer defect_list' onClick={() => {
        handleClose();
        detailView();
      }}>
        {rowData.index}. Asset No - Test Conducted
      </div>
    );
  };

  const columns = [
    { field: 'index', header: 'Defect', body: defectTemplate },
    { field: 'addedBy', header: 'Operative Name' },
    { field: 'jobReference', header: 'Job Reference' },
    { field: 'addedDate', header: 'Date' },
    { field: 'addedTime', header: 'Time' },
  ];

  return (
    <>
      <CustomDataTable
        data={tests}
        columns={columns}
        isHideSearch
      />
    </>
  );
};

const TestHistoryDetailView = ({ asset }: { asset: any; }) => {
  const { tests } = asset;
  const { building } = useSelector((state: any) => state.job);
  const { id } = useParams<{ id: string; }>();
  const latestTest = tests.length > 0 ? tests[tests.length - 1] : null;
  const [hygImage, setHygImage] = useState(latestTest?.hygiene?.hygienImageUrl ?? '');

  const [asfoundImg, setasfoundImg] = useState(latestTest?.test?.asFoundImageUrl ?? '');
  const [dropTestImg, setDropTestImg] = useState(latestTest?.test?.dropTestImageUrl ?? '');
  const [resetImg, setResetImg] = useState(latestTest?.test?.resetImageUrl ?? '');
  const [satisfactionStatus, setSatisfactionStatus] = useState(
    latestTest?.satisffactoryStatus ?? ''
  );
  const [hygValue, setHygValue] = useState(latestTest?.hygiene?.hygieneText ?? '');
  const [cleanedVal, setCleanedVal] = useState(latestTest?.hygiene?.cleaned ?? '');

  return (
    <>
      <div className='row'>
        <div className='col-xl-2 col-lg-4 mb-5'>
          <button type='button' className='btn btn-secondary w-100 text-start' disabled>Tests:</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-4 col-lg-12 text-center mb-5'>
          <h6>As Found</h6>

          <img
            alt=''
            style={{
              maxHeight: '250px',
              maxWidth: '250px',
            }}
            src={asfoundImg ? process.env.REACT_APP_IMAGE_URL + asfoundImg : CheckImg}
          />
          <div className='mt-2'>
            {moment(latestTest?.test?.testAsFoundDate).utc().format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </div>
        <div className='col-xl-4 col-lg-12 text-center mb-5'>
          <h6>Drop Test</h6>

          <img
            alt=''
            style={{
              maxHeight: '250px',
              maxWidth: '250px',
              marginInline: '10px',
            }}
            src={dropTestImg ? process.env.REACT_APP_IMAGE_URL + dropTestImg : CheckImg}
          />
          <div className='mt-2'>
            {moment(latestTest?.test?.testDropTestDate).utc().format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </div>
        <div className='col-xl-4 col-lg-12 text-center mb-5'>
          <h6>Reset</h6>

          <img
            alt=''
            style={{
              maxHeight: '250px',
              maxWidth: '250px',
              marginInline: '10px',
            }}
            src={resetImg ? process.env.REACT_APP_IMAGE_URL + resetImg : CheckImg}
          />
          <div className='text-center mt-2'>
            {moment(latestTest?.test?.testRestDate).utc().format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-8 col-lg-12'>
          <div className='row'>
            <div className='col-lg-6 mb-5'>
              <button type='button' className='btn btn-secondary w-100 text-start' disabled>Hygiene:</button>
            </div>
            <div className='col-lg-6 mb-5'>
              <Select
                placeholder='Hygienes'
                options={Hygienes}
                isClearable
                getOptionLabel={(option: any) => option.hygvalue}
                getOptionValue={(option: any) => String(option.hygvalue)}
                defaultValue={{
                  hygvalue: latestTest?.hygiene?.hygieneText,
                }}
                onChange={(e: any) => {
                  setHygValue(e?.hygvalue);
                }}
              />
            </div>
            <div className='col-lg-6 mb-5'>
              <button type='button' className='btn btn-secondary w-100 text-start' disabled>Cleaned:</button>
            </div>
            <div className='col-lg-6 mb-5'>
              <Select
                options={Cleaned}
                isClearable
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => String(option.value)}
                defaultValue={{
                  label: latestTest?.hygiene?.cleaned === true ? 'Yes' : 'No',
                  value: latestTest?.hygiene?.cleaned,
                }}
                onChange={(e: any) => {
                  setCleanedVal(e?.value);
                }}
              />
            </div>
            <div className='col-lg-6'>
            </div>
            <div className='col-lg-6 mb-5'>
              <Select
                placeholder='Advisory Note'
                options={NotesData}
                isClearable
                getOptionLabel={(option: any) => option.label}
                getOptionValue={(option: any) => String(option.value)}
                defaultValue={{
                  value: latestTest?.satisffactoryStatus,
                  label: latestTest?.satisffactoryStatus,
                }}
                onChange={(e: any) => {
                  setSatisfactionStatus(e?.value);
                }}
              />
            </div>
          </div>

        </div>
        <div className='col-xl-4 col-lg-12'>
          <img
            alt=''
            style={{
              maxHeight: '250px',
              maxWidth: '250px',
            }}
            src={hygImage ? process.env.REACT_APP_IMAGE_URL + hygImage : CheckImg}
          />
        </div>
      </div>
    </>
  );
};