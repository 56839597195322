import DialogueBox from 'app/components/DialogueBox';
import { error, success } from 'app/components/toast';
import { UserArgsProps, userArgs } from 'app/modules/auth/components/Registration';
import {
  createFacilityManager,
  getAllUsersByRole,
  getBuildingsWithDetails,
  getFacilityManagersDetailedListing,
  getReferences,
  updateFacilityManager,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { SET_FM_MODAL_VISIBLE } from 'app/modules/auth/redux/jobs/actions';
import { Roles } from 'constants/Utils';
import { useFormik } from 'formik';
import { GetPermissionsByName } from 'hooks/usePermissionAcess';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { buildingDetailsArgs } from '../buildings/Buildings';

import LoaderComman from 'app/components/shared/loader.component';
import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { Dialog } from 'primereact/dialog';
import FacilityManagerDataTable from '../../components/data-table/customDataTable';

export const fmArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 1000,
  query: {
    critarion: {
      active: true,
    },
    fields: 'facilityManagerName',
  },
};

export const fmDetailedArgs = {
  sortproperty: 'createdAt',
  pageNum: 1,
  sortorder: -1,
  offset: 0,
  limit: 10,
  search: '',
  query: {
    critarion: {
      active: true,
    },
    userFields: '_id email name',
    buildingFields: 'buildingReference buildingName buildingAddress',
    clientUserFields: '_id email name phone',
    addedby: '_id email name',
    lastModifiedBy: '_id email name',
  },
};

export interface UpdateFMProps {
  facilityManagerid: string;
  facilityManagerName: string;
  active: boolean;
  user: string;
}

export interface CreateFMProps {
  facilityManagerName: string;
  facilityManagerRef: string;
  facilityManagerid?: string;
  active?: boolean;
}

export interface FMListingProps {
  _id: string;
  facilityManagerName: string;
  user: string;
  active: boolean;
  facilityManagerid: string;
}

const FacilityManager = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [facilityManagers, setFacilityManagers] = useState<any[]>([]);
  const [buildings, setBuildings] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });

  const [totalRecords, setTotalRecords] = useState(0);
  const [firstRow, setFirstRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isTableLoading, setTableLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState('createdAt');
  const [isChanged, setChanged] = useState(false);

  const { fmModalVisible } = useSelector((state: any) => state.job);
  const dispatch = useDispatch();

  const onHideModal = () => {
    setShowModal(false);
    dispatch({
      type: SET_FM_MODAL_VISIBLE,
      payload: false,
    });
  };

  const onShowModal = () => {
    setShowModal(true);
  };

  const initialValues: CreateFMProps = {
    facilityManagerName: '',
    facilityManagerRef: '',
  };

  const validationSchema: any = Yup.object().shape({
    facilityManagerName: Yup.string().required('FM RegisterName is required'),
    facilityManagerRef: Yup.string().required('FM Registerreference is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      let response = null;
      if (isEditing) {
        response = await updateFacilityManager(values);
      } else response = await createFacilityManager(values);
      if (response.status === 'Success') {
        success('FM Register Created successfully');
        formik.resetForm();
        setIsEditing(false);
        setSubmitting(false);
        onHideModal();
        const fms = await getFMListing();
        setFacilityManagers(fms);
        setLoading(false);
      } else {
        setStatus(response.message);
        setSubmitting(false);
        error('Something went wrong');
        setLoading(false);
      }
    },
  });

  const getFMListing = async (isSave = false) => {
    fmDetailedArgs.pageNum = pageNumber;
    fmDetailedArgs.limit = pageSize;
    fmDetailedArgs.sortorder = sortOrder;
    fmDetailedArgs.sortproperty = sortField;
    fmDetailedArgs.search = filterValue;

    fmDetailedArgs.query.critarion.active = true;
    const res = await getFacilityManagersDetailedListing(fmDetailedArgs);

    setTotalRecords(res.data.totalRecords);
    setTableLoading(false);
    const activeListing = res.data.facilityManagers;

    activeListing.map((r: any, i: number) => {
      r.serial = i + 1;
      r.facilityManagerName = r.facilityManagerName ? r.facilityManagerName : 'N/A';
      r.facilityManagerRef = r.facilityManagerRef ? r.facilityManagerRef : 'N/A';
    });

    if (isSave) {
      setFacilityManagers(activeListing);
    }
    return activeListing;
  };

  const getUsersByRole = async () => {
    const args: UserArgsProps = userArgs;
    args.query.critarion.role = Roles.operative;
    return await getAllUsersByRole(args);
  };

  const getBuildingListing = async () => {
    const args = buildingDetailsArgs;
    args.offset = 0;
    args.limit = 50;
    args.query.critarion.active = true;
    const res = await getBuildingsWithDetails(args);

    res.data.buildings.map((b: any, i: number) => {
      b.serial = i + 1;
    });
    return res.data.buildings;
  };

  const getAllData = async () => {
    Promise.all([await getFMListing(), await getUsersByRole(), await getBuildingListing()]).then(
      (values: any) => {
        setFacilityManagers(values[0]);
        setBuildings(values[2]);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [fmModalVisible]);

  const fetchData = async () => {
    try {
      let references = await getReferences({ getReferences: ['FM'] });
      if (references.status === 'Success') {
        formik.setFieldValue('facilityManagerRef', references.data.FM);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const permissionData = useSelector((state: any) => state.auth);
  let moduldePermision = permissionData?.user?.userPersonalPermissions;
  let permsissions: any = GetPermissionsByName('FM Register', moduldePermision);

  const facilityManagerColumns = [
    {
      field: 'facilityManagerRef',
      header: 'Reference',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'facilityManagerName',
      header: 'FM Name',
      sortable: true,
      style: { minWidth: '600px' },
    },
  ];

  const onEdit = (id: string) => {
    const fm = facilityManagers.find((x) => x._id === id);
    setIsEditing(true);

    formik.setValues({
      facilityManagerName: fm ? fm.facilityManagerName : '',
      facilityManagerRef: fm ? fm?.facilityManagerRef : '',
      active: fm ? fm.active : false,
      facilityManagerid: fm ? fm._id : '',
    });
    setTimeout(() => {
      onShowModal();
    }, 300);
  };

  const onArchiveFM = async (id: string) => {
    setLoading(true);
    const body = {
      facilityManagerid: id,
      active: false,
    };
    const response = await updateFacilityManager(body);
    if (response.status === 'Success') {
      getAllData();
      success('FM Archived successfully');
    } else {
      error('Something went wrong');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isChanged) {
      getFMListing(true);
    }
  }, [pageNumber, pageSize, sortField, sortOrder]);

  const onPageNumberChange = (e: any) => {
    setChanged(true);
    let newPageNumber = e.page + 1;

    if (pageNumber !== newPageNumber || pageSize !== e.rows) {
      setFirstRow(e.first);
      setPageNumber(newPageNumber);
      setPageSize(e.rows);
      setTableLoading(true);
    }
  };

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        setTableLoading(true);
        getFMListing(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [filterValue]);

  const onGlobalFilterChange = (e: any) => {
    setChanged(true);
    setFilterValue(e.target.value.trim());
  };

  const onSortChange = (e: any) => {
    setChanged(true);
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
    setTableLoading(true);
  };

  const clearFilter = () => {
    setChanged(true);
    setFilterValue('');
  };

  return (
    <LoaderComman isRender={!isLoading}>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body pt-0 pb-3'>
              <FacilityManagerDataTable
                dataKey='_id'
                data={facilityManagers}
                columns={facilityManagerColumns}
                isLoading={isTableLoading}
                isShowPaginator
                isShowPagSize
                rowCount={pageSize}
                totalRecords={totalRecords}
                firstRow={firstRow}
                onPageChange={onPageNumberChange}
                onGlobalFilterChange={onGlobalFilterChange}
                filterValue={filterValue}
                onSort={onSortChange}
                sortOrder={sortOrder}
                sortField={sortField}
                isShowActionColumns
                onEdit={onEdit}
                onArchive={(id: string) => setDialogueBox({ flag: true, record: id })}
                clearFilterValue={clearFilter}
              />
            </div>
          </div>
        </div>

        <Dialog position='top' header={`${isEditing ? 'Edit' : 'Add'} Facility Manager`} visible={showModal || fmModalVisible} onHide={onHideModal} style={{ width: '30vw' }} draggable={false}>
          <CreateFM
            formik={formik}
            buildings={buildings}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onHide={onHideModal}
          />
        </Dialog>

        <DialogueBox
          title={'FM Register Archive'}
          message={'Do you want  to archive ?'}
          state={dialogueBox.flag}
          onClick={() => onArchiveFM(dialogueBox.record)}
          handleClose={() => setDialogueBox({ flag: false, record: {} })}
        />
      </div>
    </LoaderComman>
  );
};

export default FacilityManager;

function CreateFM({
  formik,
  buildings,
  isEditing,
  setIsEditing,
  onHide,
}: {
  formik: any;
  buildings: any;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
  onHide: () => void;
}) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card card-custom'>
        <div className=''>
          <div className='form-group'>
            <label htmlFor='' className='required'>
              FM Name
            </label>
            <input
              type='text'
              className='form-control'
              {...formik.getFieldProps('facilityManagerName')}
              placeholder=''
            />
            {formik.touched.facilityManagerName && formik.errors.facilityManagerName ? (
              <div className='text-danger'>{formik.errors.facilityManagerName}</div>
            ) : null}
          </div>
          <div className='form-group'>
            <label htmlFor='' className='required'>
              FM Reference
            </label>
            <input
              type='text'
              className='form-control'
              {...formik.getFieldProps('facilityManagerRef')}
              placeholder=''
              disabled
            />
            {formik.touched.facilityManagerRef && formik.errors.facilityManagerRef ? (
              <div className='text-danger'>{formik.errors.facilityManagerRef}</div>
            ) : null}
          </div>
        </div>
        <div className='d-flex justify-content-end align-items-end flex-row gap-2'>
          <RoleBaseAccess moduleName='FM' isSubmit>
            <button className='btn btn-primary mr-2' type='submit'>
              {!formik.isSubmitting && (
                <span className='indicator-label px-4'>{isEditing ? 'Update' : 'Register'}</span>
              )}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </RoleBaseAccess>
          <button
            className='btn btn-dark ms-4'
            type='reset'
            onClick={() => {
              setIsEditing(false);
              formik.resetForm();
              onHide();
            }}
          >
            <span className='indicator-label px-4'>Cancel</span>
          </button>
        </div>
      </div>
    </form>
  );
}
