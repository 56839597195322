
import { error, success } from 'app/components/toast';
import {
  getBuildingAssetById,
  getSpecificJob,
  removeBuildingAsset,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { specificJobArgs } from '../jobs/Jobs';
import AssetPageTemplate from './AssetPageTemplate';

const assetArgs = {
  critarion: {
    _id: '',
  },
  buildingAssetsFilter: { assetArchived: false },
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

export interface ParamsProps {
  id: string;
}

const AssetsDetails = () => {
  const [loading, setLoading] = useState<boolean>(true);
  // const [assetDetails, setAssetDetails] = useState<any>(null)
  // const [installation, setInstallation] = useState<InstallationProps>()
  // const [accessPanel, setAccessPanel] = useState<any>(null)
  // const [ductDamper, setDuctDamper] = useState<any>(null)
  // const [fireBarrier, setFireBarrier] = useState<any>(null)
  // const [accessibility, setAccessibility] = useState<any>(null)
  const [jobsIds, setJobsIds] = useState<string[]>([]);
  const [jobData, setJobData] = useState<any>([]);

  const { id } = useParams<ParamsProps>();
  const history = useHistory();
  const { building, id: buildingId } = useSelector((state: any) => state.job);
  const dispatch = useDispatch();

  const getAssetById = async () => {
    assetArgs.critarion._id = id;
    const response = await getBuildingAssetById(assetArgs);
    return [
      response.data.installation,
      response.data.accessPanel,
      response.data.ductDamper,
      response.data.fireBarrier,
      response.data.accessibility,
      response.data,
    ];
  };

  // const getData = () => {
  //   if (building) {
  //     const assets = building.buildingAssets
  //     const asset = assets?.find((res: any) => res._id === id) ?? {}
  //     const {installation, accessPanel, ductDamper, fireBarrier, accessibility} = asset
  //     setAssetDetails(asset)
  //     setInstallation(installation)
  //     setAccessPanel(accessPanel)
  //     setDuctDamper(ductDamper)
  //     setFireBarrier(fireBarrier)
  //     setAccessibility(accessibility)
  //     setJobsIds(building.inspectionJobs)
  //     setLoading(false)
  //   } else {
  //     getAllData()
  //   }
  // }

  useEffect(() => {
    if (jobsIds.length > 0) {
      getInspectionJobs();
    }
  }, [jobsIds]);

  const getInspectionJobs = async () => {
    setJobData([]);
    jobsIds.map(async (id: string) => {
      specificJobArgs.critarion._id = id;
      await Promise.all([await getSpecificJob(specificJobArgs)]).then((jobs) => {
        setJobData((prev: any) => [...prev, jobs[0].data]);
      });
    });
  };

  useEffect(() => {
    if (jobData) {
      setLoading(false);
    }
  }, [jobData]);

  // const getAllData = async () => {
  //   const [installation, accessPanel, ductDamper, fireBarrier, accessibility, asset] =
  //     await getAssetById()
  //   setAssetDetails(asset)
  //   setInstallation(installation)
  //   setAccessPanel(accessPanel)
  //   setDuctDamper(ductDamper)
  //   setFireBarrier(fireBarrier)
  //   setAccessibility(accessibility)
  //   dispatch({
  //     type: BUILDING_DETAILS,
  //     payload: asset,
  //   })
  //   setLoading(false)
  // }

  // useEffect(() => {
  //   getData()
  // }, [])

  const navigate = () => {
    history.goBack();
  };

  const onRemove = async (_id: string) => {
    const data = { id: _id };
    const res = await removeBuildingAsset(data);
    if (res.status === 'Success') {
      success('Asset removed successfully');
      navigate();
    } else {
      error(res.message);
    }
  };

  if (loading) {
    return (
      <div className='card card-custom'>
        <div className='card-body'>
          <div className='d-flex justify-content-center'>
            <div className='spinner-border text-primary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else return <AssetPageTemplate />;
};

export default AssetsDetails;
