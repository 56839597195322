import CustomDataTable from 'app/components/data-table/customDataTable';
import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { error, success } from 'app/components/toast';
import {
  createClient,
  getClientById,
  getReferences,
  updateClient,
  uploadClientLogo,
  uploadRubberStamp,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useFormik } from 'formik';
import { UploadS3Images } from 'lib/UploadApi';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import logoImg from '../../../assets/associate.png';
import Associations from '../settings/associations';
import { CreateClientProps, UserProps, clientDetailedArgs } from './Clients';
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: end;
  justify-content: end;
  width: fit-content;
`;

const StyledImage = styled.img`
  width: 45px;
  border-radius: 50%;
`;

const ProfilePicWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ProfilePic = styled.img`
  max-height: 200px;
  max-width: 200px;
   border-radius: 3px;
  border: 1px solid #ccc;
`;

const RubberStampPic = styled.img`
   max-height: 200px;
  max-width: 200px;
  border-radius: 3px;
  border: 1px solid #ccc;
  margin-top: 0.75rem !important;
`;

interface IAssociation {
  logoPath: File | string;
  writeUp: string;
}

const CreateClientModal = ({
  getAllData,
  clientId,
  onClose,
  setClientId,
}: {
  getAllData: () => void;
  clientId: string;
  onClose: () => void;
  setClientId: (arg0: string) => any;
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [profilePic, setProfilePic] = useState<File | any>(null);
  const [rubberStamp, setRubberStamp] = useState<File | any>(null);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [inProcess, setInProcess] = useState<boolean>(false);
  const [isprofile, setIsProfile] = useState<string>('');
  const [isRubberStamp, setIsRubberStamp] = useState<string>('');
  const [isPic, setIsPic] = useState<File | any>(null);
  const [visible, setVisible] = useState(false);

  const [showModal, setShowDialog] = useState({ display: false, id: '' });

  const validationSchema = Yup.object().shape({
    // user: Yup.string().required("User is required"),
    clientName: Yup.string().required('Profile is required'),
    // businessName: Yup.string().optional(),
    website: Yup.string().required('Website is required'),
    companyRegisterationNo: Yup.string().required('Company registeration number is required'),
    VATNumber: Yup.string().required('VAT number is required'),
    // logoPath: Yup.string().required("Logo path is required"),
    themeColor: Yup.string().required('Theme color is required'),
    fontColor: Yup.string().required('Font color is required'),
    clientReference: Yup.string().required('Client reference is required'),
    address: Yup.string().required('Address is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    clientEmail: Yup.string().required('Client email is required'),
  });

  const initialValues: CreateClientProps = {
    // user: "",
    clientName: '',
    website: '',
    companyRegisterationNo: '',
    VATNumber: '',
    logoPath: '',
    themeColor: '',
    fontColor: '',
    associations: [],
    clientReference: '',
    address: '',
    phoneNumber: '',
    clientEmail: '',
    buildings: [],
    rubberStamp: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setInProcess(true);
      let bodyData: any = formik.values;
      let response = null;
      if (profilePic !== null) {
        const formData = new FormData();
        formData.append('foldername', 'clientLogos');
        formData.append('file', profilePic);

        const res = await uploadClientLogo(formData);
        // const res = await UploadS3Images(formData)
        if (res) {
          values.logoPath = res.data;
        }
      } else {
        setIsProfile('Logo is Required ');
      }

      if (rubberStamp) {
        const formData = new FormData();
        formData.append('foldername', 'rubberStamp');
        formData.append('file', rubberStamp);

        const res = await uploadRubberStamp(formData);
        if (res) {
          values.rubberStamp = res.data;
        }
      } else {
        setIsRubberStamp('Rubber Stamp is Required ');
      }

      if (values.associations.length) {
        values.associations = values.associations.map((x: any) => {
          return {
            associationId: x.associationId,
            memberShipId: x.memberShipId
          };
        });
      }
      setSubmitting(false);
      setInProcess(false);
      if (isEdit) {
        const data: any = values;
        data.clientid = clientId;
        response = await updateClient(data);
        if (response.status === 'Success') {
          formik.resetForm();
          setEdit(false);
          setStatus({ success: true });
          setSubmitting(false);
          setClientId('');
          getAllData();
          onClose();
        } else {
          error('Something went wrong');
          setStatus({ success: false });
          setSubmitting(false);
          setInProcess(false);
        }
      } else {
        response = await createClient(bodyData);
        if (response.status === 'Success') {
          success(`Client ${isEdit ? 'Updated' : 'Created'} successfully`);
          formik.resetForm();
          setEdit(false);
          setStatus({ success: true });
          setSubmitting(false);
          getAllData();
          onClose();
        } else {
          error('Something went wrong');
          setStatus({ success: false });
          setSubmitting(false);
          setInProcess(false);
        }
      }
    },
  });

  const getClientDetails = async (id: string) => {
    clientDetailedArgs.critarion._id = id;
    const res = await getClientById(clientDetailedArgs);
    const image = res.data.logoPath;
    if (res.status === 'Success') {
      const client = res.data;
      setProfilePic(image);
      setRubberStamp(res.data.rubberStamp);

      let assoData = client?.associations?.map((x: any) => {
        return {
          ...x.associationId,
          memberShipId: x.memberShipId,
          associationId: x.associationId?._id
        };
      });

      formik.setValues({
        // user: client?.user?._id,
        clientName: client.clientName,
        website: client.website,
        companyRegisterationNo: client.companyRegisterationNo,
        VATNumber: client.VATNumber,
        logoPath: image,
        themeColor: client.themeColor,
        fontColor: client?.fontColor || '#000000',
        associations: assoData,
        clientReference: client.clientReference,
        address: client.address,
        phoneNumber: client.phoneNumber,
        clientEmail: client.clientEmail,
        rubberStamp: client.rubberStamp
      });
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setClientId('');
  };

  useEffect(() => {
    if (clientId) {
      setEdit(true);
      setLoading(true);
      getClientDetails(clientId);
    } else {
      setEdit(false);
      setClientId('');
      formik.resetForm();

      const fetchData = async () => {
        try {
          let references = await getReferences({ getReferences: ['P'] });
          if (references.status === 'Success') {
            formik.setFieldValue('clientReference', references.data.P);
          }

          getAllData();
        } catch (error) { }
      };

      fetchData();
    }
  }, []);

  const bodyTemplate = (rowData: any) => {
    return <img alt='Associaltion Logo' src={process.env.REACT_APP_IMAGE_URL + rowData.associationImage} height={30} width={75} style={{ objectFit: 'contain' }} />;
  };

  const columns = [
    {
      field: "associationImage",
      header: "Association Logo",
      body: bodyTemplate,
      style: { minWidth: '150px' }
    },
    {
      field: "associationName",
      header: "Association",
      style: { minWidth: '200px' }
    },
    {
      field: "memberShipId",
      header: "Membership ID",
      style: { minWidth: '200px' }
    }
  ];

  const onUploadClientLogo = async (logos: IAssociation[]) => {
    await Promise.all(
      logos.map(async (logo: IAssociation) => {
        const formData = new FormData();
        formData.append('foldername', 'clientLogos');
        formData.append('file', logo.logoPath);
        const res = await UploadS3Images(formData);
        // const res = await uploadClientLogo(formData)
        logo.logoPath = res.data;
      })
    );
    return logos;
  };

  const onArchive = (id: string) => {
    let data = formik.values.associations.filter(x => x._id !== id);
    formik.setFieldValue("associations", data);
    setShowDialog({ display: false, id: '' });
  };

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <>
        <Dialog header="Remove Association" visible={showModal.display} onHide={() => setShowDialog({ display: false, id: '' })} draggable={false} style={{ width: '30vw' }}>
          <div className='row'>
            <div className='col-lg-12'>
              <p>Do you want to remove association?</p>
              <div className='d-flex justify-content-end'>
                <button className='btn btn-primary-bordered' type='button' onClick={() => setShowDialog({ display: false, id: '' })}>
                  Cancel
                </button>
                <button
                  className='btn btn-primary ms-3'
                  type='button'
                  onClick={() => onArchive(showModal.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Dialog>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card card-custom'>
              <div className='card-body p-0'>
                <div className='row justify-content-center py-8 px-8 py-lg-15 px-lg-10'>
                  <div className='col-xl-12'>
                    <form
                      className='form fv-plugins-bootstrap fv-plugins-framework'
                      id='kt_form'
                      onSubmit={formik.handleSubmit}
                    >
                      <CardContainer className='card card-custom'>
                        <div className='row justify-content-center'>
                          <div className='row'>
                            <div className='col-lg-6 mb-7'>
                              <label className='form-label fw-bolder text-dark fs-6 hidden w-100 text-left'>
                                Choose Logo
                              </label>
                              <input
                                id='file'
                                name='file'
                                accept='image/*'
                                type='file'
                                onChange={(event) => {
                                  // formik.setFieldValue("profilePic", event.target.files && event.target.files[0]);
                                  setProfilePic(event.target.files && event.target.files[0]);
                                }}
                              />
                              {profilePic ? (
                                <div className='avatar-preview mt-6'>
                                  <ProfilePicWrapper>
                                    <ProfilePic
                                      src={
                                        profilePic && typeof profilePic !== 'string'
                                          ? URL.createObjectURL(profilePic)
                                          : process.env.REACT_APP_IMAGE_URL + profilePic
                                      }
                                      alt='Logo image'
                                    />
                                  </ProfilePicWrapper>
                                </div>
                              ) : (
                                <ProfilePicWrapper>
                                  <ProfilePic src={logoImg} alt='' />
                                </ProfilePicWrapper>
                              )}
                              {isprofile && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span
                                      role='alert'
                                      className='text-danger pt-1 '
                                      style={{ fontSize: '12px' }}
                                    >
                                      Logo is required.
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className='col-lg-6 mb-7'>
                              <label className='form-label fw-bolder text-dark fs-6 hidden w-100 text-left'>
                                Choose Stamp
                              </label>
                              <input
                                id='file'
                                name='file'
                                accept='image/*'
                                type='file'
                                onChange={(event) => {
                                  // formik.setFieldValue("profilePic", event.target.files && event.target.files[0]);
                                  setRubberStamp(event.target.files && event.target.files[0]);
                                }}
                              />
                              {rubberStamp ? (
                                <div className='avatar-preview'>
                                  <ProfilePicWrapper>
                                    <RubberStampPic
                                      src={
                                        rubberStamp && typeof rubberStamp !== 'string'
                                          ? URL.createObjectURL(rubberStamp)
                                          : process.env.REACT_APP_IMAGE_URL + rubberStamp
                                      }
                                      alt='rubber stamp'
                                    />
                                  </ProfilePicWrapper>
                                </div>
                              ) : (
                                <ProfilePicWrapper>
                                  <RubberStampPic src={logoImg} alt='' />
                                </ProfilePicWrapper>
                              )
                              }
                              {isRubberStamp && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span
                                      role='alert'
                                      className='text-danger pt-1 '
                                      style={{ fontSize: '12px' }}
                                    >
                                      Rubber Stamp is required.
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-lg-6'>
                              <div className='col-lg-12'>
                                <div className='row'>
                                  <label
                                    htmlFor=''
                                    className='required col-xl-12 col-lg-12 col-form-label '
                                  >
                                    Profile Name
                                  </label>
                                  <div className='col-lg-12 col-xl-12'>
                                    <input
                                      type='text'
                                      className='form-control'
                                      placeholder='john'
                                      {...formik.getFieldProps('clientName')}
                                    />
                                    {formik.errors.clientName && formik.touched.clientName ? (
                                      <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                        {formik.errors.clientName}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='row'>
                                  <label
                                    htmlFor=''
                                    className='required col-xl-12 col-lg-12 col-form-label '
                                  >
                                    Reference
                                  </label>
                                  <div className='col-lg-12 col-xl-12'>
                                    <input
                                      type='text'
                                      placeholder='reference'
                                      className='form-control'
                                      {...formik.getFieldProps('clientReference')}
                                      disabled
                                    />
                                    {formik.errors.clientReference &&
                                      formik.touched.clientReference ? (
                                      <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                        {formik.errors.clientReference}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='row'>
                                  <label
                                    htmlFor=''
                                    className='required col-xl-12 col-lg-12 col-form-label '
                                  >
                                    Email
                                  </label>
                                  <div className='col-lg-12 col-xl-12'>
                                    <input
                                      type='email'
                                      placeholder='jhon@gmail.com'
                                      className='form-control'
                                      {...formik.getFieldProps('clientEmail')}
                                    />
                                    {formik.errors.clientEmail && formik.touched.clientEmail ? (
                                      <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                        {formik.errors.clientEmail}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='row'>
                                  <label
                                    htmlFor=''
                                    className='required col-xl-12 col-lg-12 col-form-label '
                                  >
                                    Phone
                                  </label>
                                  <div className='col-lg-12 col-xl-12'>
                                    <input
                                      type='text'
                                      placeholder='+00 00 0000 0000'
                                      className='form-control'
                                      {...formik.getFieldProps('phoneNumber')}
                                    />
                                    {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                                      <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                        {formik.errors.phoneNumber}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-12'>
                                <div className='row'>
                                  <label
                                    htmlFor=''
                                    className='required col-xl-12 col-lg-12 col-form-label '
                                  >
                                    Address
                                  </label>
                                  <div className='col-lg-12 col-xl-12'>
                                    <input
                                      type='text'
                                      placeholder='Permanet Address'
                                      className='form-control'
                                      {...formik.getFieldProps('address')}
                                    />
                                    {formik.errors.address && formik.touched.address ? (
                                      <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                        {formik.errors.address}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-6 mb-10'>
                              <div className='col-lg-12'>
                                <div className='row'>
                                  <label
                                    htmlFor=''
                                    className='required col-xl-12 col-lg-12 col-form-label '
                                  >
                                    Website
                                  </label>
                                  <div className='col-lg-12 col-xl-12'>
                                    <input
                                      type='text'
                                      placeholder='www.abc.com'
                                      className='form-control'
                                      {...formik.getFieldProps('website')}
                                    />
                                    {formik.errors.website && formik.touched.website ? (
                                      <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                        {formik.errors.website}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <label
                                  htmlFor=''
                                  className='required col-xl-12 col-lg-12 col-form-label '
                                >
                                  Company Registeration No
                                </label>
                                <div className='col-lg-12 col-xl-12'>
                                  <input
                                    type='text'
                                    placeholder='AB123456'
                                    className='form-control'
                                    {...formik.getFieldProps('companyRegisterationNo')}
                                  />
                                  {formik.errors.companyRegisterationNo &&
                                    formik.touched.companyRegisterationNo ? (
                                    <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                      {formik.errors.companyRegisterationNo}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-lg-12'>
                                  <div className='row'>
                                    <label
                                      htmlFor=''
                                      className='required col-xl-12 col-lg-12 col-form-label '
                                    >
                                      VAT Number
                                    </label>
                                    <div className='col-lg-12 col-xl-12'>
                                      <input
                                        type='text'
                                        placeholder='AB123456'
                                        className='form-control'
                                        {...formik.getFieldProps('VATNumber')}
                                      />
                                      {formik.errors.VATNumber && formik.touched.VATNumber ? (
                                        <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                          {formik.errors.VATNumber}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className='row '>
                                    <label
                                      htmlFor=''
                                      className='required col-xl-12 col-lg-12 col-form-label '
                                    >
                                      Brand Color 1
                                    </label>
                                    <div className='col-lg-12 col-xl-12'>
                                      <input
                                        type='color'
                                        className='form-control'
                                        style={{ height: '42px' }}
                                        {...formik.getFieldProps('themeColor')}
                                      />
                                      {formik.errors.themeColor && formik.touched.themeColor ? (
                                        <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                          {formik.errors.themeColor}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className='row  '>
                                    <label
                                      htmlFor=''
                                      className='required col-xl-12 col-lg-12  col-form-label '
                                    >
                                      Brand Color 2
                                    </label>
                                    <div className='col-lg-12 col-xl-12 '>
                                      <input
                                        type='color'
                                        className='form-control'
                                        style={{ height: '42px' }}
                                        {...formik.getFieldProps('fontColor')}
                                      />
                                      {formik.errors.fontColor && formik.touched.fontColor ? (
                                        <div className='text-danger pt-1 ' style={{ fontSize: '12px' }}>
                                          {formik.errors.fontColor}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <div className='d-flex flex-column'>
                                <div className='d-flex flex-row w-full justify-content-between align-items-center'>
                                  <label htmlFor=''>Associations</label>
                                  <RoleBaseAccess moduleName='settings' isSubmit>
                                    <button
                                      type='button'
                                      className='btn btn-primary'
                                      onClick={() => {
                                        setVisible(true);
                                        // formik.setFieldValue("associations", [...formik.values.associations, { logoPath: "", writeup: "" }])
                                        //setAssociations([...associations, { logoPath: '', writeUp: '' }]);
                                      }}
                                    >
                                      Add Association
                                    </button>
                                  </RoleBaseAccess>
                                </div>
                                <div className='row'>
                                  <div className='col-lg-12'>
                                    <RoleBaseAccess moduleName='settings'>
                                      <Associations
                                        isDropdown
                                        showModal={visible}
                                        setShowModal={setVisible}
                                        clientFormik={formik}
                                      />
                                    </RoleBaseAccess>
                                  </div>
                                  <div className='col-lg-12'>
                                    <CustomDataTable
                                      columns={columns}
                                      data={formik.values.associations}
                                      isHideSearch
                                      isHideEditAction
                                      isHideArchieveAction
                                      onDelete={(id: string) => setShowDialog({ display: true, id: id })}
                                      isShowActionColumns
                                    />
                                  </div>
                                </div>
                                {/* {associations.map((association: any, index: number) => (
                                <div
                                  key={index}
                                  className='d-flex flex-column mb-2 gap-2 align-items-end'
                                >
                                  <StyledButton
                                    type='button'
                                    className='btn btn-xs btn-danger'
                                    onClick={() => {
                                      setAssociations(associations.filter((_:any, i: number) => i !== index));
                                    }}
                                  >
                                    Remove
                                  </StyledButton>
                                  <input
                                    type='file'
                                    className='form-control'
                                    placeholder='Logo Path'
                                    accept='image/*'
                                    onChange={(e) => {
                                      if (e.target?.files === null) return;
                                      const file = e.target.files[0];
                                      if (file) {
                                        setIsPic(file);
                                        // Check if the selected file is an image (you can add more image types if needed)
                                        if (file.type.startsWith('image/')) {
                                          // Set the selected image in state
                                          association.logoPath = file;
                                        } else {
                                          toast.error('Please Select a image file');
                                        }
                                      }

                                      // setAssociations([...associations, association]);
                                    }}
                                  />
                                  <textarea
                                    rows={3}
                                    className='form-control'
                                    placeholder='Writeup'
                                    // {...formik.getFieldProps(`associations[${index}].writeup`)}
                                    onChange={(e) => {
                                      association.writeUp = e.target.value;
                                      setAssociations([...associations]);
                                    }}
                                    defaultValue={association.writeUp}
                                    required={true}
                                  />
                                </div>
                              ))} */}
                              </div>
                            </div>
                          </div>

                          <div className='col-12'>
                            <div className=' mt-5' />
                            <div className='d-flex flex-row justify-content-end gap-4'>
                              <RoleBaseAccess moduleName='Profiles' isSubmit>
                                <button className='btn btn-primary' type='submit'>
                                  {!inProcess ? (
                                    !isEdit ? (
                                      <span className='px-4'> Add Client</span>
                                    ) : (
                                      <span className='px-4'> Update</span>
                                    )
                                  ) : (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                      Please wait...{' '}
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  )}
                                </button>
                              </RoleBaseAccess>
                              <button className='btn btn-dark' type='reset' onClick={handleClose}>
                                <span className='px-4'> Cancel</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </CardContainer>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default CreateClientModal;
