/* eslint-disable react/jsx-no-target-blank */
import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AsideMenuItem } from './AsideMenuItem';

import { faBriefcase, faBuilding, faBuildingUser, faEnvelope, faFile, faGears, faMessage, faShieldHalved, faUser, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
type Props = {
  isSideBarCollapsed: boolean;
  isSideBarFocusLost: boolean;
};

export function AsideMenuMain({ isSideBarCollapsed, isSideBarFocusLost }: Props) {
  const intl = useIntl();
  const permissionData = useSelector((state: any) => state.auth);
  let moduldePermision = permissionData?.user?.userPersonalPermissions;

  const { pathname } = useLocation();
  const permissionsList = [
    { name: 'Job Administration', link: '/administration/jobs', moduleName: 'Job', htmlItem: <FontAwesomeIcon icon={faBriefcase} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("administration") && 'active'}`} /> },
    { name: 'Vault Access', link: '/vault/home', moduleName: 'Vault', htmlItem: <FontAwesomeIcon icon={faShieldHalved} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("vault") && 'active'}`} /> },
    { name: 'Profiles', link: '/profile/listing', moduleName: 'Profiles', htmlItem: <FontAwesomeIcon icon={faUser} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("profile") && 'active'}`} /> },
    { name: 'FM Register', link: '/fm/listing', moduleName: 'FM Register', htmlItem: <FontAwesomeIcon icon={faBuildingUser} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("fm") && 'active'}`} /> },
    { name: 'Buildings Register', link: '/buildings/list', moduleName: 'Buildings Register', htmlItem: <FontAwesomeIcon icon={faBuilding} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("buildings") && 'active'}`} /> },
    { name: 'Operative Register', link: '/operative/list', moduleName: 'Operatives', htmlItem: <FontAwesomeIcon icon={faUserGear} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("operative") && 'active'}`} /> },
    { name: 'News', link: '/news/list', moduleName: 'News', htmlItem: <FontAwesomeIcon icon={faEnvelope} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("news") && 'active'}`} /> },
    { name: 'Forum', link: '/forum/listing', moduleName: 'Forum', htmlItem: <FontAwesomeIcon icon={faMessage} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("forum") && 'active'}`} /> },
    { name: 'Report Library', link: '/reports/listing', moduleName: 'Report Library', htmlItem: <FontAwesomeIcon icon={faFile} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("reports") && 'active'}`} /> },
    { name: 'Settings', link: '/settings/list', moduleName: 'Settings', htmlItem: <FontAwesomeIcon icon={faGears} style={{ height: isSideBarCollapsed && isSideBarFocusLost ? '2rem' : '14px' }} className={`${pathname.includes("settings") && 'active'}`} /> },
  ];


  const renderData = () => {
    return (
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Application</span>
          </div>
        </div>

        {permissionsList.map((module, index) => {
          return (
            (
              <RoleBaseAccess moduleName={module.moduleName}>
                {isSideBarCollapsed && isSideBarFocusLost ? (
                  <div className='ps-7 mb-8'>{module.htmlItem}</div>
                )
                  :
                  <div className='d-flex py-3'>
                    <div className='ps-7 me-3'>{module.htmlItem}</div>
                    <AsideMenuItem
                      key={index}
                      title={
                        module.name === 'Job Administration'
                          ? 'Job'
                          : module?.name === 'Vault Access'
                            ? 'Vault'
                            : module.name === 'Operative Register'
                              ? 'Operatives'
                              : module?.name
                      }
                      to={module?.link}
                      hasBullet={false}
                    />
                  </div>
                }
              </RoleBaseAccess>

            )
          );
        })}
      </>
    );
    // }
  };

  let role = ['superadmin', 'operative'];
  return renderData();
}
