import ImageUploader from 'app/components/ImageUploader';
import { success } from 'app/components/toast';
import {
  rectifyAccessPanelDefect,
  rectifyAccessibilityDefect,
  rectifyFirebarrierDefect,
  rectifyInstallationDefect,
  rectifyTestDefect,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { FC, useState } from 'react';

interface Props {
  assetDetails: any;
  selectedDefect: any;
  isAccessibility?: boolean;
  isFireBarrier?: boolean;
  isAccessPanel?: boolean;
  isInstallation?: boolean;
  isTest?: boolean;
  setOpen?: any;
}

const RectifyFlow: FC<Props> = ({
  assetDetails,
  selectedDefect,
  setOpen = false,
  isAccessibility = false,
  isFireBarrier = false,
  isAccessPanel = false,
  isInstallation = false,
  isTest = false,
}) => {
  const [details, setDetails] = useState('');
  const [imgUrl1, setImgUrl1] = useState('');
  const [imgUrl2, setImgUrl2] = useState('');

  const previousData = (title: any) => {
    return {
      previouslyChangedBy: assetDetails.lastModifiedBy._id
        ? assetDetails.lastModifiedBy._id
        : assetDetails.lastModifiedBy,
      previouslyChangedOn: assetDetails?.updatedAt,
      lastOperation: title,
      previousOperation: assetDetails.lastOperation || '',
    };
  };

  const handleSubmit = async () => {
    if (isAccessibility) {
      const res = await rectifyAccessibilityDefect({
        defect: assetDetails?.accessibility?.defects?.some(
          ({ defectRectification }: any) => defectRectification?.defectRectified === false
        )
          ? true
          : false,
        defectId: selectedDefect?._id,
        buildingAssetId: assetDetails?._id,
        inspectionJobId: assetDetails?.inspectionJob,
        buildingid: assetDetails?.building,
        rectificationImages: [imgUrl1, imgUrl2],
        ...previousData('rectifyAccessibilityDefect'),
      });
      if (res.status === 'Success') {
        success('Accessibilty Defect Rectified!');
        setOpen(false);
      }
    }
    if (isFireBarrier) {
      const res = await rectifyFirebarrierDefect({
        defect: assetDetails?.fireBarrier?.defects?.some(
          ({ defectRectification }: any) => defectRectification?.defectRectified === false
        )
          ? true
          : false,
        defectId: selectedDefect?._id,
        buildingAssetId: assetDetails?._id,
        inspectionJobId: assetDetails?.inspectionJob,
        buildingid: assetDetails?.building,
        rectificationImages: [imgUrl1, imgUrl2],
        ...previousData('rectifyFirebarrierDefect'),
      });
      if (res.status === 'Success') {
        success('FireBarrier Defect Rectified!');
        setOpen(false);
      }
    }
    if (isInstallation) {
      const res = await rectifyInstallationDefect({
        defect: assetDetails?.installation?.defects?.some(
          ({ defectRectification }: any) => defectRectification?.defectRectified === false
        )
          ? true
          : false,
        defectId: selectedDefect?._id,
        buildingAssetId: assetDetails?._id,
        buildingid: assetDetails?.building,
        inspectionJobId: assetDetails?.inspectionJob,
        rectificationImages: [imgUrl1, imgUrl2],
        ...previousData('rectifyInstallationDefect'),
      });
      if (res.status === 'Success') {
        success('Installation Defect Rectified!');
        setOpen(false);
      }
    }
    if (isAccessPanel) {
      const res = await rectifyAccessPanelDefect({
        defect: assetDetails?.accessPanel?.defects?.some(
          ({ defectRectification }: any) => defectRectification?.defectRectified === false
        )
          ? true
          : false,
        defectId: selectedDefect?._id,
        buildingid: assetDetails?.building,
        inspectionJobId: assetDetails?.inspectionJob,
        buildingAssetId: assetDetails?._id,
        rectificationImages: [imgUrl1, imgUrl2],
        ...previousData('rectifyAccessPanelDefect'),
      });
      if (res.status === 'Success') {
        success('Access Panel Defect Rectified!');
        setOpen(false);
      }
    }
    if (isTest) {
      const res = await rectifyTestDefect({
        defect: assetDetails?.tests?.defects?.some(
          ({ defectRectification }: any) => defectRectification?.defectRectified === false
        )
          ? true
          : false,
        defectId: selectedDefect?._id,
        buildingid: assetDetails?.building,
        buildingAssetId: assetDetails?._id,
        rectificationImages: [imgUrl1, imgUrl2],
        testid: assetDetails?.tests[0]?._id,
        satisffactoryStatus: 'satisfactory',
        defectDetails: 'Fixed',
        satisfactoryImages: [imgUrl1, imgUrl2],
        ...previousData('rectifyTestDefect'),
      });
      if (res.status === 'Success') {
        setOpen(false);
        success('Test Defect Rectified!');
      }
    }
  };

  return (
    <div>
      <div className='ps-5 pe-5 pt-5'>
        <h3 className='my-4 font-normal'>Details</h3>
        <textarea
          onChange={(e) => {
            setDetails(e.target?.value);
          }}
          rows={5}
          value={details}
          name='defectSubcategory'
          className='my-2 form-control'
        />
        <h3 className='my-4 font-normal'>Add Photo</h3>
        <div className='d-flex flex-row justify-content-start align-items-center '>
          <ImageUploader
            img={imgUrl1}
            building={assetDetails}
            setImageUrl={setImgUrl1}
            isRectifyImg
          />
          <ImageUploader
            img={imgUrl2}
            building={assetDetails}
            setImageUrl={setImgUrl2}
            isRectifyImg
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', margin: '20px' }}>
          <button className='btn btn-primary' onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RectifyFlow;
