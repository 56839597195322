import {useState, useEffect} from 'react'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {UpdateDesclaimer, GetDisclaimer} from 'lib/SettingsApis'
import {DisclaimerProps} from 'lib/Types'
import {toast} from 'react-toastify'
import {useForm, SubmitHandler} from 'react-hook-form'
import ReportCategory from 'app/components/Settings/ReportCategory'
import SettingHeader from 'app/components/GenericComponents/SettingHeader'
import {Editor} from 'primereact/editor'

const DamperClassification = () => {
  const [isLoading, setLoading] = useState<boolean>(true)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [parentId, setParentId] = useState<string>('')
  const [discRptSubSectionDescription, setDiscRptSubSectionDescription]: any = useState('')

  const validationSchema = Yup.object({
    discRptSubSectionTitle: Yup.string().required('Title is required'),
    discRptSubSectionDescription: Yup.string().required('Description is required'),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      discRptSubSectionTitle: '',
      discRptSubSectionDescription: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<DisclaimerProps> = async (data) => {
    setSaveLoading(true)
    let res = await UpdateDesclaimer(data, parentId)
    if (res) {
      setSaveLoading(false)
      setIsEdit(false)
      toast.success(res.message)
    } else {
      toast.error(res.message)
    }
  }

  useEffect(() => {
    ;(async function fetch() {
      try {
        setLoading(true)
        let res = await GetDisclaimer()
        if (res?.status.toLowerCase() === 'success') {
          const data = res.data?.disclaimersReportPrefixs?.[0] || {}
          const _id = data?._id || ''

          setValue('discRptSubSectionTitle', data.discRptSubSectionTitle)

          setDiscRptSubSectionDescription(data.discRptSubSectionDescription || '')
          setValue('discRptSubSectionDescription', data.discRptSubSectionDescription)

          setParentId(_id)
        }
      } catch (error: any) {
        throw new Error(error?.message)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <>
      {!isLoading ? (
        <div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='card card-custom h-100'>
                <div className='card-header'>
                  <div className='card-title'>
                    <div className='card-label'>Category</div>
                  </div>
                </div>
                <div className='card-body'>
                  <ReportCategory />
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='card card-custom h-100'>
                  <div className='card-header border-bottom'>
                    <div className='card-title w-100 d-flex justify-content-between align-items-center'>
                      <div className='card-label'>Disclaimer</div>
                      <SettingHeader
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        saveLoading={saveLoading}
                      />
                    </div>
                  </div>
                  <div className='card-body'>
                    <div>
                      <div className='pb-5 '>
                        <div>
                          <div className='form-group'>
                            <label className='pb-1 text-xl' htmlFor='accessibilityName'>
                              Title
                            </label>
                            <input
                              {...register('discRptSubSectionTitle')}
                              className='form-control w-50'
                              onChange={(e) => {
                                setValue('discRptSubSectionTitle', e.currentTarget.value)
                              }}
                            />

                            {errors?.discRptSubSectionTitle?.message && (
                              <p className='text-danger'>
                                {errors?.discRptSubSectionTitle?.message}
                              </p>
                            )}
                          </div>

                          <div className='form-group'>
                            <label className='pb-1 text-xl' htmlFor='accessibilityName'>
                              Description
                            </label>
                            <Editor
                              value={discRptSubSectionDescription}
                              onTextChange={(e: any) => {
                                if (e.htmlValue) {
                                  setValue(`discRptSubSectionDescription`, e.htmlValue)
                                } else {
                                  setValue(`discRptSubSectionDescription`, '')
                                }
                              }}
                              style={{height: '320px'}}
                            />

                            {errors?.discRptSubSectionDescription?.message && (
                              <p className='text-danger'>
                                {errors?.discRptSubSectionDescription?.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-lg-12'>
            <div className='card card-custom'>
              <div className='card-body'>
                <div className='d-flex justify-content-center'>
                  <div className='spinner-border text-primary' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DamperClassification
