/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import styled from 'styled-components';
import { useLayout } from '../core';
// import {Link} from 'react-router-dom'
// import { toAbsoluteUrl} from '../../helpers'
const Logo = styled.img`
  width: 3%;
  padding: 2px;
`;

const Footer: FC = () => {
  const { classes } = useLayout();

  return (
    <div style={{ display: 'block', justifyContent: 'center' }}>
      <div className='footer py-4 flex-lg-column text-center' id='kt_footer'>
        {/* begin::Container */}
        <div
          className={`${classes.footerContainer} flex-column flex-md-row align-items-center justify-content-between`}
        >
          {/* begin::Copyright */}
          <div className='text-dark order-2 order-md-1'>
            <span className='text-muted fw-bold me-2 text-gray-400'>
              <a href='#' className='text-gray-400 text-hover-primary'>
                essential vault
              </a>
              <span className='px-1'>&copy;</span>
              {new Date().getFullYear()}
            </span>
          </div>
          {/* end::Copyright */}

          {/* begin::Nav */}
          <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 d-none'>
            <li className='menu-item'>
              <a href='#' className='menu-link ps-0 pe-2'>
                About
              </a>
            </li>
            <li className='menu-item'>
              <a href='#' className='menu-link pe-0 pe-2'>
                Contact
              </a>
            </li>
            <li className='menu-item'>
              <a href='#' className='menu-link pe-0'>
                Purchase
              </a>
            </li>
          </ul>
          {/* end::Nav */}
        </div>
        {/* end::Container */}
      </div>
    </div>
  );
};


export { Footer };

