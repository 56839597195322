export const SET_CLIENT_ID = 'SET_CLIENT_ID'
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA'

export const actions = {
  setClientId: (data: any) => {
    return {
      type: SET_CLIENT_ID,
      payload: data,
    }
  },
  setClientData: (data: any) => {
    return {
      type: SET_CLIENT_DATA,
      payload: data,
    }
  },
}

const initialState = {
  clientId: null,
  clientData: {},
}

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_CLIENT_ID:
      return {...state, clientId: action.payload}
    case SET_CLIENT_DATA:
      return {...state, clientData: action.payload}
    default:
      return state
  }
}
