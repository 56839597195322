import { Dispatch, FC, SetStateAction } from 'react';
import RoleBaseAccess from '../shared/roleBaseAccess.component';

interface MyComponentProps {
  // Define the type of the prop using React.Dispatch
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  saveLoading: boolean;
  isEdit: boolean;
}

const SettingHeader: FC<MyComponentProps> = ({ isEdit, setIsEdit, saveLoading }) => {
  return (
    <div>
      <div className='d-flex'>
        <RoleBaseAccess moduleName='settings' isSubmit>
          <button type='submit' className=' ms-3 btn btn-primary'>
            {saveLoading ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              'Update'
            )}
          </button>
        </RoleBaseAccess>
        {/* <button
          type='button'
          onClick={() => (isEdit ? setIsEdit(false) : setIsEdit(true))}
          className='border rounded px-10 pt-2 bg-transparent py-1'
        >
          {isEdit ? 'Cancel' : 'Edit'}
        </button>
        <button
          disabled={isEdit ? false : true}
          type='submit'
          className=' ms-3 btn btn-primary py-1'
        >
          {saveLoading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : isEdit ? (
            'Update'
          ) : (
            'Submit'
          )}
        </button> */}
      </div>
    </div>
  );
};

export default SettingHeader;
