import axios from 'axios'
import {axiosInstance} from '../../../App'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/users/signin`
export const REGISTER_URL = `${API_URL}/users/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/users/forgot-password`
export const VERIFY_CODE_URL = `${API_URL}/users/verify-code`
export const RESET_PASSWORD_URL = `${API_URL}/users/change-password-after-code-verification`

// Server should return AuthModel
export const login = async (data: any) => {
  const res = await axiosInstance.post(LOGIN_URL, data)
  return res.data
}

// Server should return AuthModel
export function register(data: any) {
  return axiosInstance.post<AuthModel>(REGISTER_URL, data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export async function requestPassword(email: string) {
  const res = await axiosInstance.post<{result: any}>(REQUEST_PASSWORD_URL, {email})
  return res.data
}

export async function verifyCode(verification_code: string, email: any) {
  const res = await axiosInstance.post<{result: any}>(VERIFY_CODE_URL, {verification_code, email})
  return res.data
}

export async function resetPassword(password: string) {
  const res = await axiosInstance.post<{result: any}>(RESET_PASSWORD_URL, {password})
  return res.data
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
