/* eslint-disable jsx-a11y/anchor-is-valid */
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import {useLocation, useParams} from 'react-router-dom'
import './dataTable.css'
const RemoteDataTable = ({
  data = [],
  columns = [],
  keyField,
  search = true,
  count,
  isShow,
}: {
  data: any[]
  columns: any
  keyField: string
  search?: boolean
  count?: number
  isShow?: boolean
}) => {
  const pageButtonRenderer = ({
    page,
    active,
    // disable,
    // title,
    onPageChange,
  }: {
    page: number
    active: boolean
    disable: boolean
    title: string
    onPageChange: (page: number) => void
  }) => {
    const handleClick = (e: any) => {
      e.preventDefault()
      onPageChange(page)
    }
    const activeStyle: any = {}
    if (active) {
      activeStyle.backgroundColor = 'black'
      activeStyle.color = 'white'
    } else {
      activeStyle.backgroundColor = 'gray'
      activeStyle.color = 'black'
    }
    if (typeof page === 'string') {
      activeStyle.backgroundColor = 'white'
      activeStyle.color = 'black'
    }
    return (
      <li className='page-item'>
        <a href='#' onClick={handleClick} style={activeStyle} className='px-4 py-2'>
          {page}
        </a>
      </li>
    )
  }

  // const {SearchBar} = Search

  const options: any = {
    sizePerPageList: [
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'All',
        value: data?.length,
      },
    ],
    pageButtonRenderer,
  }

  function customMatchFunc(props: any) {}

  const rowStyle = (row: number, rowIndex: number) => {
    const isEvenRow = rowIndex % 2 === 0
    return {
      backgroundColor: isEvenRow ? '#ffffff' : '#f2f2f2',
      // Set colors for even and odd rows
    }
  }

  const MySearch = (props: any) => {
    let input: any
    return (
      <div className='d-flex align-items-center  px-2 rounded bg-light'>
        <i className='bi bi-search'></i>
        <input
          className='form-control border-0 bg-transparent '
          placeholder='Search here'
          style={{backgroundColor: ''}}
          onChange={(e) => props.onSearch(e.target.value)}
          ref={(n) => (input = n)}
          type='text'
        />
      </div>
    )
  }

  // const {SearchBar} = Search
  type QuizParams = {
    id: string
  }

  const {pathname} = useLocation()
  const {id} = useParams<QuizParams>()

  return (
    <ToolkitProvider keyField={keyField} data={data} columns={columns} search={{customMatchFunc}}>
      {({searchProps, baseProps}: {searchProps: any; baseProps: any}) => (
        <>
          {search && (
            <>
              <div className='d-flex justify-content-between align-items-center w-100'>
                {pathname === '/fm/listing' && (
                  <div>
                    <h2 style={{fontSize: '18px', fontWeight: 600}}>FM</h2>
                  </div>
                )}
                {isShow === true ? (
                  <div
                    className={`my-4 ${
                      pathname === `/vault/building-details/${id}` ? 'w-500px' : 'w-300px'
                    } `}
                  >
                    <MySearch {...searchProps} delay={1000} />
                  </div>
                ) : null}
                {pathname === `/vault/building-details/${id}` && (
                  <div className='w-full '>
                    <ul className='nav  nav-bold  text-right d-flex justify-content-end align-items-end'>
                      <li className='nav-item'>
                        <div
                          className={`nav-link cursor-pointer`}
                          // onClick={() => setSelectedTab(0)}
                        >
                          <span className='nav-text text-black'>FIRE DAMPERS ASSETS</span>
                        </div>
                      </li>
                      <li className='nav-item'>
                        <div className={`nav-link cursor-pointer`}>
                          <span className='nav-text text-black'>SMOKE VENTS ASSETS</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          )}
          <hr className='' />
          <div style={{marginTop: '-13px'}}>
            <BootstrapTable
              {...baseProps}
              // striped
              hover
              pagination={paginationFactory(options)}
              condensed
              rowStyle={rowStyle}
              headerClasses='tableHeader'
            />
          </div>
          {/* <BootstrapTable
            bootstrap4
            keyField={keyField}
            data={data}
            columns={columns}
            pagination={paginationFactory(options)} /> */}
        </>
      )}
    </ToolkitProvider>
  )
}

export default RemoteDataTable
