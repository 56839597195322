import PpmCategory from 'app/components/Settings/PpmCategory';
import { useEffect, useState } from 'react';
// import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
// import { useForm, SubmitHandler, useFieldArray } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';

import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { GetDamper, UpdateDamper } from 'lib/SettingsApis';
import { DamperProps } from 'lib/Types';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import './style.css';

const DamperClassification = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [parentId, setParentId] = useState<string>('');
  const [colorChange, setColorChnage] = useState<number>();

  // Define the validation schema with Yup
  const validationSchema = Yup.object({
    dynamicInputs: Yup.array().of(
      Yup.object().shape({
        dampClsSubSectionTitle: Yup.string().required('Title is required'),
        dampClsSubSectionDescription: Yup.string().required('Description is required'),
      })
    ),
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      dynamicInputs: [{ dampClsSubSectionTitle: '', dampClsSubSectionDescription: '' }],
    },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dynamicInputs',
  });

  const onSubmit: SubmitHandler<DamperProps> = async (data) => {
    setSaveLoading(true);
    let res = await UpdateDamper(data?.dynamicInputs, parentId);
    if (res) {
      setSaveLoading(false);
      setIsEdit(false);
      toast.success(res.message);
    }
  };

  useEffect(() => {
    let fetch = async () => {
      let res = await GetDamper();
      if (res) {
        let obj = Object.assign({}, ...res?.data?.damperClassifications);
        setLoading(false);
        setParentId(obj._id);
        if (obj?.dampClassSection.length > 0) {
          reset({
            dynamicInputs: obj?.dampClassSection, // Replace 'initialArray' with your actual initial array
          });
        }
      }
    };
    fetch();
  }, []);

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='card card-custom h-100'>
              <div className='card-header'>
                <div className='card-title'>
                  <div className='card-label'>Category</div>
                </div>
              </div>
              <div className='card-body'>
                <PpmCategory />
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='card card-custom h-100'>
                <div className='card-header border-bottom'>
                  <div className='card-title w-100 d-flex justify-content-between align-items-center'>
                    <div className='card-label'>Damper Classification</div>
                    <RoleBaseAccess moduleName='settings' isSubmit>
                      <div className='d-flex'>
                        <button
                          onClick={() => setIsEdit(true)}
                          className='border rounded px-10 pt-2 bg-transparent py-1'
                        >
                          Edit
                        </button>
                        <button type='submit' className=' ms-3 btn btn-primary py-1'>
                          {saveLoading ? (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          ) : isEdit ? (
                            'Update'
                          ) : (
                            'Submit'
                          )}
                        </button>
                      </div>
                    </RoleBaseAccess>
                  </div>
                </div>
                <div className='card-body'>
                  <div>
                    {fields.map((field, index: number) => (
                      <div className='pb-5 '>
                        <div key={index}>
                          <div className='form-group'>
                            <label className='pb-1 text-xl' htmlFor='accessibilityName'>
                              Title
                            </label>
                            <input
                              disabled={isEdit ? false : true}
                              className='form-control w-50'
                              {...register(
                                `dynamicInputs.${index}.dampClsSubSectionTitle` as const
                              )}
                              defaultValue={field.dampClsSubSectionTitle}
                            />

                            {errors.dynamicInputs?.[index]?.dampClsSubSectionTitle && (
                              <p className='text-danger'>
                                {errors.dynamicInputs[index]?.dampClsSubSectionTitle?.message}
                              </p>
                            )}
                          </div>
                          <div className='d-flex justify-content-end align-items-end'>
                            <button
                              disabled={isEdit ? false : true}
                              type='button'
                              onClick={() => setColorChnage(index)}
                              className='btn btn-primary'
                            >
                              <span className='px-4'>Highlight text box</span>
                            </button>
                          </div>
                          <div className='form-group'>
                            <label className='pb-1 text-xl' htmlFor='accessibilityName'>
                              Description
                            </label>
                            <textarea
                              disabled={isEdit ? false : true}
                              {...register(
                                `dynamicInputs.${index}.dampClsSubSectionDescription` as const
                              )}
                              defaultValue={field.dampClsSubSectionDescription}
                              className={` ${index === colorChange && 'bg-light'}  form-control`}
                              rows={10}
                            ></textarea>

                            {errors.dynamicInputs?.[index]?.dampClsSubSectionDescription && (
                              <p className='text-danger'>
                                {errors.dynamicInputs[index]?.dampClsSubSectionDescription?.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <button
                            type='button'
                            disabled={isEdit ? false : true}
                            onClick={() =>
                              append({ dampClsSubSectionTitle: '', dampClsSubSectionDescription: '' })
                            }
                            className='w-100 h-40px btn '
                            style={{
                              backgroundColor: '#DD6939',
                              borderRadius: '5px',
                              color: 'white',
                            }}
                          >
                            <div className='d-flex justify-content-center align-items-center'>
                              <i className='bi bi-plus-circle px-3 text-white'></i>
                              Add new section
                            </div>
                          </button>
                        </div>
                        {fields?.length > 1 && (
                          <div className='pt-6 hr_row'>
                            <hr />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
};

export default DamperClassification;
