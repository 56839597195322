import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Vault from '.';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import AssetsArchived from './AssetsArchived';
import AssetsDetails from './AssetsDetails';
import BuildingDetails from './BuildingDetails';
import SnapshotDetails from './SnapshotDetails';

const vaultBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/vault/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const vaultBreadCrumb2: Array<PageLink> = [
  {
    title: 'Asset Listing',
    path: '/vault/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

export const RoutePath = {
  home: '/vault/home',
  buildingDetails: '/vault/building-details/:id',
  assetsDetails: '/vault/assets-details/:id',
  snapshotDetails: '/vault/snapshot-details/:id',
  snapshotAssetsDetails: '/vault/snapshotassets-details/:id',
  archivedAssets: '/vault/archivedassets/',
  archivedAssetsDetails: '/vault/archivedassetsdetails/:id',
};

const VaultPage: React.FC = () => {
  return (
    <Switch>
      <Route path={RoutePath.home}>
        <PageTitle breadcrumbs={vaultBreadCrumbs}>Vault</PageTitle>
        <Vault />
      </Route>
      <Route path={RoutePath.buildingDetails}>
        <PageTitle breadcrumbs={vaultBreadCrumb2}>Vault</PageTitle>
        <BuildingDetails />
      </Route>
      <Route path={RoutePath.assetsDetails}>
        <PageTitle breadcrumbs={vaultBreadCrumbs}>Vault</PageTitle>
        <AssetsDetails />
      </Route>
      <Route path={RoutePath.snapshotDetails}>
        {/* <PageTitle breadcrumbs={vaultBreadCrumbs}>Asset Details</PageTitle> */}
        <SnapshotDetails />
      </Route>
      <Route path={RoutePath.archivedAssets}>
        <AssetsArchived />
      </Route>
      <Redirect from='/vault' exact={true} to='/vault/home' />
      <Redirect to='/vault/home' />
    </Switch>
  );
};

export default VaultPage;
