import { toAbsoluteUrl } from '_metronic/helpers';
import DialogueBox from 'app/components/DialogueBox';
import { error, success } from 'app/components/toast';
import { UserArgsProps, userArgs } from 'app/modules/auth/components/Registration';
import { ClientDetailedProps } from 'app/modules/auth/redux/Types';
import { getAllUsersByRole, getClients, removeClient } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { SET_CLIENT_MODAL_VISIBLE } from 'app/modules/auth/redux/jobs/actions';
import { Roles } from 'constants/Utils';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap-v5';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CreateClientModal from './CreateClientModal';
import ViewClient from './ViewClient';

import LoaderComman from 'app/components/shared/loader.component';
import ProfilesDataTable from '../../components/data-table/customDataTable';

const StyledImage = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 50%;
`;

export const clientsArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  pageNum: 1,
  search: '',
  limit: 10,
  query: {
    critarion: {
      active: true,
    },
    fields: 'clientName',
  },
};

export const clientsDetailedArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  pageNum: 1,
  search: '',
  limit: 1000,
  query: {
    critarion: {
      active: true,
    },
    fields: 'clientName',
  },
};

export const clientRepresentativeArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 1000,
  query: {
    critarion: {
      active: true,
    },
    fields: '',
  },
};

export interface AssociationProps {
  logoPath: File;
  writeup: string;
}

export interface CreateClientProps {
  // user: string;
  clientName: string;
  // businessName: string
  website: string;
  companyRegisterationNo: string;
  VATNumber: string;
  logoPath: string;
  themeColor: string;
  fontColor?: string;
  associations: any[];
  clientReference: string;
  address: string;
  clientEmail: string;
  phoneNumber: string;
  buildings?: [];
  rubberStamp: '';
}

export interface UserProps {
  _id: string;
  name: string;
  email: string;
}

export const clientDetailedArgs = {
  critarion: {
    _id: '',
  },
  clientUserFields: '_id email name',
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

const Clients = () => {
  const [clientsData, setClientsData] = useState<ClientDetailedProps[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>('');
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortProperty, setSortProperty] = useState<string>('businessName');
  const dispatch = useDispatch();
  const { clientModalVisible } = useSelector((state: any) => state.job);
  const [showModal, setShowModal] = useState(clientModalVisible ?? false);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });

  const [totalRecords, setTotalRecords] = useState(0);
  const [firstRow, setFirstRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isTableLoading, setTableLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [sortField, setSortField] = useState('businessName');
  const [isChanged, setChanged] = useState(false);

  const getUsersByRole = async () => {
    const args: UserArgsProps = userArgs;
    args.query.critarion.role = Roles.operative;
    const res = await getAllUsersByRole(args);
    return res.data.users || [];
  };

  const getClientsListing = async (isSetData: boolean = false) => {
    clientsArgs.query.fields = '';
    clientsArgs.query.critarion.active = true;
    clientsArgs.sortorder = sortOrder;
    clientsArgs.sortproperty = sortProperty;
    clientsArgs.pageNum = pageNumber;
    clientsArgs.limit = pageSize;
    clientsArgs.search = filterValue;

    const response = await getClients(clientsArgs);
    const activeClients = response.data.clients;
    activeClients.map((cl: any, i: number) => {
      cl.serial = i + 1;
    });
    setTotalRecords(response.data.totalRecords);
    setClientsData([]);
    setClientsData(activeClients);
    setTableLoading(false);
    return activeClients || [];
  };

  const sortBy = (event: any) => {
    const { value } = event.target;

    // if (sortOrder === 1) {
    //   setSortOrder(-1);
    // } else {
    //   setSortOrder(1);
    // }
    setSortProperty(value);
    setChanged(true);
  };

  const logoBodyTemplate = (row: any) => {
    return (
      <span className='ps-3'>
        {row?.logoPath ? (
          <StyledImage
            src={process.env.REACT_APP_IMAGE_URL + row?.logoPath}
            alt={row.clientReference}
          />
        ) : (
          <StyledImage
            src={toAbsoluteUrl('/media/avatars/placeholder.jpg')}
            alt={row?.clientName}
          />
        )}
      </span>
    );
  };

  const statusBodyTemplate = (row: any) => {
    return (
      <span className={`text-${row.active ? 'success' : 'danger'}`}>
        {row.active ? (
          <Badge className='success' color='success'>
            Active
          </Badge>
        ) : (
          <Badge className='danger' color='danger'>
            Inactive
          </Badge>
        )}
      </span>
    );
  };

  const clientColumns: any[] = [
    {
      field: 'logoPath',
      header: 'Logo',
      body: logoBodyTemplate,
      style: { minWidth: '150px' },
    },
    {
      field: 'clientReference',
      header: 'Reference',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'clientName',
      header: 'Company Name',
      sortable: true,
      style: { minWidth: '300px' },
    },
    {
      field: 'companyRegisterationNo',
      header: 'Company Reg No',
      sortable: true,
      style: { minWidth: '300px' },
    },
    {
      field: 'VATNumber',
      header: 'VAT No.',
      sortable: true,
      style: { minWidth: '250px' },
    },
    {
      field: 'active',
      header: 'Status',
      body: statusBodyTemplate,
      style: { minWidth: '150px' },
    },
  ];

  const getAllData = async () => {
    const [clients, users] = await Promise.all([getClientsListing(), getUsersByRole()]);
    setClientsData(clients);
    setUsers(users);
    setLoading(false);
  };

  const onHideModal = () => {
    dispatch({
      type: SET_CLIENT_MODAL_VISIBLE,
      payload: false,
    });
  };

  const onShow = () => {
    dispatch({
      type: SET_CLIENT_MODAL_VISIBLE,
      payload: true,
    });
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    if (isChanged) {
      getAllData();
    }
  }, [sortProperty]);

  useEffect(() => {
    setShowModal(clientModalVisible);
  }, [clientModalVisible]);

  const onView = (id: string) => {
    // dispatch({
    //   type: SET_CLIENT_ID,
    //   payload: id,
    // })
    // localStorage.setItem('clientId', id)
    setClientId(id);
    setShow(true);
    // history.push({
    //   pathname: `/profile/view/${id}`,
    //   state: {id},
    // })
  };

  const onEdit = (id: string) => {
    setClientId(id);
    setEdit(true);
    onShow();
  };

  const handleClose = () => {
    setShow(false);
  };

  const onDelete = async (id: string) => {
    setTableLoading(true);
    const res = await removeClient({ id });
    setTableLoading(false);
    if (res.status === 'Success') {
      getAllData();
      success('Profile Archived successfully');
    } else {
      error('Profile could not be Archived');
    }
  };

  useEffect(() => {
    if (isChanged) {
      getClientsListing(true);
    }
  }, [pageNumber, pageSize, sortField, sortOrder]);

  const onPageNumberChange = (e: any) => {
    setChanged(true);
    let newPageNumber = e.page + 1;

    if (pageNumber !== newPageNumber || pageSize !== e.rows) {
      setFirstRow(e.first);
      setPageNumber(newPageNumber);
      setPageSize(e.rows);
      setTableLoading(true);
    }
  };

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        setTableLoading(true);
        getClientsListing(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [filterValue]);

  const onGlobalFilterChange = (e: any) => {
    setChanged(true);
    setFilterValue(e.target.value.trim());
  };

  const onSortChange = (e: any) => {
    setChanged(true);
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
    setTableLoading(true);
  };

  const onArchive = (id: string) => {
    setDialogueBox({ flag: true, record: String(id) });
  };

  const clearFilter = () => {
    setFilterValue('');
  };

  return (
    <LoaderComman isRender={!isLoading}>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom card-stretch gutter-b'>
            <div className='card-body pt-0 pb-3'>
              <ProfilesDataTable
                dataKey='_id'
                data={clientsData}
                columns={clientColumns}
                isLoading={isTableLoading}
                isShowPaginator
                isShowPagSize
                rowCount={pageSize}
                totalRecords={totalRecords}
                firstRow={firstRow}
                onPageChange={onPageNumberChange}
                onGlobalFilterChange={onGlobalFilterChange}
                filterValue={filterValue}
                onSort={onSortChange}
                sortOrder={sortOrder}
                sortField={sortField}
                isShowActionColumns
                isShowViewAction
                onView={onView}
                onEdit={onEdit}
                onArchive={onArchive}
                clearFilterValue={clearFilter}
              />
            </div>
          </div>
        </div>

        <Dialog header="View Client" visible={show} onHide={() => setShow(false)} style={{ width: '40vw' }} draggable={false}>
          <ViewClient onClose={handleClose} clientId={clientId} />
        </Dialog>

        <Dialog draggable={false} header={`${clientId ? 'Edit' : 'Add'} Client`} visible={showModal} onHide={() => {
          dispatch({
            type: SET_CLIENT_MODAL_VISIBLE,
            payload: false,
          });
        }} style={{ width: '40vw' }}>
          <CreateClientModal
            onClose={onHideModal}
            setClientId={(val) => setClientId(val)}
            getAllData={getAllData}
            clientId={clientId}
          />
        </Dialog>

        {/* <Modal
          style={{ minWidth: 600 }}
          className='modal-sticky modal-sticky-lg modal-sticky-top-center'
          id='createBuilding'
          role='dialog'
          data-backdrop='false'
          aria-hidden='true'
          tabIndex='-1'
          show={clientModalVisible}
          animation={true}
          fade
        >
          <CreateClientModal
            onClose={onHideModal}
            setClientId={(val) => setClientId(val)}
            getAllData={getAllData}
            clientId={clientId}
          />
        </Modal> */}

        <DialogueBox
          title={'Profile Archived'}
          message={'Do you want  to archive?'}
          state={dialogueBox.flag}
          onClick={() => onDelete(dialogueBox.record)}
          handleClose={() => setDialogueBox({ flag: false, record: {} })}
        />
      </div>
    </LoaderComman>
  );
};

export default Clients;
