import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {verifyCode} from '../redux/AuthCRUD'

const initialValues = {
  verificationCode: '',
}

const verifyCodeSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .min(4, 'Minimum 4 digits')
    .max(4, 'Maximum 4 digits')
    .required('Verification code is required'),
})

export function VerifyCode() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [message, setMessage] = useState<String | undefined>('')
  const userEmail = useSelector<RootState>(({auth}) => auth.email)

  const formik = useFormik({
    initialValues,
    validationSchema: verifyCodeSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        verifyCode(values.verificationCode, userEmail)
          .then((response: any) => {
            if (response.status === `Fail`) {
              setHasErrors(true)
              setLoading(false)
              setSubmitting(false)
              setMessage(response.message)
              setStatus(response.message)
            } else if (response.status === 'Success') {
              setHasErrors(false)
              setStatus(response.message)
              setMessage(response.message)
              setLoading(false)
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Verification code is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Verify Code</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            Enter the code you received in your email.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{message}</div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{message}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group: verificationCode */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Verification Code</label>
          <input
            type='text'
            placeholder='Enter 4 digit verification code'
            autoComplete='off'
            {...formik.getFieldProps('verificationCode')}
            className={clsx('form-control form-control-lg form-control-solid')}
          />
          {formik.touched.verificationCode && formik.errors.verificationCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.verificationCode}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
