import { Dialog } from 'primereact/dialog';

export default function PreviewReportDialog({
  defectData, visible, setVisible
}) {

  return (
    <div className="card flex justify-content-center">
      <Dialog header="Overview" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} draggable={false}>
        <div className='w-100'>
          <div className='d-lg-flex justify-content-between w-100 border bg-light mt-4 py-4 px-10'>
            <div className='w-100 pt-4'>
              <h2 className='national_ass'>Total Number of Dampers:</h2>
            </div>
            <div className='w-100'>
              <div className='form-control h-40px'> {defectData?.totalNumberOfDampers}</div>
            </div>
          </div>
          <div className='mt-8'>
            <div className=' pt-4'>
              <ul className='list-unstyled'>
                <li className='border-top bg-light px-10 py-6'>
                  <div className='d-lg-flex justify-content-between align-items-center'>
                    <div style={{ maxWidth: '680px' }}>
                      <p className='service'>Complaint Dampers:</p>
                      <span className='national_ass'>
                        Compliant dampers with clear access for inspection and testing, satisfactory
                        installation in accordance with manufacturer guidance, satisfactory fire
                        barrier and successful test.
                      </span>
                    </div>
                    <div className='count_box mt-3 mt-lg-0'>{defectData?.noOfCompliantDampers}</div>
                  </div>
                </li>
                <li className='border-top px-10 py-6'>
                  <div className='d-lg-flex justify-content-between align-items-center'>
                    <div style={{ maxWidth: '680px' }}>
                      <p className='service'>Minor Defects:</p>
                      <span className='national_ass'>
                        Minor defects noted for deviations from manufacturer installation guidance or
                        recommendations to improve accessibility for testing. Remedial program of
                        works required to rectify defects.
                      </span>
                    </div>
                    <div className='count_box_2 mt-3 mt-lg-0'>{defectData?.noOfMinorDefect}</div>
                  </div>
                </li>
                <li className='border-top bg-light px-10 py-6'>
                  <div className='d-lg-flex justify-content-between align-items-center'>
                    <div style={{ maxWidth: '680px' }}>
                      <p className='service'>Major Defects:</p>
                      <span className='national_ass'>
                        Non-Compliant dampers with major defects noted following an inspection of the
                        installation & fire barrier, and attempted test. Urgent remedial actions are
                        required to rectify defects.
                      </span>
                    </div>
                    <div className='count_box_3 mt-3 mt-lg-0'>{defectData?.noOfMajorDefects}</div>
                  </div>
                </li>
                <li className='border-top border-bottom  px-10 py-6'>
                  <div className='d-lg-flex justify-content-between align-items-center'>
                    <div style={{ maxWidth: '680px' }}>
                      <p className='service'>Untested:</p>
                      <span className='national_ass'>
                        Untested dampers which have either been surveyed or have no access
                        arrangements in place.
                      </span>
                    </div>
                    <div className='count_box_4 mt-3 mt-lg-0'>{defectData?.noOfUntestedDampers}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}