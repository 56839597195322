import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import RoleBaseAccess from './shared/roleBaseAccess.component';

interface Dialogue {
  title: string;
  message: string;
  state: boolean;
  onClick: () => void;
  handleClose: () => void;
  btnLoading?: boolean;
}

const DialogueBox: React.FC<Dialogue> = ({ title, message, state, onClick, handleClose, btnLoading }) => {
  return (
    <>
      <Dialog position='top' header={title} visible={state} onHide={handleClose} draggable={false} className='confirm-dialog'>
        <p className='fs-5 fw-bold'>{message}</p>
        <div style={{ textAlign: 'end', padding: '20px' }}>
          <Button onClick={handleClose} type='button' className='btn btn-primary-bordered'>
            <span className='px-4'>No</span>
          </Button>
          <RoleBaseAccess moduleName={title.split(' ')[0]} isSubmit>
            <Button onClick={() => {
              onClick();
              if (!btnLoading) {
                handleClose();
              }
            }} type='button' className='btn btn-primary ms-4 px-10' label={btnLoading ? 'Please wait...' : 'Yes'} icon={btnLoading && 'pi pi-spin pi-spinner'} />
          </RoleBaseAccess>
        </div>
      </Dialog>
    </>
  );
};

export default DialogueBox;
