import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { getDefectSubCategories, updateDefectSubCategory } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { useFormik } from 'formik';
import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import ExpansionDataTable from './ExpansionDataTable';

const StyledButton = styled.button`
  display: flex;
  align-items: end;
  justify-content: end;
  width: fit-content;
`;

const categoryArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 50,
  query: {
    critarion: {
      active: true,
    },
    fields: 'defectCategoryName subCategories',
  },
};

export interface ISubCategory {
  defectsubCatgTitle: string;
  defectsubCatgText: string;
  defectType: string;
}

export interface IDefectCategory {
  defectCategoryid?: string;
  categoryid?: string;
  defectCategoryName?: string;
  subCategories?: ISubCategory[];
}

const defectTypesList = [
  { label: 'MAJOR', value: 'Major' },
  { label: 'MINOR', value: 'Minor' },
  { label: 'LIMITATION', value: 'Limitation' },
];

const AddDefectSubCategory = () => {
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [isEditing, setEditing] = React.useState<boolean>(false);
  const [defectCategories, setDefectCategories] = React.useState<IDefectCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>();
  const [fetchCategoryData, setCategoryData] = React.useState<any>(null);
  const [fetchResetData, setFetchResetDate] = React.useState<any>([]);
  const [selectId, setSelectId] = React.useState<any>(null);
  const [selectData, setSelectData] = React.useState<any>({});

  const handleEdit = (categoryData: any, CategoryMainInfo: any) => {
    setEditing(true);
    setCategoryData(categoryData);
    setSelectId(categoryData?._id);
    setSelectData(CategoryMainInfo);
    formik.setFieldValue('defectCategoryid', CategoryMainInfo ? CategoryMainInfo._id : '');
    formik.setFieldValue(
      'defectCategoryName',
      CategoryMainInfo ? CategoryMainInfo.defectCategoryName : ''
    );
    formik.setFieldValue('subCategories', CategoryMainInfo ? [categoryData] : []);
  };

  React.useEffect(() => {
    formik.setFieldValue('defectCategoryid', selectedCategory ? selectedCategory._id : '');
    formik.setFieldValue(
      'defectCategoryName',
      selectedCategory ? selectedCategory.defectCategoryName : ''
    );
    formik.setFieldValue('subCategories', selectedCategory ? selectedCategory.subCategories : []);

    // defectCategoryid: fetchCategoryData ? fetchCategoryData._id : '',
    // defectCategoryName: fetchCategoryData ? fetchCategoryData.defectCategoryName : '',
    // subCategories: fetchCategoryData ? fetchCategoryData.subCategories : [],
  }, []);

  // set initial values

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = {
    defectCategoryid: '',
    defectCategoryName: '',
    subCategories: [
      {
        defectType: '',
        defectsubCatgTitle: '',
        defectsubCatgText: '',
      },
    ],
    // defectCategoryid: fetchCategoryData ? fetchCategoryData._id : '',
    // defectCategoryName: fetchCategoryData ? fetchCategoryData.defectCategoryName : '',
    // subCategories: fetchCategoryData ? fetchCategoryData.subCategories : [],
  };

  // React.useEffect(() => {
  // }, [initialValues])
  const fetchCategories = async () => {
    const res = await getDefectSubCategories(categoryArgs);
    if (res.status === 'Success') {
      return res.data.defectCategorys;
    }
  };

  const getAllData = async () => {
    const [cats] = await Promise.all([fetchCategories()]);
    setDefectCategories(cats);
    setLoading(false);
  };

  React.useEffect(() => {
    getAllData();
  }, []);

  const validationSchema: any = Yup.object().shape({
    defectCategoryName: Yup.string().required('Defect Category Name is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values: IDefectCategory, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        if (isEditing) {
          let d = Object.assign({}, ...formik?.values?.subCategories);
          let check = selectData?.subCategories?.map((item: any) =>
            item._id === selectId ? { ...item, ...d } : item
          );
          let payload = {
            defectCategoryid: formik.values?.defectCategoryid,
            defectCategoryName: formik?.values?.defectCategoryName,
            subCategories: check,
          };

          const res = await updateDefectSubCategory(payload);

          if (res.status === 'Success') {
            toast.success(res.message);
            setSubmitting(false);
            setEditing(false);
            getAllData();
            onResetForm();
          }
        } else {
          let cats;
          if (values.subCategories !== undefined) {
            selectedCategory?.subCategories?.push(...values.subCategories);
            cats = selectedCategory;
            const id = cats?._id;
            delete cats._id;
            cats.defectCategoryid = isEditing ? '' : id;
          }
          const res = await updateDefectSubCategory(cats);
          if (res.status === 'Success') {
            toast.success(res.message);
            setSubmitting(false);
            setEditing(false);
            getAllData();
            onResetForm();
          }
        }
      } catch (err) {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const onResetForm = () => {
    setCategoryData(null);
    formik.resetForm();
  };

  React.useEffect(() => { }, [selectedCategory]);

  const sortBy = () => { };

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    };
  };

  const columns: any[] = [
    {
      dataField: 'serial',
      text: '#',
      sort: true,
      headerStyle: (column: any, index: number) => {
        return {
          fontWeight: 'bold',
          backgroundColor: '#F3F5F8',
          color: 'black',
          paddingLeft: '10px',
          width: '10%',
        };
      },
      formatter: (cell: any, row: any, index: number) => <div className='ps-3'>{index + 1}</div>,
    },
    {
      dataField: 'defectCategoryName',
      text: 'Defect Category',
      sort: true,
      headerStyle,
    },
  ];

  if (isLoading) {
    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body'>
              <div className='d-flex justify-content-center'>
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className='row'>
        <div className='col-lg-4'>
          <div className='card card-custom'>
            <form onSubmit={formik.handleSubmit}>
              <div className='card-header'>
                <div className='card-title'>
                  <div className='card-label'>Create Defect Category</div>
                </div>
              </div>
              <div className='card-body'>
                <div className='form-group'>
                  <label htmlFor='defectCategoryName'>Defect Category</label>
                  <Select
                    placeholder='Defect Category Name'
                    options={defectCategories}
                    isClearable
                    getOptionLabel={(option: any) => option.defectCategoryName}
                    getOptionValue={(option: any) => String(option._id)}
                    onChange={(option: any) => {
                      formik.setFieldValue('defectCategoryName', option?.defectCategoryName);
                      setSelectedCategory(option);
                    }}
                    value={defectCategories.find((cat: any) => {
                      return cat.defectCategoryName === formik.values.defectCategoryName;
                    })}
                  />
                  {formik.errors.defectCategoryName && formik.touched.defectCategoryName ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-red'>
                        {formik.errors.defectCategoryName}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='d-flex flex-row w-full justify-content-between align-items-center'>
                  <label htmlFor=''>Sub Categories</label>
                  <button
                    type='button'
                    className='border-0 '
                    style={{ backgroundColor: '#FF6600', borderRadius: '2px' }}
                    onClick={() =>
                      formik.setFieldValue('subCategories', [
                        ...formik.values.subCategories,
                        {
                          defectType: '',
                          defectsubCatgTitle: '',
                          defectsubCatgText: '',
                        },
                      ])
                    }
                  >
                    <i className='la la-plus text-lg' style={{ color: 'white' }} />
                  </button>
                </div>
                <div className='form-group'>
                  {/* <label htmlFor='defectSubCategoryName'>Defect Sub Categories</label>*/}
                  {formik?.values?.subCategories?.map((val: any, index: number) => (
                    <div
                      key={index}
                      className='form-group d-flex flex-column mb-2 mt-2   gap-2 align-items-end'
                    >
                      <StyledButton
                        type='button'
                        className='border-0 '
                        style={{ backgroundColor: 'red', borderRadius: '2px', padding: '5px 6px' }}
                        onClick={() =>
                          formik.setFieldValue(
                            'subCategories',
                            formik.values?.subCategories?.filter((_: any, i: number) => i !== index)
                          )
                        }
                      >
                        <i className='la la-trash text-lg' style={{ color: 'white' }} />
                      </StyledButton>
                      <div className='form-group col-lg-12'>
                        <label htmlFor='defectCategoryType'>Defect Category Type</label>
                        <Select
                          placeholder='Defect Category Type'
                          options={defectTypesList}
                          isClearable
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => String(option.value)}
                          // defaultValue={
                          //   formik.values.subCategories[index]?.defectType
                          //     ? {
                          //         label: formik.values.subCategories[index].defectType === "Major" ? "MAJOR" : "MINOR" ,
                          //         value: formik.values.subCategories[index].defectType,
                          //       }
                          //     : null
                          // }
                          value={defectTypesList.find(
                            (option) =>
                              option.value === formik.values.subCategories[index].defectType
                          )}
                          onChange={(option: any) => {
                            const updatedSubCategories: any = [...formik.values.subCategories];
                            updatedSubCategories[index].defectType = option?.value;

                            // Update Formik state
                            formik.setFieldValue('subCategories', updatedSubCategories);
                          }}
                        />
                      </div>
                      <div className='form-group col-lg-12'>
                        <label htmlFor='defectCategoryName'>Defect Category Title</label>
                        <input
                          type='text'
                          className='form-control'
                          {...formik.getFieldProps(`subCategories[${index}].defectsubCatgTitle`)}
                        />
                      </div>
                      <div className='col-lg-12 form-group'>
                        <label htmlFor='defectCategoryName'>Defect Category Description</label>
                        <textarea
                          rows={4}
                          className='form-control'
                          {...formik.getFieldProps(`subCategories[${index}].defectsubCatgText`)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <CardFooter
                form={formik}
                isEditing={isEditing}
                setEditing={setEditing}
                onReset={onResetForm}
              />
            </form>
          </div>
        </div>
        <div className='col-lg-8'>
          <div className='card card-custom card-stretch gutter-b'>
            <div className='card-header border-0 py-5'>
              <h3 className='card-title align-items-start flex-row'>
                <span className='card-label font-weight-bolder text-dark'>Defect Categories</span>
                <span className='text-muted mt-1 font-weight-bold font-size-sm'>
                  {defectCategories.length} Categories
                </span>
              </h3>
              {/* <div className='card-toolbar'>
                <div>
                  <select className='form-control form-control-sm' onChange={sortBy}>
                    <option value=''>Sort By</option>
                    <option value='1'>Ascending</option>
                    <option value='-1'>Descending</option>
                  </select>
                </div>
                </div>
              */}
            </div>
            <div className='card-body pt-0 pb-3'>
              <ExpansionDataTable handleEdit={handleEdit} />
            </div>
          </div>
        </div>
      </div>
    );
};

export default AddDefectSubCategory;

function CardFooter({
  form,
  isEditing,
  setEditing,
  onReset,
}: {
  form: any;
  isEditing: boolean;
  setEditing: any;
  onReset: () => void;
}) {
  return (
    <div className='card-footer d-flex justify-content-end align-items-end gap-4'>
      <RoleBaseAccess moduleName='settings' isSubmit>
        <button className='btn btn-primary' type='submit'>
          {!form.isSubmitting && (
            <span className='indicator-label px-3'>{isEditing ? 'Update' : 'Create'}</span>
          )}
          {form.isSubmitting && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </RoleBaseAccess>
      {isEditing && (
        <button
          className='btn btn-light'
          type='reset'
          onClick={() => {
            // form.resetForm();
            onReset();
            // setEditing(false)
          }}
        >
          <span className='px-3'>Cancel</span>
        </button>
      )}
    </div>
  );
}
