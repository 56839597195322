import {useLocation} from 'react-router-dom'

export const useCurrentRoute = () => {
  const location = useLocation()

  return location.pathname
}

export const TestRegime: any = [
  {
    label: 'Yearly',
    value: 'yearly',
  },
  {
    label: 'Half Yearly',
    value: 'halfYearly',
  },
  {
    label: 'Quaterly',
    value: 'quaterly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
]

export const Roles = {
  superadmin: 'superadmin',
  client: 'profile',
  facilitymanager: 'facilitymanager',
  operative: 'operative',
  representative: 'representative',
}

export const reportContent = [
  {
    sectionNo: 'Section 1',
    title: 'Summary',
    desc: 'Summary of test and inspection results with overview of satisfactory and unsatisfactory findings',
  },
  {
    sectionNo: 'Section 2',
    title: 'Asset Register',
    desc: 'List of assets located and testing results',
  },
  {
    sectionNo: 'Section 3',
    title: 'Defect Log',
    desc: 'Lists of defects identified during test and inspection with recommended remedial actions',
  },
  {
    sectionNo: 'Section 4',
    title: 'Building Assets',
    desc: 'Service sheet for each damper asset including photographic evidence of testing and details of all inspections',
  },
  {
    sectionNo: 'Section 5',
    title: 'Inventory',
    desc: 'List of damper asset specifications and accessibility',
  },
  {
    sectionNo: 'Section 6',
    title: 'Competencies',
    desc: 'List of company associations and operative competencies',
  },
]
