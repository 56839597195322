import {CreateBuildingProps} from 'app/pages/buildings/Buildings'
import {CreateJobProps} from 'app/pages/jobs/CreateJob'
import {OperativeSignupProps} from 'app/pages/operative/Operative'
import {axiosInstance, fileAxiosInstance} from '../../../../App'

export const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_JOBS_LIST = `${API_URL}/inspectionJobs/getInspectionJobsList`
export const GET_JOBS_DETAILED_LIST = `${API_URL}/inspectionJobs/getInspectionJobsWithFullDetails`
export const GET_JOB_BY_ID = `${API_URL}/inspectionJobs/findInspectionJobById`
export const GET_JOB_DEFECT_COUNTS = `${API_URL}/inspectionJobs/inspectionJobDefectCounts`
export const GET_MULTIPLE_ASSETS_BY_JOBSIDS = `${API_URL}/inspectionJobs/getBuildingAssetsForMultipleJobs`
export const REMOVE_INSPECTION_JOB = `${API_URL}/inspectionJobs/removeInspectionJob`
export const GET_CLIENTS_LISTING = `${API_URL}/clients/getClientsList`
export const GET_CLIENT_BY_ID = `${API_URL}/clients/findClientById`
export const GET_FACILITY_MANAGER_LISTING = `${API_URL}/facilityManagers/getFacilityManagersList`
export const GET_FACILITY_MANAGER_DETAILED_LISTING = `${API_URL}/facilityManagers/getFacilityManagersWithFullDetails`
export const GET_BUILDING_LISTING = `${API_URL}/buildings/getBuildingsList`
export const GET_BUILDING_LISTING_DETAILS = `${API_URL}/buildings/getBuildingsWithFullDetails`
export const GET_OPERATIVES_LISTING = `${API_URL}/operatives/getOperativesWithFullDetails`
export const GET_OPERATIVES_DETAILED_LISTING = `${API_URL}/operatives/getOperativesWithFullDetails`
export const CREATE_INSPECTION_JOB = `${API_URL}/inspectionJobs/createInspectionJob`
export const UPDATE_INSPECTION_JOB = `${API_URL}/inspectionJobs/updateInspectionJob`
export const RESCHEDULE_INSPECTION_JOB = `${API_URL}/inspectionJobs/statusChange`
export const GET_BUILDING_BY_ID = `${API_URL}/buildings/findBuildingById`
export const GET_BUILDING_ASSETS_BY_BUILDING_ID = `${API_URL}/buildingAssets/getBuildingAssetsByBuildingId`
export const GET_BUILDING_SUMMARY_COUNTS = `${API_URL}/buildings/buildingSummaryCounts`
export const GET_OPERATIVE_BY_ID = `${API_URL}/operatives/findOperativeById`
export const GET_VAULT_LIST = `${API_URL}/vaults/getVaultsList`
export const GET_VAULT_DETAILED_LIST = `${API_URL}/vaults/getVaultsWithFullDetails`
export const CREATE_BUILDING = `${API_URL}/buildings/createBuilding`
export const UPDATE_BUILDING = `${API_URL}/buildings/updateBuilding`
export const CREATE_OPERATIVE = `${API_URL}/users/signup`
export const UPDATE_OPERATIVE = `${API_URL}/operatives/updateOperative`
export const CREATE_FACILITY_MANAGER = `${API_URL}/facilityManagers/createFacilityManager`
export const UPDATE_FACILITY_MANAGER = `${API_URL}/facilityManagers/updateFacilityManager`
export const GET_ALL_USERS_BY_ROLE = `${API_URL}/users/listAllUsers`
export const REMOVE_FACILITY_MANAGER = `${API_URL}/facilityManagers/removeFacilityManager`
export const BUILDING_ASSET_BY_ID = `${API_URL}/buildingAssets/findBuildingAssetById`
export const REMOVE_BUILDING_ASSET = `${API_URL}/buildingAssets/removeBuildingAsset`
export const GET_BUILDING_ASSET = `${API_URL}/buildingAssets/getBuildingAssetsList`
export const GET_BUILDING_ASSET_WITH_FULL_DETIALS = `${API_URL}/buildingAssets/getBuildingAssetsWithFullDetails`
export const CREATE_CLIENT = `${API_URL}/clients/createClient`
export const UPDATE_CLIENT = `${API_URL}/clients/updateClient`
export const REMOVE_CLIENT = `${API_URL}/clients/removeClient`
export const GET_CLIENT_WITH_DETAIL = `${API_URL}/getClientsWithFullDetails`
export const GET_TEST_REGIME_LISTING = `${API_URL}/testRagimes/getTestRegimesList`
export const REPORT_LISTING = `${API_URL}/reportLibrary/getReportLibrarysList`
export const REPORT_DETAILED_LISTING = `${API_URL}/reportLibrary/getReportLibrarysWithFullDetails`
export const LEGACY_REPORT_DETAILED_LISTING = `${API_URL}/reportLibrary/getLegacyReportWithFullDetails`
export const GET_SPECIFIC_REPORT = `${API_URL}/reportLibrary/findReportLibraryById`
export const UPLOAD_CERT_IMAGES = `${API_URL}/operatives/uploadOperativeCertificates`
export const CREATE_REPORT = `${API_URL}/reportLibrary/createReportLibrary`
export const GENERATE_PDF = `${API_URL}/inspectionReports/generatepdf`
export const GENERATE_PDF_STORE = `${API_URL}/inspectionReports/generatepdfstore`
// export const CREATE_REPORT = `${API_URL}/inspectionJobs/publishInspectionReport`

export const REMOVE_REPORT = `${API_URL}/reportLibrary/removeReportLibrary`
export const UPDATE_REPORT = `${API_URL}/reportLibrary/updateReportLibrary`
export const UPLOAD_PROFILE_IMAGE = `${API_URL}/users/updateprofilepic`
export const UPLOAD_CLIENT_LOGO = `${API_URL}/clients/uploadClientLogo`
export const UPLOAD_RUBBER_STAMP = `${API_URL}/clients/uploadStampImage`
export const UPLOAD_BUILDING_IMAGE = `${API_URL}/buildings/uploadSingleFile`
export const DELETE_REPORT = `${API_URL}/reportLibrary/deleteReport`

// *******************NEWS APIS*******************
export const CREATE_NEWS_MESSAGE = `${API_URL}/newsMessages/createNewsMessage`
export const GET_NEWS_LISTING = `${API_URL}/newsMessages/getNewsMessagesWithFullDetails`
export const UPDATE_NEWS_MESSAGE = `${API_URL}/newsMessages/updateNewsMessage`
export const REMOVE_NEWS_MESSAGE = `${API_URL}/newsMessages/removeNewsMessage`
export const UPLOAD_NEWS_ATTACHMENT = `${API_URL}/newsMessages/uploadAttachedFile`

// **********************ACCESSIBILITY**********************
export const CREATE_ACCESSIBILITY = `${API_URL}/accessibilities/createAccessibility`
export const GET_ACCESSIBILITY_LISTING = `${API_URL}/accessibilities/getAccessibilitysList`
export const GET_ACCESSIBILITY_LISTING_WITH_DETAILS = `${API_URL}/accessibilities/getAccessibilitysWithFullDetails`
export const UPDATE_ACCESSIBILITY = `${API_URL}/accessibilities/updateAccessibility`
export const REMOVE_ACCESSIBILITY = `${API_URL}/accessibilities/removeAccessibility`
// **********************FIRE BARRIER**********************
export const CREATE_FIRE_BARRIER = `${API_URL}/firebarriers/createFireBarrier`
export const GET_FIRE_BARRIER_LISTING = `${API_URL}/firebarriers/getFireBarriersList`
export const GET_FIRE_BARRIER_LISTING_WITH_DETAILS = `${API_URL}/firebarriers/getFireBarriersWithFullDetails`
export const UPDATE_FIRE_BARRIER = `${API_URL}/firebarriers/updateFireBarrier`
export const REMOVE_FIRE_BARRIER = `${API_URL}/firebarriers/removeFireBarrier`
// **********************INSTALLATION**********************
export const CREATE_INSTALLATION = `${API_URL}/installations/createInstallation`
export const GET_INSTALLATION_LISTING = `${API_URL}/installations/getInstallationsList`
export const GET_INSTALLATION_LISTING_WITH_DETAILS = `${API_URL}/installations/getInstallationsWithFullDetails`
export const UPDATE_INSTALLATION = `${API_URL}/installations/updateInstallation`
export const REMOVE_INSTALLATION = `${API_URL}/installations/removeInstallation`
// **********************DUCT TYPES**********************
export const CREATE_DUCT_TYPE = `${API_URL}/ductTypes/createductType`
export const GET_DUCT_TYPE_LISTING = `${API_URL}/ductTypes/getDuctTypesList`
export const GET_DUCT_TYPE_LISTING_WITH_DETAILS = `${API_URL}/ductTypes/getDuctTypesWithFullDetails`
export const UPDATE_DUCT_TYPE = `${API_URL}/ductTypes/updateDuctType`
export const REMOVE_DUCT_TYPE = `${API_URL}/ductTypes/removeDuctType`
// **********************DAMPER TYPE**********************
export const CREATE_DAMPER_TYPE = `${API_URL}/damperTypes/createDamperType`
export const GET_DAMPER_TYPE_LISTING = `${API_URL}/damperTypes/getDamperTypesList`
export const GET_DAMPER_TYPE_LISTING_WITH_DETAILS = `${API_URL}/damperTypes/getDamperTypesWithFullDetails`
export const UPDATE_DAMPER_TYPE = `${API_URL}/damperTypes/updateDamperType`
export const REMOVE_DAMPER_TYPE = `${API_URL}/damperTypes/removeDamperType`
// **********************DUCT SHAPES**********************
export const CREATE_DUCT_SHAPE = `${API_URL}/ductShapes/createDuctShape`
export const GET_DUCT_SHAPE_LISTING = `${API_URL}/ductShapes/getDuctShapesList`
export const GET_DUCT_SHAPE_LISTING_WITH_DETAILS = `${API_URL}/ductShapes/getDuctShapesWithFullDetails`
export const UPDATE_DUCT_SHAPE = `${API_URL}/ductShapes/updateDuctShape`
export const REMOVE_DUCT_SHAPE = `${API_URL}/ductShapes/removeDuctShape`

// **********************TEST API's**********************
export const ADD_TEST = `${API_URL}/buildingAssets/addTest`

// **********************REPORT HEADER TEMPLATE**********************
export const CREATE_REPORT_HEADER_TEMPLATE = `${API_URL}/headerDataReports/createHeaderDataReport`
export const GET_REPORT_HEADER_TEMPLATE_LISTING = `${API_URL}/headerDataReports/getHeaderDataReportsList`
export const UPDATE_REPORT_HEADER_TEMPLATE = `${API_URL}/headerDataReports/updateHeaderDataReport`
export const REMOVE_REPORT_HEADER_TEMPLATE = `${API_URL}/headerDataReports/removeHeaderDataReport`
// **********************REPORT FOOTER TEMPLATE**********************
export const CREATE_REPORT_FOOTER_TEMPLATE = `${API_URL}/footerDataReports/createFooterDataReport`
export const GET_REPORT_FOOTER_TEMPLATE_LISTING = `${API_URL}/footerDataReports/getFooterDataReportsList`
export const UPDATE_REPORT_FOOTER_TEMPLATE = `${API_URL}/footerDataReports/updateFooterDataReport`
export const REMOVE_REPORT_FOOTER_TEMPLATE = `${API_URL}/footerDataReports/removeFooterDataReport`
// **********************REPORT GUIDANCE TEMPLATE**********************
export const CREATE_REPORT_GUIDANCE_TEMPLATE = `${API_URL}/guidanceTemplate/createGuidanceTemplate`
export const GET_REPORT_GUIDANCE_TEMPLATE_LISTING = `${API_URL}/guidanceTemplate/getGuidanceTemplatesList`
export const UPDATE_REPORT_GUIDANCE_TEMPLATE = `${API_URL}/guidanceTemplate/updateGuidanceTemplate`
export const REMOVE_REPORT_GUIDANCE_TEMPLATE = `${API_URL}/guidanceTemplate/removeGuidanceTemplate`
// **********************REPORT SERVICES TEMPLATE**********************
export const CREATE_REPORT_SERVICE_TEMPLATE = `${API_URL}/serviceTemplate/createServiceTemplate`
export const GET_REPORT_SERVICE_TEMPLATE_LISTING = `${API_URL}/serviceTemplate/getServiceTemplatesList`
export const UPDATE_REPORT_SERVICE_TEMPLATE = `${API_URL}/serviceTemplate/updateServiceTemplate`
export const REMOVE_REPORT_SERVICE_TEMPLATE = `${API_URL}/serviceTemplate/removeServiceTemplate`
// **********************REPORT DEFECT LOG TEMPLATE**********************
export const CREATE_REPORT_DEFECT_LOG_TEMPLATE = `${API_URL}/defectLogTemplate/createDefectLogTemplate`
export const GET_REPORT_DEFECT_LOG_TEMPLATE_LISTING = `${API_URL}/defectLogTemplate/getDefectLogTemplatesList`
export const UPDATE_REPORT_DEFECT_LOG_TEMPLATE = `${API_URL}/defectLogTemplate/updateDefectLogTemplate`
export const REMOVE_REPORT_DEFECT_LOG_TEMPLATE = `${API_URL}/defectLogTemplate/removeDefectLogTemplate`
// **********************REPORT TEMPLATE**********************
export const CREATE_REPORT_TEMPLATE = `${API_URL}/reportTemplate/createReportTemplates`
export const GET_REPORT_TEMPLATE_LISTING = `${API_URL}/reportTemplate/getReportTemplatessList`
export const GET_REPORT_TEMPLATE_DETAILED_LISTING = `${API_URL}/reportTemplate/getReportTemplatessWithFullDetails`
export const UPDATE_REPORT_TEMPLATE = `${API_URL}/reportTemplate/updateReportTemplates`
export const REMOVE_REPORT_TEMPLATE = `${API_URL}/reportTemplate/removeReportTemplate`
// **********************REPORT SUMMARY**********************
export const CREATE_REPORT_SUMMARY = `${API_URL}/summaryReports/createSummaryReport`
export const GET_REPORT_SUMMARY_LISTING = `${API_URL}/summaryReports/getSummaryReportsList`
export const UPDATE_REPORT_SUMMARY = `${API_URL}/summaryReports/updateSummaryReport`
export const REMOVE_REPORT_SUMMARY = `${API_URL}/summaryReports/removeSummaryReport`
export const GET_REPORT_SUMMARY_BY_ID = `${API_URL}/summaryReports/findSummaryReportById`
// **********************REPORT CREATION FLOW**********************
export const CREATE_REPORT_CERTIFICATE = `${API_URL}/certificateReports/createCertificateReport`
// **********************REPORT GUIDANCE**********************
export const CREATE_REPORT_GUIDANCE = `${API_URL}/guidanceReports/createGuidanceReport`
export const GET_REPORT_GUIDANCE_LISTING = `${API_URL}/guidanceReports/getGuidanceReportsList`
export const UPDATE_REPORT_GUIDANCE = `${API_URL}/guidanceReports/updateGuidanceReport`
export const REMOVE_REPORT_GUIDANCE = `${API_URL}/guidanceReports/removeGuidanceReport`
// **********************REPORT SERVICES**********************
export const CREATE_REPORT_SERVICE = `${API_URL}/serviceReports/createServiceReport`
export const GET_REPORT_SERVICE_LISTING = `${API_URL}/serviceReports/getServiceReportsList`
export const UPDATE_REPORT_SERVICE = `${API_URL}/serviceReports/updateServiceReport`
export const REMOVE_REPORT_SERVICE = `${API_URL}/serviceReports/removeServiceReport`
// **********************ASSET REGISTER**********************
export const CREATE_ASSET_REGISTER = `${API_URL}/assetRegisterReports/createAssetRegisterReport`
export const GET_ASSET_REGISTER_LISTING = `${API_URL}/assetRegisterReports/getAssetRegisterReportsList`
export const UPDATE_ASSET_REGISTER = `${API_URL}/assetRegisterReports/updateAssetRegisterReport`
export const REMOVE_ASSET_REGISTER = `${API_URL}/assetRegisterReports/removeAssetRegisterReport`
// **********************REPORT DEFECT LOG**********************
export const CREATE_REPORT_DEFECT_LOG = `${API_URL}/defectLogReports/createDefectLogReport`
export const GET_REPORT_DEFECT_LOG_LISTING = `${API_URL}/defectLogReports/getDefectLogReportsList`
export const UPDATE_REPORT_DEFECT_LOG = `${API_URL}/defectLogReports/updateDefectLogReport`
export const REMOVE_REPORT_DEFECT_LOG = `${API_URL}/defectLogReports/removeDefectLogReport`
// **********************BUILDING ASSET REPORT**********************
export const CREATE_BUILDING_ASSET_REPORT = `${API_URL}/buildingAssetsReports/createAssetReportBlk`
export const GET_BUILDING_ASSET_REPORT_LISTING = `${API_URL}/buildingAssetsReports/getBuildingAssetsReportsList`
export const UPDATE_BUILDING_ASSET_REPORT = `${API_URL}/buildingAssetsReports/updateBuildingAssetsReport`
export const REMOVE_BUILDING_ASSET_REPORT = `${API_URL}/buildingAssetsReports/removeBuildingAssetsReport`
// **********************REPORT INVENTORY**********************
export const CREATE_REPORT_INVENTORY = `${API_URL}/inventoryReports/createInvertoryReport`
export const GET_REPORT_INVENTORY_LISTING = `${API_URL}/inventoryReports/getInvertoryReportsList`
export const UPDATE_REPORT_INVENTORY = `${API_URL}/inventoryReports/updateInvertoryReport`
export const REMOVE_REPORT_INVENTORY = `${API_URL}/inventoryReports/removeInvertoryReport`
// **********************COMPETENCY REPORT**********************
export const GET_COMPETENCY_REPORT_LISTING = `${API_URL}/competencyReports/getCompetencyReportsList`
export const CREATE_COMPETENCY_REPORT = `${API_URL}/competencyReports/createCompetencyReport`
export const CREATE_DISCLAIMER = `${API_URL}/disclaimerReports/createDisclaimerReport`
export const UPDATE_COMPETENCY_REPORT = `${API_URL}/competencyReports/updateCompetencyReport`
export const REMOVE_COMPETENCY_REPORT = `${API_URL}/competencyReports/removeCompetencyReport`
// **********************CREATE REPORT REMEDIAL**********************
export const CREATE_CERTIFICATE_REMEDIAL_REPORT = `${API_URL}/certificateReportsRemedials/createCertificateReportsRemedials`
export const GET_CERTIFICATE_REMEDIAL_REPORT_LISTING = `${API_URL}/certificateReportsRemedials/getCertificateReportsRemedialsList`
export const UPDATE_CERTIFICATE_REMEDIAL_REPORT = `${API_URL}/certificateReportsRemedials/updateCertificateReportsRemedials`
export const REMOVE_CERTIFICATE_REMEDIAL_REPORT = `${API_URL}/certificateReportsRemedials/removeCertificateReportsRemedials`
// **********************CREATE REMEDIAL REPORT HEADER**********************
export const CREATE_REMEDIAL_REPORT_HEADER = `${API_URL}/headerDataReportsRemedials/createHeaderDataReportsRemedials`
export const GET_REMEDIAL_REPORT_HEADER_LISTING = `${API_URL}/headerDataReportsRemedials/getHeaderDataReportsRemedialsList`
export const UPDATE_REMEDIAL_REPORT_HEADER = `${API_URL}/headerDataReportsRemedials/updateHeaderDataReportsRemedials`
export const REMOVE_REMEDIAL_REPORT_HEADER = `${API_URL}/headerDataReportsRemedials/removeHeaderDataReportsRemedials`
// **********************CREATE REMEDIAL REPORT FOOTER**********************
export const CREATE_REMEDIAL_REPORT_FOOTER = `${API_URL}/footerDataReportsRemedials/createFooterDataReportsRemedial`
export const GET_REMEDIAL_REPORT_FOOTER_LISTING = `${API_URL}/footerDataReportsRemedials/getFooterDataReportsRemedialsList`
export const UPDATE_REMEDIAL_REPORT_FOOTER = `${API_URL}/footerDataReportsRemedials/updateFooterDataReportsRemedial`
export const REMOVE_REMEDIAL_REPORT_FOOTER = `${API_URL}/footerDataReportsRemedials/removeFooterDataReportsRemedial`
// **********************CREATE REMEDIAL SUMMARY REPORT**********************
export const CREATE_REMEDIAL_SUMMARY_REPORT = `${API_URL}/remedialActionSummaryReports/createRemedialActionSummaryReports`
export const GET_REMEDIAL_SUMMARY_REPORT_LISTING = `${API_URL}/remedialActionSummaryReports/getRemedialActionSummaryReportsList`
export const UPDATE_REMEDIAL_SUMMARY_REPORT = `${API_URL}/remedialActionSummaryReports/updateRemedialActionSummaryReports`
export const REMOVE_REMEDIAL_SUMMARY_REPORT = `${API_URL}/remedialActionSummaryReports/removeRemedialActionSummaryReports`
// **********************CREATE REMEDIAL ACTION LOG REPORT**********************
export const CREATE_REMEDIAL_ACTION_LOG_REPORT = `${API_URL}/remedialActionLogsReports/createRemedialActionLogsReports`
export const GET_REMEDIAL_ACTION_LOG_REPORT_LISTING = `${API_URL}/remedialActionLogsReports/getRemedialActionLogsReportsList`
export const UPDATE_REMEDIAL_ACTION_LOG_REPORT = `${API_URL}/remedialActionLogsReports/updateRemedialActionLogsReports`
export const REMOVE_REMEDIAL_ACTION_LOG_REPORT = `${API_URL}/remedialActionLogsReports/removeRemedialActionLogsReports`

// **********************CREATE REMEDIAL ACTION LOG REPORT**********************
export const CREATE_REMEDIAL_REMAING_DEFECTS = `${API_URL}/remainingDefectRoutes/createRemainingDefect`

// **********************CREATE REMEDIAL COMPETENCY REPORT**********************
export const CREATE_REMEDIAL_COMPETENCY_REPORT = `${API_URL}/remedialCompetencyReports/createRemedialCompetencyReports`
export const GET_REMEDIAL_COMPETENCY_REPORT_LISTING = `${API_URL}/remedialCompetencyReports/getRemedialCompetencyReportsList`
export const UPDATE_REMEDIAL_COMPETENCY_REPORT = `${API_URL}/remedialCompetencyReports/updateRemedialCompetencyReports`
export const REMOVE_REMEDIAL_COMPETENCY_REPORT = `${API_URL}/remedialCompetencyReports/removeRemedialCompetencyReports`
// **********************CREATE REMEDIAL INSPECTION REPORT**********************
export const CREATE_REMEDIAL_INSPECTION_REPORT = `${API_URL}/remedialInspectionReports/createRemedialInspectionReports`
export const GET_REMEDIAL_INSPECTION_REPORT_LISTING = `${API_URL}/remedialInspectionReports/getRemedialInspectionReportsList`
export const GET_REMEDIAL_INSPECTION_REPORT_BY_ID = `${API_URL}/remedialInspectionReports/findRemedialInspectionReportsById`
export const UPDATE_REMEDIAL_INSPECTION_REPORT = `${API_URL}/remedialInspectionReports/updateRemedialInspectionReports`
export const REMOVE_REMEDIAL_INSPECTION_REPORT = `${API_URL}/remedialInspectionReports/removeRemedialInspectionReports`
// **********************GET AFDS INSPECTION REPORT**********************
export const GET_AFDS_INSPECTION_REPORT_BY_ID = `${API_URL}/inspectionReports/findInspectionReportById`
export const DELETE_AFDS_INSPECTION_REPORT = `${API_URL}/inspectionReports/removeInspectionReport`
export const UPDATE_AFDS_INSPECTION_REPORT = `${API_URL}/inspectionReports/updateInspectionReport`
// **********************CLIENT REPRESENTATIVE**********************
export const GET_CLIENT_REPRESENTATIVE_LISTING = `${API_URL}/clientRepresentatives/getClientRepresentativesList`
export const CREATE_CLIENT_REPRESENTATIVE = `${API_URL}/clientRepresentatives/createClientRepresentative`
export const UPDATE_CLIENT_REPRESENTATIVE = `${API_URL}/clientRepresentatives/updateClientRepresentative`
export const REMOVE_CLIENT_REPRESENTATIVE = `${API_URL}/clientRepresentatives/removeClientRepresentative`
export const UPLOAD_CLIENT_REP_IMAGE = `${API_URL}/clientRepresentatives/uploadClientRepresentative`
// **********************UPLOAD BUILDING ASSETS**********************
export const UPLOAD_BUILDING_ASSETS = `${API_URL}/buildingAssets/uploadExcelSheetData`
export const CREATE_BUILDING_ASSETS = `${API_URL}/buildingAssets/createBuildingAsset`
export const ADD_ACCESSIBILITY_DEFECT = `${API_URL}/buildingAssets/addAccessibilityDefect`
export const UPDATE_ACCESSIBILITY_DEFECT = `${API_URL}/buildingAssets/updateAccessibilityDefect`
export const RECTIFY_ACCESSIBILITY_DEFECT = `${API_URL}/buildingAssets/rectifyAccessibilityDefect`
export const RECTIFY_FIREBARRIER_DEFECT = `${API_URL}/buildingAssets/rectifyFireBarrierDefect`
export const RECTIFY_INSTALLATION_DEFECT = `${API_URL}/buildingAssets/rectifyInstallationDefect`
export const RECTIFY_ACCESSPANEL_DEFECT = `${API_URL}/buildingAssets/rectifyAccessPanelDefect`
export const RECTIFY_TEST_DEFECT = `${API_URL}/buildingAssets/rectifyTestDefect`
export const ADD_FIREBARRIER_DEFECT = `${API_URL}/buildingAssets/addFireBarrierDefect`
export const UPDATE_FIREBARRIER_DEFECT = `${API_URL}/buildingAssets/updateFireBarrierDefect`
export const ADD_INSTALLATION_DEFECT = `${API_URL}/buildingAssets/addInstallationDefect`
export const UPDATE_INSTALLATION_DEFECT = `${API_URL}/buildingAssets/updateInstallationDefect`
export const ADD_ACCESSPANEL_DEFECT = `${API_URL}/buildingAssets/addAccessPanelDefect`
export const UPDATE_ACCESSPANEL_DEFECT = `${API_URL}/buildingAssets/updateAccessPanelDefect`
export const ADD_TEST_DEFECT = `${API_URL}/buildingAssets/addTestDefect`
export const UPDATE_TEST_DEFECT = `${API_URL}/buildingAssets/updateTestDefect`
// Archive
// export const ARCHIVED_ACCESSIBILITY_DEFECT = `${API_URL}/buildingAssets/acrchiveAccessibilityDefect`
// export const ARCHIVED_FIREBARRIER_DEFECT = `${API_URL}/buildingAssets/acrchivefireBarrierDefect`
// export const ARCHIVED_INSTALLATION_DEFECT = `${API_URL}/buildingAssets/acrchiveinstallationDefect`
// export const ARCHIVED_ACCESSPANEL_DEFECT = `${API_URL}/buildingAssets/acrchiveaccessPanelDefect`
// export const ARCHIVED_TEST_DEFECT = `${API_URL}/buildingAssets/acrchivetestsDefect`

export const ARCHIVED_ACCESSIBILITY_DEFECT = `${API_URL}/buildingAssets/removeAccessibilityDefect`
export const ARCHIVED_FIREBARRIER_DEFECT = `${API_URL}/buildingAssets/removefireBarrierDefect`
export const ARCHIVED_INSTALLATION_DEFECT = `${API_URL}/buildingAssets/removeinstallationDefect`
export const ARCHIVED_ACCESSPANEL_DEFECT = `${API_URL}/buildingAssets/removeaccessPanelDefect`
export const ARCHIVED_TEST_DEFECT = `${API_URL}/buildingAssets/removetestsDefect`

export const REMOVE_ACCESSIBILITY_DEFECT = `${API_URL}/buildingAssets/removeAccessibilityDefect`
export const REMOVE_FIREBARRIER_DEFECT = `${API_URL}/buildingAssets/removeFireBarrierDefect`
export const REMOVE_ACCESSPANEL_DEFECT = `${API_URL}/buildingAssets/removeAccessPanelDefect`
export const REMOVE_INSTALLATION_DEFECT = `${API_URL}/buildingAssets/removeInstallationDefect`
export const REMOVE_TEST_DEFECT = `${API_URL}/buildingAssets/removeTestDefect`

export const GET_BUILDING_SNAPSHOTS = `${API_URL}/buildingAssetsSnapShots/getBuildingAssetSnapShotsWithFullDetails`
export const CREATE_BUILDING_SNAPSHOT = `${API_URL}/buildingsSnapShots/createBuildingsSnapShot`
// **********************DEFECT SUB CATEGORIES*************************
export const GET_DEFECT_SUB_CATEGORIES_LISTING = `${API_URL}/defectCategories/getDefectCategorysList`
export const CREATE_DEFECT_SUB_CATEGORY = `${API_URL}/defectCategories/createDefectCategory`
export const UPDATE_DEFECT_SUB_CATEGORY = `${API_URL}/defectCategories/updateDefectCategory`
export const REMOVE_DEFECT_SUB_CATEGORY = `${API_URL}/defectCategories/deleteSubCategoryDefect`
// **********************CREATE REPORT**********************
export const CREATE_INSPECTION_REPORT = `${API_URL}/inspectionReports/createInspectionReport`
// **********************UPLOAD LEGACY REPORT**********************
export const UPLOAD_LEGACY_INSPECTION_REPORT = `${API_URL}/inspectionJobs/uploadLegacyInspectionReport`

export const UPDATE_BUILDING_ASSET = `${API_URL}/buildingAssets/updateBuildingAsset`
export const UPDATE_BUILDING_ASSETS = `${API_URL}/buildingAssets/updateBuildingAssets`
export const CREATE_BUILDING_ASSET_SNAPSHOT = `${API_URL}/buildingAssetsSnapShots/createBuildingAssetSnapShot`
export const UPLOAD_ASSET_Images = `${API_URL}/buildingAssets/uploadMultipleImages`
export const DELETE_IMAGE = `${API_URL}/buildingAssets/deleteImage`

export const UPLOAD_TEST_Images = `${API_URL}/buildingAssets/uploadMultipleImages`
export const UPLOAD_HYGIENE_Images = `${API_URL}/buildingAssets/uploadMultipleImages`
export const UPLOAD_QR_Images = `${API_URL}/buildingAssets/uploadSingleFile`
export const UPLOAD_SATISFACTORY_Images = `${API_URL}/buildingAssets/uploadMultipleImages`
export const UPLOAD_DEFECT_Images = `${API_URL}/buildingAssets/uploadMultipleImages`
export const UPLOAD_RECTIFY_Images = `${API_URL}/buildingAssets/uploadMultipleImages`

export const GET_REFERENCES = `${API_URL}/inspectionJobs/getReferences`

export const CREATE_ASSOCIATION = `${API_URL}/associations/createAssociation`
export const UPDATE_ASSOCIATION = `${API_URL}/associations/updateAssociation`
export const UPLOAD_ASSOCIATION_IMAGE = `${API_URL}/associations/uploadSingleFile`
export const GET_ASSOCIATION = `${API_URL}/associations/getAssociationList`
export const DELETE_ASSOCIATION = `${API_URL}/associations/deleteAssociation`

export const CREATE_COMPETENCIES = `${API_URL}/competencies/createCompetencies`
export const UPDATE_COMPETENCIES = `${API_URL}/competencies/updateCompetencies`
export const UPLOAD_COMPETENCIES_IMAGE = `${API_URL}/competencies/uploadSingleFile`
export const GET_COMPETENCIES = `${API_URL}/competencies/getCompetenciesList`
export const DELETE_COMPETENCIES = `${API_URL}/competencies/deleteCompetencies`

export const UPLOAD_OBSERVATION_IMAGE = `${API_URL}/buildingAssets/uploadMultipleImages`
export const CREATE_OBSERVATIONS = `${API_URL}/buildingAssets/addObservations`
export const UPDATE_OBSERVATIONS = `${API_URL}/buildingAssets/updateObservations`
export const DELETE_OBSERVATIONS = `${API_URL}/buildingAssets/deleteObservations`

export const GET_OPERATIVE_TYPES = `${API_URL}/operativeTypes/getOperativeTypesList`
export const DELETE_OPERATIVE_TYPE = `${API_URL}/operativeTypes/removeOperativeType`

export const GET_ALL_MODULES = `${API_URL}/modules/getModulesList`
export const GET_OPERATIVE_ALL_PERMISSIONS = `${API_URL}/operativePermissions/getOperativeAllPermissions`

export const UPDATE_OPERATIVE_TYPE_PERMISSION = `${API_URL}/operativePermissions/updateOperativePermission`

export const getOperativeAllPermissions = async (data: any) => {
  const res = await axiosInstance.post(GET_OPERATIVE_ALL_PERMISSIONS, JSON.stringify(data))
  return res.data
}

export const getAllModules = async (data: any) => {
  const res = await axiosInstance.post(GET_ALL_MODULES, JSON.stringify(data))
  return res.data
}

export const getOperativeTypes = async (data: any) => {
  const res = await axiosInstance.post(GET_OPERATIVE_TYPES, JSON.stringify(data))
  return res.data
}

export const deleteOperativeType = async (data: any) => {
  const res = await axiosInstance.post(DELETE_OPERATIVE_TYPE, JSON.stringify(data))
  return res.data
}

export const getReferences = async (data: any) => {
  const res = await axiosInstance.post(GET_REFERENCES, JSON.stringify(data))
  return res.data
}

export const getJobsListing = async (jobsArgs: any) => {
  const res = await axiosInstance.post(GET_JOBS_LIST, JSON.stringify(jobsArgs))
  return res.data
}

export const getJobsListingWithDetails = async (jobsArgs: any) => {
  const res = await axiosInstance.post(GET_JOBS_DETAILED_LIST, JSON.stringify(jobsArgs))
  return res.data
}

export const getSpecificJob = async (jobsArgs: any) => {
  const res = await axiosInstance.post(GET_JOB_BY_ID, JSON.stringify(jobsArgs))
  return res.data
}

export const getJobDefectCounts = async (jobsArgs: any) => {
  const res = await axiosInstance.post(GET_JOB_DEFECT_COUNTS, JSON.stringify(jobsArgs))
  return res.data
}

export const getAssetsByJobIds = async (jobsArgs: any) => {
  const res = await axiosInstance.post(GET_MULTIPLE_ASSETS_BY_JOBSIDS, JSON.stringify(jobsArgs))
  return res.data
}

export const removeJob = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_INSPECTION_JOB, JSON.stringify(data))
  return res.data
}

export const getClients = async (clientsArgs: any) => {
  const res = await axiosInstance.post(GET_CLIENTS_LISTING, JSON.stringify(clientsArgs))
  return res.data
}

// **********************CLIENT REPRESENTATIVE**********************
export const getClientRepresentatives = async (clientRepArgs: any) => {
  const res = await axiosInstance.post(
    GET_CLIENT_REPRESENTATIVE_LISTING,
    JSON.stringify(clientRepArgs)
  )
  return res.data
}

export const createClientRepresentative = async (clientRepArgs: any) => {
  const res = await axiosInstance.post(CREATE_CLIENT_REPRESENTATIVE, JSON.stringify(clientRepArgs))
  return res.data
}

export const uploadClientRepresentativeLogo = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_CLIENT_REP_IMAGE, data)
  return res.data
}

export const updateClientRepresentative = async (clientRepArgs: any) => {
  const res = await axiosInstance.post(UPDATE_CLIENT_REPRESENTATIVE, JSON.stringify(clientRepArgs))
  return res.data
}

export const removeClientRepresentative = async (clientRepArgs: any) => {
  const res = await axiosInstance.post(REMOVE_CLIENT_REPRESENTATIVE, JSON.stringify(clientRepArgs))
  return res.data
}
// **********************CLIENT REPRESENTATIVE**********************

export const getFacilityManagers = async (fmArgs: any) => {
  const res = await axiosInstance.post(GET_FACILITY_MANAGER_LISTING, fmArgs)
  return res.data
}

export const getFacilityManagersDetailedListing = async (fmArgs: any) => {
  const res = await axiosInstance.post(GET_FACILITY_MANAGER_DETAILED_LISTING, fmArgs)
  return res.data
}

export const getBuildingList = async (buildingsArgs: any) => {
  const res = await axiosInstance.post(GET_BUILDING_LISTING, buildingsArgs)
  return res.data
}

export const getBuildingsWithDetails = async (buildingsArgs: any) => {
  const res = await axiosInstance.post(GET_BUILDING_LISTING_DETAILS, buildingsArgs)
  return res.data
}

export const getBuilding = async (buildingsArgs: any) => {
  const res = await axiosInstance.post(GET_BUILDING_BY_ID, buildingsArgs)
  return res.data
}

export const getBuildingAssetByBuildingId = async (buildingsArgs: any) => {
  const res = await axiosInstance.post(GET_BUILDING_ASSETS_BY_BUILDING_ID, buildingsArgs)
  return res.data
}

export const getBuildingSummaryCounts = async (buildingsArgs: any) => {
  const res = await axiosInstance.post(GET_BUILDING_SUMMARY_COUNTS, buildingsArgs)
  return res.data
}

export const getOperatives = async (operativesArgs: any) => {
  const res = await axiosInstance.post(GET_OPERATIVES_LISTING, operativesArgs)
  return res.data
}

export const getDetailedOperatives = async (operativesArgs: any) => {
  const res = await axiosInstance.post(GET_OPERATIVES_DETAILED_LISTING, operativesArgs)
  return res.data
}

export const createJob = async (data: CreateJobProps) => {
  const res = await axiosInstance.post(CREATE_INSPECTION_JOB, JSON.stringify(data))
  return res.data
}

export const updateJob = async (data: CreateJobProps) => {
  const res = await axiosInstance.post(UPDATE_INSPECTION_JOB, JSON.stringify(data))
  return res.data
}

export const closeJob = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_INSPECTION_JOB, JSON.stringify(data))
  return res.data
}

export const rescheduleJob = async (data: any) => {
  const res = await axiosInstance.post(RESCHEDULE_INSPECTION_JOB, JSON.stringify(data))
  return res.data
}

export const getOperative = async (operativeArgs: any) => {
  const res = await axiosInstance.post(GET_OPERATIVE_BY_ID, operativeArgs)
  return res.data
}

export const getVaults = async (vaultArgs: any) => {
  const res = await axiosInstance.post(GET_VAULT_LIST, vaultArgs)
  return res.data
}

export const getVaultDetailedListing = async (vaultArgs: any) => {
  const res = await axiosInstance.post(GET_VAULT_DETAILED_LIST, vaultArgs)
  return res.data
}

export const createBuilding = async (data: CreateBuildingProps) => {
  const res = await axiosInstance.post(CREATE_BUILDING, JSON.stringify(data))
  return res.data
}

export const updateBuilding = async (data: CreateBuildingProps) => {
  const res = await axiosInstance.post(UPDATE_BUILDING, JSON.stringify(data))
  return res.data
}

export const createOperative = async (data: OperativeSignupProps) => {
  const res = await axiosInstance.post(CREATE_OPERATIVE, JSON.stringify(data))
  return res.data
}

export const updateOperative = async (data: OperativeSignupProps) => {
  const res = await axiosInstance.post(UPDATE_OPERATIVE, JSON.stringify(data))
  return res.data
}

export const createFacilityManager = async (data: any) => {
  const res = await axiosInstance.post(CREATE_FACILITY_MANAGER, JSON.stringify(data))
  return res.data
}

export const updateFacilityManager = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_FACILITY_MANAGER, JSON.stringify(data))
  return res.data
}

export const getAllUsersByRole = async (args: any) => {
  const res = await axiosInstance.post(GET_ALL_USERS_BY_ROLE, args)
  return res.data
}

export const removeFacilityManager = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_FACILITY_MANAGER, JSON.stringify(data))
  return res.data
}

export const getBuildingAssetById = async (data: any) => {
  const res = await axiosInstance.post(BUILDING_ASSET_BY_ID, JSON.stringify(data))
  return res.data
}

export const removeBuildingAsset = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_BUILDING_ASSET, JSON.stringify(data))
  return res.data
}

export const createClient = async (data: any) => {
  const res = await axiosInstance.post(CREATE_CLIENT, JSON.stringify(data))
  return res.data
}

export const removeClient = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_CLIENT, JSON.stringify(data))
  return res.data
}

export const updateClient = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_CLIENT, JSON.stringify(data))
  return res.data
}

export const getClientById = async (data: any) => {
  const res = await axiosInstance.post(GET_CLIENT_BY_ID, JSON.stringify(data))
  return res.data
}

export const getClientWithDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_CLIENT_WITH_DETAIL, JSON.stringify(data))
  return res.data
}

export const getTestRegimeListing = async (data: any) => {
  const res = await axiosInstance.post(GET_TEST_REGIME_LISTING, JSON.stringify(data))
  return res.data
}

export const getNewsListing = async (data: any) => {
  const res = await axiosInstance.post(GET_NEWS_LISTING, JSON.stringify(data))
  return res.data
}

export const removeNews = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_NEWS_MESSAGE, JSON.stringify(data))
  return res.data
}

export const createNews = async (data: any) => {
  const res = await axiosInstance.post(CREATE_NEWS_MESSAGE, JSON.stringify(data))
  return res.data
}

export const updateNews = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_NEWS_MESSAGE, JSON.stringify(data))
  return res.data
}

export const getReportListing = async (data: any) => {
  const res = await axiosInstance.post(REPORT_LISTING, JSON.stringify(data))
  return res.data
}

export const getReportById = async (data: any) => {
  const res = await axiosInstance.post(GET_SPECIFIC_REPORT, JSON.stringify(data))
  return res.data
}

export const getDetailedReportListing = async (data: any) => {
  const res = await axiosInstance.post(REPORT_DETAILED_LISTING, JSON.stringify(data))
  return res.data
}

export const downloadReportById = async (data: any) => {
  const res = await fileAxiosInstance.post(GENERATE_PDF, JSON.stringify(data))
  return res.data
}

export const generateReportById = async (data: any) => {
  const res = await fileAxiosInstance.post(GENERATE_PDF_STORE, JSON.stringify(data))
  return res.data
}

export const uploadCertImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_CERT_IMAGES, data)
  return res.data
}

export const publishReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT, JSON.stringify(data))
  return res.data
}

export const removeReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT, JSON.stringify(data))
  return res.data
}

export const updateReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT, JSON.stringify(data))
  return res.data
}

export const deleteReport = async (data: any) => {
  const res = await axiosInstance.post(DELETE_REPORT, JSON.stringify(data))
  return res.data
}

export const uploadProfileImage = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_PROFILE_IMAGE, data)
  return res.data
}

export const uploadClientLogo = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_CLIENT_LOGO, data)
  return res.data
}

export const uploadRubberStamp = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_RUBBER_STAMP, data)
  return res.data
}

export const uploadBuildingImage = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_BUILDING_IMAGE, data)
  return res.data
}

export const uploadNewsAttachment = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_NEWS_ATTACHMENT, data)
  return res.data
}

// **********************************
export const uploadFile = async (endPoint: string, data: any) => {
  const res = await axiosInstance.post(API_URL + endPoint, data)
  return res.data
}

// *************************Accessibility*************************
export const getAccessibilitiesListing = async (data: any) => {
  const res = await axiosInstance.post(GET_ACCESSIBILITY_LISTING, JSON.stringify(data))
  return res.data
}

export const getAccessibilitiesListingWithDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_ACCESSIBILITY_LISTING_WITH_DETAILS, JSON.stringify(data))
  return res.data
}

export const updateAccessibility = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_ACCESSIBILITY, JSON.stringify(data))
  return res.data
}

export const createAccessibility = async (data: any) => {
  const res = await axiosInstance.post(CREATE_ACCESSIBILITY, JSON.stringify(data))
  return res.data
}

export const removeAccessibility = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_ACCESSIBILITY, JSON.stringify(data))
  return res.data
}
// *************************Accessibility*************************

// *************************FIRE BARRIER*************************
export const getFireBarrierListing = async (data: any) => {
  const res = await axiosInstance.post(GET_FIRE_BARRIER_LISTING, JSON.stringify(data))
  return res.data
}

export const getFireBarrierListingWithDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_FIRE_BARRIER_LISTING_WITH_DETAILS, JSON.stringify(data))
  return res.data
}

export const updateFireBarrier = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_FIRE_BARRIER, JSON.stringify(data))
  return res.data
}

export const createFireBarrier = async (data: any) => {
  const res = await axiosInstance.post(CREATE_FIRE_BARRIER, JSON.stringify(data))
  return res.data
}

export const removeFireBarrier = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_FIRE_BARRIER, JSON.stringify(data))
  return res.data
}
// *************************FIRE BARRIER*************************

// *************************INSTALLATION*************************
export const getInstallationListing = async (data: any) => {
  const res = await axiosInstance.post(GET_INSTALLATION_LISTING, JSON.stringify(data))
  return res.data
}

export const getInstallationListingWithDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_INSTALLATION_LISTING_WITH_DETAILS, JSON.stringify(data))
  return res.data
}

export const updateInstallation = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_INSTALLATION, JSON.stringify(data))
  return res.data
}

export const createInstallation = async (data: any) => {
  const res = await axiosInstance.post(CREATE_INSTALLATION, JSON.stringify(data))
  return res.data
}

export const removeInstallation = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_INSTALLATION, JSON.stringify(data))
  return res.data
}
// *************************INSTALLATION*************************

// *************************DUCT TYPE*************************
export const getDuctTypeListing = async (data: any) => {
  const res = await axiosInstance.post(GET_DUCT_TYPE_LISTING, JSON.stringify(data))
  return res.data
}

export const getDuctTypeListingWithDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_DUCT_TYPE_LISTING_WITH_DETAILS, JSON.stringify(data))
  return res.data
}

export const updateDuctType = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_DUCT_TYPE, JSON.stringify(data))
  return res.data
}

export const createDuctType = async (data: any) => {
  const res = await axiosInstance.post(CREATE_DUCT_TYPE, JSON.stringify(data))
  return res.data
}

export const removeDuctType = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_DUCT_TYPE, JSON.stringify(data))
  return res.data
}
// *************************DUCT TYPE*************************

// *************************DAMPER TYPE*************************
export const getDamperTypeListing = async (data: any) => {
  const res = await axiosInstance.post(GET_DAMPER_TYPE_LISTING, JSON.stringify(data))
  return res.data
}

export const getDamperTypeListingWithDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_DAMPER_TYPE_LISTING_WITH_DETAILS, JSON.stringify(data))
  return res.data
}

export const updateDamperType = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_DAMPER_TYPE, JSON.stringify(data))
  return res.data
}

export const createDamperType = async (data: any) => {
  const res = await axiosInstance.post(CREATE_DAMPER_TYPE, JSON.stringify(data))
  return res.data
}

export const removeDamperType = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_DAMPER_TYPE, JSON.stringify(data))
  return res.data
}
// *************************DAMPER TYPE*************************

// *************************DUCT SHAPE*************************
export const getDuctShapeListing = async (data: any) => {
  const res = await axiosInstance.post(GET_DUCT_SHAPE_LISTING, JSON.stringify(data))
  return res.data
}

export const getDuctShapeListingWithDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_DUCT_SHAPE_LISTING_WITH_DETAILS, JSON.stringify(data))
  return res.data
}

export const updateDuctShape = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_DUCT_SHAPE, JSON.stringify(data))
  return res.data
}

export const createDuctShape = async (data: any) => {
  const res = await axiosInstance.post(CREATE_DUCT_SHAPE, JSON.stringify(data))
  return res.data
}

export const removeDuctShape = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_DUCT_SHAPE, JSON.stringify(data))
  return res.data
}
// *************************DUCT SHAPE*************************

// *************************TEST API's*************************
export const addtest = async (data: any) => {
  const res = await axiosInstance.post(ADD_TEST, JSON.stringify(data))
  return res.data
}
// *************************TEST API's*************************

// *************************REPORT HEADER TEMPLATE*************************
export const getReportHeaderTemplateListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_HEADER_TEMPLATE_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportHeaderTemplate = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_HEADER_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const createReportHeaderTemplate = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_HEADER_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const removeReportHeaderTemplate = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_HEADER_TEMPLATE, JSON.stringify(data))
  return res.data
}
// *************************REPORT HEADER TEMPLATE*************************

// *************************REPORT FOOTER TEMPLATE*************************
export const getReportFooterTemplateListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_FOOTER_TEMPLATE_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportFooterTemplate = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_FOOTER_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const createReportFooterTemplate = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_FOOTER_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const removeReportFooterTemplate = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_FOOTER_TEMPLATE, JSON.stringify(data))
  return res.data
}
// *************************REPORT FOOTER TEMPLATE*************************

// *************************REPORT GUIDANCE TEMPLATE*************************
export const getReportGuidanceTemplateListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_GUIDANCE_TEMPLATE_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportGuidanceTemplate = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_GUIDANCE_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const createReportGuidanceTemplate = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_GUIDANCE_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const removeReportGuidanceTemplate = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_GUIDANCE_TEMPLATE, JSON.stringify(data))
  return res.data
}
// *************************REPORT GUIDANCE TEMPLATE*************************

// *************************REPORT SERVICE TEMPLATE*************************
export const getReportServiceTemplateListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_SERVICE_TEMPLATE_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportServiceTemplate = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_SERVICE_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const createReportServiceTemplate = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_SERVICE_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const removeReportServiceTemplate = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_SERVICE_TEMPLATE, JSON.stringify(data))
  return res.data
}
// *************************REPORT SERVICE TEMPLATE*************************

// *************************REPORT DEFECT LOG TEMPLATE*************************
export const getReportDefectLogTemplateListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_DEFECT_LOG_TEMPLATE_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportDefectLogTemplate = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_DEFECT_LOG_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const createReportDefectLogTemplate = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_DEFECT_LOG_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const removeReportDefectLogTemplate = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_DEFECT_LOG_TEMPLATE, JSON.stringify(data))
  return res.data
}
// *************************REPORT DEFECT LOG TEMPLATE*************************

// *************************REPORT TEMPLATE*************************
export const getReportTemplateListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_TEMPLATE_LISTING, JSON.stringify(data))
  return res.data
}

export const getReportTemplateDetailedListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_TEMPLATE_DETAILED_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportTemplate = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const createReportTemplate = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_TEMPLATE, JSON.stringify(data))
  return res.data
}

export const removeReportTemplate = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_TEMPLATE, JSON.stringify(data))
  return res.data
}
// *************************REPORT TEMPLATE*************************

// *************************REPORT SUMMARY*************************
export const getReportSummaryListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_SUMMARY_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportSummary = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_SUMMARY, JSON.stringify(data))
  return res.data
}

export const createReportSummary = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_SUMMARY, JSON.stringify(data))
  return res.data
}

export const removeReportSummary = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_SUMMARY, JSON.stringify(data))
  return res.data
}

// *************************REPORT CREATION FLOW*************************
export const createReportCertificate = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_CERTIFICATE, JSON.stringify(data))
  return res.data
}

// *************************REPORT GUIDANCE*************************
export const getReportGuidanceListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_GUIDANCE_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportGuidance = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_GUIDANCE, JSON.stringify(data))
  return res.data
}

export const createReportGuidance = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_GUIDANCE, JSON.stringify(data))
  return res.data
}

export const removeReportGuidance = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_GUIDANCE, JSON.stringify(data))
  return res.data
}
// *************************REPORT GUIDANCE*************************

// *************************REPORT SERVICE*************************
export const getReportServiceListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_SERVICE_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportService = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_SERVICE, JSON.stringify(data))
  return res.data
}

export const createReportService = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_SERVICE, JSON.stringify(data))
  return res.data
}

export const removeReportService = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_SERVICE, JSON.stringify(data))
  return res.data
}
// *************************REPORT SERVICE*************************

// *************************ASSET REGISTER*************************
export const getAssetRegisterListing = async (data: any) => {
  const res = await axiosInstance.post(GET_ASSET_REGISTER_LISTING, JSON.stringify(data))
  return res.data
}

export const updateAssetRegister = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_ASSET_REGISTER, JSON.stringify(data))
  return res.data
}

export const createAssetRegister = async (data: any) => {
  const res = await axiosInstance.post(CREATE_ASSET_REGISTER, JSON.stringify(data))
  return res.data
}

export const removeAssetRegister = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_ASSET_REGISTER, JSON.stringify(data))
  return res.data
}
// *************************ASSET REGISTER*************************

// *************************DEFECT LOG*****************************
export const getDefectLogListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_DEFECT_LOG_LISTING, JSON.stringify(data))
  return res.data
}

export const updateDefectLog = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_DEFECT_LOG, JSON.stringify(data))
  return res.data
}

export const createDefectLog = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_DEFECT_LOG, JSON.stringify(data))
  return res.data
}

export const removeDefectLog = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_DEFECT_LOG, JSON.stringify(data))
  return res.data
}
// *************************DEFECT LOG*****************************

// **********************BUILDING ASSET REPORT**********************
export const getBuildingAssetReportListing = async (data: any) => {
  const res = await axiosInstance.post(GET_BUILDING_ASSET_REPORT_LISTING, JSON.stringify(data))
  return res.data
}

export const updateBuildingAssetReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_BUILDING_ASSET_REPORT, JSON.stringify(data))
  return res.data
}

export const createBuildingAssetReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_BUILDING_ASSET_REPORT, data)
  return res.data
}

export const removeBuildingAssetReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_BUILDING_ASSET_REPORT, JSON.stringify(data))
  return res.data
}
// *************************BUILDING ASSET REPORT*************************

// *************************REPORT INVENTORY*****************************
export const getReportInventoryListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REPORT_INVENTORY_LISTING, JSON.stringify(data))
  return res.data
}

export const updateReportInventory = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REPORT_INVENTORY, JSON.stringify(data))
  return res.data
}

export const createReportInventory = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REPORT_INVENTORY, JSON.stringify(data))
  return res.data
}

export const removeReportInventory = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REPORT_INVENTORY, JSON.stringify(data))
  return res.data
}
// *************************REPORT INVENTORY*****************************

// *************************COMPETENCY REPORT*****************************
export const getCompetencyReportListing = async (data: any) => {
  const res = await axiosInstance.post(GET_COMPETENCY_REPORT_LISTING, JSON.stringify(data))
  return res.data
}

export const updateCompetencyReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_COMPETENCY_REPORT, JSON.stringify(data))
  return res.data
}

export const createCompetencyReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_COMPETENCY_REPORT, JSON.stringify(data))
  return res.data
}

export const createDisClaimer = async (data: any) => {
  const res = await axiosInstance.post(CREATE_DISCLAIMER, JSON.stringify(data))
  return res.data
}
export const removeCompetencyReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_COMPETENCY_REPORT, JSON.stringify(data))
  return res.data
}

export const createInspectionReport = async (date: any) => {
  const res = await axiosInstance.post(CREATE_INSPECTION_REPORT, JSON.stringify(date))
  return res.data
}
// *************************COMPETENCY REPORT*****************************

// *************************CREATE REPORT REMEDIAL*****************************
export const createCertificateRemedialReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_CERTIFICATE_REMEDIAL_REPORT, JSON.stringify(data))
  return res.data
}

export const updateCertificateRemedialReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_CERTIFICATE_REMEDIAL_REPORT, JSON.stringify(data))
  return res.data
}

export const removeCertificateRemedialReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_CERTIFICATE_REMEDIAL_REPORT, JSON.stringify(data))
  return res.data
}

export const getCertificateRemedialReportListing = async (data: any) => {
  const res = await axiosInstance.post(
    GET_CERTIFICATE_REMEDIAL_REPORT_LISTING,
    JSON.stringify(data)
  )
  return res.data
}
// *************************CREATE REPORT REMEDIAL*****************************

// *************************CREATE REMEDIAL REPORT HEADER*****************************
export const createRemedialReportHeader = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REMEDIAL_REPORT_HEADER, JSON.stringify(data))
  return res.data
}

export const updateRemedialReportHeader = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REMEDIAL_REPORT_HEADER, JSON.stringify(data))
  return res.data
}

export const removeRemedialReportHeader = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REMEDIAL_REPORT_HEADER, JSON.stringify(data))
  return res.data
}

export const getRemedialReportHeaderListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REMEDIAL_REPORT_HEADER_LISTING, JSON.stringify(data))
  return res.data
}
// *************************CREATE REMEDIAL REPORT HEADER*****************************

// *************************CREATE REMEDIAL REPORT FOOTER*****************************
export const createRemedialReportFooter = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REMEDIAL_REPORT_FOOTER, JSON.stringify(data))
  return res.data
}

export const updateRemedialReportFooter = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REMEDIAL_REPORT_FOOTER, JSON.stringify(data))
  return res.data
}

export const removeRemedialReportFooter = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REMEDIAL_REPORT_FOOTER, JSON.stringify(data))
  return res.data
}

export const getRemedialReportFooterListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REMEDIAL_REPORT_FOOTER_LISTING, JSON.stringify(data))
  return res.data
}
// *************************CREATE REMEDIAL REPORT FOOTER*****************************

// *************************CREATE REMEDIAL SUMMARY REPORT*****************************
export const createRemedialSummaryReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REMEDIAL_SUMMARY_REPORT, JSON.stringify(data))
  return res.data
}

export const updateRemedialSummaryReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REMEDIAL_SUMMARY_REPORT, JSON.stringify(data))
  return res.data
}

export const removeRemedialSummaryReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REMEDIAL_SUMMARY_REPORT, JSON.stringify(data))
  return res.data
}

export const getRemedialSummaryReportListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REMEDIAL_SUMMARY_REPORT_LISTING, JSON.stringify(data))
  return res.data
}
// *************************CREATE REMEDIAL SUMMARY REPORT*****************************

// *************************CREATE REMEDIAL ACTION LOG REPORT*****************************
export const createRemedialActionLogReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REMEDIAL_ACTION_LOG_REPORT, JSON.stringify(data))
  return res.data
}

export const updateRemedialActionLogReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REMEDIAL_ACTION_LOG_REPORT, JSON.stringify(data))
  return res.data
}

export const removeRemedialActionLogReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REMEDIAL_ACTION_LOG_REPORT, JSON.stringify(data))
  return res.data
}

export const getRemedialActionLogReportListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REMEDIAL_ACTION_LOG_REPORT_LISTING, JSON.stringify(data))
  return res.data
}

// *************************CREATE REMEDIAL ACTION LOG REPORT*****************************
export const createRemedialRemaingLogs = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REMEDIAL_REMAING_DEFECTS, JSON.stringify(data))
  return res.data
}

// *************************CREATE REMEDIAL ACTION LOG REPORT*****************************

// *************************CREATE REMEDIAL COMPETENCY REPORT*****************************
export const createRemedialCompetencyReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REMEDIAL_COMPETENCY_REPORT, JSON.stringify(data))
  return res.data
}

export const updateRemedialCompetencyReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REMEDIAL_COMPETENCY_REPORT, JSON.stringify(data))
  return res.data
}

export const removeRemedialCompetencyReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REMEDIAL_COMPETENCY_REPORT, JSON.stringify(data))
  return res.data
}

export const getRemedialCompetencyReportListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REMEDIAL_COMPETENCY_REPORT_LISTING, JSON.stringify(data))
  return res.data
}
// *************************CREATE REMEDIAL COMPETENCY REPORT*****************************

// *************************CREATE REMEDIAL INSPECTION REPORT*****************************
export const createRemedialInspectionReport = async (data: any) => {
  const res = await axiosInstance.post(CREATE_REMEDIAL_INSPECTION_REPORT, JSON.stringify(data))
  return res.data
}

export const updateRemedialInspectionReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_REMEDIAL_INSPECTION_REPORT, JSON.stringify(data))
  return res.data
}

export const removeRemedialInspectionReport = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_REMEDIAL_INSPECTION_REPORT, JSON.stringify(data))
  return res.data
}

export const getRemedialInspectionReportListing = async (data: any) => {
  const res = await axiosInstance.post(GET_REMEDIAL_INSPECTION_REPORT_LISTING, JSON.stringify(data))
  return res.data
}

export const getRemedialInspectionReport = async (data: any) => {
  const res = await axiosInstance.post(GET_REMEDIAL_INSPECTION_REPORT_BY_ID, JSON.stringify(data))
  return res.data
}
// *************************CREATE REMEDIAL INSPECTION REPORT*****************************

// *************************GET INSPECTION REPORT*****************************
export const getAFDSInspectionReport = async (data: any) => {
  const res = await axiosInstance.post(GET_AFDS_INSPECTION_REPORT_BY_ID, JSON.stringify(data))
  return res.data
}

export const removeAFDSInspectionReport = async (data: any) => {
  const res = await axiosInstance.post(DELETE_AFDS_INSPECTION_REPORT, JSON.stringify(data))
  return res.data
}

export const updateAFDSReport = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_AFDS_INSPECTION_REPORT, JSON.stringify(data))
  return res.data
}

// *************************UPLOAD BUILDING ASSETS*****************************
export const onUploadBuildingAssets = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_BUILDING_ASSETS, data)
  return res.data
}

// *************************DEFECT SUB CATEGORIES*******************************
export const getDefectSubCategories = async (data: any) => {
  const res = await axiosInstance.post(GET_DEFECT_SUB_CATEGORIES_LISTING, JSON.stringify(data))
  return res.data
}

export const createDefectSubCategory = async (data: any) => {
  const res = await axiosInstance.post(CREATE_DEFECT_SUB_CATEGORY, JSON.stringify(data))
  return res.data
}

export const updateDefectSubCategory = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_DEFECT_SUB_CATEGORY, JSON.stringify(data))
  return res.data
}

export const removeDefectSubCategory = async (data: any) => {
  const res = await axiosInstance.post(REMOVE_DEFECT_SUB_CATEGORY, JSON.stringify(data))
  return res.data
}

// *************************UPLOAD LEGACY REPORT*****************************
export const uploadLegacyReport = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_LEGACY_INSPECTION_REPORT, data)
  return res.data
}

export const UpdateBuildingAsset = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_BUILDING_ASSET, data)
  return res.data
}

export const UpdateBuildingAssets = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_BUILDING_ASSETS, data)
  return res.data
}

export const CreateBuildingAssetSnapShot = async (data: any) => {
  const res = await axiosInstance.post(CREATE_BUILDING_ASSET_SNAPSHOT, data)
  return res.data
}

export const getBuildingAsset = async (data: any) => {
  const res = await axiosInstance.post(GET_BUILDING_ASSET, data)
  return res.data
}

export const getBuildingAssetWithFullDetails = async (data: any) => {
  const res = await axiosInstance.post(GET_BUILDING_ASSET_WITH_FULL_DETIALS, data)
  return res.data
}

export const createBuildingAsset = async (data: any) => {
  const res = await axiosInstance.post(CREATE_BUILDING_ASSETS, JSON.stringify(data))
  return res.data
}
export const addAccessibilityDefect = async (data: any) => {
  const res = await axiosInstance.post(ADD_ACCESSIBILITY_DEFECT, JSON.stringify(data))
  return res.data
}
export const updateAccessibilityDefect = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_ACCESSIBILITY_DEFECT, JSON.stringify(data))
  return res.data
}
export const rectifyAccessibilityDefect = async (data: any) => {
  const res = await axiosInstance.post(RECTIFY_ACCESSIBILITY_DEFECT, JSON.stringify(data))
  return res.data
}
export const rectifyFirebarrierDefect = async (data: any) => {
  const res = await axiosInstance.post(RECTIFY_FIREBARRIER_DEFECT, JSON.stringify(data))
  return res.data
}
export const rectifyInstallationDefect = async (data: any) => {
  const res = await axiosInstance.post(RECTIFY_INSTALLATION_DEFECT, JSON.stringify(data))
  return res.data
}
export const rectifyAccessPanelDefect = async (data: any) => {
  const res = await axiosInstance.post(RECTIFY_ACCESSPANEL_DEFECT, JSON.stringify(data))
  return res.data
}
export const rectifyTestDefect = async (data: any) => {
  const res = await axiosInstance.post(RECTIFY_TEST_DEFECT, JSON.stringify(data))
  return res.data
}
export const addFirebarrierDefect = async (data: any) => {
  const res = await axiosInstance.post(ADD_FIREBARRIER_DEFECT, JSON.stringify(data))
  return res.data
}
export const updateFirebarrierDefect = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_FIREBARRIER_DEFECT, JSON.stringify(data))
  return res.data
}
export const addInstallationDefect = async (data: any) => {
  const res = await axiosInstance.post(ADD_INSTALLATION_DEFECT, JSON.stringify(data))
  return res.data
}
export const updateInstallationDefect = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_INSTALLATION_DEFECT, JSON.stringify(data))
  return res.data
}
export const addAccessPanelDefect = async (data: any) => {
  const res = await axiosInstance.post(ADD_ACCESSPANEL_DEFECT, JSON.stringify(data))
  return res.data
}
export const updateAccessPanelDefect = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_ACCESSPANEL_DEFECT, JSON.stringify(data))
  return res.data
}

export const addTestDefect = async (data: any) => {
  const res = await axiosInstance.post(ADD_TEST_DEFECT, JSON.stringify(data))
  return res.data
}
export const updateTestDefect = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_TEST_DEFECT, JSON.stringify(data))
  return res.data
}

export const removeDefects = async (data: any) => {
  let url = ''
  if (data.accessibility) {
    url = REMOVE_ACCESSIBILITY_DEFECT
  } else if (data.fireBarrier) {
    url = REMOVE_FIREBARRIER_DEFECT
  } else if (data.installation) {
    url = REMOVE_INSTALLATION_DEFECT
  } else if (data.accessPanel) {
    url = REMOVE_ACCESSPANEL_DEFECT
  } else if (data.tests) {
    url = REMOVE_TEST_DEFECT
  }

  if (url) {
    const res = await axiosInstance.post(url, JSON.stringify(data.defect))
    return res.data
  }
}

export const archivedAccessibilityDefect = async (data: any) => {
  const res = await axiosInstance.post(ARCHIVED_ACCESSIBILITY_DEFECT, JSON.stringify(data))
  return res.data
}

export const archivedFirebarrierDefect = async (data: any) => {
  const res = await axiosInstance.post(ARCHIVED_FIREBARRIER_DEFECT, JSON.stringify(data))
  return res.data
}

export const archivedInstallationDefect = async (data: any) => {
  const res = await axiosInstance.post(ARCHIVED_INSTALLATION_DEFECT, JSON.stringify(data))
  return res.data
}

export const archivedAccessPanelt = async (data: any) => {
  const res = await axiosInstance.post(ARCHIVED_ACCESSPANEL_DEFECT, JSON.stringify(data))
  return res.data
}

export const archivedTestPanelt = async (data: any) => {
  const res = await axiosInstance.post(ARCHIVED_TEST_DEFECT, JSON.stringify(data))
  return res.data
}

export const createBuildingSnapshot = async (data: any) => {
  const res = await axiosInstance.post(CREATE_BUILDING_SNAPSHOT, JSON.stringify(data))
  return res.data
}
export const getBuildingSnapshots = async (data: any) => {
  const res = await axiosInstance.post(GET_BUILDING_SNAPSHOTS, JSON.stringify(data))
  return res.data
}

// *************************UPLOAD Imges*****************************

export const uploadAssetImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_ASSET_Images, data)
  return res.data
}
export const uploadTestImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_TEST_Images, data)
  return res.data
}
export const uploadHygieneImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_HYGIENE_Images, data)
  return res.data
}
export const uploadDefectImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_DEFECT_Images, data)
  return res.data
}
export const uploadRectifyImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_RECTIFY_Images, data)
  return res.data
}
export const uploadQrImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_QR_Images, data)
  return res.data
}
export const uploadSatisfactoryImages = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_SATISFACTORY_Images, data)
  return res.data
}
export const deleteImage = async (data: any) => {
  const res = await axiosInstance.post(DELETE_IMAGE, data)
  return res.data
}

export const create_update_Association = async (data: any) => {
  let url = data._id ? UPDATE_ASSOCIATION : CREATE_ASSOCIATION
  const res = await axiosInstance.post(url, JSON.stringify(data))
  return res.data
}

export const uploadAssociationImage = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_ASSOCIATION_IMAGE, data)
  return res.data
}

export const getAssociation = async (data: any) => {
  const res = await axiosInstance.post(GET_ASSOCIATION, JSON.stringify(data))
  return res.data
}

export const create_update_Competencies = async (data: any) => {
  let url = data._id ? UPDATE_COMPETENCIES : CREATE_COMPETENCIES
  const res = await axiosInstance.post(url, JSON.stringify(data))
  return res.data
}

export const uploadCompetencyImage = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_COMPETENCIES_IMAGE, data)
  return res.data
}

export const getAllCompetencies = async (data: any) => {
  const res = await axiosInstance.post(GET_COMPETENCIES, JSON.stringify(data))
  return res.data
}

export const deleteAssociation = async (data: any) => {
  const res = await axiosInstance.post(DELETE_ASSOCIATION, JSON.stringify(data))
  return res.data
}

export const deleteCompetencies = async (data: any) => {
  const res = await axiosInstance.post(DELETE_COMPETENCIES, JSON.stringify(data))
  return res.data
}

export const updateOperativeTypePermission = async (data: any) => {
  const res = await axiosInstance.post(UPDATE_OPERATIVE_TYPE_PERMISSION, JSON.stringify(data))
  return res.data
}

export const uploadObservationImage = async (data: any) => {
  const res = await axiosInstance.post(UPLOAD_OBSERVATION_IMAGE, data)
  return res.data
}

export const saveObservation = async (data: any) => {
  let url = data?.observations?.observationId ? UPDATE_OBSERVATIONS : CREATE_OBSERVATIONS
  const res = await axiosInstance.post(url, JSON.stringify(data))
  return res.data
}

export const deleteObservation = async (data: any) => {
  const res = await axiosInstance.post(DELETE_OBSERVATIONS, data)
  return res.data
}
