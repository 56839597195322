import clsx from 'clsx';
import { ROUTES } from 'constants/routes';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const DefaultTitle: FC = () => {

  const vault = useSelector((state: any) => state.vault);
  const job = useSelector((state: any) => state.job);

  const [breadCrumbs, setBreadCrumbs]: any[] = useState([]);

  const { pathname } = useLocation();

  useEffect(() => {
    renderBreadCrunbs();
  }, [pathname, job]);

  const renderBreadCrunbs = () => {
    if (pathname === '/' || pathname === '/dashboard') {
      setBreadCrumbs([
        { isActive: false, isSeparator: false, path: '', title: "Dashboard" },
      ]);
    }
    else if (pathname?.includes(ROUTES.ADMINISTRATION_JOBS_CREATE) || pathname?.includes(ROUTES.ADMINISTRATION_JOBS_EDIT)) {
      let isEdit = pathname?.includes(ROUTES.ADMINISTRATION_JOBS_CREATE) ? false : true;
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.ADMINISTRATION_JOBS, title: "Jobs" },
        { isActive: false, isSeparator: false, path: '', title: `${isEdit ? "Edit" : 'Create'} Job` },
      ]);
    }
    else if (pathname?.includes(ROUTES.ADMINISTRATION_JOBS)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Jobs" },
      ]);
    }
    else if (pathname?.includes(ROUTES.VAULT_HOME)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Vault" },
      ]);
    }
    else if (pathname?.includes(ROUTES.VAULT_DETAIL)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.VAULT_HOME, title: "Vault" },
        { isActive: false, isSeparator: false, path: '', title: "Vault Details" },
      ]);
    }
    else if (pathname?.includes(ROUTES.ASSETS_ARCHIVE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.VAULT_HOME, title: "Vault" },
        { isActive: true, isSeparator: true, path: `${ROUTES.VAULT_DETAIL}${job?.buildingId}`, title: "Vault Details" },
        { isActive: false, isSeparator: false, path: '', title: "Archived Assets" },
      ]);
    }
    else if (pathname?.includes(ROUTES.ASSETS_DETAIL)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.VAULT_HOME, title: "Vault" },
        { isActive: true, isSeparator: true, path: `${ROUTES.VAULT_DETAIL}${vault?.AssetInfo?.building}`, title: "Vault Details" },
        { isActive: false, isSeparator: false, path: '', title: "Asset Details" },
      ]);
    }
    else if (pathname?.includes(ROUTES.CLIENT_LIST)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Profiles" },
      ]);
    }
    else if (pathname?.includes(ROUTES.ARCHIVE_CLIENT)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.CLIENT_LIST, title: "Profiles" },
        { isActive: false, isSeparator: false, path: '', title: "Archived Profiles" },
      ]);
    }
    else if (pathname?.includes(ROUTES.FACILITY_MANAGER)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "FM Register" },
      ]);
    }
    else if (pathname?.includes(ROUTES.FACILITY_MANAGER_ARCHIVE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.FACILITY_MANAGER, title: "FM Register" },
        { isActive: false, isSeparator: false, path: '', title: "Archived FM" },
      ]);
    }
    else if (pathname?.includes(ROUTES.BUILDING_LIST)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Buildings" },
      ]);
    }
    else if (pathname?.includes(ROUTES.BUILDING_ARCHIVE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.BUILDING_LIST, title: "Buildings" },
        { isActive: false, isSeparator: false, path: '', title: "Archived Buildings" },
      ]);
    }
    else if (pathname?.includes(ROUTES.OPERATIVE_LIST)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Operatives" },
      ]);
    }
    else if (pathname?.includes(ROUTES.OPERATIVE_ARCHIVE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.OPERATIVE_LIST, title: "Operatives" },
        { isActive: false, isSeparator: false, path: '', title: "Archived Operatives" },
      ]);
    }
    else if (pathname?.includes(ROUTES.OPERATIVE_OVERVIEW)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.OPERATIVE_LIST, title: "Operatives" },
        { isActive: false, isSeparator: false, path: '', title: "Operative Overview" },
      ]);
    }
    else if (pathname?.includes(ROUTES.NEWS_LIST)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "News" },
      ]);
    }
    else if (pathname?.includes(ROUTES.NEWS_ARCHIVE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.NEWS_LIST, title: "News" },
        { isActive: false, isSeparator: false, path: '', title: "Archived News" },
      ]);
    }
    else if (pathname?.includes(ROUTES.FORUM_LIST)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Forum" },
      ]);
    }
    else if (pathname?.includes(ROUTES.REPORT_LISTING)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Reports" },
      ]);
    }
    else if (pathname?.includes(ROUTES.ARCHIVED_REPORTS)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.REPORT_LISTING, title: "Reports" },
        { isActive: false, isSeparator: false, path: '', title: "Archived Reports" },
      ]);
    }
    else if (pathname?.includes(ROUTES.SETTINGS_LIST)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: false, isSeparator: false, path: '', title: "Settings" },
      ]);
    }
    else if (pathname?.includes(ROUTES.ACCESIBILTY)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Accessibility" },
      ]);
    }
    else if (pathname?.includes(ROUTES.FIREBARRIROR)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Fire Barrier" },
      ]);
    }
    else if (pathname?.includes(ROUTES.INSTALLATION)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Installation" },
      ]);
    }
    else if (pathname?.includes(ROUTES.DAMPTER)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Damper Types" },
      ]);
    }
    else if (pathname?.includes(ROUTES.DUCT)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Duct Types" },
      ]);
    }
    else if (pathname?.includes(ROUTES.DEFECT_SETTINGS)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Defect Categories" },
      ]);
    }
    else if (pathname?.includes(ROUTES.PPM_MOBILE_GUIDANCE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "PPM Mobile Guidance" },
      ]);
    }
    else if (pathname?.includes(ROUTES.REPORT_GUIDANCE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Report Guidance" },
      ]);
    }
    else if (pathname?.includes(ROUTES.REPORT_SERVICE)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Report Service" },
      ]);
    }
    else if (pathname?.includes(ROUTES.REPORT_DISCLAIMER)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Report Disclaimer" },
      ]);
    }
    else if (pathname?.includes(ROUTES.USER_ACCESS_PERMISSIONS)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "User Access Permissions" },
      ]);
    }
    else if (pathname?.includes(ROUTES.ASSOCIATIONS)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Associations" },
      ]);
    }
    else if (pathname?.includes(ROUTES.COMPETENCIES)) {
      setBreadCrumbs([
        { isActive: true, isSeparator: true, path: ROUTES.DASHBOARD, title: "Dashboard" },
        { isActive: true, isSeparator: true, path: ROUTES.SETTINGS_LIST, title: "Settings" },
        { isActive: false, isSeparator: false, path: '', title: "Competencies" },
      ]);
    }
  };

  return (
    <>
      <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
        {Array.from(breadCrumbs).map((item: any, index: number) => (
          <li
            className={clsx('', {
              'text-dark': !item.isSeparator && item.isActive,
              'text-muted': !item.isSeparator && !item.isActive,
            })}
            key={`${item.path}${index}`}
          >
            {item.isSeparator ? (
              <>
                <Link className='text-primary text-hover-primary' to={item.path}>
                  {item.title}
                </Link>
                <span className='text-muted px-3'><i className='pi pi-angle-right'></i></span>
              </>
            ) : (
              <span className='text-muted'>{item.title}</span>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export { DefaultTitle };

