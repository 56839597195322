import RoleBasedRouting from 'app/components/shared/roleBasedRouting.component';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MenuTestPage } from '../pages/MenuTestPage';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';

export function PrivateRoutes() {
  const JobPage = lazy(() => import('../pages/jobs/JobPage'));
  const ClientPage = lazy(() => import('../pages/profile/ClientPage'));
  const ReportPage = lazy(() => import('../pages/reports/ReportPage'));
  const VaultPage = lazy(() => import('../pages/vault/VaultPage'));
  const OperativePage = lazy(() => import('../pages/operative/OperativePage'));
  const BuildingPage = lazy(() => import('../pages/buildings/BuildingPage'));
  const FMPage = lazy(() => import('../pages/facility-manager/FacilityManagerPage'));
  const NewsPage = lazy(() => import('../pages/news/NewsPage'));
  const SettingsPage = lazy(() => import('../pages/settings/SettingsPage'));
  const PermissionsPage = lazy(() => import('../pages/settings/Permissions'));
  const ReportTemplatePage = lazy(() => import('../pages/report-templates/ReportTemplatePage'));
  const ClientRepresentativePage = lazy(
    () => import('../pages/client-rep/ClientRepresentativePage')
  );
  const ForumPage = lazy(() => import('../pages/forum/ForumPage'));

  return (
    <>
      <RoleBasedRouting></RoleBasedRouting>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/administration' component={JobPage} />
        <Route path='/vault' component={VaultPage} />
        <Route path='/profile' component={ClientPage} />
        <Route path='/client-representative' component={ClientRepresentativePage} />
        <Route path='/fm' component={FMPage} />
        <Route path='/buildings' component={BuildingPage} />
        <Route path='/operative' component={OperativePage} />
        <Route path='/reports' component={ReportPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Route path='/news' component={NewsPage} />
        <Route path='/settings' component={SettingsPage} />
        <Route path='/permissions' component={PermissionsPage} />
        <Route path='/forum' component={ForumPage} />
        <Route path='/report-templates' component={ReportTemplatePage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect exact from='/' to='/error/404' />
      </Switch>
    </>
  );
}
