export const SET_ADMIN_OPERATIVE = 'SET_ADMIN_OPERATIVE'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'

export const actions = {
  setClientId: (data: any) => {
    return {
      type: SET_ADMIN_OPERATIVE,
      payload: data,
    }
  },
  setClientPermission: (data: any) => {
    return {
      type: SET_PERMISSIONS,
      payload: data,
    }
  },
}

const initialState = {
  adminOperative: false,
  Permissions: [],
}

export const reducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_ADMIN_OPERATIVE:
      return {...state, adminOperative: action.payload}
    case SET_PERMISSIONS:
      return {...state, Permissions: action.payload}
    default:
      return state
  }
}
