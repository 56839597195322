/* eslint-disable array-callback-return */
import DialogueBox from 'app/components/DialogueBox';
import { error, success } from 'app/components/toast';
import {
  ClientProps,
  ClientRepresentativesProps,
  FacilityManagerProps,
  JobStatusProps,
  TestRegimeProps,
} from 'app/modules/auth/redux/Types';
import {
  SET_ASSET_PAGE_DATA,
  SET_ASSET_REGISTER_DATA,
  SET_CERTIFICATE_DATA,
  SET_CLIENT_DETAILS,
  SET_CREATE_INSPECTION_JOB_DATA,
  SET_DEFECT_LOG_DATA,
  SET_INVENTORY_DATA,
  SET_JOB_DETAILS,
  SET_OPERATIVES_COMPETENCES,
  SET_REPORT_HEADER_DATA,
  SET_SERVICES_DATA,
  SET_SUMMARY_DATA,
} from 'app/modules/auth/redux/create-report/actions';
import {
  createAssetRegister,
  createBuilding,
  createBuildingAssetReport,
  createCompetencyReport,
  createDefectLog,
  createInspectionReport,
  createRemedialInspectionReport,
  createReportCertificate,
  createReportFooterTemplate,
  createReportGuidance,
  createReportHeaderTemplate,
  createReportInventory,
  createReportService,
  createReportSummary,
  getBuildingsWithDetails,
  getClientRepresentatives,
  getClients,
  getFacilityManagers,
  getReferences,
  getReportGuidanceTemplateListing,
  getReportServiceTemplateListing,
  getSpecificJob,
  getVaults,
  updateBuilding,
  uploadBuildingImage,
} from 'app/modules/auth/redux/jobs/JobsCRUD';
import { SET_INITAIL_VAULT } from 'app/modules/auth/redux/vault';
import {
  getFromStorage,
  getNextTestDate,
  getOperativesByIds,
  getRemedialReportActionLog,
  getRemedialReportSummary,
  onPublishHeaderRemedial,
  onSaveRemedialActionLog,
  onSaveRemedialCertificate,
  onSaveRemedialCompetency,
  onSaveRemedialFooter,
  onSaveRemedialSummary,
  onSaveReportToLibrary,
  removeEveryKey,
  saveToStorage,
} from 'common/fetch-data';
import { LOCATION } from 'constants/endPoints';
import { useFormik } from 'formik';
import { GetPermissionsByName } from 'hooks/usePermissionAcess';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';
import { fmArgs } from '../facility-manager/FacilityManager';
import { clientRepresentativeArgs, clientsArgs } from '../profile/Clients';
import { guidanceTemplateProps } from '../report-templates/guidance-templates';
import { serviceTemplateProps } from '../report-templates/service-templates';
import { vaultArgs } from '../vault';

import LoaderComman from 'app/components/shared/loader.component';
import { SET_BUILDING_MODAL_VISIBLE } from 'app/modules/auth/redux/jobs/actions';
import BUildingsDataTable from '../../components/data-table/customDataTable';


import React from 'react';
import placeholderimage from '../../../assets/placeholderimage.jpg';


import RoleBaseAccess from 'app/components/shared/roleBaseAccess.component';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';

export const DEFAULT_VAULT = '64908257be8bcc50f84d55e4';

const testStatusArray = [
  { value: 'pending', label: 'Pending' },
  { value: 'started', label: 'Started' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
];

const testRegimeData = [
  { value: '3 months', label: '3 Months' },
  { value: '6 months', label: '6 Months' },
  { value: '9 months', label: '9 Months' },
  { value: '12 months', label: '12 Months' },
];

export const buildingsArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  limit: 1000,
  query: {
    critarion: {
      active: true,
    },
    fields: 'buildingName',
  },
};

export const buildingDetailsArgs = {
  sortproperty: 'createdAt',
  sortorder: -1,
  offset: 0,
  pageNum: 1,
  search: '',
  limit: 10000,
  query: {
    critarion: {
      active: true,
    },
    clientFields: 'clientName',
    buildingAssetsFilter: { active: true, assetArchived: false },
    buildingAssetsFields: '',
    clientRepresentativeFields: 'clientRepresentativeName',
    facilityManagerFields: 'facilityManagerName',
    buildingFields:
      'buildingReference buildingName buildingAddress testDate testStatus inspectionJobs buildingAssets buildingLocation numberOfDefects, buildingImage',
    addedby: ' email name',
    lastModifiedBy: ' email name',
  },
};

export const testRegimeArgs = {
  critarion: {
    _id: '',
  },
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

export const buildingByIdArgs = {
  critarion: {
    _id: '',
  },
  buildingAssetsFilter: { assetArchived: false },
  clientFields: '',
  clientRepresentativeFields: 'clientRepresentativeName',
  facilityManagerFields: 'facilityManagerName',
  addedby: '_id email name',
  lastModifiedBy: '_id email name',
};

export interface CreateBuildingProps {
  _id?: string;
  buildingid?: string;
  vaultId: string;
  buildingReference: string;
  buildingName: string;
  buildingAddress: string;
  client: string;
  facilityManager: string;
  clientRepresentative: string;
  testDate?: string;
  // testStatus: string;
  testRegime?: string;
  buildingLocation: {
    type: 'Point';
    coordinates: [number, number];
  };
  buildingImage?: string;
}

export interface LocationProps {
  lat: number;
  lng: number;
  address?: string;
}

export interface IReportProps {
  competencies?: any[];
  header?: any;
  certificate?: any;
  assetRegister?: any[];
  defectLog?: any[];
  inventory?: any;
  assetPage?: any[];
  summary?: any;
  service?: any;
}

const initialSummaryData: any = {
  noOfDirtyDampers: '0',
  noOfModerateDampers: '0',
  noOfCleanDampers: '0',
  totalNumberOfDampers: '0',
  noOfeClass: '0',
  noOfsClass: '0',
  noOfesClass: '0',
  noOfiClass: '0',
  noOfCompliantDampers: '0',
  noOfAdvisorNotes: '0',
  noOfNonCompliantDampers: '0',
  noOfUntestedDampers: '0',
  noOfDmpsAccPnlBothSides: '0',
  noOfDmpsPanlOneSide: '0',
};

const FMRegister = () => {
  const dispatch = useDispatch();
  const { operativeCompetences } = useSelector((state: any) => state.reportHeader);
  const { buildingModalVisible } = useSelector((state: any) => state.job);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<any>([]);
  const [facilityManagers, setFacilityManagers] = useState<any>([]);
  const [vaults, setVaults] = useState<any>({});
  const [clientRepresentatives, setClientRepresentatives] = useState<any>([]);
  const [testStatusList, setTestStatusList] = useState<JobStatusProps[]>(testStatusArray);
  const [testRegimeList] = useState<TestRegimeProps[]>(testRegimeData);
  const [location, setLocation] = useState<LocationProps>({
    lat: 0,
    lng: 0,
  });
  const [buildingData, setBuildingData] = useState<any>([]);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [sortProperty, setSortProperty] = useState<any>({
    active: true,
  });
  const [showBuildingModal, setShowBuildingModal] = useState<boolean>(false);
  const [sortPropertys, setSortPropertys] = useState<string>('businessName');
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [reportData, setReportData] = useState<IReportProps>();
  const [certificateData, setCertificateData] = useState<any>(null);
  const [summaryData, setSummaryData] = useState<any>(initialSummaryData);
  const [headerData, setHeaderData] = useState<any>(null);
  const [defectLogData, setDefectLogData] = useState<any>(null);
  const [inventoryData, setInventoryData] = useState<any>(null);
  const [competenciesData, setCompetenciesData] = useState<any>(null);
  const [assetRegisterData, setAssetRegisterData] = useState<any>(null);
  const [servicesData, setServicesData] = useState<any>(null);
  const [assetPageData, setAssetPageData] = useState<any>(null);
  const [guidanceData, setGuidanceData] = useState<any>(null);
  const [dialogueBox, setDialogueBox] = useState<any>({ flag: false, record: {} });

  const [totalRecords, setTotalRecords] = useState(0);
  const [firstRow, setFirstRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isTableLoading, setTableLoading] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [sortField, setSortField] = useState('createdAt');
  const [isChanged, setChanged] = useState(false);

  const [buildingImage, setBuildingImage] = useState('');

  const {
    inspectionJobId,
    reportHeaderData,
    reportCertificateData,
    reportGuidanceData,
    reportServicesData,
    reportSummaryData,
    reportAssetRegisterData,
    reportDefectLogData,
    reportAssetPageData,
    reportInventoryData,
    reportCompetencyData,
    reportFooterData,
    createInspectionJobData,
    loaded,
  } = useSelector((state: any) => state.reportHeader);

  useEffect(() => {
    fetchData();
  }, [buildingModalVisible]);

  const fetchData = async () => {
    try {
      let references = await getReferences({ getReferences: ['BD'] });
      if (references.status === 'Success') {
        formik.setFieldValue('buildingReference', references.data.BD);
      }
    } catch (error) { }
  };

  const onHideBuildingModal = () => {
    setShowBuildingModal(false);
    formik.resetForm();
    dispatch({
      type: SET_BUILDING_MODAL_VISIBLE,
      payload: false,
    });
  };

  const onShowBuildingModal = () => {
    setShowBuildingModal(true);
  };

  const getClientsListing = async () => {
    const clientsData = await getClients(clientsArgs);
    return clientsData.data.clients;
  };

  const getFacilityManagersListing = async () => {
    const facilityManagersData = await getFacilityManagers(fmArgs);
    return facilityManagersData.data.facilityManagers;
  };

  const getClientRepresentativesListing = async () => {
    const clientReps = await getClientRepresentatives(clientRepresentativeArgs);
    return clientReps.data.clientRepresentatives;
  };

  const getAllData = async () => {
    Promise.all([
      getClientsListing(),
      getFacilityManagersListing(),
      // getVaultListing(),
      getClientRepresentativesListing(),
      getBuildingListing(),
    ]).then((values) => {
      setClients(values[0]);
      setFacilityManagers(values[1]);
      // let obj = Object.assign({}, ...values[2])
      // setVaults(obj)
      setClientRepresentatives(values[2]);
      setBuildingData(values[3]);
      setLoading(false);
    });
  };

  const vaultData = useSelector((state: any) => state?.vault);

  const getVaultListing = async () => {
    const vaultData = await getVaults(vaultArgs);
    let obj = Object.assign({}, ...vaultData.data.vaults);
    dispatch({
      type: SET_INITAIL_VAULT,
      payload: obj,
    });
  };

  // let obj = vaults[0]

  const getBuildingListing = async (isSetData: boolean = false) => {
    buildingDetailsArgs.pageNum = pageNumber;
    buildingDetailsArgs.limit = pageSize;
    buildingDetailsArgs.sortorder = sortOrder;
    buildingDetailsArgs.sortproperty = sortField;
    buildingDetailsArgs.search = filterValue;

    const args = buildingDetailsArgs;
    args.sortorder = sortOrder;
    args.query.clientFields =
      'clientName clientEmail address phoneNumber logoPath website VATNumber companyRegisterationNo themeColor fontColor associations';
    args.offset = 0;
    args.query.critarion.active = sortProperty.active;
    const res = await getBuildingsWithDetails(args);
    setTableLoading(false);
    let data = res.data?.buildings?.map((b: any, i: number) => {
      return {
        ...b,
        serial: i + 1,
        clientName: b.client?.clientName,
        clientRepresentativeName: b.clientRepresentative?.clientRepresentativeName,
        facilityManagerName: b.facilityManager?.facilityManagerName,
      };
    });

    setTotalRecords(res.data.totalRecords);

    if (isSetData) {
      setBuildingData(data);
    }
    return data;
  };

  useEffect(() => {
    setLoading(true);
    getAllData();
    getVaultListing();
  }, []);

  const headerStyle = (column: any, index: number) => {
    return {
      fontWeight: 'bold',
    };
  };

  const onArchive = async (id: string) => {
    const args: any = {
      buildingid: id,
      active: false,
    };
    setTableLoading(true);
    const res = await updateBuilding(args);
    setTableLoading(false);
    if (res.status === 'Success') {
      success('Building archived successfully');
      getAllData();
    } else {
      error('Something went wrong');
      setLoading(false);
    }
  };

  const getGuidanceTemplate = async () => {
    const args: any = guidanceTemplateProps;
    const res = await getReportGuidanceTemplateListing(args);

    const { guidanceTitle, regulationLaws, regulationsStandands } = res.data.guidanceTemplates[0];
    const data: any = {
      guidanceTitle: 'Guidance',
      regulationLaws: regulationLaws.map((item: any) => {
        delete item._id;
        return item;
      }),
      regulationsStandands: regulationsStandands.map((item: any) => {
        delete item._id;
        return item;
      }),
    };
    onSaveGuidance(data);
  };

  const getServiceTemplates = async () => {
    const args: any = serviceTemplateProps;
    const res = await getReportServiceTemplateListing(args);
    delete res.data.serviceTemplates[0]._id;
    delete res.data.serviceTemplates[0].__v;
    delete res.data.serviceTemplates[0].createdAt;
    delete res.data.serviceTemplates[0].updatedAt;
    delete res.data.serviceTemplates[0].addedby;
    res.data.serviceTemplates[0].serviceBodies.map((body: any) => {
      delete body._id;
    });
    dispatch({
      type: SET_SERVICES_DATA,
      payload: res.data.serviceTemplates[0],
    });
    // setReportData({
    //   ...reportData,
    //   service: res.data.serviceTemplates[0]
    // });
    setServicesData(res.data.serviceTemplates[0]);
    saveToStorage('services', res.data.serviceTemplates[0]);
  };

  const jobArgs: any = {
    critarion: {
      _id: '',
    },
    clientFields:
      'clientName clientEmail address phoneNumber logoPath website VATNumber companyRegisterationNo themeColor fontColor associations memberShipNumber',
    clientRepresentativeFields: 'clientRepresentativeName',
    facilityManagerFields: 'facilityManagerName',
    opperativesFields: 'operativeName',
    buildingFields:
      'buildingReference buildingName buildingAddress testDate testStatus inspectionJobs buildingAssets buildingLocation testRegime',
    buildingAssetsFields: '',
    addedby: '_id email name',
    lastModifiedBy: '_id email name',
  };

  const onPublishReport = async (building: any) => {
    await getGuidanceTemplate();
    await getServiceTemplates();
    const { client, inspectionJobs } = building;
    const jobs: any[] = [];

    if (inspectionJobs.length > 0) {
      await Promise.all(
        inspectionJobs.map(async (job: string) => {
          jobArgs.critarion._id = job;
          const res: any = await getSpecificJob(jobArgs);
          const operatives = await getOperativesByIds(res.data.operatives);

          res.data.operatives = operatives;
          jobs.push(res.data);

          onSaveHeader(res.data.client);

          const competencyData = {
            companyCompetencies: [],
            operativeCompetencies: operatives,
          };

          dispatch({
            type: SET_OPERATIVES_COMPETENCES,
            payload: competencyData,
          });
          saveToStorage('competency', competencyData);

          setCompetenciesData(competencyData);

          dispatch({
            type: SET_CLIENT_DETAILS,
            payload: res.client,
          });
          dispatch({
            type: SET_JOB_DETAILS,
            payload: res.data,
          });
        })
      );

      if (jobs.length > 0) {
        jobs.map(async (job: any) => {
          if (job.jobType === 'AFDS') {
            await onCreateAFDSReport(job);
          } else if (job.jobType === 'Remedial') {
            await onCreateRemedialReport(job);
          }
        });
      }
    } else {
      error('No jobs found');
      return;
    }
  };

  const onSaveHeader = async (clientDetails: any) => {
    const data = {
      clientName: clientDetails.clientName,
      clientPhone: clientDetails.phoneNumber,
      clientEmailAddress: clientDetails.clientEmail,
      clientWebsite: clientDetails.website,
    };
    dispatch({
      type: SET_REPORT_HEADER_DATA,
      payload: data,
    });
    await saveToStorage('header', data);
    // setReportData({
    //   ...reportData,
    //   header: data
    // });
    setHeaderData(data);
  };

  const onPublishHeader = async (data: any) => {
    const headerData = getFromStorage('header');
    const res = await createReportHeaderTemplate(headerData);
    if (res.status === 'Success') {
      const headerId = res.data._id;
      return res.data;
    }
  };

  const onSaveCertificate = async (job: any) => {
    const data: any = {
      address: job.building.buildingAddress,
      phone: job.client.phoneNumber,
      email: job.client.clientEmail,
      client: job.client.clientName,
      associations: job.client.associations,
      themeColor: job.client.themeColor,
      fontColor: job.client.fontColor,
      website: job.client.website,
      logopath: job.client.logoPath,
      jobref: job.jobReference,
      facilitymanager: job.facilityManager.facilityManagerName,
      buildingReference: job.building.buildingReference,
      buildingName: job.building.buildingName,
      clientRepresentative: job.clientRepresentative.clientRepresentativeName,
      testRegime: job.building.testRegime,
      membershipNumber: job.membershipNumber || '',
      operatives: job.operatives || [],
      inspectionDate: moment(Date.now()).format('YYYY-MM-DD'),
    };
    dispatch({
      type: SET_CERTIFICATE_DATA,
      payload: data,
    });
    saveToStorage('certificate', data);
    // setReportData({
    //   ...reportData,
    //   certificate: data
    // });
    setCertificateData(data);
  };

  const onSaveGuidance = async (data: any) => {
    // dispatch({
    //   type: SET_GUIDANCE_DATA,
    //   payload: data
    // });
    setGuidanceData(data);
    saveToStorage('guidance', data);
  };

  const onSaveAssetRegister = async (data: any) => {
    if (data) {
      const getOps = async () => {
        // const ops = await getOperativesByIds(data.operatives).then((res: any) => {
        //   data.operatives = res;
        //   return res;
        // })
        const formData: any[] = [];
        if (data.buildingAssets.length > 0) {
          data.buildingAssets.map((asset: any, i: number) => {
            const latestTest = asset?.buildingAsset.tests.length - 1;
            formData.push({
              assetNo: asset?.buildingAsset?.assetReference || 'N/A',
              locationReference: asset?.buildingAsset?.assetLocation || 'N/A',
              operatives: data.operatives,
              testResult:
                asset?.buildingAsset.tests.length > 0
                  ? moment(asset?.buildingAsset.tests[latestTest].testDate).format('YYYY-MM-DD')
                  : 'N/A',
              installationInspection:
                asset?.buildingAsset.tests.length > 0
                  ? asset?.buildingAsset.tests[latestTest].satisffactoryStatus
                  : 'NOT TESTED',
              fireBarrierInspection: asset?.buildingAsset?.fireBarrier?.satisfactionStatus || 'N/A',
            });
          });
          const obj: any = {
            assets: formData,
            sectionName: 'Asset Register',
          };
          dispatch({
            type: SET_ASSET_REGISTER_DATA,
            payload: obj,
          });

          saveToStorage('assetRegister', obj);
          setAssetRegisterData(obj);
        } else {
          const obj: any = {
            assets: [],
            sectionName: 'Asset Register',
          };
          dispatch({
            type: SET_ASSET_REGISTER_DATA,
            payload: obj,
          });
          saveToStorage('assetRegister', obj);
          setAssetRegisterData(obj);
        }
        // setReportData({
        //   ...reportData,
        //   assetRegister: formData
        // });
      };
      getOps();
    }
  };

  const onSaveDefectLog = async (job: any) => {
    const data: any[] = [];
    job.buildingAssets.map((asset: any, i: number) => {
      let accessibility = asset?.buildingAsset?.accessibility;
      let installation = asset.buildingAsset.installation;
      let fireBarrier = asset.buildingAsset.fireBarrier;
      let accessPanel = asset.buildingAsset.accessPanel;
      let assetRef = asset.buildingAsset.assetReference;

      if (accessibility.defect || accessibility.defect !== null) {
        accessibility.defects.map((defect: any) => {
          data.push({
            name: 'Accessibility',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }

      if (installation.defect || installation.defect !== null) {
        installation.defects.map((defect: any) => {
          data.push({
            name: 'Installation',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }
      if (fireBarrier.defect || fireBarrier.defect !== null) {
        fireBarrier.defects.map((defect: any) => {
          data.push({
            name: 'Fire Barrier',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }
      if (accessPanel.defect || accessPanel.defect !== null) {
        accessPanel.defects.map((defect: any) => {
          data.push({
            name: 'Access Panel',
            assetRef: assetRef || 'N/A',
            location: asset.buildingAsset.assetLocation || 'N/A',
            defectCategory: defect.defectCategoryName || 'N/A',
            defectSubCategory: defect.defectSubCategoryName || 'N/A',
            defectNotes: defect.defectDetails || 'N/A',
            defectLogImageUrls: defect.defectImageUrls || [],
          });
        });
      }
    });

    dispatch({
      type: SET_DEFECT_LOG_DATA,
      payload: data,
    });
    saveToStorage('defectLog', data);
    // setReportData({
    //   ...reportData,
    //   defectLog: data
    // });
    setDefectLogData(data);
  };

  const onSaveInventory = async (job: any) => {
    const data: any = {
      inventoryAssets: [
        ...job.buildingAssets.map((asset: any) => {
          return {
            assetReference: asset?.buildingAsset?.assetReference || 'N/A',
            assetLocation: asset?.buildingAsset?.assetLocation || 'N/A',
            damperType: asset?.buildingAsset?.ductDamper?.damperTypeName.length
              ? asset?.buildingAsset?.ductDamper?.damperTypeName[0]?.damperTypeName
              : 'N/A',
            accessibility: asset?.buildingAsset?.accessibility?.accessibilitySkipped
              ? 'Auto'
              : 'Manual',
          };
        }),
      ],
      assetNo: job?.buildingAssets[0]?.buildingAsset.assetReference || 'N/A',
      assetLocation: job?.buildingAssets[0]?.buildingAsset.assetLocation || 'N/A',
      damperCassification:
        job?.buildingAssets[0]?.buildingAsset.ductDamper.damperTypeName.length ||
        job?.buildingAssets[0]?.buildingAsset.ductDamper.damperTypeName[0].damperTypeName ||
        'N/A',
      accessibility: job?.buildingAssets[0]?.buildingAsset.accessibility.accessibilitySkipped
        ? 'Skipped'
        : job?.buildingAssets[0]?.buildingAsset.accessibility.accessibilityType || 'N/A',
    };

    const assets: any[] = [];
    data.inventoryAssets.length &&
      data.inventoryAssets.map((asset: any) => {
        assets.push(JSON.stringify(asset));
      });
    data.inventoryAssets = assets;

    dispatch({
      type: SET_INVENTORY_DATA,
      payload: data,
    });
    saveToStorage('inventory', data);
    // setReportData({
    //   ...reportData,
    //   inventory: data
    // });
    setInventoryData(data);
  };

  const onSaveAssetPage = async (job: any) => {
    const assetsData: any[] = [];
    const { buildingAssets } = job || {};
    const assets =
      buildingAssets !== undefined || buildingAssets !== null
        ? buildingAssets.length > 0
          ? buildingAssets[0]
          : null
        : null;
    if (job && job.buildingAssets.length && assets) {
      // loop through array of building assets and set values to formik
      job.buildingAssets.map((asset: any) => {
        assetsData.push({
          buildingRef: job?.building?.buildingName || 'N/A',
          buildingName: job?.building?.buildingName || 'N/A',
          assetNo: asset.buildingAsset?.assetReference || 'N/A',
          qrImagePath: asset.buildingAsset.assetQrCode || 'N/A',
          assetImages: asset.buildingAsset.assetImages,
          locationRef: asset.buildingAsset.buildingAsset?.assetLocation || 'N/A',
          accessibilityName:
            asset.buildingAsset.accessibility?.accessibilityOptions[0].accessibilityName || 'N/A',
          accessibilitySatisfaction: asset.buildingAsset.accessibility.satisfactionStatus || 'N/A',
          fireBarrierName:
            asset.buildingAsset.fireBarrier?.fireBarrierOptions[0]?.fireBarrierName || 'N/A',
          fireBarrierSatisfaction: asset.buildingAsset.fireBarrier.satisfactionStatus || 'N/A',
          installationName:
            asset.buildingAsset.installation?.installationName[0]?.installationName || 'N/A',
          installationOrientation: asset.buildingAsset.installation.orientation || 'N/A',
          installationSatisfaction: asset.buildingAsset.installation.satisfactionStatus || 'N/A',
          ductyType: asset.buildingAsset.ductDamper.ductyType || 'N/A',
          ductSize: asset.buildingAsset.ductDamper.ductSize || 'N/A',
          ductShape: asset.buildingAsset.ductDamper.ductShapeName || 'N/A',
          damperClassification:
            asset.buildingAsset.ductDamper.damperTypeName.length > 0
              ? asset.buildingAsset.ductDamper.damperTypeName[0].damperTypeName || 'N/A'
              : 'N/A',
          accessPanelName: asset.buildingAsset.accessPanel.accessPanelOptionValue || 'N/A',
          accessPanelShape: asset.buildingAsset.accessPanel.shape || 'N/A',
          accessPanelSize: asset.buildingAsset.accessPanel.size || 'N/A',
          accessPanelBothSides: asset.buildingAsset.accessPanel.installedBothSides || 'N/A',
          accessPanelSatisfaction: asset.buildingAsset.accessPanel.accessPanelSatisfaction || 'N/A',
          testAsFoundImage: asset.buildingAsset.tests.length
            ? asset.buildingAsset.tests[0]?.test?.asFoundImageUrl || 'N/A'
            : 'N/A',
          testDropTestImage: asset.buildingAsset.tests.length
            ? asset.buildingAsset.tests[0]?.test?.dropTestImageUrl || 'N/A'
            : 'N/A',
          testRestImage: asset.buildingAsset.tests.length
            ? asset.buildingAsset.tests[0]?.test?.resetImageUrl || 'N/A'
            : 'N/A',
          sectionName: 'Asset Page',
          tests: assets.buildingAsset.tests,
        });
      });
      dispatch({
        type: SET_ASSET_PAGE_DATA,
        payload: assetsData,
      });
      saveToStorage('assetPage', assetsData);
      // setReportData({
      //   ...reportData,
      //   assetPage: assetsData
      // });
      // update reportData state and reserve the previous state
      // setReportData((prevState: any) => ({
      //   ...prevState,
      //   assetPage: assetsData
      // }));
      setAssetPageData(assetsData);
    } else {
      assetsData.push({
        buildingRef: 'N/A',
        buildingName: 'N/A',
        assetNo: 'N/A',
        qrImagePath: 'N/A',
        assetImages: 'N/A',
        locationRef: 'N/A',
        accessibilityName: 'N/A',
        accessibilitySatisfaction: 'N/A',
        fireBarrierName: 'N/A',
        fireBarrierSatisfaction: 'N/A',
        installationName: 'N/A',
        installationOrientation: 'N/A',
        installationSatisfaction: 'N/A',
        ductyType: 'N/A',
        ductSize: 'N/A',
        ductShape: 'N/A',
        damperClassification: 'N/A',
        accessPanelName: 'N/A',
        accessPanelShape: 'N/A',
        accessPanelSize: 'N/A',
        accessPanelBothSides: 'N/A',
        accessPanelSatisfaction: 'N/A',
        testAsFoundImage: 'N/A',
        testDropTestImage: 'N/A',
        testRestImage: 'N/A',
        sectionName: 'Asset Page',
        tests: [],
      });
      dispatch({
        type: SET_ASSET_PAGE_DATA,
        payload: assetsData,
      });
      saveToStorage('assetPage', assetsData);
    }
  };

  useEffect(() => { }, [
    reportCertificateData,
    // certificateData,
    // summaryData,
    // headerData,
    // defectLogData,
    // inventoryData,
    // competenciesData,
    // assetRegisterData,
    // servicesData,
    // assetPageData,
    // guidanceData,
  ]);

  // const onSaveCompetences = async (job: any) => {

  // }

  const onSaveFooter = async (job: any) => {
    const data = {
      reportJobNumber: job?.jobReference,
      reportSection: 'Report Section',
      publishDate: moment().format(),
      clientName: job?.client?.clientName,
      companyNo: job?.client?.companyRegisterationNo,
      vatNumber: job?.client?.VATNumber,
    };
    const res = await createReportFooterTemplate(data);
    if (res.status === 'Success') {
      return res.data;
    }
  };

  const onSaveSummary = async (job: any) => {
    let eClassCount = 0;
    let esClassCount = 0;
    let IClassCount = 0;
    let SClassCount = 0;
    let cleaned = 0;
    let moderate = 0;
    let dirty = 0;
    let compliantDampers = 0;
    let nonCompliantDampers = 0;
    let withAccessPanelBothSide = 0;
    let withoutAccessPanelBothSide = 0;
    let untested = 0;

    if (job.buildingAssets.length > 0) {
      untested = job.buildingAssets.filter(
        (asset: any) => asset.buildingAsset.tests.length === 0
      ).length;
      // setUntestedDampers(untested);
      // setTotalDampers(job.buildingAssets.length);

      job.buildingAssets.length &&
        job.buildingAssets.map((asset: any) => {
          const { installedBothSides, accessPanelSkipped } = asset.buildingAsset.accessPanel;
          const { tests } = asset.buildingAsset;
          if (tests.length) {
            tests.map((test: any) => {
              const { hygiene } = test;

              if (hygiene.hygieneText === 'Clean') {
                cleaned++;
              }
              if (hygiene.hygieneText === 'Moderate') {
                moderate++;
              }
              if (hygiene.hygieneText === 'Dirty') {
                dirty++;
              }
            });
          }

          if (installedBothSides && accessPanelSkipped) {
            // setWithAccessPanelBothSide(withAccessPanelBothSide + 1);
          } else if (installedBothSides && !accessPanelSkipped) {
            // setWithoutAccessPanelBothSide(withoutAccessPanelBothSide + 1);
          }

          if (asset.buildingAsset.ductDamper.damperTypeName.length) {
            asset.buildingAsset.ductDamper.damperTypeName.map((damper: any) => {
              if (damper.damperTypeName.startsWith('S Class')) {
                SClassCount++;
              }
              if (damper.damperTypeName.startsWith('ES Class')) {
                esClassCount++;
              }
              if (damper.damperTypeName.startsWith('E Class')) {
                eClassCount++;
              }
              if (damper.damperTypeName.startsWith('I Class')) {
                IClassCount++;
              }
            });
          }
          if (!asset.buildingAsset.fireBarrier.defect) {
            compliantDampers++;
            // setCompliantDampers(compliantDampers + 1);
          } else if (asset.buildingAsset.fireBarrier.defect) {
            nonCompliantDampers++;
            // setNonCompliantDampers(nonCompliantDampers + 1);
          }
        });
      // setHygienes({ dirty, cleaned, moderate });
      const data = {
        noOfDirtyDampers: dirty + '',
        noOfModerateDampers: moderate + '',
        noOfCleanDampers: cleaned + '',
        totalNumberOfDampers: job.buildingAssets.length + '',
        noOfeClass: eClassCount + '',
        noOfsClass: SClassCount + '',
        noOfesClass: esClassCount + '',
        noOfiClass: IClassCount + '',
        noOfCompliantDampers: compliantDampers + '',
        noOfAdvisorNotes: 12 + '',
        noOfNonCompliantDampers: nonCompliantDampers + '',
        noOfUntestedDampers: untested + '',
        noOfDmpsAccPnlBothSides: withAccessPanelBothSide + '',
        noOfDmpsPanlOneSide: withoutAccessPanelBothSide + '',
      };

      dispatch({
        type: SET_SUMMARY_DATA,
        payload: data,
      });
      // setReportData({
      //   ...reportData,
      //   summary: data
      // });
      setSummaryData(data);
    } else {
      const data = {
        noOfDirtyDampers: '0',
        noOfModerateDampers: '0',
        noOfCleanDampers: '0',
        totalNumberOfDampers: '0',
        noOfeClass: '0',
        noOfsClass: '0',
        noOfesClass: '0',
        noOfiClass: '0',
        noOfCompliantDampers: '0',
        noOfAdvisorNotes: '0',
        noOfNonCompliantDampers: '0',
        noOfUntestedDampers: '0',
        noOfDmpsAccPnlBothSides: '0',
        noOfDmpsPanlOneSide: '0',
      };
      dispatch({
        type: SET_SUMMARY_DATA,
        payload: data,
      });
      setSummaryData(data);
      await saveToStorage('summary', data);
    }
  };

  const states = useSelector((state: any) => state);

  const onCreateAFDSReport = async (job: any) => {
    await onSaveSummary(job);

    await onSaveAssetRegister(job);

    await onSaveCertificate(job);

    await onSaveDefectLog(job);

    await onSaveInventory(job);

    await onSaveAssetPage(job);

    await onPublishAFDSReport(job);
  };

  const onPublishAFDSReport = async (job: any) => {
    // const data = {
    //   certificate: reportCertificateData,
    //   guidance: reportGuidanceData,
    //   services: reportServicesData,
    //   summary: reportSummaryData,
    //   assetRegister: reportAssetRegisterData,
    //   defectLog: reportDefectLogData,
    //   assetPage: reportAssetPageData,
    //   inventory: reportInventoryData,
    //   competency: reportCompetencyData,
    //   footer: reportFooterData,
    // }

    const reportCertificateData = await getFromStorage('certificate');
    const reportHeaderData = await getFromStorage('header');
    const reportGuidanceData = await getFromStorage('guidance');
    const reportServicesData = await getFromStorage('services');
    const reportSummaryData = await getFromStorage('summary');
    const reportAssetRegisterData = await getFromStorage('assetRegister');
    const reportDefectLogData = await getFromStorage('defectLog');
    const reportAssetPageData = await getFromStorage('assetPage');
    const reportInventoryData = await getFromStorage('inventory');
    const reportCompetencyData = await getFromStorage('competency');

    const [
      header,
      certificate,
      guidance,
      services,
      summary,
      assetRegister,
      defectLog,
      assetPage,
      inventory,
      competency,
      footer,
    ] = await Promise.all([
      onPublishHeader(reportHeaderData),
      createReportCertificate(reportCertificateData),
      createReportGuidance(reportGuidanceData),
      createReportService(reportServicesData),
      createReportSummary(reportSummaryData),
      createAssetRegister(reportAssetRegisterData),
      createDefectLog(reportDefectLogData),
      createBuildingAssetReport(reportAssetPageData),
      createReportInventory(reportInventoryData),
      createCompetencyReport(reportCompetencyData),
      onSaveFooter(job),
    ]);
    const reportData = {
      headerDataReports: header._id,
      certificateReports: certificate.data._id,
      guidanceReport: guidance.data._id,
      serviceReport: services.data._id,
      summaryReports: summary.data._id,
      assetRegisterReports: assetRegister.data._id,
      defectLogReports: defectLog.data._id,
      buildingAssetsReports: assetPage.data._id,
      inventoryReports: inventory.data._id,
      competencyReports: competency.data._id,
      footerDataReports: footer._id,
    };

    // onSaveCompetences(job); //already saving from certificate

    // onSaveFooter(job); //call with other apis

    // return;
    const response = await createInspectionReport(reportData);

    if (response.status === 'Success') {
      // const data: ICreateReportLibraryProps = createInspectionJobData;
      // data.inspectionReport = response.data._id;
      const data: any = {
        inspectionJob: job._id,
        client: job.client._id,
        clientRepresentative: job.clientRepresentative._id,
        facilityManager: job.facilityManager._id,
        building: job.building._id,
        dateRenewalDate: getNextTestDate(job.building.testDate, job.building.testRegime) + '',
        attachment: [],
        archived: false,
        inspectionReport: response.data._id,
      };
      // if (job.jobType === 'AFDS') {
      //   data.inspectionReport = response.data._id
      // }
      // else if (job.jobType === 'Remedial') {
      //   data.remedialInspectionReports = response.data._id
      // }
      dispatch({
        type: SET_CREATE_INSPECTION_JOB_DATA,
        payload: data,
      });
      const message = await onSaveReportToLibrary(data);

      if (message === 'success') {
        success(`Report created successfully`);
        removeEveryKey();
      } else if (message === 'error') {
        error(`Could not create report.`);
      }
      // const id = response.data._id;
      // dispatch({
      //   type: SET_REPORT_ID,
      //   payload: id
      // });
    } else {
      error(`Could not create report.`);
    }
  };

  const onSaveRemedialSummaryData = async (data: any) => {
    if (data) {
      saveToStorage('remedialSummary', data);
    }
  };

  const onSaveActionLog = async (data: any) => {
    if (data) {
      saveToStorage('actionLog', data);
    }
  };

  const onSaveRemedialFooterData = async (job: any) => {
    const data = {
      reportJobNumber: job?.jobReference,
      reportSection: 'Report Section',
      publishDate: moment().format(),
      clientName: job?.client?.clientName,
      companyNo: job?.client?.companyRegisterationNo,
      vatNumber: job?.client?.VATNumber,
    };
    if (data) {
      saveToStorage('footer', data);
    }
  };

  const onCreateRemedialReport = async (job: any) => {
    await onSaveHeader(job.client);
    await onSaveCertificate(job);
    await onSaveRemedialSummaryData(getRemedialReportSummary(job));
    await onSaveActionLog(getRemedialReportActionLog(job));
    // await onSaveCompetency(job);
    await onSaveRemedialFooterData(job);

    setTimeout(async () => {
      const headerData = await getFromStorage('header');
      const certificateData = await getFromStorage('certificate');
      const summaryData = await getFromStorage('remedialSummary');
      const actionLogData = await getFromStorage('actionLog');
      const competencyData = await getFromStorage('competency');
      const footerData = await getFromStorage('footer');

      const [header, certificate, summary, actionLog, competency, footer] = await Promise.all([
        onPublishHeaderRemedial(headerData),
        onSaveRemedialCertificate(certificateData),
        onSaveRemedialSummary(summaryData),
        onSaveRemedialActionLog(actionLogData),
        onSaveRemedialCompetency(competencyData),
        onSaveRemedialFooter(footerData),
      ]);

      const reportData = {
        remedialActionReportName: 'Fire Damper Remedial Action Report',
        headerDataReportsRemedial: header._id,
        certificateReportsRemedial: certificate._id,
        remedialActionLogs: actionLog._id,
        footerDataReportsRemedial: footer._id,
        remedialCompetencies: competency._id,
        remedialActionSummary: summary._id,
      };

      const response = await createRemedialInspectionReport(reportData);

      if (response.status === 'Success') {
        const data: any = {
          inspectionJob: job._id,
          client: job.client._id,
          clientRepresentative: job.clientRepresentative._id,
          facilityManager: job.facilityManager._id,
          building: job.building._id,
          dateRenewalDate: getNextTestDate(job.building.testDate, job.building.testRegime) + '',
          attachment: [],
          archived: false,
          remedialInspectionReports: response.data._id,
        };
        // if (job.jobType === 'AFDS') {
        //   data.inspectionReport = response.data._id
        // }
        // else if (job.jobType === 'Remedial') {
        //   data.remedialInspectionReports = response.data._id
        // }

        const message = await onSaveReportToLibrary(data);

        if (message === 'success') {
          success(`Report created successfully`);
          removeEveryKey();
        } else if (message === 'error') {
          error(`Could not create report.`);
        }
      }
    }, 1000);
  };

  const permissionData = useSelector((state: any) => state.auth);
  let moduldePermision = permissionData?.user?.userPersonalPermissions;
  let permsissions: any = GetPermissionsByName('Building Register', moduldePermision);

  // ================================REPORT GENERATION PROCESS================================

  const buildingsColumn = [
    {
      field: 'buildingReference',
      header: 'Referennce',
      sortable: true,
      style: { minWidth: '150px' },
    },
    {
      field: 'buildingName',
      header: 'Building',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'buildingAddress',
      header: 'Address',
      sortable: true,
      style: { minWidth: '250px' },
    },
    {
      field: 'clientName',
      header: 'Profile',
      sortable: true,
      style: { minWidth: '250px' },
    },
    {
      field: 'facilityManagerName',
      header: 'Facility Manager',
      sortable: true,
      style: { minWidth: '250px' },
    },
    {
      field: 'clientRepresentativeName',
      header: 'Representative',
      sortable: true,
      style: { minWidth: '200px' },
    },
    {
      field: 'testRegime',
      header: 'Test Regime',
      sortable: true,
      style: { minWidth: '120px' },
    },
  ];

  const sortBy = (event: any) => {
    const { value } = event.target;

    if (sortOrder === 1) {
      setSortOrder(-1);
    } else {
      setSortOrder(1);
    }
    setChanged(true);
  };

  useEffect(() => {
    if (isChanged) {
      getAllData();
    }
  }, [sortOrder, sortProperty]);

  const initialValues: CreateBuildingProps = {
    _id: undefined,
    vaultId: '',
    buildingReference: '',
    buildingName: '',
    buildingAddress: '',
    client: '',
    facilityManager: '',
    clientRepresentative: '',
    // testDate: "",
    // testStatus: "",
    testRegime: '',
    buildingLocation: {
      type: 'Point',
      coordinates: [0, 0],
    },
    buildingImage: ''
  };

  const validationSchema = Yup.object().shape({
    // vaultId: Yup.string().required('Vault is required'),
    buildingReference: Yup.string().required('Referenceis required'),
    buildingName: Yup.string().required('Building Name is required'),
    buildingAddress: Yup.string().required('Building Address is required'),
    client: Yup.string().required('Client is required'),
    facilityManager: Yup.string().required('Facility Manager is required'),
    clientRepresentative: Yup.string().required(' Representative is required'),
    // testDate: Yup.string().required('Test Date is required'),
    // testStatus: Yup.string().required('Test Status is required'),
    testRegime: Yup.string().required('Test Regime is required'),
    // buildingLocation: Yup.object().shape({
    //   coordinates: Yup.array().of(Yup.number()).required('Building Location Coordinates are required')
    // })
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: CreateBuildingProps, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      setLoading(true);

      if (buildingImage !== null) {
        const formData = new FormData();
        formData.append('foldername', 'building');
        formData.append('file', buildingImage);

        const res = await uploadBuildingImage(formData);

        if (res) {
          values.buildingImage = res.data;
        }

        const bodyData: any = {
          ...values,
          vaultId: vaultData?.valutInfo?._id || vaults?._id,
          testStatus: 'scheduled',
          buildingLocation: {
            type: 'Point',
            coordinates: [
              // location.lat, location.lng
              0, 0,
            ],
          },
        };
        let response = null;
        if (isEditing) {
          response = await updateBuilding(bodyData);
        } else response = await createBuilding(bodyData);
        if (response.status === `Success`) {
          success(response.message);
          setSubmitting(false);
          formik.resetForm();
          // getBuildingListing();
          appendUpdatedData(response.data);
          setEditing(false);
          const data = await getBuildingListing();
          setBuildingData(data);
          setLoading(false);
          onHideBuildingModal();
        } else {
          setStatus(response.message);
          setLoading(false);
          setSubmitting(false);
          error(response.message);
        }
      }
    }
  });

  const appendUpdatedData = (building: any) => {
    const updatedData = [...buildingData];
    buildingData.find((b: any) => b._id === building._id);
    const index = updatedData.findIndex((b: any) => b._id === building._id);
    if (index !== -1) {
      updatedData[index] = building;
    }
    setBuildingData(updatedData);
  };

  Geocode.setApiKey(LOCATION.apiKey);

  const onEditBuilding = async (id: string) => {
    const building = buildingData.find((x: any) => x._id === id);

    setEditing(true);
    setLoading(true);
    if (building) {
      setBuildingImage(building?.buildingImage);
      formik.resetForm();
      setLocation({
        lat: building.buildingLocation.coordinates[1],
        lng: building.buildingLocation.coordinates[0],
        address: building.buildingAddress,
      });
      // const address: string = await getAddressFromLatLng(building.buildingLocation.coordinates[0], building.buildingLocation.coordinates[1]);

      formik.setValues({
        buildingid: building?._id,
        buildingAddress: building?.buildingAddress || '',
        vaultId: building?.vaultId,
        buildingReference: building?.buildingReference,
        buildingName: building?.buildingName,
        client: String(building?.client?._id),
        facilityManager: building?.facilityManager?._id,
        clientRepresentative: building?.clientRepresentative?._id,
        // testDate: moment(building?.testDate).format('YYYY-MM-DD HH:mm'),
        testRegime: building?.testRegime,
        buildingLocation: {
          type: 'Point',
          coordinates: [
            building.buildingLocation.coordinates[0],
            building.buildingLocation.coordinates[1],
          ],
        },
      });
    }
    setLoading(false);
    onShowBuildingModal();
  };

  useEffect(() => {
    if (isChanged) {
      getBuildingListing(true);
    }
  }, [pageNumber, pageSize, sortField, sortOrder]);

  const onPageNumberChange = (e: any) => {
    setChanged(true);
    let newPageNumber = e.page + 1;

    if (pageNumber !== newPageNumber || pageSize !== e.rows) {
      setFirstRow(e.first);
      setPageNumber(newPageNumber);
      setPageSize(e.rows);
      setTableLoading(true);
    }
  };

  useEffect(() => {
    if (isChanged) {
      const timeoutId = setTimeout(() => {
        setTableLoading(true);
        getBuildingListing(true);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [filterValue]);

  const onGlobalFilterChange = (e: any) => {
    setChanged(true);
    setFilterValue(e.target.value.trim());
  };

  const onSortChange = (e: any) => {
    setChanged(true);
    setSortOrder(e.sortOrder);
    setSortField(e.sortField);
    setTableLoading(true);
  };

  const onArchiveShow = (id: string) => {
    setDialogueBox({ flag: true, record: id });
  };

  const clearFilter = () => {
    setFilterValue('');
  };

  return (
    <LoaderComman isRender={!isLoading}>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card card-custom'>
            <div className='card-body pt-0 pb-3'>
              <BUildingsDataTable
                dataKey='_id'
                data={buildingData}
                columns={buildingsColumn}
                isLoading={isTableLoading}
                isShowPaginator
                isShowPagSize
                rowCount={pageSize}
                totalRecords={totalRecords}
                firstRow={firstRow}
                onPageChange={onPageNumberChange}
                onGlobalFilterChange={onGlobalFilterChange}
                filterValue={filterValue}
                onSort={onSortChange}
                sortOrder={sortOrder}
                sortField={sortField}
                isShowActionColumns
                onEdit={onEditBuilding}
                onArchive={onArchiveShow}
                clearFilterValue={clearFilter}
              />
            </div>
          </div>
        </div>

        <Dialog draggable={false} position='top' header={`${isEditing ? 'Edit' : 'Add'} Building`} visible={showBuildingModal || buildingModalVisible} onHide={onHideBuildingModal} style={{ width: '35vw' }}>
          <CreateBuilding
            isEditing={isEditing}
            formik={formik}
            setEditing={setEditing}
            clients={clients}
            facilityManagers={facilityManagers}
            clientRepresentatives={clientRepresentatives}
            testRegimeList={testRegimeList}
            onHide={onHideBuildingModal}
            buildingImage={buildingImage}
            setBuildingImage={setBuildingImage}
          />
        </Dialog>

        <DialogueBox
          title={'Building Archive'}
          message={'Do you want  to archive?'}
          state={dialogueBox.flag}
          onClick={() => onArchive(dialogueBox.record)}
          handleClose={() => setDialogueBox({ flag: false, record: {} })}
        />
      </div>
    </LoaderComman>
  );
};

export default FMRegister;

function CreateBuilding({
  isEditing,
  formik,
  setEditing,
  clients,
  facilityManagers,
  clientRepresentatives,
  testRegimeList,
  onHide,
  buildingImage,
  setBuildingImage
}: {
  isEditing: boolean;
  formik: any;
  setEditing: (val: boolean) => void;
  clients: ClientProps[];
  facilityManagers: FacilityManagerProps[];
  clientRepresentatives: ClientRepresentativesProps[];
  testRegimeList: TestRegimeProps[];
  onHide: () => void;
  buildingImage: string;
  setBuildingImage: any;
}) {

  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [representatives, setRepresentatives] = React.useState<any[]>([]);

  const ProfilePicWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

  const ProfilePic = styled.img`
  max-width: 250px;
  max-height: 250px;
  border: 1px solid #ccc;
`;

  return (
    <div className='col-lg-12'>
      <form onSubmit={formik.handleSubmit}>
        <div className='card card-custom'>
          <div className=''>
            <div className='row'>
              <div className='col-lg-7'>
                <div className='form-group'>
                  <label className='required pb-2' htmlFor=''>
                    Building Reference
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('buildingReference')}
                    disabled
                  />
                  {formik.touched.buildingReference && formik.errors.buildingReference ? (
                    <div className='text-danger'>{formik.errors.buildingReference}</div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label className='required pb-2' htmlFor=''>
                    Building Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('buildingName')}
                  />
                  {formik.touched.buildingName && formik.errors.buildingName ? (
                    <div className='text-danger'>{formik.errors.buildingName}</div>
                  ) : null}
                </div>
              </div>
              <div className='col-lg-5'>
                <label className='pb-2'>
                  Choose Building Image
                </label>
                <input
                  id='file'
                  name='file'
                  accept='image/*'
                  type='file'
                  onChange={(event) => {
                    setBuildingImage(event.target.files && event.target.files[0]);
                  }}
                  className='form-control'
                />
                {buildingImage ? (
                  <div className='avatar-preview mt-6'>
                    <ProfilePicWrapper>
                      <ProfilePic
                        src={
                          buildingImage && typeof buildingImage !== 'string'
                            ? URL.createObjectURL(buildingImage)
                            : process.env.REACT_APP_IMAGE_URL + buildingImage
                        }
                        alt='Building image'
                        className="img-fluid"
                        style={{ cursor: 'pointer' }}
                      />
                    </ProfilePicWrapper>
                  </div>
                ) : (
                  <ProfilePicWrapper>
                    <ProfilePic src={placeholderimage} alt='' className='mt-4' style={{ height: '150px', width: '150px' }} />
                  </ProfilePicWrapper>
                )}
              </div>
            </div>
            <div className='form-group'>
              <label className='required pb-2' htmlFor=''>
                Building Address
              </label>
              {/* <ReactGoogleAutocomplete
                  placeholder="Building Location"
                  className='form-control form-control-lg form-control-solid'
                  apiKey={LOCATION.apiKey}
                  defaultValue={location.address}
                  onPlaceSelected={(place: any) => {
                    onChangeLocation(place)
                  }}
                  required={true}
                /> */}
              <textarea
                rows={3}
                className='form-control'
                {...formik.getFieldProps('buildingAddress')}
                // onChange={e => {
                // formik.setFieldValue('buildingLocation', e.target.value);
                // }}
                // {...formik.getFieldProps('buildingLocation')}
                placeholder=''
              ></textarea>
              {formik.touched.buildingAddress && formik.errors.buildingAddress ? (
                <div className='text-danger'>{formik.errors.buildingAddress}</div>
              ) : null}
            </div>
            {/* <div className="form-group">
                <label className="pb-2" htmlFor="">Building Address</label>
                <textarea className="form-control" rows={5} defaultValue={""}
                  disabled={true}
                  {...formik.getFieldProps('buildingAddress')}
                />
                {
                  formik.touched.buildingAddress && formik.errors.buildingAddress ? (
                    <div className="text-danger">{formik.errors.buildingAddress}</div>
                  ) : null
                }
              </div> */}
            <div className='form-group'>
              <label className='required pb-2' htmlFor=''>
                Profile
              </label>
              {/* <DropDown
                  // value={clients.filter((client: ClientProps) => String(client._id) === formik.values.client)}
                  options={clients}
                  handleChange={(value: any) => {
                    formik.setFieldValue('client', value)
                  }}
                  {...formik.getFieldProps('client')}
                  multi={false}
                  // selected={formik.values.client}
                  label={(option: ClientProps) => option.clientName}
                  _value={(option: ClientProps) => String(option._id)}

                /> */}
              <Select
                placeholder='Profile'
                options={clients}
                isClearable
                getOptionLabel={(option: ClientProps) => option.clientName}
                getOptionValue={(option: ClientProps) => String(option._id)}
                onChange={(option: any) => {
                  let data = clientRepresentatives.filter(x =>
                    option.clientRepresentatives.some((y: any) => y === x._id)
                  );
                  setRepresentatives(data);
                  formik.setFieldValue('client', option?._id);
                }}
                defaultValue={clients.filter(
                  (client: ClientProps) => String(client._id) === formik.values.client
                )}
              />
              {formik.touched.client && formik.errors.client ? (
                <div className='text-danger'>{formik.errors.client}</div>
              ) : null}
            </div>
            <div className='form-group'>
              <label className='required pb-2' htmlFor=''>
                Facility Manager
              </label>
              <Select
                placeholder='Facility Manager'
                options={facilityManagers}
                isClearable
                getOptionLabel={(option: FacilityManagerProps) => option.facilityManagerName}
                getOptionValue={(option: FacilityManagerProps) => String(option._id)}
                onChange={(option: any) => {
                  formik.setFieldValue('facilityManager', option?._id);
                }}
                defaultValue={facilityManagers.filter((fm: FacilityManagerProps) => {
                  return String(fm._id) === formik.values.facilityManager;
                })}
              />
              {formik.touched.facilityManager && formik.errors.facilityManager ? (
                <div className='text-danger'>{formik.errors.facilityManager}</div>
              ) : null}
            </div>
            <div className='form-group'>
              <label className='required pb-2' htmlFor=''>
                Representative
              </label>
              <Select
                placeholder=' Representative'
                options={representatives}
                isClearable
                getOptionLabel={(option: ClientRepresentativesProps) =>
                  option.clientRepresentativeName
                }
                getOptionValue={(option: ClientRepresentativesProps) => String(option._id)}
                onChange={(option: any) => {
                  formik.setFieldValue('clientRepresentative', option?._id);
                }}
                defaultValue={clientRepresentatives.filter((cp: ClientRepresentativesProps) => {
                  return String(cp._id) === formik.values?.clientRepresentative;
                })}
              />
              {formik.touched.clientRepresentative && formik.errors.clientRepresentative ? (
                <div className='text-danger'>{formik.errors.clientRepresentative}</div>
              ) : null}
            </div>
            {/* <div className="form-group">
              <label className="pb-2" htmlFor="">Test Date</label>
              <Calendar
                showIcon
                className='w-full'
                {...formik.getFieldProps('testDate')}
                style={{zIndex: 1}}
              />
              {
                formik.touched.testDate && formik.errors.testDate ? (
                  <div className="text-danger">{formik.errors.testDate}</div>
                ) : null
              }
            </div> */}
            <div className='form-group'>
              <label className='required pb-2' htmlFor='testRegime'>
                Test Regime
              </label>
              <Select
                placeholder='Test Regime'
                options={testRegimeList}
                isClearable
                getOptionLabel={(option: TestRegimeProps) => option.label}
                getOptionValue={(option: TestRegimeProps) => String(option.value)}
                onChange={(option: any) => {
                  formik.setFieldValue('testRegime', option?.value);
                }}
                defaultValue={testRegimeList.filter((job: TestRegimeProps) => {
                  return job.value === formik.values?.testRegime;
                })}
              />
              {formik.touched.testRegime && formik.errors.testRegime ? (
                <div className='text-danger'>{formik.errors.testRegime}</div>
              ) : null}
            </div>
          </div>
          <div className='d-flex justify-content-end align-items-end'>
            <RoleBaseAccess moduleName='Buildings Register' isSubmit>
              <button className='btn btn-primary' type='submit' disabled={formik.isSubmitting}>
                {!formik.isSubmitting && (
                  <span className='indicator-label'>{isEditing ? 'Update' : 'Register'}</span>
                )}
                {formik.isSubmitting && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </RoleBaseAccess>
            <button
              className='btn btn-dark ms-4'
              type='reset'
              onClick={() => {
                setEditing(false);
                formik.resetForm();
                onHide();
              }}
            >
              <span className='indicator-label'>Cancel</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
