import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function RoleBasedRouting() {

    const userData = JSON.parse(localStorage.getItem('user'))?.data;

    const [showDialog, setShowDialog] = React.useState(false);

    const { pathname } = useLocation();

    const history = useHistory();

    const renderData = () => {
        if (pathname === '/' || pathname === '/dashboard') {
            return true;
        }

        const moduleName = pathname.includes('/administration/jobs') ? 'job' : pathname.includes('reports') ? 'report' : pathname.split('/')[1]

        if (moduleName) {
            let permission = userData?.operativePermissions?.find(x => x?.moduleId?.name.toLowerCase().includes(moduleName));

            if (!permission?.edit && (pathname.includes('edit') || pathname.includes('add'))) {
                setShowDialog(true);
            }
            else if (permission?.view) {
                return setShowDialog(false);
            }
            else {
                return setShowDialog(true);
            }
        }
    }

    React.useEffect(() => {
        renderData();
    }, []);

    React.useEffect(() => {
        renderData();
    }, [pathname])

    return (
        <>
            <Dialog header="Access Denied" visible={showDialog} onHide={() => {
                setShowDialog(false);
                history.push('/')
            }} style={{ width: '30vw' }}
            >
                <div className='d-flex'>
                    <i className='pi h3 pi-exclamation-triangle me-3'></i> You don't have access to this module.
                </div>
                <div className='d-flex justify-content-center mt-7'>
                    <button type='button' className='btn btn-primary' onClick={() => {
                        setShowDialog(false);
                        history.push('/')
                    }}>
                        Ok
                    </button>
                </div>
            </Dialog>
        </>
    );
}
export default RoleBasedRouting
