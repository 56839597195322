import { axiosInstance } from 'app/App';
import { API_URL } from 'app/modules/auth/redux/jobs/JobsCRUD';
import { toast } from 'react-toastify';

// Read Regulation section
export const GetRegulation = async () => {
  try {
    const res = await axiosInstance.post(`${API_URL}/mobileGuidanceText/getMobileGuidanceText`);
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Regulation section
export const UpdateRegulation = async (data: any) => {
  try {
    const res = await axiosInstance.post(
      `${API_URL}/mobileGuidanceText/updateMobileGuidanceText`,
      data
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

export const CreateRegulation = async (data: any) => {
  try {
    const res = await axiosInstance.post(
      `${API_URL}/mobileGuidanceText/createMobileGuidanceText`,
      data
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

///////--------- Damper Classification Api's ////////////

// Read Damper section
export const GetDamper = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/damperClassifications/getDamperClassificationsWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Damper section
export const UpdateDamper = async (data: any, parentId: string) => {
  try {
    let payload = {
      damperClassificationid: parentId,
      dampClassSection: data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/damperClassifications/updateDamperClassifications`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

////////////--------- Installation  Api's ----------///////
// Read Installation section
export const GetInstallation = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/installationGuidances/getInstallationGuidancesWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Installation section
export const UpdateInstallation = async (data: any, parentId: string) => {
  try {
    let payload = {
      installationGuidanceid: parentId,
      instSubSection: data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/installationGuidances/updateInstallationGuidance`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

///////// --------- FireBarrier  Api's ------ ///////////

// Read FireBarrier section
export const GetFireBarrier = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/firebarrierGuidances/getFirebarrierGuidancesWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update FireBarrier section
export const UpdateFireBarrier = async (data: any, parentId: string) => {
  try {
    let payload = {
      firebarrierGuidanceid: parentId,
      frbrSubSection: data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/firebarrierGuidances/updateFirebarrierGuidance`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

////////////// --------- Accessibility  Api's -----/////////

// Read Accessibility section
export const GetAccessibility = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/accessibilityGuidances/getAccessibilityGuidancesWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Accessibility section
export const UpdateAccessibility = async (data: any, parentId: string) => {
  try {
    let payload = {
      accessibilityGuidanceid: parentId,
      acblSubSection: data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/accessibilityGuidances/updateAccessibilityGuidance`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

////////////// --------- AccessPanel  Api's -----/////////

// Read Accessibility section
export const GetAccessPanel = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/accessPanelGuidances/getAccessPanelGuidancesWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Accessibility section
export const UpdateAccessPanel = async (data: any, parentId: string) => {
  try {
    let payload = {
      accessPanelGuidanceid: parentId,
      accPnlSubSection: data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/accessPanelGuidances/updateAccessPanelGuidance`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

/////////// --------- Inspection  Api's ---------------- /////////////
// Read Inspection section
export const GetInspection = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/inspTestingGuidances/getInspTestingGuidancesWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Inspection section
export const UpdateInspection = async (data: any, parentId: string) => {
  try {
    let payload = {
      inspTestingGuidanceid: parentId,
      insTstSubSection: data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/inspTestingGuidances/updateInspTestingGuidance`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

//// ----------------------  Setting Prifix api's --------------- ////////

// Guidance Api

// Read Guidance section
export const GetGuidance = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/guidanceReportPrefixs/getGuidanceReportPrefixsWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Guidance section
export const UpdateGuidance = async (data: any, parentId: string) => {
  try {
    let payload = {
      guidanceReportPrefixid: parentId,
      ...data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/guidanceReportPrefixs/updateGuidanceReportPrefix`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Service Api's

// Read Service section
export const GetService = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/serviceReportPrefixs/getServiceReportPrefixsWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Service section
export const UpdateService = async (data: any, parentId: string) => {
  try {
    let payload = {
      serviceReportPrefixid: parentId,
      ...data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/serviceReportPrefixs/updateServiceReportPrefix`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Disclaimer Api's

// Read Disclaimer section
export const GetDisclaimer = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/disclaimersReportPrefixs/getDisclaimersReportPrefixsWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Update Inspection section
export const UpdateDesclaimer = async (data: any, parentId: string) => {
  try {
    let payload = {
      disclaimersReportPrefixid: parentId,
      ...data,
    };
    const res = await axiosInstance.post(
      `${API_URL}/disclaimersReportPrefixs/updateDisclaimersReportPrefix`,
      payload
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// ---------------- User Management Ap's ------------------------------------////
// Get all operatives

export const GetOperativeTypes = async () => {
  try {
    let paylaod = {
      "sortproperty": "createdAt",
      "sortorder": -1,
      "offset": 0,
      "limit": 10,
      "pageNum": 1,
      "search": "",
      "query": {
        "critarion": {
          "active": true
        }
      }
    };
    const res = await axiosInstance.post(
      `${API_URL}/operativeTypes/getOperativeTypesList`,
      paylaod
    );
    return res.data?.data;
  } catch (err) {
    toast.error(err);
  }
};

export const GetUserPermissions = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(
      `${API_URL}/userPermissionsGroups/getUserPermissionsGroupsWithFullDetails`,
      paylaod
    );
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

export const GetOperatives = async () => {
  try {
    let paylaod = {
      sortproperty: 'createdAt',
      sortorder: -1,
      offset: 0,
      limit: 50,
      query: {
        critarion: { active: true, role: 'operative', archive: false },
        addedby: '_id email first_name',
        lastModifiedBy: '_id email first_name',
      },
    };
    const res = await axiosInstance.post(`${API_URL}/users/listAllUsers`, paylaod);
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Change operative Position
export const ChangeOperatiePosition = async (data: any) => {
  try {
    let paylaod = {
      operativeid: data?._id,
      user: data?.modelId?.user,
      operativeType: 'administrativeOperative',
      operativePosition: 'Admin Operative',
    };
    const res = await axiosInstance.post(`${API_URL}/operatives/changeOperativePosition`, paylaod);
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};

// Archive operative
export const ArchiveOperative = async (data: any) => {
  try {
    let paylaod = {
      id: data,
    };
    const res = await axiosInstance.post(`${API_URL}/users/archiveOperative`, paylaod);
    return res.data;
  } catch (err) {
    toast.error(err);
  }
};
